// React-Redux
import React from 'react';

import PropTypes from 'prop-types';
// MUI
import { makeStyles } from '@material-ui/styles';
import { Box, Typography } from '@material-ui/core';
import ListElement from 'components/ListElement/ListElement';

const useStyles = makeStyles((theme) => ({
  root: {}
}));

export default function ResourceList(props) {
  const classes = useStyles();

  return (
    <Box>
      {props.allSectionsList.map((section) => {
        return (
          <>
            <Typography variant="h4">{section.section}</Typography>
            {section.contents.map((objectElement) => (
              <ListElement
                mainText={objectElement.key}
                secondaryText={props.listElementSecondaryText}
                link={objectElement.url}
                key={objectElement.url}
                listAvatar={props.icon}
              />
            ))}
          </>
        );
      })}
    </Box>
  );
}

ResourceList.propTypes = {
  allSectionsList: PropTypes.any,
  icon: PropTypes.any,
  listElementSecondaryText: PropTypes.any
};
