import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Card, Grid, Typography, makeStyles } from '@material-ui/core';
import { Label } from '../';

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    padding: theme.spacing(3),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  overline: {
    marginTop: theme.spacing(1)
  }
}));

function Statistics4Blocks(props) {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, props.className)} elevation={6}>
      <Grid alignItems="center" container justify="space-between">
        {/* First Block */}
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography component="span" variant="h2" color="textPrimary">
              {props.block1Value}
            </Typography>
            {props.block1SideLabel && (
              <Label className={classes.label} color="primary">
                {props.block1SideLabel}
              </Label>
            )}
          </Box>
          <Typography
            className={classes.overline}
            variant="overline"
            color="textSecondary">
            {props.block1BottomLabel}
          </Typography>
        </Grid>
        {/* Second Block */}
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography component="span" variant="h2" color="textPrimary">
              {props.block2Value}
            </Typography>
            {props.block2SideLabel && (
              <Label className={classes.label} color="primary">
                {props.block2SideLabel}
              </Label>
            )}
          </Box>
          <Typography
            className={classes.overline}
            variant="overline"
            color="textSecondary">
            {props.block2BottomLabel}
          </Typography>
        </Grid>
        {/* Third Block */}
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography component="span" variant="h2" color="textPrimary">
              {props.block3Value}
            </Typography>
            {props.block3SideLabel && (
              <Label className={classes.label} color="primary">
                {props.block3SideLabel}
              </Label>
            )}
          </Box>
          <Typography
            className={classes.overline}
            variant="overline"
            color="textSecondary">
            {props.block3BottomLabel}
          </Typography>
        </Grid>
        {/* Fourth Block */}
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography component="span" variant="h2" color="textPrimary">
              {props.block4Value}
            </Typography>
            {props.block4SideLabel && (
              <Label className={classes.label} color="primary">
                {props.block4SideLabel}
              </Label>
            )}
          </Box>
          <Typography
            className={classes.overline}
            variant="overline"
            color="textSecondary">
            {props.block4BottomLabel}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}

Statistics4Blocks.propTypes = {
  className: PropTypes.string,
  // block 1
  block1Value: PropTypes.any,
  block1SideLabel: PropTypes.any,
  block1BottomLabel: PropTypes.any,
  // block 2
  block2Value: PropTypes.any,
  block2SideLabel: PropTypes.any,
  block2BottomLabel: PropTypes.any,
  // block 3
  block3Value: PropTypes.any,
  block3SideLabel: PropTypes.any,
  block3BottomLabel: PropTypes.any,
  // block 4
  block4Value: PropTypes.any,
  block4SideLabel: PropTypes.any,
  block4BottomLabel: PropTypes.any
};

export default Statistics4Blocks;
