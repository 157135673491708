import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Box, Grid, Typography, makeStyles, Grow } from '@material-ui/core';

import { BuyMembershipButtonLink, MiniVideoPlayer } from 'components';
import Can from 'rbac/Can';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    // background: 'url(/images/wave3.svg)',
    // backgroundRepeat: 'no-repeat'
  },
  action: {
    backgroundColor: theme.palette.common.white
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  },
  image: {
    width: '100%',
    maxHeight: 400
  },
  upDown: {
    animation: 'up-down 2s ease-in-out infinite alternate-reverse both'
  },

  svg: {
    position: 'absolute',
    zIndex: '0'
  }
}));

function ImageHeader(props) {
  const classes = useStyles();

  const USER = useSelector((state) => state.user.user);

  return (
    <Grow in={true} timeout={1000}>
      <div className={clsx(classes.root, props.className)}>
        <Grid alignItems="center" container justify="space-between" spacing={3}>
          {!props.imageRight && (
            <Grid item md={6} xs={12}>
              {props.isVideo ? (
                <MiniVideoPlayer src={props.videoUrl} />
              ) : (
                <img
                  alt="Cover"
                  className={clsx(
                    classes.image,
                    props.isUpDownAnimation && classes.upDown
                  )}
                  src={props.imageUrl}
                />
              )}
            </Grid>
          )}

          <Grid item md={6} xs={12}>
            <Typography variant="overline" color="secondary">
              {props.smallTopLabel}
            </Typography>
            <Typography variant="h2" color="textPrimary">
              {props.heading}
            </Typography>
            <Typography variant="subtitle1" color="textPrimary">
              {props.subtitle}
            </Typography>
            <Box mt={3}>
              <Grid container spacing={3}>
                {props.miniStatsArray &&
                  props.miniStatsArray.map((stats) => (
                    <Grid item>
                      <Typography variant="h1" color="secondary">
                        {stats.value}
                      </Typography>
                      <Typography variant="overline" color="textSecondary">
                        {stats.label}
                      </Typography>
                    </Grid>
                  ))}
              </Grid>
            </Box>
            {props.price && (
              <Box mt={1}>
                <Typography variant="h3" color="textSecondary">
                  {props.price}
                </Typography>
                <Typography variant="overline" color="textSecondary">
                  Price
                </Typography>
              </Box>
            )}

            <Box mt={2}>
              {props.isBuyMembershipLink && (
                <Can
                  role={USER.user_type}
                  perform="paid_content:view"
                  no={() => <BuyMembershipButtonLink />}
                  yes={() => <Alert severity="success">You're a member</Alert>}
                />
              )}
            </Box>
            <Box mt={2}>
              {props.customComponent}
              {/* <Button className={classes.action} variant="contained">
              <CreditCardIcon className={classes.actionIcon} />
              Buy Membership
            </Button> */}
            </Box>
          </Grid>
          {props.imageRight && (
            <Grid item md={6} xs={12}>
              <img
                alt="Cover"
                className={clsx(
                  classes.image,
                  props.isUpDownAnimation && classes.upDown
                )}
                src={props.imageUrl}
              />
            </Grid>
          )}
        </Grid>
      </div>
    </Grow>
  );
}

ImageHeader.propTypes = {
  isVideo: PropTypes.any,
  videoUrl: PropTypes.any,
  className: PropTypes.any,
  smallTopLabel: PropTypes.any,
  heading: PropTypes.any,
  subtitle: PropTypes.any,
  imageUrl: PropTypes.any,
  price: PropTypes.any,
  imageRight: PropTypes.any, // default image is on the left and text on the right
  customComponent: PropTypes.any,
  miniStatsArray: PropTypes.any,
  isUpDownAnimation: PropTypes.any,
  isBuyMembershipLink: PropTypes.any
};

// export default ImageHeader;
export default React.memo(ImageHeader);
