import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setNewToken as a_setNewToken } from 'redux/actions/userActions';
import { useLocation } from 'react-router';
const queryString = require('query-string');

function RefreshToken() {
  const location = useLocation();
  let dispatch = useDispatch();

  useEffect(() => {
    // console.log(location);
    const parsed = queryString.parse(location.search);
    const refresh = parsed.refresh;
    if (refresh === 'true') {
      console.log(`refreshing payment`);
      dispatch(a_setNewToken());
      //   dispatch(setSnackbar('Payment Verified', 'success'));
    }
  }, [location.pathname]);

  return null;
}

export default RefreshToken;
