import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 80,
    paddingBottom: 80
  },
  title: {
    fontWeight: theme.typography.fontWeightRegular
  }
}));

function Testimonials(props) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root)}>
      <Container maxWidth="md">
        <Typography
          variant="h2"
          align="center"
          color="textPrimary"
          className={classes.title}>
          &quot; {props.quote} &quot;
        </Typography>
        <Box mt={6} display="flex" justifyContent="center" alignItems="center">
          <Avatar src="/static/home/olivier.png" />
          <Box ml={2}>
            <Typography variant="body1" color="textPrimary">
              {props.personName}
              <Typography
                color="textSecondary"
                display="inline"
                component="span">
                {props.personDetails}
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Container>
    </div>
  );
}

Testimonials.propTypes = {
  quote: PropTypes.string,
  personName: PropTypes.string,
  personDetails: PropTypes.string
};

export default Testimonials;
