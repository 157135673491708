import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
// import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  }
}));

function getSteps(stepperList) {
  let steps = [];
  for (let element of stepperList) {
    steps.push(element.heading);
  }
  return steps;
}

function getStepContent(step, stepperList) {
  let element = stepperList.find((element) => element.stepIndex == step);
  return element.text;
  //   switch (step) {
  //     case 0:
  //       return `For each ad campaign that you create, you can control how much
  //               you're willing to spend on clicks and conversions, which networks
  //               and geographical locations you want your ads to show on, and more.`;
  //     case 1:
  //       return 'An ad group contains one or more ads which target a shared set of keywords.';
  //     case 2:
  //       return `Try out different ad text to see what brings in the most customers,
  //               and learn how to enhance your ads using features like ad extensions.
  //               If you run into any problems with your ads, find out how to tell if
  //               they're running and how to resolve approval issues.`;
  //     default:
  //       return 'Unknown step';
  //   }
}

export default function VerticalStepper(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps(props.stepperList);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Fade top>
                <Typography>
                  {getStepContent(index, props.stepperList)}
                </Typography>
              </Fade>
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}>
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}>
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} className={classes.button}>
            Reset
          </Button>
        </Paper>
      )}
    </div>
  );
}

VerticalStepper.propTypes = {
  stepperList: PropTypes.any
};
