import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

import OverallReviews from './OverallReviews';
import ReviewCard from './ReviewCard';

import config from 'config/main';
var reviews = config.reviewsData;

const useStyles = makeStyles((theme) => ({
  root: {},
  review: {
    marginTop: theme.spacing(2)
  }
}));

function Reviews(props) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, props.className)}>
      <OverallReviews ratings={reviews.map((review) => review.rating)} />
      {reviews.map((review) => (
        <ReviewCard
          className={classes.review}
          key={review.id}
          review={review}
        />
      ))}
    </div>
  );
}

Reviews.propTypes = {
  className: PropTypes.string
};

export default Reviews;
