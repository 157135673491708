import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import { connect } from 'react-redux';

import {
  Player,
  ControlBar,
  PlaybackRateMenuButton,
  BigPlayButton,
  LoadingSpinner
} from 'video-react';

import 'video-react/dist/video-react.css';

const styles = (theme) => ({
  root: {
    // padding: theme.spacing(3),
    margin: '5px',
    borderRadius: '20px',
    transition: 'transform 0.2s;',
    '&:hover': {
      '-ms-transform': 'scale(1.05);' /* IE 9 */,
      '-webkit-transform': 'scale(1.05);' /* Safari 3-8 */,
      '  transform': 'scale(1.05);'
      // background: theme.palette.primary.light
    }
  },
  media: {
    minHeight: 160,
    minWidth: 300
  },
  videoTitle: {
    margin: '10px'
  }
});

const sources = {
  sintelTrailer: 'http://media.w3.org/2010/05/sintel/trailer.mp4',
  bunnyTrailer: 'http://media.w3.org/2010/05/bunny/trailer.mp4',
  bunnyMovie: 'http://media.w3.org/2010/05/bunny/movie.mp4',
  test: 'http://media.w3.org/2010/05/video/movie_300.webm'
};

class VideoPlayer extends PureComponent {
  constructor(props, context) {
    super(props, context);
  }

  componentDidUpdate(prevProps) {
    if (this.props.src !== prevProps.src) {
      console.log(`VIDEO SOURCE UPDATED`);
      this.player.load();
    }
  }

  render() {
    const { classes } = this.props;
    return (
      //   <Card className={classes.root}>
      //     <div>
      <Player
        ref={(player) => {
          this.player = player;
        }}
        // autoPlay>
        autoPlay>
        <BigPlayButton position="center" />
        <LoadingSpinner />
        {/* <source src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4" /> */}
        <source src={this.props.src} />
        <ControlBar>
          <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
        </ControlBar>
      </Player>

      //   <div>
      //     <Typography
      //       variant="h3"
      //       gutterBottom
      //       align="center"
      //       className={classes.videoTitle}>
      //       How to use audio-learning
      //     </Typography>
      //     <Typography
      //       variant="subtitle1"
      //       gutterBottom
      //       align="center"
      //       className={classes.videoTitle}>
      //       This lets you learn on your own pace...
      //     </Typography>
      //   </div>
      //     </div>
      //   </Card>
    );
  }
}

const mapStateToProps = (state) => ({});

VideoPlayer.propTypes = {
  src: PropTypes.any
};

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles, { withTheme: true })(VideoPlayer));
