import axios from 'axios';

import {
  MEMBERSHIPS_LOADING,
  MEMBERSHIPS_SET,
  MEMBERSHIPS_ERROR,
  // order
  CREATE_ORDER_LOADING,
  CREATE_ORDER_SET,
  CREATE_ORDER_ERROR,
  // verify signature
  VERIFY_PAYMENT_SIGNATURE_LOADING,
  VERIFY_PAYMENT_SIGNATURE_SET,
  VERIFY_PAYMENT_SIGNATURE_ERROR,
  ///////////////////////////////////////////// NEW /////////////////////////////////////////////
  // referred memberships
  REFERRED_MEMBERSHIPS_LOADING,
  REFERRED_MEMBERSHIPS_SET,
  REFERRED_MEMBERSHIPS_ERROR,
  // 7 day referred membership counts
  REFERRED_MEMBERSHIPS_COUNTS_LOADING,
  REFERRED_MEMBERSHIPS_COUNTS_SET,
  REFERRED_MEMBERSHIPS_COUNTS_ERROR,
  // referred memberships
  ALL_MEMBERSHIPS_LOADING,
  ALL_MEMBERSHIPS_SET,
  ALL_MEMBERSHIPS_ERROR,
  // all membership counts
  ALL_MEMBERSHIPS_COUNTS_LOADING,
  ALL_MEMBERSHIPS_COUNTS_SET,
  ALL_MEMBERSHIPS_COUNTS_ERROR,
  // My earning sales
  MY_EARNING_SALES_LOADING,
  MY_EARNING_SALES_SET,
  MY_EARNING_SALES_ERROR,
  // All earning sales
  All_EARNING_SALES_LOADING,
  All_EARNING_SALES_SET,
  All_EARNING_SALES_ERROR,
  // My bank details
  MY_BANK_DETAILS_LOADING,
  MY_BANK_DETAILS_SET,
  MY_BANK_DETAILS_ERROR,
  // Update My bank details
  UPDATE_MY_BANK_DETAILS_LOADING,
  UPDATE_MY_BANK_DETAILS_SET,
  UPDATE_MY_BANK_DETAILS_ERROR,
  // pay the earned money to the user (for referrer)
  PAY_EARNED_MONEY_LOADING,
  PAY_EARNED_MONEY_SET,
  PAY_EARNED_MONEY_ERROR,
  // money_transactions
  MONEY_TRANSACTIONS_LOADING,
  MONEY_TRANSACTIONS_SET,
  MONEY_TRANSACTIONS_ERROR,
  // add_membership
  ADD_MEMBERSHIP_LOADING,
  ADD_MEMBERSHIP_SET,
  ADD_MEMBERSHIP_ERROR,
  // all_stats
  ALL_STATS_LOADING,
  ALL_STATS_SET,
  ALL_STATS_ERROR,
  // get_offer
  GET_OFFER_LOADING,
  GET_OFFER_SET,
  GET_OFFER_ERROR
} from '../types';

import config from 'config/main';

import { requestHandler } from '../requestHandler';

export const getMemberships = () => async (dispatch) => {
  requestHandler({
    dispatch: dispatch,
    requestType: 'GET',
    requestUrl: `/api/membership/get_memberships/`,
    postData: null,
    loadingReducer: MEMBERSHIPS_LOADING,
    successReducer: MEMBERSHIPS_SET,
    errorReducer: MEMBERSHIPS_ERROR,
    successCallback: null,
    errorCallback: null
  });
};

export const createOrder = (orderData, successCallback) => async (dispatch) => {
  requestHandler({
    dispatch: dispatch,
    requestType: 'POST',
    requestUrl: `/api/membership/create_order/`,
    postData: orderData,
    loadingReducer: CREATE_ORDER_LOADING,
    successReducer: CREATE_ORDER_SET,
    errorReducer: CREATE_ORDER_ERROR,
    successCallback: successCallback,
    errorCallback: null
  });
};
export const verifyPaymentSignature = (
  paymentResponseRzp,
  successCallback
) => async (dispatch) => {
  requestHandler({
    dispatch: dispatch,
    requestType: 'POST',
    requestUrl: `/api/membership/verify_signature_and_validate_payment/`,
    postData: paymentResponseRzp,
    loadingReducer: VERIFY_PAYMENT_SIGNATURE_LOADING,
    successReducer: VERIFY_PAYMENT_SIGNATURE_SET,
    errorReducer: VERIFY_PAYMENT_SIGNATURE_ERROR,
    successCallback: successCallback,
    errorCallback: null
  });
};

////////////////////////////////////////////// NEW /////////////////////////////////////

export const getReferredMemberships = () => async (dispatch) => {
  requestHandler({
    dispatch: dispatch,
    requestType: 'GET',
    requestUrl: `/api/membership/get_referred_memberships/`,
    postData: null,
    loadingReducer: REFERRED_MEMBERSHIPS_LOADING,
    successReducer: REFERRED_MEMBERSHIPS_SET,
    errorReducer: REFERRED_MEMBERSHIPS_ERROR,
    successCallback: null,
    errorCallback: null
  });
};
export const getReferredMembershipsCounts = () => async (dispatch) => {
  requestHandler({
    dispatch: dispatch,
    requestType: 'GET',
    requestUrl: `/api/membership/get_7_days_referred_memberships_counts/`,
    postData: null,
    loadingReducer: REFERRED_MEMBERSHIPS_COUNTS_LOADING,
    successReducer: REFERRED_MEMBERSHIPS_COUNTS_SET,
    errorReducer: REFERRED_MEMBERSHIPS_COUNTS_ERROR,
    successCallback: null,
    errorCallback: null
  });
};
export const getAllMemberships = () => async (dispatch) => {
  requestHandler({
    dispatch: dispatch,
    requestType: 'GET',
    requestUrl: `/api/membership/get_all_memberships/`,
    postData: null,
    loadingReducer: ALL_MEMBERSHIPS_LOADING,
    successReducer: ALL_MEMBERSHIPS_SET,
    errorReducer: ALL_MEMBERSHIPS_ERROR,
    successCallback: null,
    errorCallback: null
  });
};
export const getAllMembershipsCounts = () => async (dispatch) => {
  requestHandler({
    dispatch: dispatch,
    requestType: 'GET',
    requestUrl: `/api/membership/get_7_days_all_memberships_counts/`,
    postData: null,
    loadingReducer: ALL_MEMBERSHIPS_COUNTS_LOADING,
    successReducer: ALL_MEMBERSHIPS_COUNTS_SET,
    errorReducer: ALL_MEMBERSHIPS_COUNTS_ERROR,
    successCallback: null,
    errorCallback: null
  });
};
export const getMyEarningSales = () => async (dispatch) => {
  requestHandler({
    dispatch: dispatch,
    requestType: 'GET',
    requestUrl: `/api/membership/get_my_earning_sales/`,
    postData: null,
    loadingReducer: MY_EARNING_SALES_LOADING,
    successReducer: MY_EARNING_SALES_SET,
    errorReducer: MY_EARNING_SALES_ERROR,
    successCallback: null,
    errorCallback: null
  });
};
export const getAllEarningSales = () => async (dispatch) => {
  requestHandler({
    dispatch: dispatch,
    requestType: 'GET',
    requestUrl: `/api/membership/get_all_earning_sales/`,
    postData: null,
    loadingReducer: All_EARNING_SALES_LOADING,
    successReducer: All_EARNING_SALES_SET,
    errorReducer: All_EARNING_SALES_ERROR,
    successCallback: null,
    errorCallback: null
  });
};
export const getMyBankDetails = (successCallback) => async (dispatch) => {
  requestHandler({
    dispatch: dispatch,
    requestType: 'GET',
    requestUrl: `/api/membership/get_update_my_bank_details/`,
    postData: null,
    loadingReducer: MY_BANK_DETAILS_LOADING,
    successReducer: MY_BANK_DETAILS_SET,
    errorReducer: MY_BANK_DETAILS_ERROR,
    successCallback: successCallback,
    errorCallback: null
  });
};
export const updateMyBankDetails = (data, successCallback) => async (
  dispatch
) => {
  requestHandler({
    dispatch: dispatch,
    requestType: 'PATCH',
    requestUrl: `/api/membership/get_update_my_bank_details/`,
    postData: data,
    loadingReducer: UPDATE_MY_BANK_DETAILS_LOADING,
    successReducer: UPDATE_MY_BANK_DETAILS_SET,
    errorReducer: UPDATE_MY_BANK_DETAILS_ERROR,
    successCallback: successCallback,
    errorCallback: null
  });
};
export const payEarnedMoney = (earning_id, successCallback) => async (
  dispatch
) => {
  requestHandler({
    dispatch: dispatch,
    requestType: 'GET',
    requestUrl: `/api/membership/pay_money_earned_user/${earning_id}/`,
    postData: null,
    loadingReducer: PAY_EARNED_MONEY_LOADING,
    successReducer: PAY_EARNED_MONEY_SET,
    errorReducer: PAY_EARNED_MONEY_ERROR,
    successCallback: successCallback,
    errorCallback: null
  });
};

// getMoneyTransactions
export const getMoneyTransactions = () => async (dispatch) => {
  requestHandler({
    dispatch: dispatch,
    requestType: 'GET',
    requestUrl: `/api/membership/get_my_money_transactions/`,
    postData: null,
    loadingReducer: MONEY_TRANSACTIONS_LOADING,
    successReducer: MONEY_TRANSACTIONS_SET,
    errorReducer: MONEY_TRANSACTIONS_ERROR,
    successCallback: null,
    errorCallback: null
  });
};

// AddMembership
export const addMembership = (membership_id, successCallback) => async (
  dispatch
) => {
  requestHandler({
    dispatch: dispatch,
    requestType: 'GET',
    requestUrl: `/api/membership/add_membership_manually/${membership_id}/`,
    postData: null,
    loadingReducer: ADD_MEMBERSHIP_LOADING,
    successReducer: ADD_MEMBERSHIP_SET,
    errorReducer: ADD_MEMBERSHIP_ERROR,
    successCallback: successCallback,
    errorCallback: null
  });
};

// getAllStats
export const getAllStats = () => async (dispatch) => {
  requestHandler({
    dispatch: dispatch,
    requestType: 'GET',
    requestUrl: `/api/membership/get_all_stats/`,
    postData: null,
    loadingReducer: ALL_STATS_LOADING,
    successReducer: ALL_STATS_SET,
    errorReducer: ALL_STATS_ERROR,
    successCallback: null,
    errorCallback: null
  });
};

// getGetOffer
export const getGetOffer = (offer_id) => async (dispatch) => {
  requestHandler({
    dispatch: dispatch,
    requestType: 'GET',
    requestUrl: `/api/payments/get_offer/${offer_id}/`,
    postData: null,
    loadingReducer: GET_OFFER_LOADING,
    successReducer: GET_OFFER_SET,
    errorReducer: GET_OFFER_ERROR,
    successCallback: null,
    errorCallback: null,
    apiBaseUrl: config.general.payments_base_url_api
  });
};
