import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Box, Typography } from '@material-ui/core';
import config from 'config/main';
//
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import { connect } from 'react-redux';
import { PopupContainer } from 'components';
import StockChooserForm from 'views/PatternHistory/StockChooserForm';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60,
    background: theme.palette.primary.main
  },
  signInBtn: {
    margin: '10px'
  },
  name: {
    marginTop: theme.spacing(1)
  }
}));

const Profile = (props) => {
  const { className, user, ...rest } = props;

  const classes = useStyles();

  const userSection = user.is_authenticated ? (
    <>
      <Avatar
        alt="Person"
        className={classes.avatar}
        component={RouterLink}
        src={user.avatar}
        to="/account"
      />
      <Typography className={classes.name} variant="h4">
        {user.user.username}
      </Typography>
      <Typography variant="body2">{user.user.email}</Typography>
    </>
  ) : (
    <>
      <Avatar
        alt="Person"
        className={classes.avatar}
        component={RouterLink}
        src={user.avatar}
        to="/sign-in2"
      />

      <Button
        className={classes.signInBtn}
        color="primary"
        size="small"
        variant="contained"
        component={Link}
        to={'/sign-in2'}>
        Sign In
      </Button>
    </>
  );

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      {userSection}
      <Box mt={1}>
        <PopupContainer
          openerButtonName="Stock View"
          title="select a stock to view in detail"
          buttonColor="secondary"
          isDialogFullWidth={false}
          buttonIcon={<TrendingUpIcon />}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <StockChooserForm
              buttonName="Open Stock"
              callHistoryApi={false}
              redirectToStockLink={true}
              redirectLinkFormat={config.pageData.StockView.route.path}
            />
          </Box>
        </PopupContainer>
      </Box>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

function mapStateToProps(state) {
  return { user: state.user };
}

export default connect(mapStateToProps, {})(Profile);
