import React from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';

import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';

import { Link } from 'react-router-dom';

import { CourseCard, PopupContainer, ListElement } from '../../components';

import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  loader: {
    color: theme.palette.primary.contrastText
  }
}));

const AllCourses = () => {
  const classes = useStyles();

  const s_allCourses = useSelector((state) => state.course.all_courses);
  const s_all_course_module_lessons = useSelector(
    (state) => state.course.all_course_module_lessons
  );

  const getMyCourses = () => {
    const getCourseActionButton = (courseId) => {
      var linkString = `/course-view/${courseId}`;
      var buttonName = 'View Course';

      return (
        <Button
          size="small"
          color="primary"
          variant="contained"
          component={Link}
          to={linkString}>
          {buttonName}
        </Button>
      );
    };

    const getCourseActionButton2 = (courseId) => {
      function getCourseContentsList() {
        let course = s_all_course_module_lessons.value.find(
          (course) => course.id == courseId
        );

        return course.modules.map((module) => (
          <>
            <Typography variant="h4" gutterBottom>
              {module.name}
            </Typography>
            {/* <PerfectScrollbar> */}
            {module.lessons.map((lesson) => (
              <ListElement
                mainText={lesson.name}
                secondaryText={lesson.description}
                // link="#"
                listAvatar={<VideoLibraryIcon />}
                key={lesson.id}
              />
            ))}
            {/* </PerfectScrollbar> */}
          </>
        ));
      }

      return (
        <PopupContainer
          title="Course Contents"
          openerButtonName="view contents">
          <>
            {s_all_course_module_lessons.loading && <LinearProgress />}
            {s_all_course_module_lessons.is_fetched && getCourseContentsList()}
          </>
        </PopupContainer>
      );
    };

    return (
      <>
        {s_allCourses.map((course) => (
          <Grid item lg={4} md={6} sm={6} xl={4} xs={12}>
            <CourseCard
              name={course.name}
              description={course.description}
              thumbnailImage={course.thumbnail_image}
              courseActionButton={getCourseActionButton(course.id)}
              courseActionButton2={getCourseActionButton2(course.id)}
            />
          </Grid>
        ))}
      </>
    );
  };

  let myCourses = getMyCourses();

  return (
    <div className={classes.root}>
      <Grid container spacing={4} justify="space-between" alignItems="stretch">
        {myCourses}
      </Grid>
    </div>
  );
};

export default AllCourses;
