import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Typography, Hidden, Box } from '@material-ui/core';
import config from 'config/main';
import { ThemeChanger } from 'layouts/Main/components';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.topBar.main,
    color: theme.palette.text.primary,
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.divider}`,
    zIndex: theme.zIndex.drawer + 100
  },
  logo_text: {
    color: theme.palette.primary.contrastText,
    marginTop: '17px',
    marginLeft: '10px',
    marginBottom: '13px'
  },

  logo_container: {
    display: 'flex'
  },
  logo_img: {
    marginTop: '12px',
    width: '47px',
    height: '35px'
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const Topbar = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className, 'fancyGradient#')}
      color="primary"
      position="fixed">
      <Toolbar className={classes.toolBar}>
        <RouterLink to="/">
          <div className={classes.logo_container}>
            <img
              alt="Logo"
              src={config.general.logo}
              className={classes.logo_img}
            />
            <Typography gutterBottom variant="h3" className={classes.logo_text}>
              {/* To be hidden in screens smaller than medium */}
              <Hidden mdDown>{config.general.company_name}</Hidden>
              {/* To be hidden in screens larger than large */}
              <Hidden lgUp>{config.general.company_short_name}</Hidden>
            </Typography>
          </div>
        </RouterLink>
        <Box>
          <ThemeChanger />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
