// React-Redux
import React from 'react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// MUI
import { makeStyles } from '@material-ui/styles';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  TextField
} from '@material-ui/core';
import { updateProfileDetails } from '../../redux/actions/userActions';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '10px'
    // margin: theme.spacing(3)
  }
}));

export default function EditCertificateName() {
  const [name, setName] = React.useState(null);
  const [btnDisabled, setBtnDisabled] = useState(true);

  useEffect(() => {}, []);
  const classes = useStyles();
  // assuming the action to get the profile details has been called in any of
  // higher components
  let certName = useSelector((state) => state.user.profile.name_on_certificate);

  let dispatch = useDispatch();

  useEffect(() => {
    setName(certName);
  }, [certName]);

  const handleChange = (event) => {
    setName(event.target.value);
  };

  useEffect(() => {
    // For checking to enable submit button
    if (!name) {
      setBtnDisabled(true);
    } else {
      setBtnDisabled(false);
    }
  }, [name]);

  const saveData = (event) => {
    event.preventDefault();
    const saveObj = {
      name_on_certificate: name
    };
    dispatch(updateProfileDetails(saveObj));
  };

  return (
    <div>
      <Card className={classes.root}>
        <form autoComplete="off" noValidate onSubmit={saveData}>
          <CardHeader
            subheader="The name that should appear on your certificates. 
            The name given here will be used for generating all the certificates"
            title="Name on certificates"
          />
          {/* <Divider /> */}
          <CardContent>
            <TextField
              fullWidth
              // helperText={error && 'Please specify the first name'}
              label="Full name on certificate"
              margin="dense"
              name="name_on_certificate"
              onChange={handleChange}
              required
              //   in the below it's made {name || ''} to avoid some bug of
              //   MUI of not focusing when value is already loaded
              value={name || ''}
              variant="outlined"
              // error={true}
            />
          </CardContent>
          <CardActions>
            <Button
              disabled={btnDisabled}
              color="primary"
              variant="contained"
              onClick={saveData}>
              Save Details (name of the certificate)
            </Button>
          </CardActions>
        </form>
      </Card>
    </div>
  );
}
