import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Flip from 'react-reveal/Flip';
import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    '& dt': {
      marginTop: theme.spacing(2)
    }
  }
}));

function FAQ(props) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root)}>
      <Container maxWidth="lg">
        <Typography variant="h1" color="textPrimary">
          {props.heading}
        </Typography>
        <Box my={3}>
          <Divider />
        </Box>
        <Typography variant="overline" color="secondary">
          {props.subtitle}
        </Typography>
        <Grid container spacing={3} component="dl">
          {props.faqs &&
            props.faqs.map((faq) => (
              <Grid item xs={12} md={6} lg={6}>
                <Flip top>
                  <Box mt={6}>
                    <dd>
                      <Typography variant="h4" color="textPrimary">
                        {faq.question}
                      </Typography>
                    </dd>
                    <dt>
                      <Typography variant="body1" color="textSecondary">
                        {faq.answer}
                      </Typography>
                    </dt>
                  </Box>
                </Flip>
              </Grid>
            ))}
        </Grid>
      </Container>
    </div>
  );
}

FAQ.propTypes = {
  heading: PropTypes.any,
  subtitle: PropTypes.any,
  faqs: PropTypes.any
};

export default FAQ;
