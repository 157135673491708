import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
// import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
// import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    overflowWrap: 'break-all'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.contrastText
  },
  main: {
    background: theme.palette.primary.main,
    borderRadius: '10px',
    '&:hover': {
      background: theme.palette.success.main
    }
  },
  accordionIcon: {
    color: theme.palette.primary.contrastText
  },
  accordion: {
    borderRadius: '10px',
    marginTop: 5
  },
  description: {
    padding: theme.spacing(3)
  }
}));

export default function ExpansionList(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {props.list &&
        props.list.map((element) => (
          <Accordion
            defaultExpanded={props.defaultExpanded}
            className={classes.accordion}>
            <AccordionSummary
              className={classes.main}
              expandIcon={<ExpandMoreIcon className={classes.accordionIcon} />}
              aria-controls="panel1a-content"
              id={element.heading}>
              <Typography className={classes.heading}>
                {element.heading}
              </Typography>
            </AccordionSummary>
            {/* <Divider /> */}
            <Box className={classes.description}>{element.description}</Box>
            {/* <AccordionDetails className={classes.description}>
              {element.description}
            </AccordionDetails> */}
          </Accordion>
        ))}
    </div>
  );
}

ExpansionList.defaultProps = {
  defaultExpanded: true
};

ExpansionList.propTypes = {
  list: PropTypes.any,
  defaultExpanded: PropTypes.any
};
