import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Zoom from 'react-reveal/Zoom';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { useSelector } from 'react-redux';
import Can from 'rbac/Can';
import config from 'config/main';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(1),
    background: theme.palette.success.main
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  buttonPosition: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 70,
    left: 'auto',
    position: 'fixed',
    zIndex: 5
  }
}));

export default function FloatingBuyButton() {
  let USER = useSelector((state) => state.user.user);
  const classes = useStyles();

  return (
    <Can
      role={USER && USER.user_type}
      perform="paid_content:view"
      no={() => (
        <>
          <Zoom top>
            <div className={classes.buttonPosition}>
              <Tooltip title="whatsapp">
                <Fab
                  href={`https://wa.me/${config.general.whatsapp_number}?text=${config.general.whatsapp_preloaded_message}`}
                  target="_blank"
                  // variant="extended"
                  variant="round"
                  size="small"
                  color="primary"
                  aria-label="whatsapp"
                  className={classes.fab}>
                  &nbsp;
                  <WhatsAppIcon className="infiniteZoomInOut" />
                </Fab>
              </Tooltip>
            </div>
          </Zoom>
        </>
      )}
    />
  );
}
