import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Divider,
  makeStyles
} from '@material-ui/core';
import Chart from './Chart';

const useStyles = makeStyles(() => ({
  root: {},
  chart: {
    height: '100%'
  }
}));

function PerformanceOverTime(props) {
  const classes = useStyles();

  console.log(props.data);
  console.log(props.labels);

  return (
    <Card className={clsx(classes.root)}>
      <CardHeader title={props.title} />
      <Divider />
      <CardContent>
        <PerfectScrollbar>
          <Box height={375}>
            {/* <Box height={375} minWidth={500}> */}
            <Chart
              className={classes.chart}
              //   data={[1, 2, 3]}
              //   labels={['ab', 'cd', 'ef']}
              data={props.data}
              dataType={props.dataType}
              labels={props.labels}
            />
          </Box>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
}

PerformanceOverTime.propTypes = {
  title: PropTypes.any,
  data: PropTypes.any,
  dataType: PropTypes.any,
  labels: PropTypes.any
};

export default PerformanceOverTime;
