import React, { Suspense, lazy } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { RouteWithLayout } from './components';
import { LoadingScreen } from './components';
import config from 'config/main';

import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

const AccountView = lazy(() => import('./views/Account'));
const SignUpView = lazy(() => import('./views/SignUp'));
const SignIn2View = lazy(() => import('./views/SignIn2'));
const SignupSuccessView = lazy(() =>
  import('./views/SignupSuccess/SignupSuccess')
);
const ForgotPasswordView = lazy(() =>
  import('./views/ForgotPassword/ForgotPassword')
);
const ChangePasswordView = lazy(() => import('./views/ChangePassword'));
const UserEmailVerificationView = lazy(() =>
  import('./views/UserEmailVerification/UserEmailVerification')
);
const NotFoundView = lazy(() => import('./views/NotFound'));
const MyCoursesView = lazy(() => import('./views/MyCoursesView'));
const AllCoursesView = lazy(() => import('./views/AllCoursesView'));
const CourseDetailView = lazy(() => import('./views/CourseDetailView'));
const CertificateVerifyView = lazy(() =>
  import('./views/CertificateVerifyView')
);
const MyCertificatesView = lazy(() => import('./views/MyCertificates'));
const BuyMembershipView = lazy(() => import('./views/BuyMembership'));
const ReviewsView = lazy(() => import('./views/Reviews'));
const ProjectsView = lazy(() => import('./views/Projects'));
const DigitalLibraryView = lazy(() => import('./views/DigitalLibrary'));
const AudioLearningView = lazy(() => import('./views/AudioLearning'));
const CourseVideoView = lazy(() => import('./views/CourseVideo'));
const MySalesView = lazy(() => import('./views/MySales'));
const AllSalesView = lazy(() => import('./views/AllSales'));
const MyCustomersView = lazy(() => import('./views/MyCustomers'));
const AllCustomersView = lazy(() => import('./views/AllCustomers'));
const MyMoneyTransactionsView = lazy(() =>
  import('./views/MyMoneyTransactions')
);
const AllUserEarningsView = lazy(() => import('./views/AllUserEarnings'));
const InteractiveMcqView = lazy(() => import('./views/InteractiveMcq'));
const CoreInterview = lazy(() => import('./views/CoreInterview'));
const NotesAndDefinitions = lazy(() => import('./views/NotesAndDefinitions'));
const GeneralInterview = lazy(() => import('./views/GeneralInterview'));
const FreeVideos = lazy(() => import('./views/FreeVideos'));
const PublicProfileView = lazy(() => import('./views/PublicProfile'));

const MainLandingView = lazy(() => import('./views/LandingPages/MainLanding'));

const SampleCertificateGeneratorView = lazy(() =>
  import('./views/SampleCertificateGenerator')
);
const ResumeTemplatesView = lazy(() => import('./views/ResumeTemplates'));
const CoverLetterTemplatesView = lazy(() =>
  import('./views/CoverLetterTemplates')
);
const JobsView = lazy(() => import('./views/Jobs'));

const AboutUsView = lazy(() => import('./views/StaticPages/AboutUs'));
const ContactUsView = lazy(() => import('./views/StaticPages/ContactUs'));
const PrivacyPolicyView = lazy(() =>
  import('./views/StaticPages/PrivacyPolicy')
);
const TermsAndConditionsView = lazy(() =>
  import('./views/StaticPages/TermsAndConditions')
);
const CancellationAndRefundPoliciesView = lazy(() =>
  import('./views/StaticPages/CancellationAndRefundPolicies')
);

const NewsView = lazy(() => import('./views/News'));
const PatternHistoryView = lazy(() => import('./views/PatternHistory'));
const RealtimePatternsHistoryView = lazy(() =>
  import('./views/RealtimePatterns')
);
const StockViewView = lazy(() => import('./views/StockView'));
const ExternalScreenerView = lazy(() => import('./views/ExternalScreener'));
const PositionsView = lazy(() => import('./views/Positions'));
const PatternSubscriptionsView = lazy(() =>
  import('./views/PatternSubscriptions')
);
const ScalperView = lazy(() => import('./views/Scalper'));
const CandlesWikiView = lazy(() => import('./views/CandlesWiki'));
const DashboardView = lazy(() => import('./views/Dashboard'));

const ThankYouView = lazy(() => import('./views/ThankYou'));

const pageData = config.pageData;

const getView = (view, isEnabled) => {
  if (isEnabled) {
    return view;
  } else {
    return NotFoundView;
  }
};

const Routes = () => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {/* <Redirect exact from="/" to="/all-courses" /> */}

        <RouteWithLayout
          exact
          component={getView(AccountView, pageData.Account.route.isEnabled)}
          layout={MainLayout}
          path={pageData.Account.route.path}
          onlyAuthUsers={pageData.Account.route.onlyAuthUsers}
        />

        <RouteWithLayout
          exact
          component={getView(MyCoursesView, pageData.myCourses.route.isEnabled)}
          layout={MainLayout}
          path={pageData.myCourses.route.path}
          onlyAuthUsers={pageData.myCourses.route.onlyAuthUsers}
        />

        <RouteWithLayout
          exact
          component={getView(
            AllCoursesView,
            pageData.allCourses.route.isEnabled
          )}
          layout={MainLayout}
          path={pageData.allCourses.route.path}
          onlyAuthUsers={pageData.allCourses.route.onlyAuthUsers}
        />

        <RouteWithLayout
          exact
          component={getView(
            CourseDetailView,
            pageData.CourseDetail.route.isEnabled
          )}
          layout={MainLayout}
          path={pageData.CourseDetail.route.path}
          onlyAuthUsers={pageData.CourseDetail.route.onlyAuthUsers}
        />

        <RouteWithLayout
          exact
          component={getView(
            CertificateVerifyView,
            pageData.CertificateVerify.route.isEnabled
          )}
          layout={MainLayout}
          path={pageData.CertificateVerify.route.path}
          onlyAuthUsers={pageData.CertificateVerify.route.onlyAuthUsers}
        />

        <RouteWithLayout
          exact
          component={getView(
            MyCertificatesView,
            pageData.MyCertificates.route.isEnabled
          )}
          layout={MainLayout}
          path={pageData.MyCertificates.route.path}
          onlyAuthUsers={pageData.MyCertificates.route.onlyAuthUsers}
        />

        <RouteWithLayout
          exact
          component={getView(
            BuyMembershipView,
            pageData.BuyMembership.route.isEnabled
          )}
          layout={MainLayout}
          path={pageData.BuyMembership.route.path}
          onlyAuthUsers={pageData.BuyMembership.route.onlyAuthUsers}
        />

        <RouteWithLayout
          exact
          component={getView(
            ReviewsView,
            pageData.Membership2Landing.route.isEnabled
          )}
          layout={MainLayout}
          path={pageData.Reviews.route.path}
          onlyAuthUsers={pageData.Reviews.route.onlyAuthUsers}
        />

        <RouteWithLayout
          exact
          component={getView(ProjectsView, pageData.projects.route.isEnabled)}
          layout={MainLayout}
          path={pageData.projects.route.path}
          onlyAuthUsers={pageData.projects.route.onlyAuthUsers}
        />

        <RouteWithLayout
          exact
          component={getView(
            DigitalLibraryView,
            pageData.digitalLibrary.route.isEnabled
          )}
          layout={MainLayout}
          path={pageData.digitalLibrary.route.path}
          onlyAuthUsers={pageData.digitalLibrary.route.onlyAuthUsers}
        />

        <RouteWithLayout
          exact
          component={getView(
            AudioLearningView,
            pageData.audioLearning.route.isEnabled
          )}
          layout={MainLayout}
          path={pageData.audioLearning.route.path}
          onlyAuthUsers={pageData.audioLearning.route.onlyAuthUsers}
        />
        <RouteWithLayout
          exact
          component={getView(
            CourseVideoView,
            pageData.CourseVideo.route.isEnabled
          )}
          layout={MainLayout}
          path={pageData.CourseVideo.route.path}
          onlyAuthUsers={pageData.CourseVideo.route.onlyAuthUsers}
        />

        <RouteWithLayout
          exact
          component={getView(MySalesView, pageData.MySalesHome.route.isEnabled)}
          layout={MainLayout}
          path={pageData.MySalesHome.route.path}
          onlyAuthUsers={pageData.MySalesHome.route.onlyAuthUsers}
        />

        <RouteWithLayout
          exact
          component={getView(AllSalesView, pageData.AllSales.route.isEnabled)}
          layout={MainLayout}
          path={pageData.AllSales.route.path}
          onlyAuthUsers={pageData.AllSales.route.onlyAuthUsers}
        />

        <RouteWithLayout
          exact
          component={getView(
            MyCustomersView,
            pageData.MyCustomers.route.isEnabled
          )}
          layout={MainLayout}
          path={pageData.MyCustomers.route.path}
          onlyAuthUsers={pageData.MyCustomers.route.onlyAuthUsers}
        />

        <RouteWithLayout
          exact
          component={getView(
            AllCustomersView,
            pageData.AllCustomers.route.isEnabled
          )}
          layout={MainLayout}
          path={pageData.AllCustomers.route.path}
          onlyAuthUsers={pageData.AllCustomers.route.onlyAuthUsers}
        />

        <RouteWithLayout
          exact
          component={getView(
            MyMoneyTransactionsView,
            pageData.MoneyTransactions.route.isEnabled
          )}
          layout={MainLayout}
          path={pageData.MoneyTransactions.route.path}
          onlyAuthUsers={pageData.MoneyTransactions.route.onlyAuthUsers}
        />

        <RouteWithLayout
          exact
          component={getView(
            AllUserEarningsView,
            pageData.AllUserEarnings.route.isEnabled
          )}
          layout={MainLayout}
          path={pageData.AllUserEarnings.route.path}
          onlyAuthUsers={pageData.AllUserEarnings.route.onlyAuthUsers}
        />

        <RouteWithLayout
          exact
          component={getView(
            InteractiveMcqView,
            pageData.InteractiveMcqs.route.isEnabled
          )}
          layout={MainLayout}
          path={pageData.InteractiveMcqs.route.path}
          onlyAuthUsers={pageData.InteractiveMcqs.route.onlyAuthUsers}
        />

        <RouteWithLayout
          exact
          component={getView(
            CoreInterview,
            pageData.CoreInterview.route.isEnabled
          )}
          layout={MainLayout}
          path={pageData.CoreInterview.route.path}
          onlyAuthUsers={pageData.CoreInterview.route.onlyAuthUsers}
        />

        <RouteWithLayout
          exact
          component={getView(
            NotesAndDefinitions,
            pageData.NotesAndDefinitions.route.isEnabled
          )}
          layout={MainLayout}
          path={pageData.NotesAndDefinitions.route.path}
          onlyAuthUsers={pageData.NotesAndDefinitions.route.onlyAuthUsers}
        />

        <RouteWithLayout
          exact
          component={getView(
            GeneralInterview,
            pageData.GeneralInterview.route.isEnabled
          )}
          layout={MainLayout}
          path={pageData.GeneralInterview.route.path}
          onlyAuthUsers={pageData.GeneralInterview.route.onlyAuthUsers}
        />

        <RouteWithLayout
          exact
          component={getView(FreeVideos, pageData.FreeVideos.route.isEnabled)}
          layout={MainLayout}
          path={pageData.FreeVideos.route.path}
          onlyAuthUsers={pageData.FreeVideos.route.onlyAuthUsers}
        />

        <RouteWithLayout
          exact
          component={getView(
            PublicProfileView,
            pageData.PublicProfile.route.isEnabled
          )}
          layout={MainLayout}
          path={pageData.PublicProfile.route.path}
          onlyAuthUsers={pageData.PublicProfile.route.onlyAuthUsers}
        />

        <RouteWithLayout
          exact
          component={getView(
            SampleCertificateGeneratorView,
            pageData.SampleCertificateGenerator.route.isEnabled
          )}
          layout={MainLayout}
          path={pageData.SampleCertificateGenerator.route.path}
          onlyAuthUsers={
            pageData.SampleCertificateGenerator.route.onlyAuthUsers
          }
        />
        <RouteWithLayout
          exact
          component={getView(
            ResumeTemplatesView,
            pageData.ResumeTemplates.route.isEnabled
          )}
          layout={MainLayout}
          path={pageData.ResumeTemplates.route.path}
          onlyAuthUsers={pageData.ResumeTemplates.route.onlyAuthUsers}
        />
        <RouteWithLayout
          exact
          component={getView(
            CoverLetterTemplatesView,
            pageData.CoverLetterTemplates.route.isEnabled
          )}
          layout={MainLayout}
          path={pageData.CoverLetterTemplates.route.path}
          onlyAuthUsers={pageData.CoverLetterTemplates.route.onlyAuthUsers}
        />

        <RouteWithLayout
          exact
          component={getView(JobsView, pageData.Jobs.route.isEnabled)}
          layout={MainLayout}
          path={pageData.Jobs.route.path}
          onlyAuthUsers={pageData.Jobs.route.onlyAuthUsers}
        />
        <RouteWithLayout
          exact
          component={getView(
            ChangePasswordView,
            pageData.ChangePassword.route.isEnabled
          )}
          layout={MainLayout}
          path={pageData.ChangePassword.route.path}
          onlyAuthUsers={pageData.ChangePassword.route.onlyAuthUsers}
        />

        {/* Main landing page */}
        <RouteWithLayout
          exact
          component={getView(
            MainLandingView,
            pageData.MainLanding.route.isEnabled
          )}
          layout={MainLayout}
          path={pageData.MainLanding.route.path}
          onlyAuthUsers={pageData.MainLanding.route.onlyAuthUsers}
        />
        {/* static pages */}
        <RouteWithLayout
          exact
          component={getView(AboutUsView, pageData.AboutUs.route.isEnabled)}
          layout={MainLayout}
          path={pageData.AboutUs.route.path}
          onlyAuthUsers={pageData.AboutUs.route.onlyAuthUsers}
        />
        <RouteWithLayout
          exact
          component={getView(ContactUsView, pageData.ContactUs.route.isEnabled)}
          layout={MainLayout}
          path={pageData.ContactUs.route.path}
          onlyAuthUsers={pageData.ContactUs.route.onlyAuthUsers}
        />
        <RouteWithLayout
          exact
          component={getView(
            PrivacyPolicyView,
            pageData.PrivacyPolicy.route.isEnabled
          )}
          layout={MainLayout}
          path={pageData.PrivacyPolicy.route.path}
          onlyAuthUsers={pageData.PrivacyPolicy.route.onlyAuthUsers}
        />
        <RouteWithLayout
          exact
          component={getView(
            TermsAndConditionsView,
            pageData.TermsAndConditions.route.isEnabled
          )}
          layout={MainLayout}
          path={pageData.TermsAndConditions.route.path}
          onlyAuthUsers={pageData.TermsAndConditions.route.onlyAuthUsers}
        />
        <RouteWithLayout
          exact
          component={getView(
            CancellationAndRefundPoliciesView,
            pageData.CancellationAndRefundPolicies.route.isEnabled
          )}
          layout={MainLayout}
          path={pageData.CancellationAndRefundPolicies.route.path}
          onlyAuthUsers={
            pageData.CancellationAndRefundPolicies.route.onlyAuthUsers
          }
        />

        <RouteWithLayout
          exact
          component={getView(NewsView, pageData.News.route.isEnabled)}
          layout={MainLayout}
          path={pageData.News.route.path}
          onlyAuthUsers={pageData.News.route.onlyAuthUsers}
        />
        <RouteWithLayout
          exact
          component={getView(
            PatternHistoryView,
            pageData.PatternHistory.route.isEnabled
          )}
          layout={MainLayout}
          path={pageData.PatternHistory.route.path}
          onlyAuthUsers={pageData.PatternHistory.route.onlyAuthUsers}
        />
        <RouteWithLayout
          exact
          component={getView(
            RealtimePatternsHistoryView,
            pageData.RealtimePatterns.route.isEnabled
          )}
          layout={MainLayout}
          path={pageData.RealtimePatterns.route.path}
          onlyAuthUsers={pageData.RealtimePatterns.route.onlyAuthUsers}
        />
        <RouteWithLayout
          exact
          component={getView(StockViewView, pageData.StockView.route.isEnabled)}
          layout={MainLayout}
          path={pageData.StockView.route.path}
          onlyAuthUsers={pageData.StockView.route.onlyAuthUsers}
        />
        <RouteWithLayout
          exact
          component={getView(
            ExternalScreenerView,
            pageData.ExternalScreener.route.isEnabled
          )}
          layout={MainLayout}
          path={pageData.ExternalScreener.route.path}
          onlyAuthUsers={pageData.ExternalScreener.route.onlyAuthUsers}
        />
        <RouteWithLayout
          exact
          component={getView(PositionsView, pageData.Positions.route.isEnabled)}
          layout={MainLayout}
          path={pageData.Positions.route.path}
          onlyAuthUsers={pageData.Positions.route.onlyAuthUsers}
        />
        <RouteWithLayout
          exact
          component={getView(
            PatternSubscriptionsView,
            pageData.PatternSubscriptions.route.isEnabled
          )}
          layout={MainLayout}
          path={pageData.PatternSubscriptions.route.path}
          onlyAuthUsers={pageData.PatternSubscriptions.route.onlyAuthUsers}
        />
        <RouteWithLayout
          exact
          component={getView(ScalperView, pageData.Scalper.route.isEnabled)}
          layout={MainLayout}
          path={pageData.Scalper.route.path}
          onlyAuthUsers={pageData.Scalper.route.onlyAuthUsers}
        />
        <RouteWithLayout
          exact
          component={getView(
            CandlesWikiView,
            pageData.CandlesWiki.route.isEnabled
          )}
          layout={MainLayout}
          path={pageData.CandlesWiki.route.path}
          onlyAuthUsers={pageData.CandlesWiki.route.onlyAuthUsers}
        />
        <RouteWithLayout
          exact
          component={getView(DashboardView, pageData.Dashboard.route.isEnabled)}
          layout={MainLayout}
          path={pageData.Dashboard.route.path}
          onlyAuthUsers={pageData.Dashboard.route.onlyAuthUsers}
        />

        <RouteWithLayout
          exact
          component={getView(ThankYouView, pageData.ThankYou.route.isEnabled)}
          layout={MainLayout}
          path={pageData.ThankYou.route.path}
          onlyAuthUsers={pageData.ThankYou.route.onlyAuthUsers}
        />

        {/* MINIMAL LAYOUT */}

        <RouteWithLayout
          exact
          component={getView(SignUpView, pageData.SignUp.route.isEnabled)}
          layout={MinimalLayout}
          path={pageData.SignUp.route.path}
          onlyAuthUsers={pageData.SignUp.route.onlyAuthUsers}
        />

        <RouteWithLayout
          exact
          component={getView(SignIn2View, pageData.SignIn.route.isEnabled)}
          layout={MinimalLayout}
          path={pageData.SignIn.route.path}
          onlyAuthUsers={pageData.SignIn.route.onlyAuthUsers}
        />

        <RouteWithLayout
          exact
          component={getView(
            SignupSuccessView,
            pageData.SignupSuccess.route.isEnabled
          )}
          layout={MinimalLayout}
          path={pageData.SignupSuccess.route.path}
          onlyAuthUsers={pageData.SignupSuccess.route.onlyAuthUsers}
        />

        <RouteWithLayout
          exact
          component={getView(
            ForgotPasswordView,
            pageData.ForgotPassword.route.isEnabled
          )}
          layout={MinimalLayout}
          path={pageData.ForgotPassword.route.path}
          onlyAuthUsers={pageData.ForgotPassword.route.onlyAuthUsers}
        />

        <RouteWithLayout
          exact
          component={getView(
            UserEmailVerificationView,
            pageData.UserEmailVerification.route.isEnabled
          )}
          layout={MinimalLayout}
          path={pageData.UserEmailVerification.route.path}
          onlyAuthUsers={pageData.UserEmailVerification.route.onlyAuthUsers}
        />

        <RouteWithLayout
          component={NotFoundView}
          exact
          layout={MinimalLayout}
          path={pageData.NotFound.route.path}
          onlyAuthUsers={pageData.NotFound.route.onlyAuthUsers}
        />

        <Redirect to={pageData.NotFound.route.path} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
