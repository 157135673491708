import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
  makeStyles
} from '@material-ui/core';

import { BuyMembershipButton } from 'components';
import config from 'config/main';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    height: '100%',
    paddingTop: 120,
    paddingBottom: 120
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  product: {
    position: 'relative',
    padding: theme.spacing(5, 3),
    cursor: 'pointer',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    '&:hover': {
      transform: 'scale(1.1)'
    }
  },
  productImage: {
    borderRadius: theme.shape.borderRadius,
    position: 'absolute',
    top: -24,
    left: theme.spacing(3),
    height: 48,
    width: 48,
    fontSize: 24
  },
  recommendedProduct: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white
  },
  chooseButton: {
    backgroundColor: theme.palette.common.white
  }
}));

function PricingView(props) {
  let s_user = useSelector((state) => state.user.user);
  let dispatch = useDispatch();
  const classes = useStyles();

  return (
    <>
      <Typography align="center" variant="h1" color="textPrimary">
        Pricing
      </Typography>
      <Box mt={3}>
        {/* {s_verify_payment_signature.value &&
          s_verify_payment_signature.value.payment_success && (
            <Alert severity="success">Payment Successful</Alert>
          )}
        {s_verify_payment_signature.error && (
          <Alert severity="error">Payment Failed. Try again!</Alert>
        )} */}

        {/* <Typography align="center" variant="subtitle1" color="textSecondary">
          View the 3 most chosen pricing plans
        </Typography> */}
      </Box>

      <Box mt={3} textAlign="center">
        <Grid container spacing={4}>
          <Grid item md={3} xs={12}></Grid>
          <Grid item md={6} xs={12}>
            <Paper className={classes.product} elevation={1}>
              <img
                alt="Product"
                className={classes.productImage}
                src="/images/pricing_icons/product_enterprise.svg"
              />
              <Typography
                component="h3"
                gutterBottom
                variant="overline"
                color="textSecondary">
                {props.data['MEMBERSHIP_3'].display_name}
              </Typography>
              <div>
                <Typography
                  component="span"
                  display="inline"
                  variant="h3"
                  color="textPrimary">
                  {props.data['MEMBERSHIP_3'].price['INR'].name}
                </Typography>
              </div>
              <Typography variant="overline" color="textSecondary">
                {props.data['MEMBERSHIP_3'].access_validity}
              </Typography>
              <Box my={2}>
                <Divider />
              </Box>
              <Typography variant="body2" color="textPrimary">
                {props.data['MEMBERSHIP_3'].detail_points.map((element) => (
                  <>
                    {element}
                    <br />
                  </>
                ))}
              </Typography>
              <Box my={2}>
                <Divider />
              </Box>
              <Button
                target="_blank"
                variant="contained"
                fullWidth
                color="default"
                href={config.general.brochureUrl}>
                Download Brochure
              </Button>
              <Box mt={1}>
                <BuyMembershipButton
                  buttonName="Buy now"
                  membershipType="MEMBERSHIP_3"
                  colorType="secondary"
                  buttonFullWidth={true}
                />
              </Box>
            </Paper>
          </Grid>
          <Grid item md={3} xs={12}></Grid>
        </Grid>
      </Box>

      {/* <Box mt={3}>
        <Grid container spacing={4}>
          <Grid item md={4} xs={12}>
            <Paper className={classes.product} elevation={1}>
              <img
                alt="Product"
                className={classes.productImage}
                src="/images/pricing_icons/product_freelancer.svg"
              />
              <Typography
                component="h3"
                gutterBottom
                variant="overline"
                color="textSecondary">
                {props.data['MEMBERSHIP_1'].display_name}
              </Typography>
              <div>
                <Typography
                  component="span"
                  display="inline"
                  variant="h3"
                  color="textPrimary">
                  {props.data['MEMBERSHIP_1'].price['INR'].name}
                </Typography>
              </div>
              <Typography variant="overline" color="textSecondary">
                {props.data['MEMBERSHIP_1'].access_validity}
              </Typography>
              <Box my={2}>
                <Divider />
              </Box>
              <Typography variant="body2" color="textPrimary">
                {props.data['MEMBERSHIP_1'].detail_points.map((element) => (
                  <>
                    {element}
                    <br />
                  </>
                ))}
              </Typography>
              <Box my={2}>
                <Divider />
              </Box>
              <Button
                variant="contained"
                fullWidth
                color="default"
                href={config.pageData.Membership1Landing.route.path}>
                View Details
              </Button>
              <Box mt={1}>
                <BuyMembershipButton
                  buttonName="Buy now"
                  membershipType="MEMBERSHIP_1"
                  colorType="secondary"
                  buttonFullWidth={true}
                />
              </Box>
            </Paper>
          </Grid>
          <Grid item md={4} xs={12}>
            <Paper
              className={clsx(classes.product, classes.recommendedProduct)}
              elevation={1}>
              <img
                alt="Product"
                className={classes.productImage}
                src="/images/pricing_icons/product_agency--outlined.svg"
              />
              <Typography
                component="h3"
                gutterBottom
                variant="overline"
                color="inherit">
                {props.data['MEMBERSHIP_2'].display_name}
              </Typography>
              <div>
                <Typography
                  component="span"
                  display="inline"
                  variant="h3"
                  color="inherit">
                  {props.data['MEMBERSHIP_2'].price['INR'].name}
                </Typography>
              </div>
              <Typography variant="overline" color="inherit">
                {props.data['MEMBERSHIP_2'].access_validity}
              </Typography>
              <Box my={2}>
                <Divider />
              </Box>
              <Typography variant="body2" color="inherit">
                {props.data['MEMBERSHIP_2'].detail_points.map((element) => (
                  <>
                    {element}
                    <br />
                  </>
                ))}
              </Typography>
              <Box my={2}>
                <Divider />
              </Box>
              <Button
                variant="contained"
                fullWidth
                color="default"
                href={config.pageData.Membership2Landing.route.path}>
                View Details
              </Button>
              <Box mt={1}>
                <BuyMembershipButton
                  buttonName="Buy now"
                  membershipType="MEMBERSHIP_2"
                  colorType="primary"
                  buttonFullWidth={true}
                />
              </Box>
            </Paper>
          </Grid>
          <Grid item md={4} xs={12}>
            <Paper className={classes.product} elevation={1}>
              <img
                alt="Product"
                className={classes.productImage}
                src="/images/pricing_icons/product_enterprise.svg"
              />
              <Typography
                component="h3"
                gutterBottom
                variant="overline"
                color="textSecondary">
                {props.data['MEMBERSHIP_3'].display_name}
              </Typography>
              <div>
                <Typography
                  component="span"
                  display="inline"
                  variant="h3"
                  color="textPrimary">
                  {props.data['MEMBERSHIP_3'].price['INR'].name}
                </Typography>
              </div>
              <Typography variant="overline" color="textSecondary">
                {props.data['MEMBERSHIP_3'].access_validity}
              </Typography>
              <Box my={2}>
                <Divider />
              </Box>
              <Typography variant="body2" color="textPrimary">
                {props.data['MEMBERSHIP_3'].detail_points.map((element) => (
                  <>
                    {element}
                    <br />
                  </>
                ))}
              </Typography>
              <Box my={2}>
                <Divider />
              </Box>
              <Button
                variant="contained"
                fullWidth
                color="default"
                href={config.pageData.Membership3Landing.route.path}>
                View Details
              </Button>
              <Box mt={1}>
                <BuyMembershipButton
                  buttonName="Buy now"
                  membershipType="MEMBERSHIP_3"
                  colorType="secondary"
                  buttonFullWidth={true}
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box> */}
    </>
  );
}

export default PricingView;
