import pageData from '../pageData';
import general from '../general';
import membershipTypes from '../membershipTypes';
import { configureStore } from '@reduxjs/toolkit';
let company = general.company_name;

export default {
  features: {
    topLabel: `Explore Digital-Marketing-${company}`,
    heading: 'Our features',
    featuresList: [
      {
        heading: '150+ Videos',
        text: `150+ HD quality explanation streaming videos that covers most of the 
            latest digital-marketing concepts with practical approach.`
      },
      {
        heading: 'Audio Learning Section',
        text: `Your learning doesn't stop if you are busy to watch the videos.
            You can plug in your earphones and listen to digital-marketing concepts`
      },
      // {
      //   heading: 'Digital Library',
      //   text: `You get access to almost all the digital-marketing books and a few
      //       custom made PDF files which are accessible digitally with life-time validity`
      // },
      {
        heading: 'Research Projects',
        text: `If you are into research, this section is gonna be a bomb of value!
            We've a decent collection of the open source digital-marketing projects that you can explore `
      },
      {
        heading: `Mastery Certification`,
        text: `On completion of all the courses (videos) and the live classes, you'll get a final certificate
        for the program `
      },
      {
        heading: 'Individual Course Certifications',
        text: `For every course you complete, you'll automatically get a certificate that'll be 
            visible in the "certificates" section!`
      },
      {
        heading: 'Core-interview Section',
        text: `This section is to prepare you for the core-subject interview questions.
        The core-interview interview questions would be completely related to ${general.specialization} `
      },
      {
        heading: 'Interactive MCQs',
        text: `This section focuses on various MCQ questions on ${general.specialization}. MCQs are important
        as they train you on being quick and making decisions. It has a red-green animation too!`
      },
      {
        heading: 'Notes & Definitions',
        text: `This sections has basic notes and definitions related to ${general.specialization}.
        This is the best for last minute preparations as well as to revise topics.`
      },
      {
        heading: 'General Interview',
        text: `Knowing ${general.specialization} alone won't suffice. You'll need to know the basic
        interview tips and general rules like the HR-interview rules to crack an interview successfully.
        This section includes everything related to it`
      },
      {
        heading: 'Jobs Section',
        text: `This was the most awaited feature. We post jobs weekly here that is filtered by our team
        and others in collaboration. Once you're confident, you can apply to jobs here.
        The jobs posted here are trustable and verified!`
      },
      {
        heading: 'Resume & Cover-letter Templates',
        text: `We often see students struggling with building their profile, resume and writing 
        a cover letter. Hence we've included these 2 separate dedicated sections that have a variety
        of resume and cover-letter templates that you can directly start editing.`
      }
    ]
  },
  overview: [
    {
      heading: '150+ HD Videos',
      subtitle: `Access to all the highest quality videos courses that cover stock-trading from A-Z with practical examples 
          and hands-on training.. 
          The videos are self paced and you can watch them anytime.`,
      imageUrl: '/images/undraw_youtube_tutorial_2gn3.svg',
      imageRight: false,
      isButton: true,
      actionButtonText: 'View Courses',
      actionButtonUrl: '/all-courses'
    },
    {
      heading: 'Stock News Feed',
      subtitle: `Real-Time news feed that displays news related to the stock market which is updated every hour.
      This is built to make sure you take the correct trades based on fundamental news`,
      imageUrl: '/images/undraw_newspaper_k72w.svg',
      imageRight: true,
      isButton: true,
      actionButtonText: 'News Feed',
      actionButtonUrl: '/news'
    },
    {
      heading: 'Practice Scalp Trading',
      subtitle: `India's first web platform where you can go back in time to a particular pattern
      and practice scalping in a simulated environment
      that resembles realtime stock movement with real data`,
      imageUrl: '/images/undraw_bear_market_ania.svg',
      imageRight: false,
      isButton: true,
      actionButtonText: 'Scalper',
      actionButtonUrl: '/scalper'
    },
    {
      heading: 'Pattern History',
      subtitle: `View complete history of patterns a stock has formed in the past.
      This can be very useful for back-testing a pattern based strategy`,
      imageUrl: '/images/undraw_Stock_prices_re_js33.svg',
      imageRight: true,
      isButton: true,
      actionButtonText: 'Pattern History',
      actionButtonUrl: '/pattern-history'
    },
    {
      heading: 'Pattern Subscriptions',
      subtitle: `Subscribe to your favourite patterns to receive real-time SMS alerts whenever any stock forms that pattern.
      We currently support 86 different candlestick patterns which are screened every second using our AI robots`,
      imageUrl: '/images/undraw_Personal_text_re_vqj3.svg',
      imageRight: false,
      isButton: false
      // actionButtonText: 'Pattern History',
      // actionButtonUrl: '/pattern-history'
    },
    {
      heading: 'Virtual Trading',
      subtitle: `Worried about losing money in the market while practicing trading? This is why we're here. 
      Use the virtual trading setup by ${company} to trade with free 1Cr of virtual cash. We do include brokerage
      and other trade charges to simulate real environment`,
      imageUrl: '/images/undraw_Finance_re_gnv2.svg',
      imageRight: true,
      isButton: false
      // actionButtonText: 'Pattern History',
      // actionButtonUrl: '/pattern-history'
    },
    {
      heading: 'Audio Learning',
      subtitle: `Audio learning section is a newly introduced section in ${company}.
          You can plug in your earphones and listen to the stock market concepts or podcasts while 
          you're travelling or busy with some other work where you can't watch the videos`,
      imageUrl: '/images/undraw_walk_in_the_city_1ma6.svg',
      imageRight: false,
      isButton: true,
      actionButtonText: 'View Audio Learning',
      actionButtonUrl: '/audio-learning'
    },
    // {
    //   heading: `Membership Certification`,
    //   subtitle: `On completion of all the course videos and attending the weekend live
    //       classes online, you'll get a final certificate for the program`,
    //   imageUrl: '/images/undraw_certification.svg',
    //   imageRight: false,
    //   isButton: false,
    //   actionButtonText: '',
    //   actionButtonUrl: '/'
    // },
    {
      heading: 'Course Certifications',
      subtitle: `On completion of every course (in the 'All Courses' section), you get a 
          certificate for it. You don't have to wait/ask for the certificate as it's automatically 
          generated after course completion which is digitally signed by a QR code. You get a total
          of 13 certifications for 13 courses`,
      imageUrl: '/images/undraw_certificate_343v.svg',
      imageRight: true,
      isButton: false
      // actionButtonText: '',
      // actionButtonUrl: '/'
    },

    {
      heading: 'Stock Market Interview Prep',
      subtitle: `This section is useful for those looking to work in finance/market
      post completion of the course. It helps you prepare for the stock-market related
      interview questions as we've handpicked the most important questions and solutions`,
      imageUrl: '/images/undraw_co-working_825n.svg',
      imageRight: false,
      isButton: true,
      actionButtonText: 'Stock-Interview',
      actionButtonUrl: 'core-interview'
    },
    {
      heading: 'Interactive MCQs',
      subtitle: `Interactive MCQs are the best way to make your concepts stronger.
      It also improves your decision making skills in choosing the right option among the confusing
      options. A right answer converts to green colour and wrong to red. You'll need to keep clicking
      the options until you get the right answer -- this engages you more into the questions. There's
      also explanations given for every answer`,
      imageUrl: '/images/undraw_questions_75e0.svg',
      imageRight: true,
      isButton: true,
      actionButtonText: 'MCQs',
      actionButtonUrl: '/interactive-mcqs'
    },
    {
      heading: 'Notes and Definitions',
      subtitle: `Love reading? then this is the perfect place for you to learn!
      This section has Notes and Definitions of the most important stock/finance concepts. This section is very
      useful to revise concepts when there isn't much time to watch the videos.`,
      imageUrl: '/images/undraw_taking_notes_tjaf.svg',
      imageRight: false,
      isButton: true,
      actionButtonText: 'Notes',
      actionButtonUrl: '/notes-definitions'
    },
    // {
    //   heading: 'General Interview',
    //   subtitle: `This section has the most frequently asked interview questions.
    //   These questions are not technical and don't contain the core concepts.
    //   It's important to make sure you ace this interview as well. It doesn't matter how well you answer
    //   the technical interviews if you don't perform well in this. So practice everything from this section too`,
    //   imageUrl: '/images/undraw_conversation_h12g.svg',
    //   imageRight: true,
    //   isButton: true,
    //   actionButtonText: 'View General Interview',
    //   actionButtonUrl: '/general-interview'
    // },
    {
      heading: `Stock Market/Finance Jobs`,
      subtitle: `No more hunting for jobs on LinkedIn or Indeed.
      We post only the valid, trusted jobs from authentic sources here. You can blindly
      start applying to jobs from here as we've done the base homework in filtering the jobs for you`,
      imageUrl: '/images/undraw_in_the_office_o44c.svg',
      imageRight: true,
      isButton: true,
      actionButtonText: 'Jobs',
      actionButtonUrl: '/jobs'
    },
    // {
    //   heading: 'Resume Templates',
    //   subtitle: `No more searching the internet for various resume templates.
    //   You can pick any of the resume templates from here and use them as a base
    //   template to create your resume. Resume is the first thing that the employers see
    //   which would mainly be used for screening purposes`,
    //   imageUrl: '/images/undraw_resume_1hqp.svg',
    //   imageRight: true,
    //   isButton: true,
    //   actionButtonText: 'View Resume Templates',
    //   actionButtonUrl: '/resume-templates'
    // },
    // {
    //   heading: 'Cover Letter Templates',
    //   subtitle: `Cover letter is one of the most important as most of the times, your cover
    //   letter is seen even before your resume!. Cover letter is the only way to make sure you stand
    //   out among the other applicants. Without a cover letter you'd miss the opportunity to tell your
    //   prospective employees who you are or why they should hire you.`,
    //   imageUrl: '/images/undraw_arrived_f58d.svg',
    //   imageRight: true,
    //   isButton: true,
    //   actionButtonText: 'View Cover Letter Templates',
    //   actionButtonUrl: '/cover-letter-templates'
    // },
    // {
    //   heading: 'Digital Library',
    //   subtitle: `Get access to every damn book that's there on stock trading here
    //       in a digital PDF format which can be accessed indefinitely!`,
    //   imageUrl: '/images/undraw_book_lover_mkck.svg',
    //   imageRight: false,
    //   isButton: true,
    //   actionButtonText: 'Digital Library',
    //   actionButtonUrl: '/digital-library'
    // },
    {
      heading: 'Dark Theme',
      subtitle: `Dark theme is one of the most requested feature which is good for 
          late night working as it strains your eye less with lesser light colours!
          Our dark theme follows all proper dark theme material design principals.
          You can switch to dark/light theme by clicking the theme button on the top navigation-bar`,
      imageUrl: '/images/undraw_dark_mode_2xam.svg',
      imageRight: true,
      isButton: false,
      actionButtonText: '',
      actionButtonUrl: '/'
    },
    {
      heading: 'Public Profile',
      subtitle: `Your public profile on ${company} gets automatically generated as you 
          complete courses. You'll need to fill up your basic profile details so that it's 
          reflected in your public profile. The main advantage of your public profile is that 
          you can share your public-profile URL with the recruiters or in your profile or in the
          job application while applying for a job and this would definitely add up a lot of value as the recruiter can 
          directly view all of your QR-code-signed certificates and your basic details from your profile like
          your resume. This is of a great value as all the details the recruiter is viewing is on 
          ${company} which is authentic!`,
      imageUrl: '/images/undraw_profile_6l1l.svg',
      imageRight: false,
      isButton: false,
      actionButtonText: '',
      actionButtonUrl: '/'
    }
    // {
    //   heading: '4.7+ Star rating',
    //   subtitle: `We've no hesitation to say that we're ranked the best when it comes
    //       to technology and quality of training. With having 4.7+
    //       star rating, we stand
    //       in the top for stock trading education`,
    //   imageUrl: '/images/undraw_my_password_d6kg.svg',
    //   imageRight: true,
    //   isButton: true,
    //   actionButtonText: 'View Reviews',
    //   actionButtonUrl: '/reviews'
    // }
  ],
  callToAction: {
    heading1: '?',
    heading2: ``,
    buttonName: `Buy Now`,
    colorType: 'primary'
  },
  largeTestimonial: {
    quote: `${company} has been the gateway to my success, that helped me become a full-time trader`,
    personName: 'Ramesh Bhat ',
    personDetails: 'Full Time Equity Trader'
  },
  testimonial: {
    heading: 'Satisfied Stock Traders',
    testimonial1: {
      text: `This is the best platform to get you the best experience on ${general.specialization}. 
          I learnt the most of ${general.specialization} from this platform which is technology oriented 
          that makes learning a lot easier with access to the content!`,
      personName: 'Anshul Sharma',
      personDetails: 'Options Trader'
    },
    testimonial2: {
      text: `There's a lot to learn from this platform. They literally have tools for everything
      to make you the best trader. Especially the scalp trading platform is mind-blowing with AI pattern alerts.
      Realtime SMS pattern alerts are very useful`,
      personName: 'Arjun Kumar',
      personDetails: 'Equity Trader'
    },
    testimonial3: {
      text: `Their MCQ, Interview, videos, and the creative Audio learning sections are 
          amazing! With the virtual trading system, I was able to learn investing & Swing trading without
          having to lose money`,
      personName: 'Sunitha',
      personDetails: 'Stocks Advisor'
    }
  },
  faqs: {
    heading: 'Frequently asked questions',
    subtitle: `${company}`,
    faqs: [
      {
        question: `How many type of memberships are there?`,
        answer: `We have only 1 membership that has access to all the features shown in
        the main landing page including daily SMS patterns alerts for the 5 (out of 86 total patterns)
        candlestick patterns you can subscribe to.`
      },
      {
        question: `How long is the programme?`,
        answer: `While there is no fixed duration for the course, the membership is valid for
        4 months as most students have taken about 4-6 months to turn profitable :)`
      },
      {
        question: 'Is it completely self-paced?',
        answer: `While most of our courses are video-based and self paced, we do have live classes
        on a few weekend days which will be informed in advance.
            The courses in the "All Courses" section which are the course videos can be 
            watched anytime on your own pace which is the most important part of your course 
            as it includes practical approaches.`
      },
      {
        question: `How many certifications do we get in this programme?`,
        answer: `You get one certificate for every course you complete in the 'All Courses' section. 
        There are a total of 13 courses that cover A-Z concepts of the stock market.
        So a total of 13 certifications`
      },
      {
        question: 'When is the audio-learning useful?',
        answer: `The audio-learning is a newly introduced feature in our application 
            where users can learn by just listening to audio. Yes it's more like a podcast which 
            is designed to help you keep learning even with your super busy lifestyle!`
      },
      {
        question: 'What is the public profile about?',
        answer: `The public profile is your profile that is public. It'll have a link/URL 
            which you can copy from either "My Certificates" or "Public Profile" section in the 
            application. Make sure you share this URL while applying for jobs or with the 
            recruiter as this will boost your profile by a lot. When this URL is opened by your 
            recruiter, they'll see all of your certificates and your other profile details 
            that include your profile image, email, phone, address, bio and resume. (These 
              details have to be entered by you. However the certificates are automatically 
              fetched from your certificates :))`
      }
      // {
      //   question: 'What books are there in the Digital-Library?',
      //   answer: `It includes most of the stock-market books. You don't have to
      //       go searching for books online or anywhere else. These books are for additional
      //       information for those who'd wanna dig deeper into stock-trading!`
      // }
    ]
  },
  verticalStepper: [
    {
      stepIndex: 0,
      heading: `Create an Account/Signup with us`,
      text: `Create an account here on ${company} by signing up. (Enter your details to signup,
        verify your email address by clicking on the link emailed, and then login)
      This gives you the access to buy the course`
    },
    {
      stepIndex: 1,
      heading: `Buy the course`,
      text: `
      All payments are handled securely by RazorPay payment gateway.`
    },
    {
      stepIndex: 2,
      heading: `Automatic Access to All the Courses`,
      text: `The moment payment is made (successful) you'll be redirected to the "All Courses" 
      page where you can access all the courses. The one-time payment gives you life-time 
      access to all the courses on this platform. From here you can access all the courses 
      and watch their videos`
    },
    {
      stepIndex: 3,
      heading: `Start Watching Videos`,
      text: `With access to all the courses, you start watching all the courses of all the videos.
      A video will be marked 'complete' only after you watch it completely from the beginning.
      After you complete every video in the course, you'll see a green tick mark against it 
      indicating that you've completed the video. You can watch the video any number of times.
      To mark a course as 'completed', you'll need to watch all the videos.`
    },
    {
      stepIndex: 4,
      heading: `Get Course Certificates`,
      text: `While you're completing the videos in the course, you should be able to see your 
      course completion percentage increasing. Once you've watched all the videos for any course,
      you'll see that the course completion percentage is 100% and you should also be able to see 
      a new button enabled in the course video view page named "CHECK FOR COURSE CERTIFICATE".
      This will appear only after you've completed watching all the videos in a course.
      On clicking this, you'll see a popup to generate a certificate for this course and you'll even 
      be able to set the name on the certificate before generating here.
      If a certificate had already been generated previously, you'll see that certificate here when
      you click on this button.`
    },
    {
      stepIndex: 5,
      heading: `Certificates Automatically Added to 'My Certificates' Section`,
      text: `When you receive a certificate, you should also be able to see it in the 
      "My Certificates" section. This section in the app shows all the certificates you've received
      from us. You can click on each certificate and view them. You can share the certificate URL
      directly with others (recruiter). For this part, we have a whole new section!`
    },
    {
      stepIndex: 6,
      heading: `Something About the Certificates`,
      text: `Our certificates have a great value when it comes to placement/hiring.
      So we have made sure that our certificates cannot be duplicated as we sign every certificate 
      digitally with a QR-code that is uniquely generated. A certificate signed with QR-code 
      is generated only for the members who complete a course. On scanning the QR and visiting the 
      URL verifies that the certificate is valid. This is also used by the recruiters to check if the 
      certificate is valid and original. It verifies that the certificate is valid as it opens the 
      certificate in our web-app stating it's valid when scanned`
    },
    {
      stepIndex: 7,
      heading: `Something About the Certificates`,
      text: `Our certificates have a great value when it comes to placement/hiring.
      So we have made sure that our certificates cannot be duplicated as we sign every certificate 
      digitally with a QR-code that is uniquely generated. A certificate signed with QR-code 
      is generated only for the members who complete a course. On scanning the QR and visiting the 
      URL verifies that the certificate is valid. This is also used by the recruiters to check if the 
      certificate is valid and original. It verifies that the certificate is valid as it opens the 
      certificate in our web-app stating it's valid when scanned.
      You get one certificate for completing every course. So if there are 10 courses, you'll get 
      10 certificates. We constantly keep adding more courses`
    },
    {
      stepIndex: 8,
      heading: `Generation of Public Profile`,
      text: `Public profile is a newly introduced section in our web-app which comes in very 
      handy and useful during your placement/job time. The public profile is an automatically 
      generated profile that should shared with your recruiters/employers. You should share the URL
      of your public profile with them either on email or your job application or personally so 
      that they have a look at this. Sharing this is more valuable than just sharing your resume.
      The public profile will have all of your details that are entered by you in your profile page
      (Bio, Address, Country, Phone Number) plus your general details like your email address, 
      first name, last name - which were entered by you while creating the account.
      The most important part of your public profile is that it shows all of your certificates 
      in a list view where the recruiters can view them there. This is a much better way to 
      represent your certificates than uploading them anywhere else and showing them. They can be
      100% sure of the credibility of the certificate when they automatically see it in your
      profile (on our web-app) when opened through your public profile URL
      `
    },
    {
      stepIndex: 9,
      heading: `Audio Learning`,
      text: `Audio Learning is a creative introduction on our web-app. This is an 
      added feature which makes us stand out from the others. Audio learning focuses on teaching 
      the concepts with just audio and not videos. This can be very useful when the user is 
      either busy or not in a situation to watch videos (while travelling to work).
      You'd have to just put on earphone and list to the audio. 
      We've made sure that it is easy to understand all the lessons in the audio-learning
      section and also made sure that a lot of valuable content is given out here`
    },
    {
      stepIndex: 10,
      heading: `Projects`,
      text: `Projects section will be useful if you want to have a look at all the projects done.
      It'll also have a few open source projects uploaded here which when studied, can be 
      mentioned in your resume to add up more value to your profile. 
      This will also have all the project files uploaded for reference that were used in 
      the videos for teaching. These can be of great value as you'll have a quick access to these.`
    },
    // {
    //   stepIndex: 11,
    //   heading: `Digital Library`,
    //   text: `We're sure you might have had tough days before searching for books online
    //   related to the topic you're working on and found it difficult to get them.
    //   Also, it's no doubt that there are a lof of books lying around and you won't know
    //   if it's worth reading or not. We've filtered the best books and placed it digitally here`
    // },
    {
      stepIndex: 12,
      heading: `Attend Live Classes`,
      text: `We've live weekend classes
      to train you on 
      various topics. The live weekend classes (mainly only on Sundays) would be informed
      a lot in advance.`
    },
    {
      stepIndex: 13,
      heading: `${general.specialization} program Certificate`,
      text: `When you've bought a program, you'll have an option to generate
      another certificate for the completion of the  program.
      You'll have an option to generate this certificate in the "My Certificates" section.`
    },
    {
      stepIndex: 14,
      heading: `Core-interview`,
      text: `You've to complete all the questions and answers in this section.
      This section covers most of the important ${general.specialization} related interview questions.
      Once you've mastered this section, you'll have a much better idea about the ${general.specialization}
      interviews`
    },
    {
      stepIndex: 15,
      heading: `Interactive MCQs`,
      text: `This section has to be completed by you as there are a few MCQ questions on
      ${general.specialization} which fine tunes your brain. Practising MCQ are important as they 
      help you in improving your decision making skills and also it'll be useful as a lot of the 
      ${general.specialization} companies conduct MCQ question test as a screening process.`
    },
    {
      stepIndex: 16,
      heading: `Notes & Definitions`,
      text: `You've to go through all the notes and definitions in this section as it'll contain a lot
      of important topics. This is the most useful section for last minute preparation
       when you can't watch videos`
    },
    {
      stepIndex: 17,
      heading: `General Interview`,
      text: `The general interview has normal interview tips and interview questions. 
      These are not core interview questions related to ${general.specialization}.
      But these are very important to crack final interview rounds that are not technical.
      You should complete all the questions in this section.`
    },
    {
      stepIndex: 18,
      heading: `Resume Templates`,
      text: `Pick a resume template and start building your resume. Include all the important activities
      in it and also all the ${general.specialization} certifications too. We've included the best resume
      templates that you can pick from here.`
    },
    {
      stepIndex: 19,
      heading: `Cover Letter Templates`,
      text: `Cover letters are very important while applying for a job. Sometimes it is more important
      than your resume too as this is how you can stand out from the others who are applying for the 
      same job. Pick a good cover letter template from this section and start writing it.`
    },
    {
      stepIndex: 20,
      heading: `Jobs`,
      text: `It's the time to hop over to the jobs section now in our application. 
      We collect the most genuine and verified job openings in various companies and post it here.
      Once you've completed all the previous steps, you're all set to start applying 
      for jobs here in this section!`
    },
    {
      stepIndex: 21,
      heading: `The End`,
      text: `By this time, you should have got a job and you're all set to live the life of your dreams!`
    }
  ]
};
