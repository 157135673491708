import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Avatar } from '@material-ui/core';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
// import { ThemeChanger } from 'layouts/Main/components';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    background: theme.palette.primary.main
  },
  description: {
    'white-space': 'pre-wrap;'
  }
}));

export default function DescriptionCard(props) {
  const classes = useStyles();

  return (
    <Card className={classes.root} elevation={5}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {props.titleIcon ? props.titleIcon : <OfflineBoltIcon />}
          </Avatar>
        }
        title={props.title}
        subheader={props.subtitle}
      />
      <CardActionArea>
        {/* <CardMedia
          className={classes.media}
          image="/static/images/cards/contemplative-reptile.jpg"
          title="Contemplative Reptile"
        /> */}
        <CardContent>
          <Typography gutterBottom variant="h4" component="h2">
            {props.header}
          </Typography>
          {/* <pre> */}
          <Typography
            variant="body1"
            color="textSecondary"
            component="p"
            className={classes.description}>
            {props.description}
          </Typography>
          {/* </pre> */}
        </CardContent>
      </CardActionArea>
      <CardActions>
        {props.actionButtonName && (
          <Button
            size="medium"
            color="primary"
            variant="contained"
            target="_blank"
            href={props.actionButtonUrl}>
            {props.actionButtonName}
          </Button>
        )}
      </CardActions>
    </Card>
  );
}

DescriptionCard.propTypes = {
  title: PropTypes.any,
  titleIcon: PropTypes.any,
  subtitle: PropTypes.any,
  header: PropTypes.any,
  description: PropTypes.any,
  actionButtonName: PropTypes.any,
  actionButtonUrl: PropTypes.any
};
