import * as moment from 'moment';

import { createReducer } from '@reduxjs/toolkit';

import { SNACK_BAR } from '../types';

const initialState = {
  // the below toggler is to see if the value of snackbar_toggler changes in the useEffect
  // of [snackbar_toggler] only so that we can display the snackbar message directly if snackbar_toggler
  // changes
  snackbar_toggler: false,
  message: 'welcome',
  variant: 'info'
};

export default createReducer(initialState, {
  SNACK_BAR: (state, action) => {
    state['snackbar_toggler'] = !state['snackbar_toggler'];
    state['message'] = action.payload.message;
    state['variant'] = action.payload.variant;
    state['action_function'] = action.payload.action_function;
    state['action_name'] = action.payload.action_name;
  }
});
