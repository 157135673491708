import get from './envConfig';

// these are general data that'll used in multiple places
const company_name = 'AntShade';
// const price_string = '₹4,900/Year + GST';
const price_string = '₹5,782/Year';
export default {
  // domain_root_url: 'https://digital-marketing.graduator.school',
  domain_root_url: get('domain_root_url'),
  api_base_url: get('api_base_url'),
  api_base_url_nodejs: get('api_base_url_nodejs'),
  payments_base_url_api: get('payments_base_url_api'),
  payments_base_url_web: get('payments_base_url_web'),
  currency: 'INR',
  company_name: company_name,
  specialization: 'Stock Trading',
  workspace_name: `Stock Trading Made Easy`,
  company_short_name: 'AntShade',
  total_certifications: 12,
  company_quote:
    '“I will tell you how to become rich. Close the doors. Be fearful when others are greedy. Be greedy when others are fearful.” – Warren Buffett',
  company_slogan: 'Stock Trading Made Easy!',
  help_email: 'info@antshade.com',
  contact_number: '+91 9980214873',
  whatsapp_number: '919980214873',
  whatsapp_preloaded_message: `Hello, I am interested to know more about ${company_name}`,
  logo: '/logo_img_only.png',
  enableReduxDevTools: get('enableReduxDevTools'),
  // enableIntroductionVideoComponent: true,
  userEmailVerificationNeeded: false,
  whatsappButtonName: 'Whatsapp us',
  price: price_string,
  buyFloatingButtonName: `Buy Access for ${price_string}`,
  // enquireButton: 'WHATSAPP', // or "FORM"
  enquireButton: 'FORM', // or "FORM"
  enableFloatingWhatsappButton: false,
  youtubeVideoUrl: 'https://www.youtube.com/embed/otVs5IdAriY?rel=0',
  landingPageVideoUrl:
    'https://all-resources-dj.s3.ap-south-1.amazonaws.com/webinar-videos/antshade/720p+YouTube+downloaded+video.mp4'

  // defaultPageDescription: ""
};
