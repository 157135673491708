import store from './redux/store';
// actions

import { userSet, userUnset } from './redux/actions/userActions';

import axios from 'axios';

var jwtDecode = require('jwt-decode');

const isJwtExpired = (exp) => {
  if (new Date() <= exp * 1000) {
    return false;
  } else {
    return true;
  }
};

const setAuthenticatedGlobal = (token) => {
  console.log(`setting AUTH`);
  localStorage.setItem('token', token);
  let decodedToken = jwtDecode(token);
  store.dispatch(userSet(decodedToken));
  axios.defaults.headers.common['Authorization'] = `JWT ${token}`;
};

const setUnauthenticatedGlobal = () => {
  localStorage.removeItem('token');
  store.dispatch(userUnset());
  delete axios.defaults.headers.common['Authorization'];
};

class Auth {
  constructor() {}

  login(userData) {
    console.dir(userData);
    setAuthenticatedGlobal(userData.token);
  }

  logout() {
    setUnauthenticatedGlobal();
  }

  isAuthenticated() {
    try {
      let token = localStorage.getItem('token');
      if (!token) {
        console.log(`setUnauthenticatedGlobal -- !token`);
        setUnauthenticatedGlobal();
        return false;
      } else {
        // the below will throw an error if the JWT token isn't of the right format (jwt format)
        let decoded = jwtDecode(token);
        if (isJwtExpired(decoded.exp)) {
          console.log(`setUnauthenticatedGlobal -- isJwtExpired`);
          setUnauthenticatedGlobal();
          return false;
        }

        // set this into the redux state
        setAuthenticatedGlobal(token);
        return true;
      }
    } catch (e) {
      // mostly error cause the token isn't in the right format
      console.error(`ERROR_HAPPENED: ${e}`);
      setUnauthenticatedGlobal();
      return false;
    }
  }
}

export default new Auth();
