import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
// import { ThemeProvider } from '@material-ui/styles';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import validate from 'validate.js';
import { CustomSnackbar } from './components';
import generalConfig from 'config/general';

import CssBaseline from '@material-ui/core/CssBaseline';
// Scroll reset
import {
  ScrollReset,
  CaptureRefQueryString,
  FloatingBuyButton,
  FloatingWhatsappButton,
  RefreshToken
} from './components';

import { chartjs } from './helpers';
import createTheme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';
// import Particles from 'react-particles-js';
// Redux
import { Provider } from 'react-redux';
import store from './redux/store';

const browserHistory = createBrowserHistory();

// some redux stuff to get state directly above provider

// const state = store.getState();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};
export default function App() {
  return (
    // <Beforeunload onBeforeunload={() => "You'll lose your data!"}>
    <Provider store={store}>
      <ThemeAndRoutes />
    </Provider>
    // </Beforeunload>
  );
}

function ThemeAndRoutes() {
  let currentTheme = useSelector((state) => state.display.theme);
  let selectedTheme;
  let localTheme = localStorage.getItem('theme');
  selectedTheme = localTheme;
  if (!localTheme) {
    selectedTheme = currentTheme;
  }

  const theme = createTheme(selectedTheme);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <CustomSnackbar />

      <Router history={browserHistory}>
        {generalConfig.enableFloatingWhatsappButton && (
          <FloatingWhatsappButton />
        )}

        <FloatingBuyButton />
        <ScrollReset />
        <CaptureRefQueryString />
        <RefreshToken />
        <Routes />
      </Router>
    </MuiThemeProvider>
  );
}
