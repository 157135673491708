import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';

import Grow from '@material-ui/core/Grow';
import AssistantIcon from '@material-ui/icons/Assistant';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.topBar.main,
    color: theme.palette.primary.contrastText,
    borderRadius: '10px'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },

  mainText: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  sideIcon: {
    color: theme.palette.topBar.contrastElement,
    // theme.name === 'lightTheme'
    //   ? theme.palette.primary.main.contrastText
    //   : theme.palette.primary.main,
    marginTop: '3px',
    height: 56,
    width: 56
  }
}));

const Header = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <Grow in={true} timeout={1000}>
      <Card
        {...rest}
        className={clsx(classes.root, 'fancyGradient#', className)}>
        <CardContent>
          <Grid container justify="space-between">
            <Grid item>
              <Typography
                className={classes.title}
                color="inherit"
                gutterBottom
                variant="body2">
                {props.top}
              </Typography>
              <Typography color="inherit" variant="h3">
                {props.main}
              </Typography>
            </Grid>
            <Grid item>
              <AssistantIcon className={classes.sideIcon} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grow>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  top: PropTypes.string,
  main: PropTypes.string
};

export default Header;
