// React-Redux
import React from 'react';

// MUI
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import config from 'config/main';

const useStyles = makeStyles((theme) => ({
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundImage: 'url(/images/auth.)',
    // backgroundImage: 'url(/images/undraw_certification.svg)',
    backgroundImage: 'url(/images/undraw_authentication.svg)',
    // backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.primary.contrastText,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.primary.contrastText
  },
  bio: {
    color: theme.palette.primary.contrastText
  },
  main: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  top: {
    flexBasis: '20%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  bottom: {
    // backgroundImage: 'url(/images/undraw_authentication.svg)',
    flexBasis: '80%',

    display: 'flex',
    justifyContent: 'center'
    // alignItems: 'center'
  },
  heading: {
    // marginTop: theme.spacing(1),
    fontWeight: 400
    // textAlign: 'center'
  },
  subheading: {
    marginTop: theme.spacing(3),
    textAlign: 'center'
  },
  sideImage: {
    // objectFit: 'contain'
    height: '500px'
  }
}));

export default function MinimalLayoutSideImage() {
  //   const [name, setName] = React.useState(null);
  //   useEffect(() => {}, []);
  //   let certName = useSelector((state) => state.user.profile.name_on_certificate);
  //   let dispatch = useDispatch();

  const classes = useStyles();

  return (
    <div className={classes.main}>
      <div className={classes.top}>
        <Typography variant="h2" className={classes.heading}>
          {config.general.company_name}
        </Typography>

        <Typography variant="body1" className={classes.subheading}>
          {config.general.company_quote}
        </Typography>
      </div>
      <div className={classes.bottom}>
        <img
          className={classes.sideImage}
          alt="side bar image"
          // src="/images/undraw_certification.svg"
          // src="/images/undraw_secure_login.svg"
          // src="/images/undraw_secure_login2.svg"
          src="/images/undraw_secure_login3.svg"
        />
      </div>
    </div>
  );
}
