// React-Redux
import React from 'react';

import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
// MUI
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { setSnackbar as a_setSnackbar } from '../../redux/actions/snackbarActions';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(6)
  },
  listItem: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    marginTop: theme.spacing(2),
    cursor: 'pointer',

    borderRadius: '10px'
  },
  neutralAnswerItem: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary
  },

  rightAnswerItem: {
    backgroundColor: theme.palette.success.main,
    color: 'white'
  },
  wrongAnswerItem: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    animation: 'shake 0.5s;'
  },
  listText: {
    overflowWrap: 'break-word'
  },
  heading: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  }
}));

export default React.memo(function McqElement(props) {
  var data = props.data;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [optionsColor, setOptionsColor] = React.useState({
    option1: classes.neutralAnswerItem,
    option2: classes.neutralAnswerItem,
    option3: classes.neutralAnswerItem,
    option4: classes.neutralAnswerItem
  });

  console.log(optionsColor);
  //   useEffect(() => {}, []);
  //   let certName = useSelector((state) => state.user.profile.name_on_certificate);
  //   let dispatch = useDispatch();

  const onOptionClick = (option) => {
    let color;
    // choosing the color of the list element based on right or wrong option
    if (data.rightOption == option) {
      color = classes.rightAnswerItem;
      dispatch(a_setSnackbar('Right answer', 'success'));
    } else {
      color = classes.wrongAnswerItem;
    }

    setOptionsColor({ ...optionsColor, [option]: color });

    console.log(optionsColor);

    // setting the color to the appropriate state based on the option:
  };
  McqElement.propTypes = {
    data: PropTypes.any
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4">{data.question}</Typography>
      {/* option1 */}
      <ListItem
        className={clsx(classes.listItem, optionsColor.option1)}
        onClick={() => {
          onOptionClick('option1');
        }}>
        <ListItemText primary={data.option1} className={classes.listText} />
      </ListItem>
      {/* option2 */}
      <ListItem
        className={clsx(classes.listItem, optionsColor.option2)}
        onClick={() => {
          onOptionClick('option2');
        }}>
        <ListItemText primary={data.option2} className={classes.listText} />
      </ListItem>
      {/* option3 */}
      <ListItem
        className={clsx(classes.listItem, optionsColor.option3)}
        onClick={() => {
          onOptionClick('option3');
        }}>
        <ListItemText primary={data.option3} className={classes.listText} />
      </ListItem>
      {/* option4 */}
      <ListItem
        className={clsx(classes.listItem, optionsColor.option4)}
        onClick={() => {
          onOptionClick('option4');
        }}>
        <ListItemText primary={data.option4} className={classes.listText} />
      </ListItem>

      {data.explanation && (
        <Box mt={3}>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Typography className={classes.heading}>Explanation</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>{data.explanation}</Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Box>
      )}
    </div>
  );
});

// McqElement.propTypes = {
//   data: PropTypes.any
// };

// function propsAreEqual(prev, next) {
//   return (
//     prev.type === next.type &&
//     prev.buyPrice === next.buyPrice &&
//     prev.sellPrice === next.sellPrice &&
//     prev.quantity === next.quantity &&
//     prev.stopLoss === next.stopLoss &&
//     prev.brokerage === next.brokerage &&
//     prev.ltp === next.ltp &&
//     prev.pl === next.pl &&
//     prev.actionButton === next.actionButton
//   );
// }
