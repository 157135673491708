import React from 'react';
// import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Avatar, Box, Card, Typography, makeStyles } from '@material-ui/core';
// import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

const useStyles = makeStyles((theme) => ({
  rootLight: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  rootDark: {
    color: theme.palette.secondary.contrastText,
    // color: 'red',
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  avatarLight: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    height: 48,
    width: 48
  },
  avatarDark: {
    backgroundColor: theme.palette.secondary.contrastText,
    color: theme.palette.secondary.main,
    height: 48,
    width: 48
  }
}));

function DashboardCard(props) {
  const classes = useStyles();
  const data = {
    value: '24,000',
    currency: '$',
    difference: 4
  };

  return (
    <Card
      className={props.isDarkCard ? classes.rootDark : classes.rootLight}
      elevation={24}>
      <Box flexGrow={1}>
        <Typography
          component="h3"
          gutterBottom
          variant="overline"
          // color="textSecondary"
        >
          {props.label}
        </Typography>
        <Box display="flex" alignItems="center" flexWrap="wrap">
          <Typography
            variant="h3"
            //  color="textPrimary"
          >
            {props.value}
          </Typography>
        </Box>
      </Box>
      <Avatar
        className={props.isDarkCard ? classes.avatarDark : classes.avatarLight}>
        {props.icon}
      </Avatar>
    </Card>
  );
}

DashboardCard.propTypes = {
  label: PropTypes.any,
  value: PropTypes.any,
  icon: PropTypes.any,
  isDarkCard: PropTypes.any
};

export default DashboardCard;
