import React from 'react';

import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/styles';

import moment from 'moment';

import { CertificateListElement } from '../../components';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.dark,
    marginTop: '20px'
  }
}));

export default function CertificatesList(props) {
  const classes = useStyles();

  return (
    <List className={classes.root}>
      {props.certificates.map((certificate) => (
        <CertificateListElement
          certificateId={certificate.certificate_id}
          main={certificate.display_name}
          secondary={moment(certificate.generated_on).format(
            'dddd MMMM D YYYY, h:mm:ss a'
          )}
        />
      ))}
    </List>
  );
}

/*
certificates
*/
