export const SNACK_BAR = 'SNACK_BAR';

// theme

export const THEME_SET = 'THEME_SET';

// User

export const USER_SET = 'USER_SET';
export const USER_SET_LOADING = 'USER_SET_LOADING';
export const USER_SET_ERROR = 'USER_SET_ERROR';

// new_token
export const NEW_TOKEN_LOADING = 'NEW_TOKEN_LOADING';
export const NEW_TOKEN_SET = 'NEW_TOKEN_SET';
export const NEW_TOKEN_ERROR = 'NEW_TOKEN_ERROR';

// resend verification
export const RESEND_VERIFICATION_SET = 'RESEND_VERIFICATION_SET';
export const RESEND_VERIFICATION_LOADING = 'RESEND_VERIFICATION_LOADING';
export const RESEND_VERIFICATION_ERROR = 'RESEND_VERIFICATION_ERROR';

// verify user email
export const VERIFY_USER_EMAIL_SET = 'VERIFY_USER_EMAIL_SET';
export const VERIFY_USER_EMAIL_LOADING = 'VERIFY_USER_EMAIL_LOADING';
export const VERIFY_USER_EMAIL_ERROR = 'VERIFY_USER_EMAIL_ERROR';

// forgot password request
export const FORGOT_PASSWORD_SET = 'FORGOT_PASSWORD_SET';
export const FORGOT_PASSWORD_LOADING = 'FORGOT_PASSWORD_LOADING';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';

// verify forgot password
export const VERIFY_FORGOT_PASSWORD_LOADING = 'VERIFY_FORGOT_PASSWORD_LOADING';
export const VERIFY_FORGOT_PASSWORD_SET = 'VERIFY_FORGOT_PASSWORD_SET';
export const VERIFY_FORGOT_PASSWORD_ERROR = 'VERIFY_FORGOT_PASSWORD_ERROR';

// Change password for forgot password flow
export const CHANGE_PASSWORD_FP_LOADING = 'CHANGE_PASSWORD_FP_LOADING';
export const CHANGE_PASSWORD_FP_SET = 'CHANGE_PASSWORD_FP_SET';
export const CHANGE_PASSWORD_FP_ERROR = 'CHANGE_PASSWORD_FP_ERROR';

// Signup
// USER_SIGNUP_LOADING, USER_SIGNUP_SET, USER_SIGNUP_ERROR
export const USER_SIGNUP_LOADING = 'USER_SIGNUP_LOADING';
export const USER_SIGNUP_SET = 'USER_SIGNUP_SET';
export const USER_SIGNUP_ERROR = 'USER_SIGNUP_ERROR';

export const USER_UNSET = 'USER_UNSET';

// profile
export const USER_DETAILS_LOADING = 'USER_DETAILS_LOADING';
export const USER_DETAILS_SET = 'USER_DETAILS_SET';

// mainly for profile image upload only
export const USER_PROFILE_IMAGE_SET = 'USER_PROFILE_IMAGE_SET';
export const USER_PROFILE_IMAGE_LOADING = 'USER_PROFILE_IMAGE_LOADING';

export const USER_PROFILE_DETAILS_SET = 'USER_PROFILE_DETAILS_SET';
export const USER_PROFILE_DETAILS_LOADING = 'USER_PROFILE_DETAILS_LOADING';

export const HANDLE_PROFILE_DETAILS_EDIT_CHANGE =
  'HANDLE_PROFILE_DETAILS_EDIT_CHANGE';

// Course relate stuff

// modules
// export const MODULES_FETCH = 'MODULES_FETCH';
// export const MODULES_FETCH_LOADING = 'MODULES_FETCH_LOADING';
export const MODULES_SET = 'MODULES_SET';

// course
export const COURSE_ALL_LOADING = 'COURSE_ALL_LOADING';
export const COURSE_ALL_SET = 'COURSE_ALL_SET';
export const COURSE_ALL_ERROR = 'COURSE_ALL_ERROR';

export const COURSE_SET = 'COURSE_SET';
export const COURSE_COMPLETION_PERCENTAGE_SET =
  'COURSE_COMPLETION_PERCENTAGE_SET';

export const LESSON_CURRENT_SET = 'LESSON_CURRENT_SET';

// User completions
export const USER_COMPLETIONS_SET = 'USER_COMPLETIONS_SET';

export const COMPLETE_COURSE_OR_LESSON = 'COMPLETE_COURSE_OR_LESSON';

// Certificate Reducer

export const CERTIFICATES_SET = 'CERTIFICATES_SET';
export const CERTIFICATES_LOADING = 'CERTIFICATES_LOADING';
// verify
export const CERTIFICATE_VERIFY_SET = 'CERTIFICATE_VERIFY_SET';
export const CERTIFICATES_VERIFY_LOADING = 'CERTIFICATES_VERIFY_LOADING';
export const CERTIFICATES_VERIFY_ERROR = 'CERTIFICATES_VERIFY_ERROR';
// generate
export const CERTIFICATE_GENERATE_SET = 'CERTIFICATE_GENERATE_SET';
export const CERTIFICATE_GENERATE_LOADING = 'CERTIFICATE_GENERATE_LOADING';
export const CERTIFICATE_GENERATE_ERROR = 'CERTIFICATE_GENERATE_ERROR';

// course enrollment

export const ENROLL_COURSE_SET = 'ENROLL_COURSE_SET';
export const ENROLL_COURSE_LOADING = 'ENROLL_COURSE_LOADING';
export const ENROLL_COURSE_ERROR = 'ENROLL_COURSE_ERROR';

/*
Membership
*/

// get memberships
export const MEMBERSHIPS_SET = 'MEMBERSHIPS_SET';
export const MEMBERSHIPS_LOADING = 'MEMBERSHIPS_LOADING';
export const MEMBERSHIPS_ERROR = 'MEMBERSHIPS_ERROR';
// create order
export const CREATE_ORDER_SET = 'CREATE_ORDER_SET';
export const CREATE_ORDER_LOADING = 'CREATE_ORDER_LOADING';
export const CREATE_ORDER_ERROR = 'CREATE_ORDER_ERROR';
// after order, verify signature and confirm payment
export const VERIFY_PAYMENT_SIGNATURE_SET = 'VERIFY_PAYMENT_SIGNATURE_SET';
export const VERIFY_PAYMENT_SIGNATURE_LOADING =
  'VERIFY_PAYMENT_SIGNATURE_LOADING';
export const VERIFY_PAYMENT_SIGNATURE_ERROR = 'VERIFY_PAYMENT_SIGNATURE_ERROR';

/*
Resource
*/

// projects
export const PROJECTS_SET = 'PROJECTS_SET';
export const PROJECTS_LOADING = 'PROJECTS_LOADING';
export const PROJECTS_ERROR = 'PROJECTS_ERROR';
// digital library
export const DIGITAL_LIBRARY_SET = 'DIGITAL_LIBRARY_SET';
export const DIGITAL_LIBRARY_LOADING = 'DIGITAL_LIBRARY_LOADING';
export const DIGITAL_LIBRARY_ERROR = 'DIGITAL_LIBRARY_ERROR';
// audio learning
export const AUDIO_LEARNING_SET = 'AUDIO_LEARNING_SET';
export const AUDIO_LEARNING_LOADING = 'AUDIO_LEARNING_LOADING';
export const AUDIO_LEARNING_ERROR = 'AUDIO_LEARNING_ERROR';

// My Sales page:
// Membership APIs for My sales page:
// referred memberships
export const REFERRED_MEMBERSHIPS_SET = 'REFERRED_MEMBERSHIPS_SET';
export const REFERRED_MEMBERSHIPS_LOADING = 'REFERRED_MEMBERSHIPS_LOADING';
export const REFERRED_MEMBERSHIPS_ERROR = 'REFERRED_MEMBERSHIPS_ERROR';
// 7 day referred membership counts
export const REFERRED_MEMBERSHIPS_COUNTS_SET =
  'REFERRED_MEMBERSHIPS_COUNTS_SET';
export const REFERRED_MEMBERSHIPS_COUNTS_LOADING =
  'REFERRED_MEMBERSHIPS_COUNTS_LOADING';
export const REFERRED_MEMBERSHIPS_COUNTS_ERROR =
  'REFERRED_MEMBERSHIPS_COUNTS_ERROR';
// referred memberships
export const ALL_MEMBERSHIPS_SET = 'ALL_MEMBERSHIPS_SET';
export const ALL_MEMBERSHIPS_LOADING = 'ALL_MEMBERSHIPS_LOADING';
export const ALL_MEMBERSHIPS_ERROR = 'ALL_MEMBERSHIPS_ERROR';
// all membership counts
export const ALL_MEMBERSHIPS_COUNTS_SET = 'ALL_MEMBERSHIPS_COUNTS_SET';
export const ALL_MEMBERSHIPS_COUNTS_LOADING = 'ALL_MEMBERSHIPS_COUNTS_LOADING';
export const ALL_MEMBERSHIPS_COUNTS_ERROR = 'ALL_MEMBERSHIPS_COUNTS_ERROR';
// My earning sales
export const MY_EARNING_SALES_SET = 'MY_EARNING_SALES_SET';
export const MY_EARNING_SALES_LOADING = 'MY_EARNING_SALES_LOADING';
export const MY_EARNING_SALES_ERROR = 'MY_EARNING_SALES_ERROR';
// All earning sales
export const All_EARNING_SALES_SET = 'All_EARNING_SALES_SET';
export const All_EARNING_SALES_LOADING = 'All_EARNING_SALES_LOADING';
export const All_EARNING_SALES_ERROR = 'All_EARNING_SALES_ERROR';
// My bank details
export const MY_BANK_DETAILS_SET = 'MY_BANK_DETAILS_SET';
export const MY_BANK_DETAILS_LOADING = 'MY_BANK_DETAILS_LOADING';
export const MY_BANK_DETAILS_ERROR = 'MY_BANK_DETAILS_ERROR';
// Update My bank details
export const UPDATE_MY_BANK_DETAILS_SET = 'UPDATE_MY_BANK_DETAILS_SET';
export const UPDATE_MY_BANK_DETAILS_LOADING = 'UPDATE_MY_BANK_DETAILS_LOADING';
export const UPDATE_MY_BANK_DETAILS_ERROR = 'UPDATE_MY_BANK_DETAILS_ERROR';
// pay the earned money to the user (for referrer)
export const PAY_EARNED_MONEY_SET = 'PAY_EARNED_MONEY_SET';
export const PAY_EARNED_MONEY_LOADING = 'PAY_EARNED_MONEY_LOADING';
export const PAY_EARNED_MONEY_ERROR = 'PAY_EARNED_MONEY_ERROR';

// money_transactions
export const MONEY_TRANSACTIONS_LOADING = 'MONEY_TRANSACTIONS_LOADING';
export const MONEY_TRANSACTIONS_SET = 'MONEY_TRANSACTIONS_SET';
export const MONEY_TRANSACTIONS_ERROR = 'MONEY_TRANSACTIONS_ERROR';

// add_membership
export const ADD_MEMBERSHIP_LOADING = 'ADD_MEMBERSHIP_LOADING';
export const ADD_MEMBERSHIP_SET = 'ADD_MEMBERSHIP_SET';
export const ADD_MEMBERSHIP_ERROR = 'ADD_MEMBERSHIP_ERROR';

// all_stats
export const ALL_STATS_LOADING = 'ALL_STATS_LOADING';
export const ALL_STATS_SET = 'ALL_STATS_SET';
export const ALL_STATS_ERROR = 'ALL_STATS_ERROR';
// mcqs
export const MCQS_LOADING = 'MCQS_LOADING';
export const MCQS_SET = 'MCQS_SET';
export const MCQS_ERROR = 'MCQS_ERROR';

// core_interview
export const CORE_INTERVIEW_LOADING = 'CORE_INTERVIEW_LOADING';
export const CORE_INTERVIEW_SET = 'CORE_INTERVIEW_SET';
export const CORE_INTERVIEW_ERROR = 'CORE_INTERVIEW_ERROR';

// general_interview
export const GENERAL_INTERVIEW_LOADING = 'GENERAL_INTERVIEW_LOADING';
export const GENERAL_INTERVIEW_SET = 'GENERAL_INTERVIEW_SET';
export const GENERAL_INTERVIEW_ERROR = 'GENERAL_INTERVIEW_ERROR';

// notes_and_definitions
export const NOTES_AND_DEFINITIONS_LOADING = 'NOTES_AND_DEFINITIONS_LOADING';
export const NOTES_AND_DEFINITIONS_SET = 'NOTES_AND_DEFINITIONS_SET';
export const NOTES_AND_DEFINITIONS_ERROR = 'NOTES_AND_DEFINITIONS_ERROR';

// free_videos
export const FREE_VIDEOS_LOADING = 'FREE_VIDEOS_LOADING';
export const FREE_VIDEOS_SET = 'FREE_VIDEOS_SET';
export const FREE_VIDEOS_ERROR = 'FREE_VIDEOS_ERROR';

// all_course_module_lessons
export const ALL_COURSE_MODULE_LESSONS_LOADING =
  'ALL_COURSE_MODULE_LESSONS_LOADING';
export const ALL_COURSE_MODULE_LESSONS_SET = 'ALL_COURSE_MODULE_LESSONS_SET';
export const ALL_COURSE_MODULE_LESSONS_ERROR =
  'ALL_COURSE_MODULE_LESSONS_ERROR';

// generate_specialization_certificate
export const GENERATE_SPECIALIZATION_CERTIFICATE_LOADING =
  'GENERATE_SPECIALIZATION_CERTIFICATE_LOADING';
export const GENERATE_SPECIALIZATION_CERTIFICATE_SET =
  'GENERATE_SPECIALIZATION_CERTIFICATE_SET';
export const GENERATE_SPECIALIZATION_CERTIFICATE_ERROR =
  'GENERATE_SPECIALIZATION_CERTIFICATE_ERROR';

// generate_sample_certificate
export const GENERATE_SAMPLE_CERTIFICATE_LOADING =
  'GENERATE_SAMPLE_CERTIFICATE_LOADING';
export const GENERATE_SAMPLE_CERTIFICATE_SET =
  'GENERATE_SAMPLE_CERTIFICATE_SET';
export const GENERATE_SAMPLE_CERTIFICATE_ERROR =
  'GENERATE_SAMPLE_CERTIFICATE_ERROR';

// get_user_public_profile
export const GET_USER_PUBLIC_PROFILE_LOADING =
  'GET_USER_PUBLIC_PROFILE_LOADING';
export const GET_USER_PUBLIC_PROFILE_SET = 'GET_USER_PUBLIC_PROFILE_SET';
export const GET_USER_PUBLIC_PROFILE_ERROR = 'GET_USER_PUBLIC_PROFILE_ERROR';

// resume_templates
export const RESUME_TEMPLATES_LOADING = 'RESUME_TEMPLATES_LOADING';
export const RESUME_TEMPLATES_SET = 'RESUME_TEMPLATES_SET';
export const RESUME_TEMPLATES_ERROR = 'RESUME_TEMPLATES_ERROR';

// cover_letter_templates
export const COVER_LETTER_TEMPLATES_LOADING = 'COVER_LETTER_TEMPLATES_LOADING';
export const COVER_LETTER_TEMPLATES_SET = 'COVER_LETTER_TEMPLATES_SET';
export const COVER_LETTER_TEMPLATES_ERROR = 'COVER_LETTER_TEMPLATES_ERROR';

// jobs
export const JOBS_LOADING = 'JOBS_LOADING';
export const JOBS_SET = 'JOBS_SET';
export const JOBS_ERROR = 'JOBS_ERROR';

// book_call
export const BOOK_CALL_LOADING = 'BOOK_CALL_LOADING';
export const BOOK_CALL_SET = 'BOOK_CALL_SET';
export const BOOK_CALL_ERROR = 'BOOK_CALL_ERROR';

// get_offer
export const GET_OFFER_LOADING = 'GET_OFFER_LOADING';
export const GET_OFFER_SET = 'GET_OFFER_SET';
export const GET_OFFER_ERROR = 'GET_OFFER_ERROR';

// news
export const NEWS_LOADING = 'NEWS_LOADING';
export const NEWS_SET = 'NEWS_SET';
export const NEWS_ERROR = 'NEWS_ERROR';

// history
export const HISTORY_LOADING = 'HISTORY_LOADING';
export const HISTORY_SET = 'HISTORY_SET';
export const HISTORY_ERROR = 'HISTORY_ERROR';

// latest_history
export const LATEST_HISTORY_LOADING = 'LATEST_HISTORY_LOADING';
export const LATEST_HISTORY_SET = 'LATEST_HISTORY_SET';
export const LATEST_HISTORY_ERROR = 'LATEST_HISTORY_ERROR';

// my_positions
export const MY_POSITIONS_LOADING = 'MY_POSITIONS_LOADING';
export const MY_POSITIONS_SET = 'MY_POSITIONS_SET';
export const MY_POSITIONS_ERROR = 'MY_POSITIONS_ERROR';

// close_position
export const CLOSE_POSITION_LOADING = 'CLOSE_POSITION_LOADING';
export const CLOSE_POSITION_SET = 'CLOSE_POSITION_SET';
export const CLOSE_POSITION_ERROR = 'CLOSE_POSITION_ERROR';

// open_position
export const OPEN_POSITION_LOADING = 'OPEN_POSITION_LOADING';
export const OPEN_POSITION_SET = 'OPEN_POSITION_SET';
export const OPEN_POSITION_ERROR = 'OPEN_POSITION_ERROR';

// my_pattern_subscriptions
export const MY_PATTERN_SUBSCRIPTIONS_LOADING =
  'MY_PATTERN_SUBSCRIPTIONS_LOADING';
export const MY_PATTERN_SUBSCRIPTIONS_SET = 'MY_PATTERN_SUBSCRIPTIONS_SET';
export const MY_PATTERN_SUBSCRIPTIONS_ERROR = 'MY_PATTERN_SUBSCRIPTIONS_ERROR';

// create_pattern_subscriptions
export const CREATE_PATTERN_SUBSCRIPTIONS_LOADING =
  'CREATE_PATTERN_SUBSCRIPTIONS_LOADING';
export const CREATE_PATTERN_SUBSCRIPTIONS_SET =
  'CREATE_PATTERN_SUBSCRIPTIONS_SET';
export const CREATE_PATTERN_SUBSCRIPTIONS_ERROR =
  'CREATE_PATTERN_SUBSCRIPTIONS_ERROR';

// update_pattern_subscriptions
export const UPDATE_PATTERN_SUBSCRIPTIONS_LOADING =
  'UPDATE_PATTERN_SUBSCRIPTIONS_LOADING';
export const UPDATE_PATTERN_SUBSCRIPTIONS_SET =
  'UPDATE_PATTERN_SUBSCRIPTIONS_SET';
export const UPDATE_PATTERN_SUBSCRIPTIONS_ERROR =
  'UPDATE_PATTERN_SUBSCRIPTIONS_ERROR';

// delete_pattern_subscription
export const DELETE_PATTERN_SUBSCRIPTION_LOADING =
  'DELETE_PATTERN_SUBSCRIPTION_LOADING';
export const DELETE_PATTERN_SUBSCRIPTION_SET =
  'DELETE_PATTERN_SUBSCRIPTION_SET';
export const DELETE_PATTERN_SUBSCRIPTION_ERROR =
  'DELETE_PATTERN_SUBSCRIPTION_ERROR';

// scalp_patterns
export const SCALP_PATTERNS_LOADING = 'SCALP_PATTERNS_LOADING';
export const SCALP_PATTERNS_SET = 'SCALP_PATTERNS_SET';
export const SCALP_PATTERNS_ERROR = 'SCALP_PATTERNS_ERROR';

// scalp_data
export const SCALP_DATA_LOADING = 'SCALP_DATA_LOADING';
export const SCALP_DATA_SET = 'SCALP_DATA_SET';
export const SCALP_DATA_ERROR = 'SCALP_DATA_ERROR';

// candles_wiki
export const CANDLES_WIKI_LOADING = 'CANDLES_WIKI_LOADING';
export const CANDLES_WIKI_SET = 'CANDLES_WIKI_SET';
export const CANDLES_WIKI_ERROR = 'CANDLES_WIKI_ERROR';

// add_scalp_trade_stat
export const ADD_SCALP_TRADE_STAT_LOADING = 'ADD_SCALP_TRADE_STAT_LOADING';
export const ADD_SCALP_TRADE_STAT_SET = 'ADD_SCALP_TRADE_STAT_SET';
export const ADD_SCALP_TRADE_STAT_ERROR = 'ADD_SCALP_TRADE_STAT_ERROR';
