/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import config from 'config/main';
import {
  Box,
  Chip,
  Divider,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
//job and resources
import WorkIcon from '@material-ui/icons/Work';
import DescriptionIcon from '@material-ui/icons/Description';
import AssignmentIcon from '@material-ui/icons/Assignment';

import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import CodeIcon from '@material-ui/icons/Code';
import HeadsetIcon from '@material-ui/icons/Headset';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import NotesIcon from '@material-ui/icons/Notes';
import ViewListIcon from '@material-ui/icons/ViewList';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PublicIcon from '@material-ui/icons/Public';
// import VisibilityIcon from '@material-ui/icons/Visibility';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import HistoryIcon from '@material-ui/icons/History';
import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan';
import PlaylistAddCheckTwoToneIcon from '@material-ui/icons/PlaylistAddCheckTwoTone';
import SpeedIcon from '@material-ui/icons/Speed';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import DashboardIcon from '@material-ui/icons/Dashboard';
// import Can from 'rbac/Can';
// import canDo from 'rbac/Can';
import { canFunction } from 'rbac/Can';
import { Users as UsersIcon } from 'react-feather';
// import Logo from 'src/components/Logo';
import NavItem from './NavItem';
import Profile from './Profile';

const getNavConfig = (USER) => {
  const navConfig = [
    {
      subheader: 'Stock Market',
      isEnabled: true,
      items: [
        {
          title: 'Dashboard',
          href: config.pageData.Dashboard.route.path,
          isEnabled: config.pageData.Dashboard.route.isEnabled,
          icon: DashboardIcon
        },
        {
          title: 'Latest News',
          href: config.pageData.News.route.path,
          isEnabled: config.pageData.News.route.isEnabled,
          icon: PublicIcon,
          info: () => <Chip color="secondary" size="small" label="new" />
        },
        {
          title: 'Pattern History',
          href: config.pageData.PatternHistory.route.path,
          isEnabled: config.pageData.PatternHistory.route.isEnabled,
          icon: HistoryIcon
        },
        {
          title: 'Realtime Patterns',
          href: config.pageData.RealtimePatterns.route.path,
          isEnabled: config.pageData.RealtimePatterns.route.isEnabled,
          icon: ShowChartIcon
        },
        {
          title: 'Scanners',
          href: config.pageData.ExternalScreener.route.path,
          isEnabled: config.pageData.ExternalScreener.route.isEnabled,
          icon: SettingsOverscanIcon
        },
        {
          title: 'My Positions',
          href: config.pageData.Positions.route.path,
          isEnabled: config.pageData.Positions.route.isEnabled,
          icon: MonetizationOnIcon
        },
        {
          title: 'My Pattern Subscriptions',
          href: config.pageData.PatternSubscriptions.route.path,
          isEnabled: config.pageData.PatternSubscriptions.route.isEnabled,
          icon: PlaylistAddCheckTwoToneIcon
        },
        {
          title: 'Scalp Trading',
          href: config.pageData.Scalper.route.path,
          isEnabled: config.pageData.Scalper.route.isEnabled,
          icon: SpeedIcon
        },
        {
          title: 'Candles Wiki Guide',
          href: config.pageData.CandlesWiki.route.path,
          isEnabled: config.pageData.CandlesWiki.route.isEnabled,
          icon: MenuBookIcon
        }
      ]
    },
    {
      subheader: 'Course',
      isEnabled: true,
      items: [
        {
          title: 'All Courses',
          href: config.pageData.allCourses.route.path,
          isEnabled: config.pageData.allCourses.route.isEnabled,
          icon: VideoLabelIcon,
          info: () => <Chip color="secondary" size="small" label="view all" />
        },
        {
          title: 'Enrolled Courses',
          href: config.pageData.myCourses.route.path,
          isEnabled: config.pageData.myCourses.route.isEnabled,
          icon: PlayCircleFilledIcon
        },
        {
          title: 'Free Videos',
          href: config.pageData.FreeVideos.route.path,
          isEnabled: config.pageData.FreeVideos.route.isEnabled,
          icon: PlayArrowIcon
        }
      ]
    },
    {
      isEnabled: true,
      subheader: 'Digital Resources',
      items: [
        {
          title: 'Audio Learning',
          href: config.pageData.audioLearning.route.path,
          isEnabled: config.pageData.audioLearning.route.isEnabled,
          icon: HeadsetIcon,
          info: () => <Chip color="secondary" size="small" label="new" />
        },
        {
          title: 'Projects',
          href: config.pageData.projects.route.path,
          isEnabled: config.pageData.projects.route.isEnabled,
          icon: CodeIcon
        },
        {
          title: 'Digital-Library',
          href: config.pageData.digitalLibrary.route.path,
          isEnabled: config.pageData.digitalLibrary.route.isEnabled,
          icon: LocalLibraryIcon
        }
      ]
    },
    {
      isEnabled: true,
      subheader: 'Question & Answer Bank',
      items: [
        {
          title: 'Core Interview',
          href: config.pageData.CoreInterview.route.path,
          isEnabled: config.pageData.CoreInterview.route.isEnabled,
          icon: ViewListIcon
        },
        {
          title: 'Interactive MCQs',
          href: config.pageData.InteractiveMcqs.route.path,
          isEnabled: config.pageData.InteractiveMcqs.route.isEnabled,
          icon: DoneAllIcon
        },
        {
          title: 'Notes & Definitions',
          href: config.pageData.NotesAndDefinitions.route.path,
          isEnabled: config.pageData.NotesAndDefinitions.route.isEnabled,
          icon: NotesIcon
        },
        {
          title: 'General Interview',
          href: config.pageData.GeneralInterview.route.path,
          isEnabled: config.pageData.GeneralInterview.route.isEnabled,
          icon: ViewListIcon
        }
      ]
    },

    {
      isEnabled: true,
      subheader: 'Job Portal',
      items: [
        {
          title: 'View Job Portal',
          icon: WorkIcon,
          items: [
            {
              title: 'View Jobs',
              href: config.pageData.Jobs.route.path,
              isEnabled: config.pageData.Jobs.route.isEnabled
            },
            {
              title: 'Resume Templates',
              href: config.pageData.ResumeTemplates.route.path,
              isEnabled: config.pageData.ResumeTemplates.route.isEnabled
            },
            {
              title: 'Cover-Letter Templates',
              href: config.pageData.CoverLetterTemplates.route.path,
              isEnabled: config.pageData.CoverLetterTemplates.route.isEnabled
            }
          ]
        }
      ]
    },
    {
      isEnabled: canFunction({
        role: USER.user_type,
        perform: 'authViews:view'
      }),
      subheader: 'Referrals',
      items: [
        {
          title: 'My Referred Students',
          icon: MonetizationOnIcon,
          items: [
            {
              title: 'My Sales Home',
              href: config.pageData.MySalesHome.route.path,
              isEnabled: config.pageData.MySalesHome.route.isEnabled
            },
            {
              title: 'My Customers',
              href: config.pageData.MyCustomers.route.path,
              isEnabled: config.pageData.MyCustomers.route.isEnabled
            },
            {
              title: 'Money Transactions',
              href: config.pageData.MoneyTransactions.route.path,
              isEnabled: config.pageData.MoneyTransactions.route.isEnabled
            }
          ]
        }
      ]
    },
    {
      subheader: 'Admin',
      isEnabled: canFunction({
        role: USER.user_type,
        perform: 'adminResources:view'
      }),
      items: [
        {
          title: 'All Earnings',
          icon: VerifiedUserIcon,
          items: [
            {
              title: 'All Sales Home',
              href: config.pageData.AllSales.route.path,
              isEnabled: config.pageData.AllSales.route.isEnabled
            },
            {
              title: 'All Customers',
              href: config.pageData.AllCustomers.route.path,
              isEnabled: config.pageData.AllCustomers.route.isEnabled
            },
            {
              title: 'All User Earnings',
              href: config.pageData.AllUserEarnings.route.path,
              isEnabled: config.pageData.AllUserEarnings.route.isEnabled
            }
          ]
        }
      ]
    },
    {
      isEnabled: canFunction({
        role: USER.user_type,
        perform: 'authViews:view'
      }),
      subheader: 'Account',
      items: [
        {
          title: 'Account',
          icon: UsersIcon,
          items: [
            {
              title: 'My Profile',
              href: config.pageData.Account.route.path,
              isEnabled: config.pageData.Account.route.isEnabled
            },

            {
              title: 'Change Password',
              href: config.pageData.ChangePassword.route.path,
              isEnabled: config.pageData.ChangePassword.route.isEnabled
            },
            {
              title: 'Public Profile',
              // href: `/public-profile/${USER.username}`
              href: `${config.pageData.PublicProfile.route.path}`.replace(
                ':username',
                USER.username
              ),
              isEnabled: config.pageData.PublicProfile.route.isEnabled
            }
          ]
        },
        {
          title: 'My Certificates',
          icon: CardMembershipIcon,
          href: config.pageData.MyCertificates.route.path,
          isEnabled: config.pageData.MyCertificates.route.isEnabled
        }
      ]
    }
  ];

  return navConfig;
};

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}>
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    item.isEnabled &&
      acc.push(
        <NavItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          key={key}
          info={item.info}
          title={item.title}
        />
      );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  // const { user } = useSelector((state) => state.account);
  const USER = useSelector((state) => state.user.user);
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  let navConfig = getNavConfig(USER);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">{/* <Logo /> */}</RouterLink>
          </Box>
        </Hidden>
        <Box margin="10px">
          <Profile />
        </Box>

        <Divider />
        <Box p={2}>
          {navConfig.map(
            (config) =>
              config.isEnabled && (
                <List
                  key={config.subheader}
                  subheader={
                    <ListSubheader disableGutters disableSticky>
                      {config.subheader}
                    </ListSubheader>
                  }>
                  {renderNavItems({
                    items: config.items,
                    pathname: location.pathname
                  })}
                </List>
              )
          )}
        </Box>
        <Divider />
        <Box p={2}>
          <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
            <Typography variant="h6" color="textPrimary">
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              component="a"
              href={`mailto:${config.general.help_email}`}>
              {/* to="mailto:webmaster@example.com"> */}
              Email us
            </Link>
            <Typography variant="h6" color="textPrimary">
              {config.general.help_email}
            </Typography>
            {/* <Typography variant="h6" color="textPrimary">
              Phone: {config.general.contact_number}
            </Typography> */}
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <SwipeableDrawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary">
          {content}
        </SwipeableDrawer>
      </Hidden>
      <Hidden mdDown>
        <SwipeableDrawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent">
          {content}
        </SwipeableDrawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
