import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { useTheme } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%'
    // overflowWrap: 'break-all'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.contrastText
  },
  main: {
    background: theme.palette.primary.main,
    borderRadius: '10px'
  }
}));

export default function TradingViewChart(props) {
  const chartRef = useRef(null);
  let currentTheme = useSelector((state) => state.display.theme);
  const theme = useTheme(); // Problem is that, this doesn't change dynamically when the theme changes.
  const classes = useStyles();
  var chart;

  useEffect(() => {
    // var chart = LightweightCharts.createChart(document.body, { -- It was like this before
    // We can't use document.body as we don't wanna attach the chart to the body, but instead to this component div tag
    // Hence we created a reference to this component's div tag using useRef() [const chartRef = useRef(null);] Hook and then passed it.
    // To access the ref, we need to use .current (like: chartRef.current)
    //
    chartRef.current.innerHTML = '';
    // we're setting the above ref to '' as this useEffect() gets called every time we change the theme.
    // If we won't set the above ref's innerHTML to '', we'll be appending the chart again and again.
    chart = window.LightweightCharts.createChart(chartRef.current, {
      //   width: 600,
      //   height: 300,
      //   width: window.screen.width * 0.7,
      //   height: window.screen.height * 0.6,
      width: props.width,
      height: props.height,
      crosshair: {
        mode: window.LightweightCharts.CrosshairMode.Normal
      },
      //   localization: {
      //     locale: 'ja-JP'
      //   },
      timeScale: {
        timeVisible: true,
        secondsVisible: false
      },
      watermark: {
        visible: true,
        fontSize: 24,
        horzAlign: 'center',
        vertAlign: 'center',
        color: 'rgba(171, 71, 188, 0.5)',
        text: 'AntShade.com'
      },
      layout:
        currentTheme === 'lightTheme'
          ? {
              backgroundColor: '#FFFFFF',
              lineColor: '#2B2B43',
              textColor: '#191919'
            }
          : {
              backgroundColor: '#2B2B43',
              lineColor: '#2B2B43',
              textColor: '#D9D9D9'
            }
    });

    var candleSeries = chart.addCandlestickSeries();

    // var data = [
    //   {
    //     time: 1558688100,
    //     open: 54.62,
    //     high: 55.5,
    //     low: 54.52,
    //     close: 54.9
    //   },
    //   {
    //     time: 1558688400,
    //     open: 55.08,
    //     high: 55.27,
    //     low: 54.61,
    //     close: 54.98
    //   },
    //   {
    //     time: 1558688700,
    //     open: 55.08,
    //     high: 55.27,
    //     low: 54.61,
    //     close: 55.1
    //   }
    // ];

    // candleSeries.setData(data);
    props.candleData && candleSeries.setData(props.candleData); // adding data only if present

    var volumeSeries = chart.addHistogramSeries({
      color: theme.palette.secondary.main,
      priceFormat: {
        type: 'volume'
      },
      priceScaleId: '',
      scaleMargins: {
        top: 0.8,
        bottom: 0
      }
    });
    // let volData = [
    //   {
    //     time: 1558688100,
    //     value: 19103293.0
    //     // color: 'rgba(0, 150, 136, 0.8)'
    //   },
    //   {
    //     time: 1558688400,
    //     value: 21737523.0
    //     // color: 'rgba(0, 150, 136, 0.8)'
    //   },
    //   {
    //     time: 1558688700,
    //     value: 29328713.0
    //     // color: 'rgba(0, 150, 136, 0.8)'
    //   }
    // ];
    props.volumeData && volumeSeries.setData(props.volumeData); // adding data only if present
  }, [currentTheme]);

  return (
    // <PerfectScrollbar>
    <div className={classes.root} ref={chartRef}></div>
    // </PerfectScrollbar>
  );
}

TradingViewChart.propTypes = {
  candleData: PropTypes.any,
  volumeData: PropTypes.any,
  width: PropTypes.any,
  height: PropTypes.any
};

//   useEffect(() => {
//     window.location.reload(false);
//   }, [currentTheme]);

//   useEffect(() => {
//     let chartTheme =
//       currentTheme === 'lightTheme'
//         ? {
//             backgroundColor: '#FFFFFF',
//             lineColor: '#2B2B43',
//             textColor: '#191919'
//           }
//         : {
//             backgroundColor: '#2B2B43',
//             lineColor: '#2B2B43',
//             textColor: '#D9D9D9'
//           };
//     chart.applyOptions({ layout: chartTheme }); // chart object is not accessible here as some refresh happens :(
//   }, [currentTheme]);
