import {
  COURSE_SET,
  MODULES_SET,
  LESSON_CURRENT_SET,
  CERTIFICATES_LOADING,
  CERTIFICATES_SET,
  CERTIFICATES_VERIFY_LOADING,
  CERTIFICATE_VERIFY_SET,
  CERTIFICATES_VERIFY_ERROR,
  CERTIFICATE_GENERATE_LOADING,
  CERTIFICATE_GENERATE_SET,
  CERTIFICATE_GENERATE_ERROR,
  // generate_specialization_certificate
  GENERATE_SPECIALIZATION_CERTIFICATE_LOADING,
  GENERATE_SPECIALIZATION_CERTIFICATE_SET,
  GENERATE_SPECIALIZATION_CERTIFICATE_ERROR,
  // generate_sample_certificate
  GENERATE_SAMPLE_CERTIFICATE_LOADING,
  GENERATE_SAMPLE_CERTIFICATE_SET,
  GENERATE_SAMPLE_CERTIFICATE_ERROR
} from '../types';

import * as moment from 'moment';

import { createReducer } from '@reduxjs/toolkit';
import store from '../store';

var initialObject = {
  loading: false,
  value: null,
  error: null,
  is_fetched: false
};

const initialState = {
  certificates_loading: false,
  certificates_list: [],

  certificate_verify_loading: null,
  certificate_verify: null,
  certificate_verify_error: null,

  // generated certificate is appended to certificates_list
  certificate_generate_loading: false,
  certificate_generate_error: null,
  // generate_specialization_certificate

  generate_specialization_certificate: { ...initialObject },
  // generate_sample_certificate

  generate_sample_certificate: { ...initialObject }
};

export default createReducer(initialState, {
  CERTIFICATES_LOADING: (state, action) => {
    state['certificates_loading'] = action.payload;
  },
  CERTIFICATES_SET: (state, action) => {
    state['certificates_list'] = action.payload;
  },
  // verify
  CERTIFICATES_VERIFY_LOADING: (state, action) => {
    state['certificate_verify_loading'] = action.payload;
  },
  CERTIFICATE_VERIFY_SET: (state, action) => {
    state['certificate_verify'] = action.payload;
  },
  CERTIFICATES_VERIFY_ERROR: (state, action) => {
    state['certificate_verify_error'] = action.payload;
  },
  CERTIFICATE_GENERATE_LOADING: (state, action) => {
    state['certificate_generate_loading'] = action.payload;
  },
  CERTIFICATE_GENERATE_SET: (state, action) => {
    state['certificates_list'].push(action.payload);
  },
  CERTIFICATE_GENERATE_ERROR: (state, action) => {
    state['certificate_generate_error'] = action.payload;
  },
  //  -- generate_specialization_certificate
  GENERATE_SPECIALIZATION_CERTIFICATE_LOADING: (state, action) => {
    state.generate_specialization_certificate['loading'] = action.payload;
  },
  GENERATE_SPECIALIZATION_CERTIFICATE_SET: (state, action) => {
    state.generate_specialization_certificate['value'] = action.payload;
    state.generate_specialization_certificate['is_fetched'] = true;
  },
  GENERATE_SPECIALIZATION_CERTIFICATE_ERROR: (state, action) => {
    state.generate_specialization_certificate['error'] = action.payload;
  },
  //  -- generate_sample_certificate
  GENERATE_SAMPLE_CERTIFICATE_LOADING: (state, action) => {
    state.generate_sample_certificate['loading'] = action.payload;
  },
  GENERATE_SAMPLE_CERTIFICATE_SET: (state, action) => {
    state.generate_sample_certificate['value'] = action.payload;
    state.generate_sample_certificate['is_fetched'] = true;
  },
  GENERATE_SAMPLE_CERTIFICATE_ERROR: (state, action) => {
    state.generate_sample_certificate['error'] = action.payload;
  }
});
