import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

function ScrollReset() {
  const location = useLocation();
  const myRef = useRef();

  useEffect(() => {
    console.log(`--------------------scrolled--------------`);
    myRef.current.scrollTo(0, 0);
  }, [location.pathname]);

  return <div ref={myRef}></div>;
}

export default ScrollReset;

// class ScrollReset extends Component {
//   constructor(props) {
//     super(props);
//     this.myRef = React.createRef(); // Create a ref object
//   }
//   componentDidMount() {
//     this.myRef.current.scrollTo(0, 0);
//     console.log(`--------------------scrolled--------------`);
//   }
//   render() {
//     return <div ref={this.myRef}></div>;
//   } // attach the ref property to a dom element
// }
// export default ScrollReset;
