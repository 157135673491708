// React-Redux
import React from 'react';
import { CopyToClipboard as Copy } from 'react-copy-to-clipboard';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
// MUI
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { setSnackbar as a_setSnackbar } from 'redux/actions/snackbarActions';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {}
}));

export default function CopyToClipboard(props) {
  //   const [name, setName] = React.useState(null);
  //   useEffect(() => {}, []);
  //   let certName = useSelector((state) => state.user.profile.name_on_certificate);
  let dispatch = useDispatch();

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Copy
        text={props.text}
        onCopy={() =>
          dispatch(a_setSnackbar('Copied to clipboard', 'success'))
        }>
        <Button variant="contained" color="primary" fullWidth={true}>
          {props.buttonName}
        </Button>
        {/* <button>{props.buttonName}</button> */}
      </Copy>
    </div>
  );
}

CopyToClipboard.defaultProps = {
  buttonFullWidth: false
};

CopyToClipboard.propTypes = {
  text: PropTypes.any,
  buttonName: PropTypes.any,
  buttonFullWidth: PropTypes.any
};
