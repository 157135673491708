import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenPopupContainer(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        size="small"
        variant={props.buttonVariant}
        color={props.buttonColor}
        fullWidth={props.buttonFullWidth}>
        {props.buttonIcon}
        &nbsp;
        {props.openerButtonName}
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {props.title}
            </Typography>
            {props.actionButton}
          </Toolbar>
        </AppBar>
        <h1>{props.children}</h1>
      </Dialog>
    </div>
  );
}

FullScreenPopupContainer.defaultProps = {
  isCloseButtonRequired: true,
  isDialogFullWidth: true,
  dialogMaxWidth: 'sm',
  buttonVariant: 'contained',
  buttonColor: 'primary',
  buttonFullWidth: false,
  buttonIcon: null
};

FullScreenPopupContainer.propTypes = {
  openerButtonName: PropTypes.any,
  title: PropTypes.any,
  contentText: PropTypes.any,
  actionButton: PropTypes.any,
  // default valued props
  isCloseButtonRequired: PropTypes.any,
  isDialogFullWidth: PropTypes.any,
  dialogMaxWidth: PropTypes.any,
  buttonVariant: PropTypes.any, // button
  buttonColor: PropTypes.any,
  buttonFullWidth: PropTypes.any,
  buttonIcon: PropTypes.any
};
