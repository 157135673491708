// React-Redux
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import 'react-perfect-scrollbar/dist/css/styles.css';

// MUI
import { makeStyles } from '@material-ui/styles';
import { setSnackbar as a_setSnackbar } from 'redux/actions/snackbarActions';
import { BookCall as a_BookCall } from 'redux/actions/dataActions';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import {
  Box,
  TextField,
  Button,
  LinearProgress,
  Typography
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    minWidth: 150
  }
}));

export default function BookACall(props) {
  const [name, setName] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [country, setCountry] = React.useState('+91');
  const [phone, setPhone] = React.useState(null);
  const [call_date_time, setDate] = React.useState(new Date());
  //   useEffect(() => {}, []);
  let s_book_call = useSelector((state) => state.data.book_call);
  let dispatch = useDispatch();

  const classes = useStyles();

  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };
  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };
  const handleDateChange = (date) => {
    console.log(date);
    console.log(moment(date).format('YYYY-MM-DD'));
    setDate(date);
  };

  const submitBookCallData = (event) => {
    event.preventDefault();

    let data = {
      name,
      email,
      phone,
      country,
      call_date_time: moment(call_date_time).format('YYYY-MM-DD')
    };
    dispatch(
      a_BookCall(data, function (resp) {
        console.log(resp);
        dispatch(a_setSnackbar('Successfully booked a call', 'success'));
      })
    );
  };

  return (
    <Box>
      <form autoComplete="on" noValidate onSubmit={submitBookCallData}>
        <Typography>Enter your details and we'll call you up</Typography>
        <Box mt={2}>
          <TextField
            error={s_book_call.error && s_book_call.error.name}
            helperText={s_book_call.error && s_book_call.error.name}
            fullWidth
            label="Name"
            name="name"
            onChange={handleNameChange}
            required
            value={name || ''}
            variant="outlined"
          />
        </Box>
        <Box mt={2}>
          <TextField
            error={s_book_call.error && s_book_call.error.email}
            helperText={s_book_call.error && s_book_call.error.email}
            fullWidth
            label="Email ID"
            name="email"
            onChange={handleEmailChange}
            required
            value={email || ''}
            variant="outlined"
          />
        </Box>
        <Box mt={2}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="country_id">Country</InputLabel>
            <Select
              fullWidth
              label="Country"
              labelId="demo-simple-select-outlined-label"
              id="country_id_select"
              value={country}
              onChange={handleCountryChange}>
              <MenuItem value="+91">India (+91)</MenuItem>
              <MenuItem value="+1">United States (+1)</MenuItem>
              <MenuItem value="+44">United Kingdom (+44)</MenuItem>
              <MenuItem value="+65">Singapore (+65)</MenuItem>
              {/* <MenuItem value="+1">Canada</MenuItem> */}
            </Select>
          </FormControl>
        </Box>
        <Box mt={2}>
          <TextField
            error={s_book_call.error && s_book_call.error.phone}
            helperText={s_book_call.error && s_book_call.error.phone}
            fullWidth
            label="Phone Number"
            name="phone"
            onChange={handlePhoneChange}
            required
            value={phone || ''}
            variant="outlined"
          />
        </Box>
        Your Phone: {country} {phone}
        <Box mt={2}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              error={s_book_call.error && s_book_call.error.call_date_time}
              helperText={
                s_book_call.error &&
                s_book_call.error.call_date_time &&
                "Make sure you've entered the date properly"
              }
              autoOk={true}
              required={true}
              margin="normal"
              id="date-picker-dialog"
              label="Pick a Date"
              format="dd/MM/yyyy"
              value={call_date_time}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
            />
          </MuiPickersUtilsProvider>
        </Box>
        {s_book_call.value && (
          <Box mt={2}>
            <Alert variant="filled" severity="success">
              Successfully booked a call
            </Alert>
          </Box>
        )}
        {s_book_call.loading && <LinearProgress />}
        <Box mt={2}>
          <Button type="submit" color="primary" variant="contained" fullWidth>
            {s_book_call.loading ? 'Booking call...' : 'Book a Call'}
          </Button>
        </Box>
      </form>
    </Box>
  );
}
