import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// auth
import auth from '../../../../authentication';
import { Divider } from '@material-ui/core';
import { PopupContainer } from 'components';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  icon: {
    color: theme.palette.primary.contrastText
  }
}));

function ProfileIcon(props) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    auth.logout();
    handleClose();
    // props.history.push('/sign-in2');
  };

  let menuItems = props.user.is_authenticated ? (
    <div>
      {/* <MenuItem component={Link} onClick={handleClose}>
        <PopupContainer openerButtonName="Test Popup"></PopupContainer>
      </MenuItem> */}
      <MenuItem component={Link} to={'/account'} onClick={handleClose}>
        Profile
      </MenuItem>
      <MenuItem component={Link} to={'/sign-in2'} onClick={handleSignOut}>
        SignOut
      </MenuItem>
    </div>
  ) : (
    <div>
      <MenuItem component={Link} to={'/sign-in2'} onClick={handleClose}>
        SignIn
      </MenuItem>
      <Divider />
      <MenuItem component={Link} to={'/all-courses'} onClick={handleClose}>
        View All Courses
      </MenuItem>
    </div>
  );

  // The below doesn't work cause of the need of "anchorEl" -- IDK what this is... might be some position

  // // my logic

  // const [open, setOpen] = React.useState(false);

  // const setMenuOpen = () => {
  //   setOpen(true);
  // };

  // const setMenuClose = () => {
  //   setOpen(false);
  // };

  return (
    <div>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit">
        <AccountCircle className={classes.icon} />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}>
        {menuItems}
      </Menu>
    </div>
  );
}

function mapStateToProps(state) {
  return { user: state.user };
}

export default connect(mapStateToProps, {})(ProfileIcon);
