// React-Redux
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import config from 'config/main';
// MUI
import { makeStyles } from '@material-ui/styles';
import { PopupContainer } from 'components';
import { setSnackbar as a_setSnackbar } from 'redux/actions/snackbarActions';
import { generateSampleCertificate as a_generateSampleCertificate } from 'redux/actions/certificateActions';
import {
  Box,
  TextField,
  Button,
  LinearProgress,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {}
}));

export default function SampleCertificatePopup(props) {
  const [name, setName] = React.useState(null);
  //   useEffect(() => {}, []);
  let s_generate_sample_certificate = useSelector(
    (state) => state.certificate.generate_sample_certificate
  );
  let dispatch = useDispatch();

  const classes = useStyles();

  const handleChange = (event) => {
    setName(event.target.value);
  };

  const generateCertificate = (event) => {
    event.preventDefault();

    dispatch(
      a_generateSampleCertificate({ name: name }, function (resp) {
        console.log(resp);
        dispatch(a_setSnackbar('Generated a sample certificate', 'success'));
      })
    );
  };

  return (
    <PopupContainer
      openerButtonName="Click to Generate a sample certificate"
      buttonFullWidth={props.buttonFullWidth}
      buttonColor="primary"
      buttonVariant="contained"
      title="Generate a sample certificate now"
      contentText="Enter your name below and generate a certificate to see how your certificate 
      design would look like."
      dialogMaxWidth="sm">
      <form autoComplete="off" noValidate onSubmit={generateCertificate}>
        <TextField
          error={
            s_generate_sample_certificate.error &&
            s_generate_sample_certificate.error.name
          }
          helperText={
            s_generate_sample_certificate.error &&
            s_generate_sample_certificate.error.name
          }
          fullWidth
          label="Name on certificate"
          margin="dense"
          name="name_on_certificate"
          onChange={handleChange}
          required
          value={name || ''}
          variant="standard"
        />
        {s_generate_sample_certificate.loading && <LinearProgress />}
        <Box mt={2}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            onClick={generateCertificate}>
            {s_generate_sample_certificate.loading
              ? 'Generating...'
              : 'Generate certificate'}
          </Button>
        </Box>
      </form>

      {s_generate_sample_certificate.is_fetched && (
        <Box mt={2}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <PerfectScrollbar>
              <img
                src={s_generate_sample_certificate.value.certificate_file}
                alt="sample certificate"
              />
            </PerfectScrollbar>
          </Box>
          <Typography color="textSecondary" align="center">
            This is just a sample certificate. The actual certificate for:
            {config.membershipTypes.MEMBERSHIP_3.name} will look different and
            be of better quality with the course name & valid QR-code sign. The
            "Sample Certificate" watermark won't be there in the actual
            certificate
          </Typography>
        </Box>
      )}
    </PopupContainer>
  );
}

SampleCertificatePopup.defaultProps = {
  buttonFullWidth: false
};

SampleCertificatePopup.propTypes = {
  buttonFullWidth: PropTypes.any
};
