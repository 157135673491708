import { createReducer } from '@reduxjs/toolkit';

var initialObject = {
  loading: false,
  value: null,
  error: null,
  is_fetched: false
};

const initialState = {
  // mcqs

  mcqs: { ...initialObject },
  // core_interview

  core_interview: { ...initialObject },
  // general_interview

  general_interview: { ...initialObject },
  // notes_and_definitions

  notes_and_definitions: { ...initialObject },
  // candles_wiki

  candles_wiki: { ...initialObject }
};

export default createReducer(initialState, {
  // get -- mcqs
  MCQS_LOADING: (state, action) => {
    state.mcqs['loading'] = action.payload;
  },
  MCQS_SET: (state, action) => {
    state.mcqs['value'] = action.payload;
    state.mcqs['is_fetched'] = true;
  },
  MCQS_ERROR: (state, action) => {
    state.mcqs['error'] = action.payload;
  },
  // get -- core_interview
  CORE_INTERVIEW_LOADING: (state, action) => {
    state.core_interview['loading'] = action.payload;
  },
  CORE_INTERVIEW_SET: (state, action) => {
    state.core_interview['value'] = action.payload;
    state.core_interview['is_fetched'] = true;
  },
  CORE_INTERVIEW_ERROR: (state, action) => {
    state.core_interview['error'] = action.payload;
  },
  // get -- general_interview
  GENERAL_INTERVIEW_LOADING: (state, action) => {
    state.general_interview['loading'] = action.payload;
  },
  GENERAL_INTERVIEW_SET: (state, action) => {
    state.general_interview['value'] = action.payload;
    state.general_interview['is_fetched'] = true;
  },
  GENERAL_INTERVIEW_ERROR: (state, action) => {
    state.general_interview['error'] = action.payload;
  },
  // get -- notes_and_definitions
  NOTES_AND_DEFINITIONS_LOADING: (state, action) => {
    state.notes_and_definitions['loading'] = action.payload;
  },
  NOTES_AND_DEFINITIONS_SET: (state, action) => {
    state.notes_and_definitions['value'] = action.payload;
    state.notes_and_definitions['is_fetched'] = true;
  },
  NOTES_AND_DEFINITIONS_ERROR: (state, action) => {
    state.notes_and_definitions['error'] = action.payload;
  },
  // get -- candles_wiki
  CANDLES_WIKI_LOADING: (state, action) => {
    state.candles_wiki['loading'] = action.payload;
  },
  CANDLES_WIKI_SET: (state, action) => {
    state.candles_wiki['value'] = action.payload;
    state.candles_wiki['is_fetched'] = true;
  },
  CANDLES_WIKI_ERROR: (state, action) => {
    state.candles_wiki['error'] = action.payload;
  }
});
