import axios from 'axios';
import store from '../store';

import { THEME_SET } from '../types';

export const setTheme = (themeType = 'lightTheme') => async (dispatch) => {
  dispatch({
    type: THEME_SET,
    payload: themeType
  });
};
