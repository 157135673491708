import axios from 'axios';
import store from './store';
import { setSnackbar } from './actions/snackbarActions';
import { django } from './URLS';

/*
Read about named params in JS:
https://medium.com/@afontcu/cool-javascript-9-named-arguments-functions-that-get-and-return-objects-337b6f8cfa07
*/

export const requestHandler = async ({
  dispatch,
  requestType = 'GET',
  requestUrl = null,
  postData = null,
  loadingReducer = null,
  successReducer = null,
  errorReducer = null,
  successCallback = null,
  errorCallback = null,
  apiBaseUrl = django
}) => {
  // set loading to true before operation
  if (loadingReducer) {
    dispatch({
      type: loadingReducer,
      payload: true
    });
  }
  // call the API
  try {
    if (!postData) {
      // initializing the postData to {} if there's no data sent
      postData = {};
    }
    let result;
    if (requestType === 'GET') {
      result = await axios.get(`${apiBaseUrl}${requestUrl}`);
    }
    if (requestType === 'POST') {
      result = await axios.post(`${apiBaseUrl}${requestUrl}`, postData);
    }
    if (requestType === 'PATCH') {
      result = await axios.patch(`${apiBaseUrl}${requestUrl}`, postData);
    }
    if (requestType === 'DELETE') {
      result = await axios.delete(`${apiBaseUrl}${requestUrl}`);
    }

    let data = result.data;

    // set payload to data obtained to the success reducer
    if (successReducer) {
      dispatch({
        type: successReducer,
        payload: data
      });
    }

    // clearing errors after success
    if (errorReducer) {
      dispatch({
        type: errorReducer,
        payload: null
      });
    }

    // set loading to false after operation
    if (loadingReducer) {
      dispatch({
        type: loadingReducer,
        payload: false
      });
    }

    // call the successCallback if there's a callback function
    if (successCallback) {
      successCallback(data);
    }
  } catch (err) {
    // set loading to false as there's an error
    if (loadingReducer) {
      dispatch({
        type: loadingReducer,
        payload: false
      });
    }

    // console.error log it
    console.error(
      `ERROR_HAPPENED in ${requestType}::${requestUrl} API call. ERROR_OBJ: ${err}`
    );

    // handle the error
    errorHandler(err, errorReducer, dispatch);

    // call the errorCallback if there's a callback function
    if (errorCallback) {
      errorCallback(err);
    }
  }
};

const errorHandler = (err, errorReducer, dispatch) => {
  if (err.response && err.response.data) {
    let errorData = err.response.data;

    switch (errorData.error_type) {
      case 'VALIDATION_ERROR':
        let error_object = {};
        console.log('VALIDATION_ERROR');
        for (const key in errorData) {
          let value = errorData[key];
          if (key !== 'error_type') {
            error_object[key] = value;
          }
        }
        // set snackbar
        dispatch(setSnackbar(`There were validation errors`, 'error'));
        // # do something with the error_object
        if (errorReducer && Object.keys(error_object).length) {
          dispatch({
            type: errorReducer,
            payload: error_object
          });
        }
        break;

      case 'CUSTOM_ERROR':
        console.log('CUSTOM_ERROR');
        // # set snackbar from the message field
        dispatch(setSnackbar(`${errorData.message}`, 'error'));
        if (errorReducer && errorData.error_details) {
          dispatch({
            type: errorReducer,
            payload: errorData.error_details
          });
        }
        break;

      case 'AUTHENTICATION_FAILED_ERROR':
        console.log('AUTHENTICATION_FAILED_ERROR');
        dispatch(
          setSnackbar(`Authentication failed: ${errorData.detail}`, 'error')
        );
        // removing the token as there's foul request
        localStorage.removeItem('token');
        break;
      case 'NOT_AUTHENTICATED_ERROR':
        console.log('NOT_AUTHENTICATED_ERROR');
        dispatch(
          setSnackbar(`You're not authenticated: ${errorData.detail}`, 'error')
        );
        // removing the token as there's foul request
        localStorage.removeItem('token');
        break;
      case 'PERMISSION_DENIED_ERROR':
        console.log('PERMISSION_DENIED_ERROR');
        dispatch(
          setSnackbar(`You don't have the permission for this action`, 'error')
        );
        break;
      case 'NOT_FOUND_ERROR':
        console.log('NOT_FOUND_ERROR');
        dispatch(setSnackbar(`Not found - 404`, 'error'));
        if (errorReducer) {
          dispatch({
            type: errorReducer,
            payload: 'Not found'
          });
        }
        break;
      case '404_ERROR':
        console.log('404_ERROR');
        dispatch(setSnackbar(`Not found - 404`, 'error'));
        if (errorReducer) {
          dispatch({
            type: errorReducer,
            payload: 'Not found'
          });
        }
        break;
      case 'THROTTLED_ERROR':
        console.log('THROTTLED_ERROR');
        dispatch(
          setSnackbar(
            `Oops...you did that too many times. Come back tomorrow!`,
            'error'
          )
        );
        if (errorReducer) {
          dispatch({
            type: errorReducer,
            payload: 'throttled'
          });
        }
        break;
      default:
        // dispatch(setSnackbar(`Something went wrong..try again`, 'error'));
        if (errorReducer) {
          dispatch({
            type: errorReducer,
            payload: 'UNDETECTED_ERROR_OCCURRED'
          });
          dispatch(
            setSnackbar(
              `Something went wrong. Couldn't process your request`,
              'error'
            )
          );
        }
    }
  } else {
    // for stuff like network error -- when the client has no internet connection, it comes
    // here
    dispatch(
      setSnackbar(
        `Something went wrong. Couldn't process your request`,
        'error'
      )
    );
  }
};

/*
Format of the important Errors
VALIDATION_ERROR:
{
    "username": [
        "A user with that username already exists."
    ],
    "email": [
        "Enter a valid email address."
    ],
    "error_type": "VALIDATION_ERROR"
}
CUSTOM_ERROR:
{
    "message": "Invalid Credentials",
    "error_object": null,
    "error_details": {
        "email": "Invalid Email"
    },
    "error_type": "CUSTOM_ERROR"
}
AUTHENTICATION_FAILED_ERROR:
{
    "detail": "SIgnature expired",
    "error_type": "AUTHENTICATION_FAILED_ERROR"
}
NOT_AUTHENTICATED_ERROR:
{
    "detail": "Authentication credentials were not provided.",
    "error_type": "NOT_AUTHENTICATED_ERROR"
}

*/

/*
VALIDATION_ERROR
"AUTHENTICATION_FAILED_ERROR"
"NOT_AUTHENTICATED_ERROR"
"PERMISSION_DENIED_ERROR"
"NOT_FOUND_ERROR"
"CUSTOM_ERROR"
"404_ERROR"
*/
