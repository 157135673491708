import { useEffect } from 'react';
import { useLocation } from 'react-router';
const queryString = require('query-string');

function CaptureRefQueryString() {
  const location = useLocation();

  useEffect(() => {
    // console.log(location);
    const parsed = queryString.parse(location.search);
    localStorage.setItem('ref', parsed.ref);
    captureCountry(parsed.country);
  }, [location.pathname]);

  return null;
}

const captureCountry = (queryCountry) => {
  let allowedCountries = ['US', 'IN'];
  let defaultCountry = 'IN';
  let country = queryCountry;
  if (country) {
    if (allowedCountries.includes(country)) {
      localStorage.setItem('country', country);
    } else {
      localStorage.setItem('country', defaultCountry);
    }
  } else {
    if (!allowedCountries.includes(localStorage.getItem('country'))) {
      localStorage.setItem('country', defaultCountry);
    }
  }
};

export default CaptureRefQueryString;
