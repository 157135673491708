import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import config from 'config/main';
import { Button, LinearProgress, Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { getHistory as a_getHistory } from 'redux/actions/stockActions';
import { setSnackbar as a_setSnackbar } from 'redux/actions/snackbarActions';

const stockOptions = Object.keys(config.stock.stocks).map(
  (stockNameKey) => stockNameKey
);
const timeFrameOptions = config.stock.timeFrames;

export default function StockChooserForm(props) {
  let s_history = useSelector((state) => state.stock.history);
  let dispatch = useDispatch();

  const generateHistory = (event) => {
    event.preventDefault();

    if (props.callHistoryApi) {
      dispatch(
        a_getHistory(tf, stock, function (resp) {
          console.log(resp);
          dispatch(a_setSnackbar('Success! Scroll Down :)', 'success'));
        })
      );
    } else if (props.redirectToStockLink && props.redirectLinkFormat) {
      let link = props.redirectLinkFormat.replace(':tf', tf);
      link = link.replace(':stock', stock);
      // console.log(`LINK:::::${link}`);
      // historyPath.push(link); // -- Not using this as this doesn't reload the page. Hence it doesn't close the dialog :P
      window.location = link; // -- Hence using a shitty way of redirecting -- which isn't recommended in REACT :P
    }
  };

  const [stock, setStock] = React.useState(null);
  const [tf, setTf] = React.useState(null);
  const [inputValueStock, setInputValueStock] = React.useState('');
  const [inputValueTf, setInputValueTf] = React.useState('');

  return (
    <box>
      {/* <div>{`stock: ${stock !== null ? `'${stock}'` : 'null'}`}</div>
      <div>{`tf: ${tf !== null ? `'${tf}'` : 'null'}`}</div>
      <div>{`inputValueStock: '${inputValueStock}'`}</div>
      <div>{`inputValueTf: '${inputValueTf}'`}</div> */}
      <br />
      {/* Stock Selection */}
      <Typography variant="subtitle1" gutterBottom>
        Choose the stock and time-frame:
      </Typography>
      <Autocomplete
        fullWidth
        value={stock}
        onChange={(event, newValue) => {
          setStock(newValue);
        }}
        inputValue={inputValueStock}
        onInputChange={(event, newInputValue) => {
          setInputValueStock(newInputValue);
        }}
        id="controllable-stock"
        options={stockOptions}
        // style={{ width: 300 }}
        renderInput={(params) => (
          <TextField {...params} label="Choose The Stock" variant="outlined" />
        )}
      />
      <br />
      {/* Time-Frame selection */}
      <Autocomplete
        fullWidth
        value={tf}
        onChange={(event, newValue) => {
          setTf(newValue);
        }}
        inputValue={inputValueTf}
        onInputChange={(event, newInputValue) => {
          setInputValueTf(newInputValue);
        }}
        id="controllable-tf"
        options={timeFrameOptions}
        // style={{ width: 300 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Choose The Time Frame"
            variant="outlined"
          />
        )}
      />
      <br />

      <Button
        type="submit"
        color="primary"
        variant="contained"
        fullWidth
        disabled={!(stock && tf)}
        onClick={generateHistory}>
        {s_history.loading ? 'Loading....' : props.buttonName}
      </Button>
      {s_history.loading && <LinearProgress />}
    </box>
  );
}

StockChooserForm.propTypes = {
  buttonName: PropTypes.any,
  callHistoryApi: PropTypes.any,
  redirectToStockLink: PropTypes.any,
  redirectLinkFormat: PropTypes.any
};
