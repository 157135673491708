// React-Redux
import React from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { twilight as codeStyle } from 'react-syntax-highlighter/dist/esm/styles/prism';
// import SyntaxHighlighter from 'react-syntax-highlighter';
// import { docco as codeStyle } from 'react-syntax-highlighter/dist/esm/styles/hljs';
// MUI
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';

// List
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  textData: {
    'white-space': 'pre-wrap;'
  },
  image: {
    width: '100%',
    maxHeight: 400
  }
}));

export default function NotesBlock(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card className={classes.root}>
        <CardContent>
          <div>
            <Typography variant="h4" component="h2">
              {props.heading}
            </Typography>
            <br />
            {props.image && (
              <img
                loading="lazy"
                alt="Cover"
                className={clsx(classes.image)}
                src={props.image}
              />
            )}
            <br />

            <Typography className={classes.textData} color="textSecondary">
              {props.textData}
            </Typography>
            {props.listDataTitle && (
              <Box>
                <br />
                <Typography className={classes.pos} color="textSecondary">
                  {props.listDataTitle}
                </Typography>
              </Box>
            )}

            {props.listData && (
              <Box>
                {/* <br /> */}
                <List component="nav" aria-label="main mailbox folders">
                  {props.listData.map((element) => (
                    <ListItem button>
                      <ListItemIcon>
                        <CheckCircleIcon />
                      </ListItemIcon>
                      <ListItemText primary={element} />
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}

            {props.codeTitle && (
              <Box>
                <br />
                <Typography className={classes.pos} color="textSecondary">
                  {props.codeTitle}
                </Typography>
              </Box>
            )}
            {props.code && (
              <Box>
                <br />
                <PerfectScrollbar>
                  <SyntaxHighlighter
                    language={props.codeLanguage}
                    style={codeStyle}>
                    {props.code}
                  </SyntaxHighlighter>
                </PerfectScrollbar>
              </Box>
            )}
          </div>
        </CardContent>
        <CardActions></CardActions>
      </Card>
    </div>
  );
}

NotesBlock.propTypes = {
  heading: PropTypes.any,
  image: PropTypes.any,
  textData: PropTypes.any,
  listDataTitle: PropTypes.any,
  listData: PropTypes.any,
  codeTitle: PropTypes.any,
  codeLanguage: PropTypes.any,
  code: PropTypes.any
};
