import { createReducer } from '@reduxjs/toolkit';

var initialObject = {
  loading: false,
  value: null,
  error: null,
  is_fetched: false
};

const initialState = {
  // news

  news: { ...initialObject },
  // history

  history: { ...initialObject },
  // latest_history

  latest_history: { ...initialObject },
  // my_positions

  my_positions: { ...initialObject },
  // close_position

  close_position: { ...initialObject },
  // open_position

  open_position: { ...initialObject }
};

export default createReducer(initialState, {
  // get -- news
  NEWS_LOADING: (state, action) => {
    state.news['loading'] = action.payload;
  },
  NEWS_SET: (state, action) => {
    state.news['value'] = action.payload;
    state.news['is_fetched'] = true;
  },
  NEWS_ERROR: (state, action) => {
    state.news['error'] = action.payload;
  },

  // get -- history
  HISTORY_LOADING: (state, action) => {
    state.history['loading'] = action.payload;
  },
  HISTORY_SET: (state, action) => {
    state.history['value'] = action.payload;
    state.history['is_fetched'] = true;
  },
  HISTORY_ERROR: (state, action) => {
    state.history['error'] = action.payload;
  },

  // get -- latest_history
  LATEST_HISTORY_LOADING: (state, action) => {
    state.latest_history['loading'] = action.payload;
  },
  LATEST_HISTORY_SET: (state, action) => {
    state.latest_history['value'] = action.payload;
    state.latest_history['is_fetched'] = true;
  },
  LATEST_HISTORY_ERROR: (state, action) => {
    state.latest_history['error'] = action.payload;
  },

  // get -- my_positions
  MY_POSITIONS_LOADING: (state, action) => {
    state.my_positions['loading'] = action.payload;
  },
  MY_POSITIONS_SET: (state, action) => {
    state.my_positions['value'] = action.payload;
    state.my_positions['is_fetched'] = true;
  },
  MY_POSITIONS_ERROR: (state, action) => {
    state.my_positions['error'] = action.payload;
  },
  // get -- close_position
  CLOSE_POSITION_LOADING: (state, action) => {
    state.close_position['loading'] = action.payload;
  },
  CLOSE_POSITION_SET: (state, action) => {
    state.close_position['value'] = action.payload;
    state.close_position['is_fetched'] = true;
  },
  CLOSE_POSITION_ERROR: (state, action) => {
    state.close_position['error'] = action.payload;
  },
  // get -- open_position
  OPEN_POSITION_LOADING: (state, action) => {
    state.open_position['loading'] = action.payload;
  },
  OPEN_POSITION_SET: (state, action) => {
    state.open_position['value'] = action.payload;
    state.open_position['is_fetched'] = true;
    // To also add the new position to the positions reducer as we don't wanna call the get-positions API again :)
    // state.my_positions['value'].positions.push(action.payload);
  },
  OPEN_POSITION_ERROR: (state, action) => {
    state.open_position['error'] = action.payload;
  }
});
