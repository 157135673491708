// React-Redux
import React from 'react';

import PropTypes from 'prop-types';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import LockOpenIcon from '@material-ui/icons/LockOpen';
// MUI
import { makeStyles } from '@material-ui/styles';
import { PopupContainer } from 'components';
import { Box, Button, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    actionIcon: {
      marginRight: theme.spacing(3)
    }
  }
}));

export default function SigninSignupPopup(props) {
  const classes = useStyles();

  return (
    <PopupContainer
      buttonFullWidth={props.buttonFullWidth}
      buttonColor={props.colorType}
      title="SignIn/SignUp to continue"
      buttonVariant="contained"
      contentText="You need to SignIn or SignUp to buy a membership"
      openerButtonName={
        <>
          <ShoppingCartIcon className={classes.actionIcon} /> &nbsp;{' '}
          {props.buttonName}{' '}
        </>
      }>
      <Box mt={6}>
        <Button
          href="/sign-up?next=/buy-membership"
          variant="contained"
          color="primary"
          fullWidth>
          <AccountBalanceIcon /> &nbsp; Create an account
        </Button>
      </Box>
      <Box textAlign="center" mt={2}>
        <Typography>Or</Typography>
      </Box>
      <Box mt={2}>
        <Button
          href="/sign-in2?next=/buy-membership"
          variant="contained"
          color="primary"
          fullWidth>
          <LockOpenIcon /> &nbsp; Sign-in
        </Button>
      </Box>
    </PopupContainer>
  );
}

SigninSignupPopup.defaultProps = {
  buttonFullWidth: false
};

SigninSignupPopup.propTypes = {
  colorType: PropTypes.any,
  buttonFullWidth: PropTypes.any,
  buttonFullWidth: PropTypes.any,
  buttonName: PropTypes.any
};
