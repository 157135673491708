// React-Redux
import React from 'react';

import PropTypes from 'prop-types';
// MUI
import { makeStyles } from '@material-ui/styles';

import { Button, Box } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import config from 'config/main';
import { BookACall, VideoPlayerPopup } from 'components';

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(1)
  }
}));

export default function BuyMembershipButtonLink(props) {
  const classes = useStyles();

  return (
    <Box display="flex" flexWrap="wrap">
      <Box m={0.5}>
        <Button
          //   className={classes.action}
          color={props.buttonColor}
          variant={props.buttonVariant}
          size="small"
          component="a"
          href="/buy-membership">
          <ShoppingCartIcon className={classes.root} />
          Buy @ {config.general.price}
        </Button>
      </Box>
      {/* <Box m={0.5}>
        <BookACall />
      </Box> */}
      {config.general.enableIntroductionVideoComponent && (
        <Box m={0.5}>
          <VideoPlayerPopup
            buttonName="video"
            src={config.buyMembershipCard.bottomLeftVideoSrc}
            heading={config.buyMembershipCard.bottomLeftVideoHeading}
          />
        </Box>
      )}
    </Box>
  );
}

BuyMembershipButtonLink.defaultProps = {
  buttonColor: 'primary',
  buttonVariant: 'contained'
};

BuyMembershipButtonLink.propTypes = {
  buttonColor: PropTypes.any,
  buttonVariant: PropTypes.any
};
