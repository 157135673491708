// React-Redux
import React from 'react';

import { useHistory } from 'react-router-dom';
// MUI
import { makeStyles } from '@material-ui/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import CardMembershipIcon from '@material-ui/icons/CardMembership';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.background.dark
    },
    marginTop: theme.spacing(2),
    borderRadius: theme.spacing(2)
  }
}));

export default function CertificateListElement(props) {
  const classes = useStyles();
  const history = useHistory();

  const certificateView = (certificateId) => {
    console.log(`clicked to view certificate: ${certificateId}`);
    history.push(`/verify/${certificateId}`);
  };

  return (
    // <div className={classes.root}>
    <ListItem
      className={classes.root}
      onClick={() => {
        certificateView(props.certificateId);
      }}>
      <ListItemAvatar>
        <Avatar>
          <CardMembershipIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={props.main} secondary={props.secondary} />
    </ListItem>
    // </div>
  );
}

/*
certificateId
main
secondary
*/
