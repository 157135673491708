import React from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

import { addMembership as a_addMembership } from '../../redux/actions/membershipActions';
import {
  setSnackbar as a_setSnackbar,
  setSnackbar
} from '../../redux/actions/snackbarActions';
import { Box, Typography, LinearProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  textField: {
    marginTop: theme.spacing(3)
  }
}));

export default function AddReferredCustomerPopup(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [membership_id, setMembershipId] = useState('');

  let s_add_membership = useSelector(
    (state) => state.membership.add_membership
  );
  let dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // // // Change handlers
  const handleMembershipIdChange = (event) => {
    event.persist();
    setMembershipId(event.target.value);
  };

  const handleAddMembership = async (event) => {
    event.preventDefault();

    dispatch(
      a_addMembership(membership_id, function (resp) {
        dispatch(setSnackbar('Successfully added membership', 'success'));
      })
    );
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        size="small"
        variant="contained"
        color="primary">
        <AccountBalanceIcon className={classes.socialIcon} />
        Add my referred membership
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          Add your referred membership now
        </DialogTitle>
        <form className={classes.form} onSubmit={handleAddMembership}>
          <DialogContent>
            <DialogContentText>
              Please enter the Membership ID of your customer. The membership ID
              would be sent to the customer in an email after their payment
            </DialogContentText>

            <TextField
              className={classes.textField}
              error={
                s_add_membership.error &&
                s_add_membership.error.membership_error
              }
              fullWidth
              helperText={
                s_add_membership.error &&
                s_add_membership.error.membership_error
                  ? s_add_membership.error.membership_error
                  : null
              }
              label="Membership ID"
              name="membership_id"
              // required={true}
              onChange={handleMembershipIdChange}
              type="text"
              value={membership_id || ''}
              variant="standard"
            />

            <Box margin="10px 0">
              <Typography variant="subtitle2">
                {s_add_membership.value ? (
                  <>Successfully added a membership</>
                ) : null}
              </Typography>
              {s_add_membership.loading && <LinearProgress />}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
            <Button color="primary" type="submit">
              {s_add_membership.loading ? (
                <>Saving details.....</>
              ) : (
                <>Add membership</>
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
