import {
  // news
  NEWS_LOADING,
  NEWS_SET,
  NEWS_ERROR,
  // history
  HISTORY_LOADING,
  HISTORY_SET,
  HISTORY_ERROR,
  // latest_history
  LATEST_HISTORY_LOADING,
  LATEST_HISTORY_SET,
  LATEST_HISTORY_ERROR,
  // my_positions
  MY_POSITIONS_LOADING,
  MY_POSITIONS_SET,
  MY_POSITIONS_ERROR,
  // close_position
  CLOSE_POSITION_LOADING,
  CLOSE_POSITION_SET,
  CLOSE_POSITION_ERROR,
  // open_position
  OPEN_POSITION_LOADING,
  OPEN_POSITION_SET,
  OPEN_POSITION_ERROR
} from '../types';

import { requestHandler } from '../requestHandler';

import config from 'config/main';

// NODE-JS API ACTIONS

// getNews
export const getNews = () => async (dispatch) => {
  requestHandler({
    dispatch: dispatch,
    requestType: 'GET',
    requestUrl: `/api/stock/news/`,
    postData: null,
    loadingReducer: NEWS_LOADING,
    successReducer: NEWS_SET,
    errorReducer: NEWS_ERROR,
    successCallback: null,
    errorCallback: null,
    apiBaseUrl: config.general.api_base_url_nodejs
  });
};

// getHistory
export const getHistory =
  (timeFrame, stock, showLatest, callback) => async (dispatch) => {
    requestHandler({
      dispatch: dispatch,
      requestType: 'GET',
      requestUrl: `/api/stock/history?tf=${timeFrame}&stock=${stock}&showLatest=${showLatest}`,
      postData: null,
      loadingReducer: HISTORY_LOADING,
      successReducer: HISTORY_SET,
      errorReducer: HISTORY_ERROR,
      successCallback: callback,
      errorCallback: null,
      apiBaseUrl: config.general.api_base_url_nodejs
    });
  };

// getLatestHistory
export const getLatestHistory =
  (callback = function () {}) =>
  async (dispatch) => {
    requestHandler({
      dispatch: dispatch,
      requestType: 'GET',
      requestUrl: `/api/stock/latestHistoryByPattern/`,
      postData: null,
      loadingReducer: LATEST_HISTORY_LOADING,
      successReducer: LATEST_HISTORY_SET,
      errorReducer: LATEST_HISTORY_ERROR,
      successCallback: callback,
      errorCallback: null,
      apiBaseUrl: config.general.api_base_url_nodejs
    });
  };

// DJANGO API ACTIONS

// getMyPositions
export const getMyPositions = () => async (dispatch) => {
  requestHandler({
    dispatch: dispatch,
    requestType: 'GET',
    requestUrl: `/api/stock/my_positions/`,
    postData: null,
    loadingReducer: MY_POSITIONS_LOADING,
    successReducer: MY_POSITIONS_SET,
    errorReducer: MY_POSITIONS_ERROR,
    successCallback: null,
    errorCallback: null
  });
};

// getClosePosition
export const closePosition = (positionId, callback) => async (dispatch) => {
  requestHandler({
    dispatch: dispatch,
    requestType: 'GET',
    requestUrl: `/api/stock/close_position/${positionId}/`,
    postData: null,
    loadingReducer: CLOSE_POSITION_LOADING,
    successReducer: CLOSE_POSITION_SET,
    errorReducer: CLOSE_POSITION_ERROR,
    successCallback: callback,
    errorCallback: null
  });
};

// getOpenPosition
export const openPosition =
  (stock_name, quantity, trade_action_type, tag, callback) =>
  async (dispatch) => {
    requestHandler({
      dispatch: dispatch,
      requestType: 'GET',
      requestUrl: `/api/stock/open_position/?stock_name=${stock_name}&quantity=${quantity}&trade_action_type=${trade_action_type}&tag=${tag}`,
      postData: null,
      loadingReducer: OPEN_POSITION_LOADING,
      successReducer: OPEN_POSITION_SET,
      errorReducer: OPEN_POSITION_ERROR,
      successCallback: callback,
      errorCallback: null
    });
  };
