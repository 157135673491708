import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Toolbar,
  Badge,
  Hidden,
  IconButton,
  Typography
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import GraphicEqIcon from '@material-ui/icons/GraphicEq';
import config from 'config/main';

import { ProfileIcon, ThemeChanger } from '../';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.topBar.main,
    color: theme.palette.text.primary,
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.divider}`,
    zIndex: theme.zIndex.drawer + 100
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  logo_text: {
    color: theme.palette.primary.contrastText,
    marginTop: '17px',
    marginLeft: '10px',
    marginBottom: '13px'
  },

  logo_container: {
    display: 'flex'
  },
  logo_img: {
    marginTop: '12px',
    width: '47px',
    height: '35px'
  },
  minimalSideDrawerButton: {
    color: theme.palette.topBar.contrastElement
  }
}));

const Topbar = (props) => {
  const { className, onMobileNavOpen, ...rest } = props;

  const classes = useStyles();

  const [notifications] = useState([]);

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <Hidden lgUp>
          <IconButton
            className={classes.minimalSideDrawerButton}
            onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>

        <RouterLink to="/">
          <div className={classes.logo_container}>
            {/* To not show the Logo in mobile/small screens */}
            <Hidden mdDown>
              <img
                alt="Logo"
                src={config.general.logo}
                className={classes.logo_img}
              />
            </Hidden>

            <Typography gutterBottom variant="h3" className={classes.logo_text}>
              {/* To be hidden in screens smaller than medium */}
              <Hidden mdDown>{config.general.company_name}</Hidden>
              {/* To be hidden in screens larger than large */}
              <Hidden lgUp>{config.general.company_short_name}</Hidden>
            </Typography>
          </div>
        </RouterLink>
        <div className={classes.flexGrow} />

        <ThemeChanger />
        <ProfileIcon />
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
