import {
  // new_token
  NEW_TOKEN_LOADING,
  NEW_TOKEN_SET,
  NEW_TOKEN_ERROR,
  USER_SET_LOADING,
  USER_SET,
  USER_SET_ERROR,
  USER_SIGNUP_LOADING,
  USER_SIGNUP_SET,
  USER_SIGNUP_ERROR,
  RESEND_VERIFICATION_LOADING,
  RESEND_VERIFICATION_SET,
  RESEND_VERIFICATION_ERROR,
  VERIFY_USER_EMAIL_SET,
  VERIFY_USER_EMAIL_LOADING,
  VERIFY_USER_EMAIL_ERROR,
  FORGOT_PASSWORD_LOADING,
  FORGOT_PASSWORD_SET,
  FORGOT_PASSWORD_ERROR,
  VERIFY_FORGOT_PASSWORD_LOADING,
  VERIFY_FORGOT_PASSWORD_SET,
  VERIFY_FORGOT_PASSWORD_ERROR,
  CHANGE_PASSWORD_FP_LOADING,
  CHANGE_PASSWORD_FP_SET,
  CHANGE_PASSWORD_FP_ERROR,
  USER_UNSET,
  USER_DETAILS_LOADING,
  USER_DETAILS_SET,
  USER_PROFILE_DETAILS_LOADING,
  USER_PROFILE_DETAILS_SET,
  USER_PROFILE_IMAGE_LOADING,
  USER_PROFILE_IMAGE_SET,
  HANDLE_PROFILE_DETAILS_EDIT_CHANGE,
  USER_COMPLETIONS_SET,
  COMPLETE_COURSE_OR_LESSON,
  ENROLL_COURSE_SET,
  // get_user_public_profile
  GET_USER_PUBLIC_PROFILE_LOADING,
  GET_USER_PUBLIC_PROFILE_SET,
  GET_USER_PUBLIC_PROFILE_ERROR
} from '../types';

import { createReducer } from '@reduxjs/toolkit';

import axios from 'axios';

var jwtDecode = require('jwt-decode');

var initialObject = {
  loading: false,
  value: null,
  error: null,
  is_fetched: false
};

const initialState = {
  token_set: false,
  user_loading: false,
  user: null,
  user_error: null,
  is_authenticated: false,
  // the below 2 are the user_details of USER_DETAILS_SET
  profile: '',
  courses: [],
  user_details_loading: true,
  user_profile_details_loading: false,
  user_profile_image_loading: false,
  // completions
  user_completions: {
    lessons: [],
    courses: []
  },
  user_auth: {
    // signup
    user_signup_loading: false,
    user_signed_up: null,
    user_signup_error: null,
    // resend verification
    resend_verification_loading: false,
    resend_verification: null,
    resend_verification_error: null,
    // verify user email
    verify_user_email_loading: false,
    verify_user_email: null,
    verify_user_email_error: null,
    // forgot password request
    forgot_password_loading: false,
    forgot_password: null,
    forgot_password_error: null,
    // verify forgot password
    verify_forgot_password_loading: false,
    verify_forgot_password: null,
    verify_forgot_password_error: null,
    // Change password for forgot password flow
    change_password_fp_loading: false,
    change_password_fp: null,
    change_password_fp_error: null
  },
  // new_token

  new_token: { ...initialObject },
  // get_user_public_profile

  get_user_public_profile: { ...initialObject }
};

export default createReducer(initialState, {
  // login
  USER_SET_LOADING: (state, action) => {
    state['user_loading'] = action.payload;
  },
  USER_SET: (state, action) => {
    state['user'] = action.payload.user;
    state['is_authenticated'] = true;
  },
  USER_SET_ERROR: (state, action) => {
    state['user_error'] = action.payload;
  },
  // signup
  USER_SIGNUP_LOADING: (state, action) => {
    state.user_auth['user_signup_loading'] = action.payload;
  },
  USER_SIGNUP_SET: (state, action) => {
    state.user_auth['user_signed_up'] = action.payload;
  },
  USER_SIGNUP_ERROR: (state, action) => {
    state.user_auth['user_signup_error'] = action.payload;
  },
  // resend verification
  RESEND_VERIFICATION_LOADING: (state, action) => {
    state.user_auth['resend_verification_loading'] = action.payload;
  },
  RESEND_VERIFICATION_SET: (state, action) => {
    state.user_auth['resend_verification'] = action.payload;
  },
  RESEND_VERIFICATION_ERROR: (state, action) => {
    state.user_auth['resend_verification_error'] = action.payload;
  },

  // user email verification
  VERIFY_USER_EMAIL_LOADING: (state, action) => {
    state.user_auth['verify_user_email_loading'] = action.payload;
  },
  VERIFY_USER_EMAIL_SET: (state, action) => {
    state.user_auth['verify_user_email'] = action.payload;
  },
  VERIFY_USER_EMAIL_ERROR: (state, action) => {
    state.user_auth['verify_user_email_error'] = action.payload;
  },
  // forgot password request
  FORGOT_PASSWORD_LOADING: (state, action) => {
    state.user_auth['forgot_password_loading'] = action.payload;
  },
  FORGOT_PASSWORD_SET: (state, action) => {
    state.user_auth['forgot_password'] = action.payload;
  },
  FORGOT_PASSWORD_ERROR: (state, action) => {
    state.user_auth['forgot_password_error'] = action.payload;
  },

  // verify forgot password
  VERIFY_FORGOT_PASSWORD_LOADING: (state, action) => {
    state.user_auth['verify_forgot_password_loading'] = action.payload;
  },
  VERIFY_FORGOT_PASSWORD_SET: (state, action) => {
    state.user_auth['verify_forgot_password'] = action.payload;
  },
  VERIFY_FORGOT_PASSWORD_ERROR: (state, action) => {
    state.user_auth['verify_forgot_password_error'] = action.payload;
  },
  // change password for forgot password flow
  CHANGE_PASSWORD_FP_LOADING: (state, action) => {
    state.user_auth['change_password_fp_loading'] = action.payload;
  },
  CHANGE_PASSWORD_FP_SET: (state, action) => {
    state.user_auth['change_password_fp'] = action.payload;
  },
  CHANGE_PASSWORD_FP_ERROR: (state, action) => {
    state.user_auth['change_password_fp_error'] = action.payload;
  },

  USER_UNSET: (state, action) => {
    state['user'] = {};
    // CHANGED_RECENTLY
    state['user']['user_type'] = 'UNAUTHENTICATED';
    state['user']['membership_type'] = 'MEMBERSHIP_NULL';
    state['is_authenticated'] = false;
  },
  USER_DETAILS_LOADING: (state, action) => {
    state['user_details_loading'] = true;
  },
  USER_DETAILS_SET: (state, action) => {
    state['profile'] = action.payload.profile;
    state['courses'] = action.payload.courses;
    state['user_details_loading'] = false;
  },

  USER_PROFILE_DETAILS_LOADING: (state, action) => {
    state['user_profile_details_loading'] = true;
  },
  USER_PROFILE_DETAILS_SET: (state, action) => {
    state['profile'] = action.payload;
    state['user_profile_details_loading'] = false;
  },
  USER_PROFILE_IMAGE_LOADING: (state, action) => {
    state['user_profile_image_loading'] = true;
  },
  USER_PROFILE_IMAGE_SET: (state, action) => {
    state['profile'] = action.payload;
    state['user_profile_image_loading'] = false;
  },
  HANDLE_PROFILE_DETAILS_EDIT_CHANGE: (state, action) => {
    // updating profile details to override previous state profile with action.payload profile
    state['profile'] = { ...state['profile'], ...action.payload };
  },
  USER_COMPLETIONS_SET: (state, action) => {
    state['user_completions'] = action.payload;
  },
  COMPLETE_COURSE_OR_LESSON: (state, action) => {
    let courseLesson = action.payload;
    if (courseLesson.hasOwnProperty('lesson')) {
      state['user_completions'].lessons.push(courseLesson.lesson);
    }

    if (courseLesson.hasOwnProperty('course')) {
      state['user_completions'].courses.push(courseLesson.course);
    }
  },

  ENROLL_COURSE_SET: (state, action) => {
    state['courses'].push(action.payload);
  },
  // set -- new_token
  NEW_TOKEN_LOADING: (state, action) => {
    state.new_token['loading'] = action.payload;
  },
  NEW_TOKEN_SET: (state, action) => {
    state.new_token['value'] = action.payload;
    state.new_token['is_fetched'] = true;
    // other operations to set Global authentication:
    //* USER_SET operations:
    setAuthenticatedGlobal(state, action.payload.token);

    // can't call the below function directly as it has a dispatch in it and we can't dispatch an action from a reducer

    // auth.login(action.payload);
  },
  NEW_TOKEN_ERROR: (state, action) => {
    state.new_token['error'] = action.payload;
  },
  //  -- get_user_public_profile
  GET_USER_PUBLIC_PROFILE_LOADING: (state, action) => {
    state.get_user_public_profile['loading'] = action.payload;
  },
  GET_USER_PUBLIC_PROFILE_SET: (state, action) => {
    state.get_user_public_profile['value'] = action.payload;
    state.get_user_public_profile['is_fetched'] = true;
  },
  GET_USER_PUBLIC_PROFILE_ERROR: (state, action) => {
    state.get_user_public_profile['error'] = action.payload;
  }
});

const setAuthenticatedGlobal = (state, token) => {
  console.log(`setting AUTH`);
  localStorage.setItem('token', token);
  let decodedToken = jwtDecode(token);
  state['user'] = decodedToken;
  state['is_authenticated'] = true;

  axios.defaults.headers.common['Authorization'] = `JWT ${token}`;
};
