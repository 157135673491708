import {
  // projects
  PROJECTS_LOADING,
  PROJECTS_SET,
  PROJECTS_ERROR,
  // digital library
  DIGITAL_LIBRARY_LOADING,
  DIGITAL_LIBRARY_SET,
  DIGITAL_LIBRARY_ERROR,
  // audio learning
  AUDIO_LEARNING_LOADING,
  AUDIO_LEARNING_SET,
  AUDIO_LEARNING_ERROR,
  // resume_templates
  RESUME_TEMPLATES_LOADING,
  RESUME_TEMPLATES_SET,
  RESUME_TEMPLATES_ERROR,
  // cover_letter_templates
  COVER_LETTER_TEMPLATES_LOADING,
  COVER_LETTER_TEMPLATES_SET,
  COVER_LETTER_TEMPLATES_ERROR
} from '../types';

import { createReducer } from '@reduxjs/toolkit';

var initialObject = {
  loading: false,
  value: null,
  error: null,
  is_fetched: false
};

const initialState = {
  // projects
  projects: { ...initialObject },
  // digital library
  digital_library: { ...initialObject },
  // audio learning
  audio_learning: { ...initialObject },
  // free_videos

  free_videos: { ...initialObject },
  // resume_templates

  resume_templates: { ...initialObject },
  // cover_letter_templates

  cover_letter_templates: { ...initialObject }
};

export default createReducer(initialState, {
  // projects
  PROJECTS_LOADING: (state, action) => {
    state.projects['loading'] = action.payload;
  },

  PROJECTS_SET: (state, action) => {
    state.projects['value'] = action.payload;
    state.projects['is_fetched'] = true;
  },
  PROJECTS_ERROR: (state, action) => {
    state.projects['error'] = action.payload;
  },
  // digital library
  DIGITAL_LIBRARY_LOADING: (state, action) => {
    state.digital_library['loading'] = action.payload;
  },

  DIGITAL_LIBRARY_SET: (state, action) => {
    state.digital_library['value'] = action.payload;
    state.digital_library['is_fetched'] = true;
  },
  DIGITAL_LIBRARY_ERROR: (state, action) => {
    state.digital_library['error'] = action.payload;
  },
  // audio learning
  AUDIO_LEARNING_LOADING: (state, action) => {
    state.audio_learning['loading'] = action.payload;
  },

  AUDIO_LEARNING_SET: (state, action) => {
    state.audio_learning['value'] = action.payload;
    state.audio_learning['is_fetched'] = true;
  },
  AUDIO_LEARNING_ERROR: (state, action) => {
    state.audio_learning['error'] = action.payload;
  },
  // get -- free_videos
  FREE_VIDEOS_LOADING: (state, action) => {
    state.free_videos['loading'] = action.payload;
  },
  FREE_VIDEOS_SET: (state, action) => {
    state.free_videos['value'] = action.payload;
    state.free_videos['is_fetched'] = true;
  },
  FREE_VIDEOS_ERROR: (state, action) => {
    state.free_videos['error'] = action.payload;
  },
  // get -- resume_templates
  RESUME_TEMPLATES_LOADING: (state, action) => {
    state.resume_templates['loading'] = action.payload;
  },
  RESUME_TEMPLATES_SET: (state, action) => {
    state.resume_templates['value'] = action.payload;
    state.resume_templates['is_fetched'] = true;
  },
  RESUME_TEMPLATES_ERROR: (state, action) => {
    state.resume_templates['error'] = action.payload;
  },
  // get -- cover_letter_templates
  COVER_LETTER_TEMPLATES_LOADING: (state, action) => {
    state.cover_letter_templates['loading'] = action.payload;
  },
  COVER_LETTER_TEMPLATES_SET: (state, action) => {
    state.cover_letter_templates['value'] = action.payload;
    state.cover_letter_templates['is_fetched'] = true;
  },
  COVER_LETTER_TEMPLATES_ERROR: (state, action) => {
    state.cover_letter_templates['error'] = action.payload;
  }
});
