const env = process.env.NODE_ENV !== 'production' ? 'DEV' : 'PROD';
// const env = 'DEV';
// const env = process.env.NODE_ENV === 'development'? "DEV": "PROD" -- don't use

// process.env.REACT_APP_ENV;

let constants = {
  DEV: {
    domain_root_url: window.location.origin,
    // api_base_url: 'https://marketing.graduator-apis.com',
    api_base_url: 'http://127.0.0.1:8000',
    // api_base_url_nodejs: 'http://127.0.0.1:5000',
    api_base_url_nodejs: 'https://stock-antshade.graduator-apis.com',
    razor_pay_key_id: 'rzp_test_Pq5FbV5TrICRbz',
    enableReduxDevTools: true,
    // external payments -- another server
    payments_base_url_api: 'http://127.0.0.1:8000',
    payments_base_url_web: 'http://127.0.0.1:3001'
  },
  PROD: {
    domain_root_url: window.location.origin,
    // api_base_url: 'http://127.0.0.1:8000',
    api_base_url: 'https://course-antshade.graduator-apis.com',
    api_base_url_nodejs: 'https://stock-antshade.graduator-apis.com',
    razor_pay_key_id: 'rzp_live_6yWx11NhZwG0Ph',
    enableReduxDevTools: false,
    // external payments -- another server
    payments_base_url_api: 'https://marketing.graduator-apis.com',
    payments_base_url_web: 'https://graduator.school'
  }
};

const get = (key) => {
  return constants[env][key];
};

export default get;
