import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { useTheme } from '@material-ui/styles';

import config from 'config/main';

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%'
    // overflowWrap: 'break-all'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.contrastText
  },
  main: {
    background: theme.palette.primary.main,
    borderRadius: '10px'
  }
}));

export default function TradingViewTickerTape(props) {
  let widgetRef = useRef(null);
  let currentTheme = useSelector((state) => state.display.theme);
  const theme = useTheme(); // Problem is that, this doesn't change dynamically when the theme changes.
  const classes = useStyles();
  var chart;

  useEffect(() => {
    // We can't use document.body as we don't wanna attach the chart to the body, but instead to this component div tag
    // Hence we created a reference to this component's div tag using useRef() [const widgetRef = useRef(null);] Hook and then passed it.
    // To access the ref, we need to use .current (like: chartRef.current)
    //
    widgetRef.current.innerHTML = '';
    // we're setting the above ref to '' as this useEffect() gets called every time we change the theme.
    // If we won't set the above ref's innerHTML to '', we'll be appending the chart again and again.
    const script = document.createElement('script');
    script.src =
      'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js';
    script.async = true;
    script.innerHTML = '';
    // JSON.stringify() is very important here. Won't work without that!
    script.innerHTML = JSON.stringify({
      symbols: config.stock.tradingViewTickerTapeSymbols,
      showSymbolLogo: props.showSymbolLogo,
      colorTheme: currentTheme === 'lightTheme' ? 'light' : 'dark',
      isTransparent: false,
      displayMode: 'adaptive',
      locale: 'in'
    });
    /* JSON-ENCODED SETTINGS STRING FROM EMBED CODE */
    widgetRef.current.appendChild(script);
  }, [currentTheme]);

  return (
    <div class="tradingview-widget-container" ref={widgetRef}>
      <div class="tradingview-widget-container__widget"></div>
    </div>
  );
}

TradingViewTickerTape.defaultProps = {
  showSymbolLogo: true
};

TradingViewTickerTape.propTypes = {
  showSymbolLogo: PropTypes.any
};
