import _ from 'lodash';
import { colors, createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import typography from './typography';
import { softShadows, strongShadows } from './shadows';

// const white = '#FFFFFF';
// const black = '#000000';

const baseConfig = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    }
  }
};

const myThemes = {
  lightTheme: {
    name: 'lightTheme',
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      }
    },
    palette: {
      type: 'light',
      action: {
        active: colors.blueGrey[600]
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white
      },
      primary: {
        main: colors.indigo[600],
        contrastDimText: '#ffffff80'
      },
      success: {
        main: '#1bc943'
      },
      secondary: {
        main: '#5850EC',
        contrastText: colors.common.white
      },
      error: {
        main: '#b00020'
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600]
      },
      topBar: {
        main: colors.indigo[600],
        contrastText: colors.common.white,
        contrastElement: colors.common.white
      },
      // colors
      // it's equivalent colors for present for dark theme too
      customColors: {
        dark: '#070919',
        blueGradient: 'linear-gradient(135deg,#6b73ff 10%,#000dff)'
        // blueGradient: ' linear-gradient(315deg, #3f0d12 0%, #a71d31 74%)'
        // blueGradient: ' linear-gradient(315deg, #04619F 0%, #000000 74%)'
      }
      // topBarContrastText: {
      //   main: colors.common.white
      // }
    },
    shadows: softShadows
  },
  darkTheme: {
    name: 'darkTheme',
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#282C34',
        dark: '#1c2025',
        paper: '#282C34'
      },
      primary: {
        main: '#8a85ff',
        contrastDimText: '#ffffff80'
      },
      success: {
        main: '#03dac6'
      },
      error: {
        main: '#b00020'
      },
      secondary: {
        main: '#8a85ff'
      },
      text: {
        primary: '#e6e5e8',
        secondary: '#adb0bb'
      },
      topBar: {
        main: '#282C34',
        contrastText: colors.common.white,
        contrastElement: '#8a85ff'
      },
      customColors: {
        dark: '#282C34',
        blueGradient:
          'linear-gradient(to left top, #0f0f0f, #1d1d24, #2a2a39, #373850, #434768)'
        // blueGradient: ' linear-gradient(315deg, #04619F 0%, #000000 74%)'
      }
    },
    shadows: strongShadows
  }
};

export default function createTheme(themeType = 'darkTheme') {
  let themeSelected = myThemes[themeType];
  let responsiveFontSizesSelected = true;

  let theme = createMuiTheme(
    _.merge({}, baseConfig, themeSelected, { direction: 'ltr' })
  );

  if (responsiveFontSizesSelected) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
}

// const theme = createTheme();
// export default theme;
