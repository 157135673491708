import React from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles } from '@material-ui/core';
import { DashboardCard } from '../';

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    padding: theme.spacing(3),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  overline: {
    marginTop: theme.spacing(1)
  }
}));

function Dashboard4Cards(props) {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item lg={3} sm={6} xs={12}>
        <DashboardCard />
      </Grid>
      <Grid item lg={3} sm={6} xs={12}>
        <DashboardCard />
      </Grid>
      <Grid item lg={3} sm={6} xs={12}>
        <DashboardCard />
      </Grid>
      <Grid item lg={3} sm={6} xs={12}>
        <DashboardCard />
      </Grid>
    </Grid>
  );
}

Dashboard4Cards.propTypes = {
  className: PropTypes.string,
  // block 1
  block1Value: PropTypes.any,
  block1SideLabel: PropTypes.any,
  block1BottomLabel: PropTypes.any,
  // block 2
  block2Value: PropTypes.any,
  block2SideLabel: PropTypes.any,
  block2BottomLabel: PropTypes.any,
  // block 3
  block3Value: PropTypes.any,
  block3SideLabel: PropTypes.any,
  block3BottomLabel: PropTypes.any,
  // block 4
  block4Value: PropTypes.any,
  block4SideLabel: PropTypes.any,
  block4BottomLabel: PropTypes.any
};

export default Dashboard4Cards;
