import config from '../config/main';
import memberships from './rbac-memberships';

const rules = {
  [config.userTypes.UNAUTHENTICATED]: {
    static: ['buyMembershipCard:view', 'signInSignUpPopup:view']
  },
  [config.userTypes.FREE_USER]: {
    static: ['buyMembershipCard:view', 'authViews:view']
  },
  [config.userTypes.AFFILIATE_USER]: {
    static: ['authViews:view']
  },
  [config.userTypes.EXTERNAL_ADMIN_USER]: {
    static: [
      'adminResources:view',
      'authViews:view',
      'paid_content:view'
      // ...memberships.MEMBERSHIP_3.static
    ]
  },
  [config.userTypes.PAID_USER]: {
    static: ['authViews:view', 'paid_content:view']
  },

  /*
    The below rules are not being used by our application.
    They are here for example to be referred for future :)
  */
  visitor: {
    static: ['posts:list', 'home-page:visit']
  },
  writer: {
    static: [
      'posts:list',
      'posts:create',
      'users:getSelf',
      'home-page:visit',
      'dashboard-page:visit'
    ],
    dynamic: {
      'posts:edit': ({ userId, postOwnerId }) => {
        if (!userId || !postOwnerId) return false;
        return userId === postOwnerId;
      }
    }
  },
  admin: {
    static: [
      'posts:list',
      'posts:create',
      'posts:edit',
      'posts:delete',
      'users:get',
      'users:getSelf',
      'home-page:visit',
      'dashboard-page:visit'
    ]
  }
};

export default rules;

/*
Using can component template:


<Can
  role={USER.user_type}
  perform="RESOURCE:ACTION"
  yes={() => <>YES_COMPONENT</>}
  no={() => <>NO_COMPONENT</>}
/>

*/
