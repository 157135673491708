import general from 'config/general';
import main_landing_page from './landingPages/main';
import membership_1_landing_page from './landingPages/membership_1';
import membership_2_landing_page from './landingPages/membership_2';
import membership_3_landing_page from './landingPages/membership_3';
import membershipTypes from './membershipTypes';
import privacyPolicy from './staticPages/privacyPolicy';
import termsAndConditions from './staticPages/termsAndConditions';
import cancellationAndRefundPolicies from './staticPages/cancellationAndRefundPolicies';
let company = general.company_name;

export default {
  SignUp: {
    route: {
      path: '/sign-up',
      isEnabled: true,
      onlyAuthUsers: false
    },
    pageTitle: 'Sign Up',
    header: {
      top: 'signup',
      main: 'Create an Account'
    }
  },
  SignIn: {
    route: {
      path: '/sign-in2',
      isEnabled: true,
      onlyAuthUsers: false
    },
    pageTitle: 'Sign In',
    header: {
      top: 'SignIn',
      main: 'Sign-in now!'
    }
  },
  SignupSuccess: {
    route: {
      path: '/signup-success',
      isEnabled: true,
      onlyAuthUsers: false
    },
    pageTitle: 'Successful',
    successText: 'Successfully Registered',
    instructionText1:
      'Verify your account by clicking on the link sent to your email address',
    instructionText2:
      "You won't be able to login to your account until you verify your email"
  },
  ForgotPassword: {
    route: {
      path: '/forgot-password/:token',
      isEnabled: true,
      onlyAuthUsers: false
    },
    pageTitle: 'Forgot Password'
  },
  ChangePassword: {
    route: {
      path: '/change-password',
      isEnabled: true,
      onlyAuthUsers: false
    },
    alertText: `For security reasons, we've enabled only "Forgot-password" flow for
    users to reset their password. You'll have to click on the below
    button and enter your email address to which the password resent link
    would be sent to.`,
    pageTitle: 'Change Password'
  },
  UserEmailVerification: {
    route: {
      path: '/verify-user/:token',
      isEnabled: true,
      onlyAuthUsers: false
    },
    pageTitle: 'Verify Email',
    successText:
      'Your account has been successfully verified. You can now login :)'
  },
  NotFound: {
    route: {
      path: '/not-found',
      isEnabled: true,
      onlyAuthUsers: false
    },
    pageTitle: 'Not Found',
    mainText: '404: The page you are looking for isn’t here',
    description: `You either tried some shady route or you came here by mistake.
    Whichever it is, try using the navigation`,
    imageUrl: `/images/undraw_page_not_found_su7k.svg`
  },
  allCourses: {
    route: {
      path: '/all-courses',
      isEnabled: true,
      onlyAuthUsers: false
    },
    pageTitle: 'All Courses',
    imageHeader: {
      smallTopLabel: company,
      heading: 'All Courses',
      subHeading: `These courses cover in-depth concepts of the stock-market. You'll receive 
      certifications for every course you complete. A membership would give you access to all 
      of these  courses. These courses have multiple modules and lessons under them. Our courses 
      are taken by the best traders and is aligned with the latest strategies`,
      imageUrl: '/images/undraw_teaching_f1cm.svg',
      miniStatsArray: [{ label: 'Lessons', value: '250+' }],
      isBuyMembershipLink: true,
      isUpDownAnimation: true,
      imageRight: false
    },
    statsCard: {
      card1Label: 'Total Courses',
      card1Value: general.total_certifications,

      card2Label: 'Total Videos Lessons',
      card2Value: '200+'
    }
  },
  myCourses: {
    route: {
      path: '/my-courses',
      isEnabled: true,
      onlyAuthUsers: true
    },
    pageTitle: 'My Courses',
    header: {
      top: 'my courses',
      main: 'Enrolled Courses'
    }
  },
  MyCertificates: {
    route: {
      path: '/my-certificates',
      isEnabled: true,
      onlyAuthUsers: true
    },
    pageTitle: 'My Certificates',
    header: {
      top: 'certificates',
      main: 'My Certificates'
    }
  },
  Account: {
    route: {
      path: '/account',
      isEnabled: true,
      onlyAuthUsers: true
    },
    pageTitle: 'Profile',
    header: {
      top: 'courses',
      main: 'Enrolled Courses'
    }
  },

  audioLearning: {
    route: {
      path: '/audio-learning',
      isEnabled: true,
      onlyAuthUsers: false
    },
    pageTitle: 'Audio Learning',
    imageHeader: {
      smallTopLabel: company,
      heading: 'Audio Learning',
      subHeading: `Can't watch videos? Well, your learning doesn't stop.. you can learn by just listening here which has audio for most of the topics. You can listen to any 
      topic you want while you're busy with some other work. This helps you continue your learning 
      even when you're occupied with some other work!`,
      imageUrl: '/images/undraw_walk_in_the_city_1ma6.svg',
      miniStatsArray: [],
      isBuyMembershipLink: true,
      isUpDownAnimation: true,
      imageRight: false
    },
    statsCard: {
      card1Label: 'Total Audio Duration',
      card1Value: '6 Hr+',
      card2Label: 'TOTAL AUDIO LEARNINGS AVAILABLE',
      card2Value: '50+'
    }
  },
  CourseVideo: {
    route: {
      path: '/webinar',
      isEnabled: true,
      onlyAuthUsers: false
    },
    pageTitle: 'Stock Trading Webinar',
    courseVideoUrl:
      'https://all-resources-dj.s3.ap-south-1.amazonaws.com/webinar-videos/digital-marketing/Graduator-Digital-Marketing-Webinar.mp4',
    header: {
      top: general.specialization,
      main: 'Webinar'
    },
    // imageHeader: {
    //   smallTopLabel: company,
    //   heading: 'Audio Learning',
    //   subHeading: `Can't watch videos? Well, your learning doesn't stop.. you can learn by just listening here  which has audio for most of the topics. You can listen to any
    //   topic you want while you're busy with some other work. This helps you continue your learning
    //   even when you're occupied with some other work!`,
    //   imageUrl: '/images/undraw_walk_in_the_city_1ma6.svg',
    //   miniStatsArray: [{ label: 'Users', value: '300k+' }],
    //   isBuyMembershipLink: true,
    //   isUpDownAnimation: true,
    //   imageRight: false
    // },
    statsCard: {
      card1Label: 'WATCHING NOW',
      card1SideLabel: 'Live',
      card1Value: getRandomNumber(200, 230),

      card2Label: 'TOTAL CERTIFICATIONS',
      card2Value: general.total_certifications
    }
  },
  projects: {
    route: {
      path: '/projects',
      isEnabled: false,
      onlyAuthUsers: false
    },
    pageTitle: 'Projects & Resources',

    imageHeader: {
      smallTopLabel: company,
      heading: 'Projects & Resources',
      subHeading: `Access all the best beginner friendly open source hand picked projects that would 
        give a complete practical approach to solving/handling real-world problems. These resources 
        follow the best industry level practices. These projects will also add a high value to 
        your CV/resume`,
      imageUrl: '/images/undraw_code_typing_7jnv.svg',
      miniStatsArray: [{ label: 'Users', value: '300k+' }],
      isBuyMembershipLink: true,
      isUpDownAnimation: true,
      imageRight: false
    },
    statsCard: {
      card1Label: 'TOTAL STUDENTS USING THE PROJECTS',
      card1Value: '30,000+',
      card2Label: 'TOTAL PROJECTS AVAILABLE',
      card2Value: '15+'
    }
  },
  ResumeTemplates: {
    route: {
      path: '/resume-templates',
      isEnabled: true,
      onlyAuthUsers: false
    },
    pageTitle: 'Resume Templates',

    imageHeader: {
      smallTopLabel: company,
      heading: 'Resume Templates',
      subHeading: `No more searching the internet for various resume templates. You can pick 
      any of the resume templates from here and use them as a base template to create 
      your resume. Resume is the first thing that the employers see which would mainly be used for 
      screening purposes`,
      imageUrl: '/images/undraw_resume_1hqp.svg',
      miniStatsArray: [{ label: 'Total Users', value: '300k+' }],
      isBuyMembershipLink: true,
      isUpDownAnimation: true,
      imageRight: false
    },
    statsCard: {
      card1Label: 'Total Resume Downloads',
      card1Value: '200,000',
      card2Label: 'Resumes Templates available',
      card2Value: '40+'
    }
  },
  CoverLetterTemplates: {
    route: {
      path: '/cover-letter-templates',
      isEnabled: true,
      onlyAuthUsers: false
    },
    pageTitle: 'Cover Letter Templates',

    imageHeader: {
      smallTopLabel: company,
      heading: 'Cover Letter Templates',
      subHeading: `Cover letter is one of the most important as most of the times, your 
      cover letter is seen even before your resume!. Cover letter is the only way to make sure
      you stand out among the other applicants. Without a cover letter you'd miss the opportunity 
      to tell your prospective employees who you are or why they should hire you.`,
      imageUrl: '/images/undraw_arrived_f58d.svg',
      miniStatsArray: [{ label: 'Total Users', value: '300k+' }],
      isBuyMembershipLink: true,
      isUpDownAnimation: true,
      imageRight: false
    },
    statsCard: {
      card1Label: 'Total Cover Letter Downloads',
      card1Value: '150,000',
      card2Label: 'Cover Letter Templates available',
      card2Value: '30+'
    }
  },
  Jobs: {
    route: {
      path: '/jobs',
      isEnabled: true,
      onlyAuthUsers: false
    },
    pageTitle: 'Jobs',

    imageHeader: {
      smallTopLabel: company,
      heading: `${general.specialization} Jobs`,
      subHeading: `No more hunting for jobs on LinkedIn or Indeed for ${general.specialization} as
      we post only the valid, trusted jobs from authentic sources here. You can blindly start 
      applying to jobs from here as we've done the base homework in filtering the jobs for you!`,
      imageUrl: '/images/undraw_in_the_office_o44c.svg',
      miniStatsArray: [],
      isBuyMembershipLink: true,
      isUpDownAnimation: true,
      imageRight: false
    },
    statsCard: {
      card1Label: 'Total Jobs listed',
      card1Value: '2,000+',
      card2Label: 'Jobs Listed Every Month',
      card2Value: '100+'
    }
  },
  digitalLibrary: {
    route: {
      path: '/digital-library',
      isEnabled: true,
      onlyAuthUsers: false
    },
    pageTitle: 'Digital Library',

    imageHeader: {
      smallTopLabel: company,
      heading: 'Digital Library',
      subHeading: `Access the best e-books here. You don't have to search around for various books on
          the Stock Market. The set of books here cover A-Z concepts of stock-market which can be
          accessed unlimited number of times`,
      imageUrl: '/images/undraw_book_lover_mkck.svg',
      miniStatsArray: [{ label: 'Books', value: '40+' }],
      isBuyMembershipLink: true,
      isUpDownAnimation: true,
      imageRight: false
    },
    statsCard: {
      card1Label: 'TOTAL LIBRARY VIEWS',
      card1Value: '18,000+',
      card2Label: 'TOTAL BOOKS AVAILABLE',
      card2Value: '40+'
    }
  },
  MySalesHome: {
    route: {
      path: '/my-sales-home',
      isEnabled: true,
      onlyAuthUsers: true
    },
    pageTitle: 'My Sales',

    imageHeader: {
      smallTopLabel: company,
      heading: 'Start Earning Money!',
      subHeading: `Start getting paid on every sale you make. You earn a commission when 
      someone buys the membership thought the link you've shared or if you mark a sale manually
       using the customer's membership ID.`,
      imageUrl: '/images/undraw_wallet_aym5.svg',
      miniStatsArray: [],
      isBuyMembershipLink: true,
      isUpDownAnimation: true,
      imageRight: false
    }
  },
  AllSales: {
    route: {
      path: '/all-sales-home',
      isEnabled: true,
      onlyAuthUsers: true
    },
    pageTitle: 'All Sales',
    header: {
      top: 'sales',
      main: 'All Sales'
    }
  },
  // // // Question and Answer Bank
  InteractiveMcqs: {
    route: {
      path: '/interactive-mcqs',
      isEnabled: true,
      onlyAuthUsers: false
    },
    pageTitle: 'Interactive MCQs',

    imageHeader: {
      smallTopLabel: company,
      heading: 'Interactive MCQs',
      subHeading: `Interactive MCQs are the best way to make your concepts stronger. 
      It also improves your decision making skills in choosing the right option among the 
      confusing options. A right answer converts to green colour and wrong to red. You'll need to
      keep clicking the options until you get the right answer -- this engages you more into 
      the questions. There's also explanations given for every answer`,
      imageUrl: '/images/undraw_questions_75e0.svg',
      miniStatsArray: [{ label: 'MCQs', value: '100+' }],
      isBuyMembershipLink: true,
      isUpDownAnimation: true,
      imageRight: false
    },
    statsCard: {
      card1Label: 'Total Stock Scans',
      card1Value: '1 million+',
      card2Label: 'MCQS AVAILABLE',
      card2Value: '100+'
    }
  },
  CoreInterview: {
    route: {
      path: '/stock-market-finance-interview',
      isEnabled: true,
      onlyAuthUsers: false
    },
    pageTitle: 'Stock Market/Finance interview preparation',

    imageHeader: {
      smallTopLabel: company,
      heading: 'Stock Market Interview',
      subHeading: `This section has the most asked and the most important CORE interview questions and
      their answers. Understanding the questions and answers from here will make it a lot easier to know 
      what's actually being asked in the main rounds of the interview and also make it easier to crack 
      good companies. Interviews are very important as that's the only place to represent your 
      logical skills. So this section should not be skipped`,
      imageUrl: '/images/undraw_interview_rmcf.svg',
      miniStatsArray: [],
      isBuyMembershipLink: true,
      isUpDownAnimation: true,
      imageRight: false
    },
    statsCard: {
      card1Label: 'Total Stock Scans',
      card1Value: '1 million+',
      card2Label: 'NUMBER OF QUESTION & ANSWERS',
      card2Value: '100+'
    }
  },
  NotesAndDefinitions: {
    route: {
      path: '/notes-definitions',
      isEnabled: true,
      onlyAuthUsers: false
    },
    pageTitle: 'Stock Market Notes and Definitions',

    imageHeader: {
      smallTopLabel: company,
      heading: 'Notes and Definitions',
      subHeading: `This section has Notes and Definitions of the most important topics.
      This section is useful when there isn't much time to watch the videos.`,
      imageUrl: '/images/undraw_taking_notes_tjaf.svg',
      miniStatsArray: [],
      isBuyMembershipLink: true,
      isUpDownAnimation: true,
      imageRight: false
    },
    statsCard: {
      card1Label: 'Total Stock Scans',
      card1Value: '1 million+',
      card2Label: 'TOTAL NOTES',
      card2Value: '50+'
    }
  },
  GeneralInterview: {
    route: {
      path: '/general-interview',
      isEnabled: false,
      onlyAuthUsers: false
    },
    pageTitle: 'General Interview',

    imageHeader: {
      smallTopLabel: company,
      heading: 'General Interview',
      subHeading: `This section has the most frequently asked interview questions.
      These questions are not technical and don't contain the core concepts. It's important 
      to make sure you ace this interview as well. It doesn't matter how well you answer the technical
      interviews if you don't perform well in this. So practice everything from this section too`,
      imageUrl: '/images/undraw_conversation_h12g.svg',
      miniStatsArray: [{ label: 'Users', value: '300k+' }],
      isBuyMembershipLink: true,
      isUpDownAnimation: true,
      imageRight: false
    },
    statsCard: {
      card1Label: 'Total Stock Scans',
      card1Value: '1 million+',
      card2Label: 'NUMBER OF QUESTION & ANSWERS',
      card2Value: '40+'
    }
  },
  FreeVideos: {
    route: {
      path: '/daily-trade-videos',
      isEnabled: true,
      onlyAuthUsers: false
    },
    pageTitle: 'Daily Trade Videos',
    header: {
      top: 'Daily Trade Videos',
      main: 'Trades'
    },

    imageHeader: {
      smallTopLabel: company,
      heading: 'Daily Trade Videos',
      subHeading: `Watch the videos here to get a better idea of our strategies`,
      imageUrl: '/images/undraw_video_streaming_yyld.svg',
      miniStatsArray: [],
      isBuyMembershipLink: true,
      isUpDownAnimation: true,
      imageRight: false
    },
    statsCard: {
      card1Label: 'TOTAL DAILY VISITORS',
      card1Value: '100,000',
      card2Label: 'Total Stock Scans',
      card2Value: '1 million+'
    }
  },
  CertificateVerify: {
    route: {
      path: '/verify/:certificateId',
      isEnabled: true,
      onlyAuthUsers: false
    },
    pageTitle: 'Certificate Verify',
    header: {
      top: 'certificate',
      main: 'Certificate Details'
    }
  },
  CourseDetail: {
    route: {
      path: '/course-view/:id',
      isEnabled: true,
      onlyAuthUsers: true
    }
  },
  PublicProfile: {
    route: {
      path: '/public-profile/:username',
      isEnabled: true,
      onlyAuthUsers: false
    },
    pageTitle: 'Public Profile',
    imageHeader: {
      smallTopLabel: company,
      imageUrl: '/images/undraw_profile_6l1l.svg',
      // miniStatsArray: [{ label: 'Users', value: '300k+' }],
      isBuyMembershipLink: false,
      isUpDownAnimation: true,
      imageRight: false
    },
    header1: {
      top: 'Profile'
    },

    header2: {
      top: 'certifications',
      main: 'All Certifications'
    },
    statsCard: {
      card1Label: 'Total certifications completed by the user',
      card2Label: 'Total courses completed by the user'
    }
  },
  MyCustomers: {
    route: {
      path: '/my-sales-customers',
      isEnabled: true,
      onlyAuthUsers: true
    },
    pageTitle: 'My Customers',
    header: {
      top: 'sales',
      main: 'My Referred Customers'
    }
  },
  MoneyTransactions: {
    route: {
      path: '/money-transactions',
      isEnabled: true,
      onlyAuthUsers: true
    },
    pageTitle: 'Money Transactions',
    header: {
      top: 'payouts',
      main: 'Money Transactions'
    }
  },
  AllCustomers: {
    route: {
      path: '/all-sales-customers',
      isEnabled: true,
      onlyAuthUsers: true
    },
    pageTitle: 'All Customers',
    header: {
      top: 'sales',
      main: 'All Customers'
    }
  },
  AllUserEarnings: {
    route: {
      path: '/all-user-earnings',
      isEnabled: true,
      onlyAuthUsers: true
    },
    pageTitle: "All Users' Earnings",
    header: {
      top: 'user commissions',
      main: "All Users' Earnings"
    }
  },
  Reviews: {
    route: {
      path: '/reviews',
      isEnabled: true,
      onlyAuthUsers: false
    },
    pageTitle: 'Reviews',
    imageHeader: {
      smallTopLabel: company,
      heading: 'Reviews',
      subHeading: `True reviews are the ones that make us stand out from the other
      Stock Market certifications. Reviews are submitted only by the people who have 
      completed the course training at ${company}`,
      imageUrl: '/images/undraw_personal_opinions_g3kr.svg',
      miniStatsArray: [],
      isBuyMembershipLink: true,
      isUpDownAnimation: true,
      imageRight: false
    }
  },
  SampleCertificateGenerator: {
    route: {
      path: '/sample-certificate-generator',
      isEnabled: true,
      onlyAuthUsers: false
    },
    pageTitle: 'Sample Certificate',
    imageHeader: {
      smallTopLabel: company,
      heading: 'Sample Certificate Generator',
      subHeading: `Generate your sample certificate here by entering your name below in the form.
      This is only a sample certificate just to show you the design of how the actual certificate 
      would look like. And of course, the "sample certificate" watermark won't be there in the actual
      certificate and it'll have the course name and a valid QR-code signed on it and be of 
      better image quality`,
      imageUrl: '/images/undraw_certificate_343v.svg',
      miniStatsArray: [],
      isBuyMembershipLink: false,
      isUpDownAnimation: true,
      imageRight: false
    },
    statsCard: {
      card1Label: 'Total Stock Scans',
      card1Value: '1 million+',

      card2Label: 'Total Certifications Given',
      card2Value: '2,00,000+'
    }
  },
  BuyMembership: {
    route: {
      path: '/buy-membership',
      isEnabled: true,
      onlyAuthUsers: false
    },
    pageTitle: 'Buy Membership',
    imageHeader: {
      smallTopLabel: company,
      heading: 'Buy Membership Now!',
      subHeading: `With over 1 million AI stock scans and being the best at Stock Trading, 
      we are here to provide the most valuable and the best Stock Trading 
      learning/certification for you.`,
      imageUrl: '/images/undraw_Credit_card_3ed6.svg',
      miniStatsArray: [],
      isBuyMembershipLink: false,
      isUpDownAnimation: true,
      imageRight: false
    },

    statsCard: {
      card1Label: 'Total Alerts Sent',
      card1Value: '80k+',

      card2Label: 'TOTAL CERTIFICATIONS',
      card2Value: general.total_certifications,

      card3Label: 'Total AI Scans',
      card3Value: '~1 million+',
      card3SideLabel: 'Count',

      card4Label: 'Live NOW',
      card4SideLabel: 'Live',
      card4Value: getRandomNumber(3000, 3200)
    }
  },
  Membership1Landing: {
    route: {
      path: '/membership-1',
      isEnabled: false,
      onlyAuthUsers: false
    },
    pageTitle: `Buy ${membershipTypes.MEMBERSHIP_1.name} Membership for Digital Marketing`,
    membershipType: 'MEMBERSHIP_1',

    imageHeader: {
      heading: `${membershipTypes.MEMBERSHIP_1.name} Membership`,
      subHeading: `${membershipTypes.MEMBERSHIP_1.membership_name} takes you from a complete beginner 
      to an expert in ${general.specialization} in a period of just a few weeks/months. This provides 
      certifications for every course you complete in this membership which of a great value when it comes 
      to your job-interview or placements. Apart from this, you also get another certificate for completing
      all the courses. All of the courses here in this membership are self-paced and can be watched 
      in your convenient time. If there are any weekend classes, you'll be informed in advance.`,
      imageUrl: '/images/undraw_social_networking_nqk4.svg',
      miniStatsArray: [{ label: 'Users', value: '300k+' }],
      isBuyMembershipLink: false,
      isUpDownAnimation: true,
      imageRight: false
    },
    statsCard: {
      // keep all the labels short as the line in the card will go to 2 lines other wise.
      card1Label: `Total Memberships`,
      card1Value: '120,000+',

      card2Label: 'CERTIFICATIONS',
      card2Value: general.total_certifications,

      card3Label: 'DAILY ENROLLMENTS',
      card3Value: '~400',

      card4Label: 'Students  LIVE NOW',
      card4Value: getRandomNumber(4000, 4200)
    },
    ...membership_1_landing_page
  },
  Membership2Landing: {
    route: {
      path: '/membership-2',
      isEnabled: false,
      onlyAuthUsers: false
    },
    pageTitle: `Buy ${membershipTypes.MEMBERSHIP_2.name} Membership for Digital Marketing`,
    membershipType: 'MEMBERSHIP_2',

    imageHeader: {
      heading: `${membershipTypes.MEMBERSHIP_2.name} Membership`,
      subHeading: `${membershipTypes.MEMBERSHIP_2.name} membership gives you a complete overview of the best industry 
      standards and practices that are currently being followed across various organizations 
      and also to work on live production projects. You also get an ${membershipTypes.MEMBERSHIP_2.name} completion certification from ${company} along with the 
      course completion certification for each course which is of high value for your 
      placements/job-interview. Apart from the self-paced courses, there will be live weekend classes
      which will be informed in advance`,
      imageUrl: '/images/undraw_teaching_f1cm.svg',
      miniStatsArray: [{ label: 'Users', value: '300k+' }],
      isBuyMembershipLink: false,
      isUpDownAnimation: true,
      imageRight: false
    },
    statsCard: {
      // keep all the labels short as the line in the card will go to 2 lines other wise.
      card1Label: `SUCCESSFUL ${membershipTypes.MEMBERSHIP_2.name}s`,
      card1Value: '80,000+',

      card2Label: 'CERTIFICATIONS',
      card2Value: general.total_certifications,

      card3Label: 'DAILY ENROLLMENTS',
      card3Value: '~350',

      card4Label: 'Students  LIVE NOW',
      card4Value: getRandomNumber(2300, 2500)
    },
    ...membership_2_landing_page
  },
  Membership3Landing: {
    route: {
      path: '/membership-3',
      isEnabled: false,
      onlyAuthUsers: false
    },
    pageTitle: `Buy ${membershipTypes.MEMBERSHIP_3.name} Membership for Digital Marketing`,
    membershipType: 'MEMBERSHIP_3',

    imageHeader: {
      heading: `${membershipTypes.MEMBERSHIP_3.name} Membership`,
      subHeading: `${membershipTypes.MEMBERSHIP_3.membership_name} is the most widely chosen programme
      as this prepares you right from a naive beginner to an expert in ${general.specialization}.
      This programme trains you on all the required concepts and also we've separate dedicated sections like
      Core-interview, Interactive MCQs, Notes & Definitions, General Interview, Jobs, 
      Resume Templates and Cover letter Templates section. Apart from what's included in the other memberships,
       these are the extra sections you've access to and extra live classes too. Most of our students
       who have taken the ${membershipTypes.MEMBERSHIP_3.membership_name} have successfully landed a job`,
      imageUrl: '/images/undraw_certificate_343v.svg',
      miniStatsArray: [{ label: 'Users', value: '300k+' }],
      isBuyMembershipLink: false,
      isUpDownAnimation: true,
      imageRight: false
    },
    statsCard: {
      // keep all the labels short as the line in the card will go to 2 lines other wise.
      card1Label: `SUCCESSFUL students`,
      card1Value: '20,000+',

      card2Label: 'CERTIFICATIONS',
      card2Value: general.total_certifications,

      card3Label: 'DAILY ENROLLMENTS',
      card3Value: '~30',

      card4Label: 'Students LIVE NOW',
      card4Value: getRandomNumber(2300, 2500)
    },
    ...membership_3_landing_page
  },
  MainLanding: {
    route: {
      path: '/',
      isEnabled: true,
      onlyAuthUsers: false
    },
    pageTitle: `Learn the art of stock trading & investing with technology - ${company}`,
    membershipType: 'MEMBERSHIP_3',

    imageHeader: {
      heading: `${company}`,
      subHeading: `${company} is India's first stock market learning platform that uses technology to train users to trade stocks.
      We're not just a video based learning platform, but more with Audio Podcasts, Books, Quizzes, Rich video classes and more.
      But what's more interesting that sets us way apart? Well, it's the AI based real-time alerts, candlestick pattern detections, 
      latest stocks news-feed, virtual real-time trading and yeah a lot more where you can even go back in time and practice scalping :)`,
      imageUrl: '/images/undraw_Investing_re_bov7.svg',
      // miniStatsArray: [
      //   { label: 'Users', value: '300k+' },
      //   { label: 'Daily Enrollment', value: '~500' }
      // ],
      isBuyMembershipLink: true,
      isUpDownAnimation: true,
      imageRight: false
    },
    statsCard: {
      card1Label: 'Total Alerts Sent',
      card1Value: '80k+',

      card2Label: 'TOTAL CERTIFICATIONS',
      card2Value: general.total_certifications,

      card3Label: 'Total AI Scans',
      card3Value: '~1 million+',
      card3SideLabel: 'Count',

      card4Label: 'Live NOW',
      card4SideLabel: 'Live',
      card4Value: getRandomNumber(3000, 3200)
    },
    ...main_landing_page
  },
  AboutUs: {
    route: {
      path: '/about-us',
      isEnabled: false,
      onlyAuthUsers: false
    },
    pageTitle: `About Us`,

    imageHeader: {
      heading: `About Us`,
      subHeading: `Welcome to ${company}, Your number one source for online stock-market education.
      We're dedicated to giving you the very best of stock-market/finance education experience with a focus on
      quality of education with the best use of AI technologies. 
      Founded by Adithya K V, ${company} has come a long way from its beginnings in Bangalore.
      When the founder first started out, his passion to improve stock-market education drove him to 
      quit his day job, do tons of research so that ${company} can offer you the world's best
      practical stock-market education. ${company} is different from the other digital educational platforms.
      ${company} doesn't sell you courses. It gives a dedicated access to a workplace with a set of tools.
      ${company} has dedicated sections for candlestick pattern alerts, stock news feed,
      virtual trading, scalp-trading, pattern subscriptions, video courses, audio-learnings, 
      , digital-library, core stock-market-interview preparation, interactive-mcq,
      notes & definitions, resume building, cover-letter building and even a jobs
      section where we post verified jobs related to stock-market/finance.
      hope you enjoy our services as much as we enjoy offering them to you.
      If you have any questions or comments, please don't hesitate to contact us -- Adithya KV (Founder)`,
      imageUrl: '/images/adithya_kv_suit_svg.svg',
      founder: 'Adithya K V',
      miniStatsArray: [
        { label: 'Total AI Scans', value: '1 million+' },
        { label: 'Daily Visitors', value: '50k+' }
      ],
      isBuyMembershipLink: false,
      isUpDownAnimation: true,
      imageRight: false
    },
    statsCard: {
      // keep all the labels short as the line in the card will go to 2 lines other wise.
      card1Label: `Daily Visits`,
      card1Value: '50k+',

      card2Label: 'Total Alerts Sent',
      card2Value: '80k+',

      card3Label: 'Total AI Scans',
      card3Value: '~1 million+',

      card4Label: 'LIVE NOW',
      card4Value: getRandomNumber(3000, 3300)
    }
    // ...main_landing_page
  },
  ContactUs: {
    route: {
      path: '/contact-us',
      isEnabled: true,
      onlyAuthUsers: false
    },
    pageTitle: `Contact Us`,

    imageHeader: {
      heading: `Contact Us`,
      subHeading: `We are just one click away from calling you.
      Click on the button below and enter your details, choose a date and submit it.
      Also, you can write to us at: ${general.help_email} 
      You should receive a call soon! `,
      imageUrl: '/images/undraw_contact_us_15o2.svg',
      miniStatsArray: [],
      isBuyMembershipLink: false,
      isUpDownAnimation: true,
      imageRight: false
    }
  },
  PrivacyPolicy: {
    route: {
      path: '/privacy-policy',
      isEnabled: true,
      onlyAuthUsers: false
    },
    pageTitle: `Privacy Policy`,
    header: {
      top: 'policy',
      main: `Privacy Policy`
    },
    ...privacyPolicy
  },
  TermsAndConditions: {
    route: {
      path: '/terms-and-conditions',
      isEnabled: true,
      onlyAuthUsers: false
    },
    pageTitle: `Terms & Conditions`,
    header: {
      top: 'Terms',
      main: `Terms & Conditions`
    },
    ...termsAndConditions
  },
  CancellationAndRefundPolicies: {
    route: {
      path: '/cancellation-and-refund-policies',
      isEnabled: true,
      onlyAuthUsers: false
    },
    pageTitle: `Cancellation & Refund Policies`,
    header: {
      top: 'cancellation',
      main: `Cancellation Policies`
    },
    ...cancellationAndRefundPolicies
  },
  News: {
    route: {
      path: '/news',
      isEnabled: true,
      onlyAuthUsers: false
    },
    pageTitle: 'News',

    imageHeader: {
      smallTopLabel: company,
      heading: `Latest News`,
      subHeading: `No more searching online on various news websites to stay updated on the latest news. 
      We're here with the latest news that is updated every 10 mins! Say GoodBye to all the other news websites.
      We've handpicked the most appropriate stock market news :)`,
      imageUrl: '/images/undraw_newspaper_k72w.svg',
      miniStatsArray: [],
      isBuyMembershipLink: true,
      isUpDownAnimation: true,
      imageRight: false
    },
    statsCard: {
      card1Label: 'Total Articles Delivered',
      card1Value: '90,000+',
      card2Label: 'Daily New Articles',
      card2Value: '300+'
    }
  },
  PatternHistory: {
    route: {
      path: '/pattern-history',
      isEnabled: true,
      onlyAuthUsers: false
    },
    pageTitle: 'History of detected patterns',

    imageHeader: {
      smallTopLabel: company,
      heading: `History of patterns detected`,
      subHeading: `This feed shows the history of all the patterns detected for a stock with respect to a time-frame that you select.
      This is updated instantaneously without any delay as we understand how important this piece of information is :)`,
      imageUrl: '/images/undraw_Stock_prices_re_js33.svg',
      miniStatsArray: [],
      isBuyMembershipLink: true,
      isUpDownAnimation: true,
      imageRight: false
    },
    statsCard: {
      card1Label: 'Total Articles Delivered',
      card1Value: '90,000+',
      card2Label: 'Daily New Articles',
      card2Value: '300+'
    }
  },
  RealtimePatterns: {
    route: {
      path: '/realtime-patterns',
      isEnabled: true,
      onlyAuthUsers: false
    },
    pageTitle: 'Realtime Patterns',

    imageHeader: {
      smallTopLabel: company,
      heading: `Realtime Latest Patterns`,
      subHeading: `This feed shows you the patterns that are detected in real-time. This is an instantaneous feed
      that only shows the latest detected patterns in the current time-frame (for all time-frames) :)`,
      imageUrl: '/images/undraw_professor_8lrt.svg',
      miniStatsArray: [],
      isBuyMembershipLink: true,
      isUpDownAnimation: true,
      imageRight: false
    },
    statsCard: {
      card1Label: 'Updates',
      card1Value: 'Every 5 mins',
      card2Label: 'Total Patterns',
      card2Value: '90+'
    }
  },
  StockView: {
    route: {
      path: '/stock-view/:stock/:tf/',
      isEnabled: true,
      onlyAuthUsers: false
    },
    pageTitle: 'Stock Details',
    header: {
      top: 'Stock View',
      main: 'Reliance'
    }
  },
  ExternalScreener: {
    route: {
      path: '/external-screener',
      isEnabled: true,
      onlyAuthUsers: true
    },
    pageTitle: 'Stock Screeners',
    header: {
      top: 'Stock Screeners',
      main: 'Screeners'
    }
  },
  Positions: {
    route: {
      path: '/positions',
      isEnabled: true,
      onlyAuthUsers: false
    },
    pageTitle: 'My Trade Positions',
    header: {
      top: 'My Trades',
      main: 'Positions'
    }
  },
  PatternSubscriptions: {
    route: {
      path: '/pattern-subscriptions',
      isEnabled: true,
      onlyAuthUsers: false
    },
    pageTitle: 'My Pattern Subscriptions',
    header: {
      top: 'Pattern Subscriptions',
      main: 'Subscriptions'
    }
  },
  Scalper: {
    imageHeader: {
      smallTopLabel: company,
      heading: `Practice Scalping`,
      subHeading: `The best place in India to practice scalping! Yes you choose a candle-pattern to trade
      and we go back and let that happen! We simulate real-time ticks at the same phase that makes you
      feel you've gone back in time. We don't just replay candles, but we replay every tick to understand psychology.
      You can buy/short with live pattern detection and pattern guide real-time with brokerage simulation.
      Sounds exciting? Try it then :)`,
      imageUrl: '/images/undraw_Investment_data_re_sh9x.svg',
      miniStatsArray: [],
      isBuyMembershipLink: true,
      isUpDownAnimation: true,
      imageRight: false
    },
    route: {
      path: '/scalper',
      isEnabled: true,
      onlyAuthUsers: false
    },
    pageTitle: 'Practice Scalp Trading',
    header: {
      top: 'Practice Scalping',
      main: 'Scalper'
    }
  },
  CandlesWiki: {
    route: {
      path: '/candlestick-patterns-guide',
      isEnabled: true,
      onlyAuthUsers: false
    },
    pageTitle: 'Candlestick Patterns',

    imageHeader: {
      smallTopLabel: company,
      heading: `Candlestick Patterns Guide`,
      subHeading: `This is a complete guide to all the set of candlestick patterns that we detect in real-time. This should be one
      stop place for learning all the patterns. Make sure you practice trading every single pattern several times :)`,
      imageUrl: '/images/undraw_Books_l33t.svg',
      miniStatsArray: [{ label: 'Total Patterns', value: '86+' }],
      isBuyMembershipLink: true,
      isUpDownAnimation: true,
      imageRight: false
    },
    statsCard: {
      card1Label: 'Total Patterns',
      card1Value: '86+',
      card2Label: 'Real-time Detection',
      card2Value: 'All Patterns'
    }
  },
  Dashboard: {
    route: {
      path: '/dashboard',
      isEnabled: true,
      onlyAuthUsers: true
    },
    pageTitle: 'Dashboard',

    statsCard: {
      card1Label: 'Total Patterns',
      card1Value: '86+',
      card2Label: 'Real-time Detection',
      card2Value: 'All Patterns'
    }
  },
  ThankYou: {
    route: {
      path: '/thank-you',
      isEnabled: true,
      onlyAuthUsers: false
    },
    pageTitle: 'Purchase Confirmed. Thank you',
    mainText: 'Thank You, Welcome!',
    description: `Your purchase for 1 year membership of AntShade has been confirmed. Thank you 
    once again for choosing AntShade.`,
    infoText: `If you are facing any problems, kindly email us at: ${general.help_email}`,
    imageUrl: `/images/undraw_winners_ao-2-o.svg`
  }
};

function getRandomNumber(min = 1, max = 10) {
  // return Math.floor(Math.random() * 9 + 1);
  // random between 1 and 10
  // between min and max where min is included

  let num = Math.random() * (max - min) + min;
  return Math.floor(num);
}
