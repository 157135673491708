import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { Alert } from '@material-ui/lab';
import { Box } from '@material-ui/core';
import { Can, canFunction } from 'rbac/Can';
import PropTypes from 'prop-types';
import BuyMembershipCard from 'components/BuyMembershipCard';

const useStyles = makeStyles((theme) => ({
  root: {}
}));

function CanOrBuy(props) {
  let USER = useSelector((state) => state.user.user);
  let dispatch = useDispatch();

  const dispatchAllowedActions = (user, allowFor, actions = []) => {
    if (actions.length) {
      if (
        canFunction({
          membership: user.membership_type,
          role: user.user_type,
          perform: allowFor
        })
      ) {
        for (let action of actions) {
          // Can be used only when action functions don't have parameters
          dispatch(action());
        }
      }
    }
  };

  return (
    <Can
      membership={USER.membership_type}
      role={USER.user_type}
      perform={props.allowFor}
      yes={() => <>{props.children}</>}
      no={() => (
        <>
          <Box>
            <Alert severity="info">
              You'll need to buy a membership plan to access this section
            </Alert>
          </Box>
          <Box mt={6}>
            <BuyMembershipCard />
          </Box>
        </>
      )}
    />
  );
}

// export const isCanOrBuyFunction = (
//   allowFor = 'membership_3_resources:view'
// ) => {
//   let USER = useSelector((state) => state.user.user);
//   return canFunction({
//     membership: USER.membership_type,
//     role: USER.user_type,
//     perform: allowFor
//   });
// };

CanOrBuy.defaultProps = {
  allowFor: 'membership_3_resources:view'
};

CanOrBuy.propTypes = {
  allowFor: PropTypes.any
};

export default React.memo(CanOrBuy);
