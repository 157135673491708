import axios from 'axios';
import store from '../store';

import { SNACK_BAR } from '../types';

export const setSnackbar =
  (
    message = null,
    variant = 'info',
    action_function = null,
    action_name = null
  ) =>
  async (dispatch) => {
    // variant could be success, error, warning, info, or default
    dispatch({
      type: SNACK_BAR,
      payload: { message, variant, action_function, action_name }
    });
  };
