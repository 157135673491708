// React-Redux
import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'react-perfect-scrollbar/dist/css/styles.css';
import config from 'config/main';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
// MUI
import { makeStyles } from '@material-ui/styles';
import { PopupContainer, ContactUsForm } from 'components';
import { setSnackbar as a_setSnackbar } from 'redux/actions/snackbarActions';
import { BookCall as a_BookCall } from 'redux/actions/dataActions';
import Jump from 'react-reveal/Jump';

import PhoneIcon from '@material-ui/icons/Phone';

import { Box, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 150
  },
  whatsappButton: {
    background: theme.palette.success.main
  }
}));

export default function BookACall(props) {
  const [email, setEmail] = React.useState(null);
  const [country, setCountry] = React.useState('India');
  const [phone, setPhone] = React.useState(null);
  const [call_date_time, setDate] = React.useState(new Date());
  //   useEffect(() => {}, []);
  // let s_book_call = useSelector((state) => state.data.book_call);
  let dispatch = useDispatch();

  const classes = useStyles();

  return (
    <Jump>
      <PopupContainer
        buttonIcon={<PhoneIcon className="infiniteZoomInOut" />}
        openerButtonName={props.buttonName}
        buttonFullWidth={props.buttonFullWidth}
        buttonColor="primary"
        buttonVariant="contained"
        title="Book a call now"
        contentText="Get in touch with us"
        dialogMaxWidth="sm">
        <Box>
          {config.general.enquireButton === 'WHATSAPP' ? (
            <Button
              className={classes.whatsappButton}
              href={`https://wa.me/${config.general.whatsapp_number}?text=${config.general.whatsapp_preloaded_message}`}
              target="_blank"
              variant="contained"
              color="primary"
              fullWidth>
              <WhatsAppIcon /> &nbsp; {config.general.whatsappButtonName}
            </Button>
          ) : (
            <ContactUsForm />
          )}
        </Box>
      </PopupContainer>
    </Jump>
  );
}

BookACall.defaultProps = {
  buttonFullWidth: false,
  buttonName: 'Enquire Now'
};

BookACall.propTypes = {
  buttonFullWidth: PropTypes.any,
  buttonName: PropTypes.any
};
