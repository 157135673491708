import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import PropTypes from 'prop-types';
import RepeatIcon from '@material-ui/icons/Repeat';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Box, Hidden } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
    borderRadius: '5px'
    // minWidth: '30vw',
    // display: 'inline-block',
    // wordBreak: 'break-word'
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main
  }
}));

export default function CustomizedTimeline(props) {
  const classes = useStyles();

  const getComponent = () => {
    return (
      <>
        {props.list &&
          props.list.map((element) => (
            <TimelineItem>
              <TimelineOppositeContent>
                <Typography variant="body2" color="textSecondary">
                  {element.time}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="secondary">
                  <RepeatIcon />
                </TimelineDot>
                <TimelineConnector className={classes.secondaryTail} />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={8} className={classes.paper}>
                  {/* <Box width="40vw"> */}
                  <Box>
                    <PerfectScrollbar>
                      <Typography variant="h6" component="h1">
                        {element.heading}
                      </Typography>
                      <Typography gutterBottom>
                        {element.description}
                      </Typography>
                      {element.descriptionList &&
                        element.descriptionList.map((item) => (
                          <>
                            <Typography variant="body1">
                              <i>{item}</i>
                            </Typography>
                            {/* <br /> */}
                          </>
                        ))}
                    </PerfectScrollbar>
                  </Box>
                </Paper>
              </TimelineContent>
            </TimelineItem>
          ))}
      </>
    );
  };

  return (
    <>
      <Hidden mdDown>
        <Timeline align="alternate">{getComponent()}</Timeline>
      </Hidden>
      <Hidden lgUp>
        <Timeline align="left">{getComponent()}</Timeline>
      </Hidden>
    </>
  );
}

CustomizedTimeline.propTypes = {
  list: PropTypes.any
};
