// React-Redux
import React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
// MUI
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import config from 'config/main';

import { DarkCard, VideoPlayerPopup, BookACall } from 'components';
import { Container, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
    // height: '20px',
    background: theme.palette.error.light,
    color: theme.palette.error.contrastText
  }
}));

export default function BuyMembershipCard() {
  const [render, setRender] = React.useState(false);
  useEffect(() => {
    setTimeout(() => {
      setRender(true);
    }, 1000);
  }, []);
  //   let certName = useSelector((state) => state.user.profile.name_on_certificate);
  //   let dispatch = useDispatch();

  const classes = useStyles();

  return (
    <Container maxWidth="md">
      {render && (
        <>
          <Box m={2} display="flex" alignItems="center" justifyContent="center">
            <BookACall />
          </Box>
          <DarkCard
            heading={config.buyMembershipCard.heading}
            subHeading={config.buyMembershipCard.subHeading}
            doubleSideList={config.buyMembershipCard.doubleSideList}
            bottomRightButtonLink={
              config.buyMembershipCard.bottomRightButtonLink
            }
            bottomRightButtonLinkName={
              config.buyMembershipCard.bottomRightButtonLinkName
            }
            bottomLeft={
              config.general.enableIntroductionVideoComponent && (
                <VideoPlayerPopup
                  src={config.buyMembershipCard.bottomLeftVideoSrc}
                  heading={config.buyMembershipCard.bottomLeftVideoHeading}
                />
              )
            }
          />
          <Box m={2} display="flex" alignItems="center" justifyContent="center">
            <BookACall />
          </Box>
        </>
      )}
    </Container>
  );
}

BuyMembershipCard.propTypes = {
  heading: PropTypes.any
};
