import rules from './rbac-rules';
import rbacMemberships from './rbac-memberships';
var allRules = rules;

const check = (rules, membership, role, action, data) => {
  // only if membership is given:

  if (membership) {
    const membership_permissions = rbacMemberships[membership];
    if (!membership_permissions) {
      // membership type not found or no permissions in it
      return false;
    }
    const staticMembershipPermissions = membership_permissions.static;

    if (
      staticMembershipPermissions &&
      staticMembershipPermissions.includes(action)
    ) {
      // static membership rule not provided for action
      return true;
    }
  }

  // dynamic membership permissions are not implemented yet -- has to be done later if needed

  const permissions = rules[role];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

export const Can = (props) =>
  check(rules, props.membership, props.role, props.perform, props.data)
    ? props.yes()
    : props.no();

Can.defaultProps = {
  yes: () => null,
  no: () => null
};

export const canFunction = ({ membership, role, perform, data }) => {
  return check(rules, membership, role, perform, data);
};
export const canFunctionWithDefaults = ({
  user,
  perform = 'membership_3_resources:view',
  data
}) => {
  return check(rules, user.membership_type, user.user_type, perform, data);
};

export default Can;

/*
Using can component template:


<Can
  role={USER.user_type}
  perform="RESOURCE:ACTION"
  yes={() => <>YES_COMPONENT</>}
  no={() => <>NO_COMPONENT</>}
/>


*/
