// React-Redux
import React from 'react';

import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// MUI
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { SigninSignupPopup } from 'components';

import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { Button } from '@material-ui/core';

import config from 'config/main';

import Can from 'rbac/Can';

const useStyles = makeStyles((theme) => ({
  root: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  }
}));

export default function BuyMembershipPaymentLink(props) {
  let USER = useSelector((state) => state.user.user);
  const classes = useStyles();
  const history = useHistory();

  const redirectToPayments = (offerId) => {
    let redirectUrl = `${
      config.general.payments_base_url_web
    }/payments/offer/${offerId}?userEmail=${
      USER.email
    }&country=${localStorage.getItem('country')}`;
    console.log(redirectUrl);
    window.location = redirectUrl;
  };

  return (
    <div className={classes.root}>
      {/* <Tooltip placement="top" title={props.price ? props.price : 'Buy Now'}> */}
      <Can
        role={USER.user_type}
        perform="signInSignUpPopup:view"
        yes={() => (
          <>
            <SigninSignupPopup
              buttonFullWidth={props.buttonFullWidth}
              colorType={props.colorType}
              buttonName={props.buttonName}
            />
          </>
        )}
        no={() => (
          <>
            <Button
              fullWidth={props.buttonFullWidth}
              //   className={classes.action}
              onClick={() => {
                redirectToPayments(props.offerId);
              }}
              color={props.colorType}
              variant="contained"
              // component="a"
              //   href={`${config.general.payments_base_url_web}`}
            >
              <ShoppingCartIcon className={classes.actionIcon} />
              {props.buttonName}
            </Button>
          </>
        )}
      />
    </div>
  );
}

BuyMembershipPaymentLink.defaultProps = {
  buttonFullWidth: false
};

BuyMembershipPaymentLink.propTypes = {
  buttonName: PropTypes.any,
  offerId: PropTypes.any,
  colorType: PropTypes.any,
  buttonFullWidth: PropTypes.any
};
