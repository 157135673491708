import {
  COURSE_SET,
  MODULES_SET,
  LESSON_CURRENT_SET,
  COURSE_COMPLETION_PERCENTAGE_SET,
  COURSE_ALL_LOADING,
  COURSE_ALL_SET,
  COURSE_ALL_ERROR,
  ENROLL_COURSE_SET,
  ENROLL_COURSE_LOADING,
  ENROLL_COURSE_ERROR,
  // all_course_module_lessons
  ALL_COURSE_MODULE_LESSONS_LOADING,
  ALL_COURSE_MODULE_LESSONS_SET,
  ALL_COURSE_MODULE_LESSONS_ERROR
} from '../types';

import * as moment from 'moment';

import { createReducer } from '@reduxjs/toolkit';
import store from '../store';

var initialObject = {
  loading: false,
  value: null,
  error: null,
  is_fetched: false
};

const initialState = {
  // all courses
  all_courses_loading: false,
  all_courses: [],
  all_courses_error: null,

  // current course opened for viewing (video view page)
  course: null,
  course_fetched: false,
  // modules
  modules: null,
  modules_fetched: false,

  current_lesson_playing: null,
  course_completion_percentage: null,

  // enrollment into the course
  enroll_course: { ...initialObject },
  // all_course_module_lessons

  all_course_module_lessons: { ...initialObject }
};

export default createReducer(initialState, {
  COURSE_ALL_LOADING: (state, action) => {
    state['all_courses_loading'] = action.payload;
  },
  COURSE_ALL_SET: (state, action) => {
    state['all_courses'] = action.payload;
  },
  COURSE_ALL_ERROR: (state, action) => {
    state['all_courses_error'] = action.payload;
  },

  COURSE_SET: (state, action) => {
    state['course'] = action.payload;
    state['course_fetched'] = true;
  },
  MODULES_SET: (state, action) => {
    state['modules'] = action.payload;
    state['modules_fetched'] = true;
    state['current_lesson_playing'] = action.payload[0].lessons[0];
  },
  LESSON_CURRENT_SET: (state, action) => {
    setCurrentLesson(state, action.payload);
    // state['current_lesson_playing'] = action.payload;
  },
  COURSE_COMPLETION_PERCENTAGE_SET: (state, action) => {
    state['course_completion_percentage'] = action.payload;
  },

  // enrollment
  ENROLL_COURSE_LOADING: (state, action) => {
    state.enroll_course['loading'] = action.payload;
  },
  ENROLL_COURSE_SET: (state, action) => {
    // implementations: userReducer
    state.enroll_course['value'] = action.payload;
  },
  ENROLL_COURSE_ERROR: (state, action) => {
    state.enroll_course['error'] = action.payload;
  },
  // get -- all_course_module_lessons
  ALL_COURSE_MODULE_LESSONS_LOADING: (state, action) => {
    state.all_course_module_lessons['loading'] = action.payload;
  },
  ALL_COURSE_MODULE_LESSONS_SET: (state, action) => {
    state.all_course_module_lessons['value'] = action.payload;
    state.all_course_module_lessons['is_fetched'] = true;
  },
  ALL_COURSE_MODULE_LESSONS_ERROR: (state, action) => {
    state.all_course_module_lessons['error'] = action.payload;
  }
});

const setCurrentLesson = (state, lessonId) => {
  let found = false;
  state.modules.forEach((course) => {
    course.lessons.forEach((lesson) => {
      if (lesson.id === lessonId) {
        found = true;
        state['current_lesson_playing'] = lesson;
        return;
      }
    });

    if (found) return;
  });
};
