// React-Redux
import React from 'react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
// MUI
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { SigninSignupPopup } from 'components';

import CreditCardIcon from '@material-ui/icons/CreditCard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import {
  Button,
  Backdrop,
  CircularProgress,
  Box,
  Typography,
  Tooltip
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import config from 'config/main';

import { setSnackbar as a_setSnackbar } from 'redux/actions/snackbarActions';
import { setNewToken as a_setNewToken } from 'redux/actions/userActions';
import {
  createOrder as a_createOrder,
  verifyPaymentSignature as a_verifyPaymentSignature
} from 'redux/actions/membershipActions';
import Can from 'rbac/Can';

// // actions
// import {
//   handleProfileDetailsEditChange,
//   updateProfileDetails
// } from '../../redux/actions/userActions';

// import { setSnackbar } from '../../redux/actions/snackbarActions';

const useStyles = makeStyles((theme) => ({
  root: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  }
}));

export default function BuyMembershipButton(props) {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  let s_create_order = useSelector((state) => state.membership.create_order);
  let s_verify_payment_signature = useSelector(
    (state) => state.membership.verify_payment_signature
  );
  let s_user = useSelector((state) => state.user.user);
  const USER = s_user;
  let dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const handleOrderClient = (orderCreateResponse) => {
    var options = {
      key: config.razorPay_config.key_id, // Enter the Key ID generated from the Dashboard
      amount: `${orderCreateResponse.amount}`, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: orderCreateResponse.currency,
      name: config.general.company_name,
      description: config.razorPay_config.transaction_description,
      // image: 'https://example.com/your_logo',
      order_id: orderCreateResponse.order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: function (response) {
        console.log(`HANDLER RESPONSE HERE..`);
        console.log(response);
        dispatch(
          a_verifyPaymentSignature(response, function (verifyResponse) {
            console.log(`verifyResponse-DJ`);
            console.log(verifyResponse);
            dispatch(a_setSnackbar('Payment Successful', 'success'));
            dispatch(a_setNewToken());
            // redirect to all-courses
            history.push(`/all-courses`);
          })
        );
        // alert(response);
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature);
      },
      prefill: {
        name: s_user.username,
        email: s_user.email
        // contact: '9999999999'
      },
      notes: {
        address: config.razorPay_config.company_address
      },
      theme: {
        color: config.razorPay_config.color_theme
      }
    };

    var rzp = new window.Razorpay(options);
    rzp.open();
  };

  const order = (membership_type) => {
    const orderObj = {
      membership_type: membership_type,
      currency: 'INR',
      referrer: localStorage.getItem('ref')
    };
    dispatch(
      a_createOrder(orderObj, function (response) {
        // success callback function
        console.log(`Successful order`);
        console.dir(response);
        handleOrderClient(response);
      })
    );
  };

  return (
    <div className={classes.root}>
      {/* <Tooltip placement="top" title={props.price ? props.price : 'Buy Now'}> */}
      <Can
        role={USER.user_type}
        perform="signInSignUpPopup:view"
        yes={() => (
          <>
            <SigninSignupPopup
              buttonFullWidth={props.buttonFullWidth}
              colorType={props.colorType}
              buttonName={props.buttonName}
            />
          </>
        )}
        no={() => (
          <>
            <Button
              fullWidth={props.buttonFullWidth}
              //   className={classes.action}
              onClick={() => {
                order(props.membershipType);
              }}
              color={props.colorType}
              variant="contained"
              // component="a"
              // href="/buy-membership"
            >
              <ShoppingCartIcon className={classes.actionIcon} />
              {props.buttonName}
            </Button>
          </>
        )}
      />

      {/* </Tooltip> */}
      <Backdrop className={classes.backdrop} open={s_create_order.loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Backdrop
        className={classes.backdrop}
        open={s_verify_payment_signature.loading}>
        <>
          <Box display="flex" flexDirection="column" alignItems="center">
            Please wait...verifying payment...do not refresh or close!
            <CircularProgress color="inherit" />
          </Box>
        </>
      </Backdrop>
    </div>
  );
}

BuyMembershipButton.defaultProps = {
  buttonFullWidth: false
};

BuyMembershipButton.propTypes = {
  buttonName: PropTypes.any,
  membershipType: PropTypes.any,
  colorType: PropTypes.any,
  buttonFullWidth: PropTypes.any
};
