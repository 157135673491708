import general from '../general';
export default {
  sections: [
    {
      header: `Claim`,
      text: `Our policy lasts 7 days. If 7 days have gone by since your purchase,
      unfortunately we can’t offer you a refund or exchange.
      `
    },
    {
      header: `Refunds (if applicable)`,
      text: `As soon as refund is made, we'll send you an email stating the same.
      We will also notify you of the approval or rejection of your refund.
      If you are approved, then your refund will be processed, and a credit will automatically
      be applied to your credit card or original method of payment, within a certain amount of days.
      `
    },
    {
      header: `Refund Policy`,
      text: `Refund is given only if applicable. Refund can be given only if 7 days haven't been 
      passed from the initial date of payment.
      Important: A refund is given only if you have NOT already made the full payment.
      Refund is applicable only if you have made one installment of payment and not 
      the complete payment for 1 year membership.
      Or, a refund is given if you have made the payment and have not got access to the 
      application/course within 3 days of making the payment.

      A complete 100% refund amount is guaranteed only if you have made the payment and 
      have not got access to the system/application due to payment related issues.
      Else, only a partial refund is given if you satisfy the above conditions.
      The partial refund is dependent your usage of the application which is 
      inversely proportional.

      To raise a request for a refund, you'll need to email us on ${general.help_email} 
      before in advance (7 days).`
    }
  ]
};
