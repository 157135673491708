import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  // Button,
  Container,
  Typography,
  makeStyles,
  Card
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 80,
    paddingBottom: 80,
    borderRadius: '5px;'
  },
  browseButton: {
    marginLeft: theme.spacing(2)
  }
}));

function CallToActionCard(props) {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root)} elevation={6}>
      <Container maxWidth="lg">
        <Typography variant="h1" align="center" color="textPrimary">
          {props.heading1}
        </Typography>
        <Typography variant="h1" align="center" color="secondary">
          {props.heading2}
        </Typography>
        <Box mt={6} display="flex" justifyContent="center" alignItems="center">
          {props.buttonComponent}
          {/* <Button
            color="secondary"
            component="a"
            href="https://material-ui.com/store/items/devias-kit-pro"
            variant="contained">
            Get the kit
          </Button> */}
        </Box>
      </Container>
    </Card>
  );
}

CallToActionCard.propTypes = {
  heading1: PropTypes.any,
  heading2: PropTypes.any,
  buttonComponent: PropTypes.any
};

export default CallToActionCard;
