import PropTypes from 'prop-types';
import React from 'react';
const { Alert } = require('@material-ui/lab');

function AlertBullets(props) {
  console.log(`AlertBullets -- RE-RENDER`);
  return (
    <div>
      <Alert severity={props.severity} variant={props.variant}>
        <b>{props.heading}</b>
        {!props.arrayList.length && (
          <>
            <br />
            None
          </>
        )}
        <ul>
          {props.arrayList.map((element) => (
            <li key={element}>{element}</li>
          ))}
        </ul>
      </Alert>
    </div>
  );
}

AlertBullets.defaultProps = {
  severity: 'info',
  variant: 'filled'
};

AlertBullets.propTypes = {
  heading: PropTypes.any,
  arrayList: PropTypes.any,
  severity: PropTypes.any,
  variant: PropTypes.any
};

// export default AlertBullets;

export default React.memo(AlertBullets);
