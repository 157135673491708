import React from 'react';
import Button from '@material-ui/core/Button';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import Zoom from '@material-ui/core/Zoom';
function MyApp() {
  const { enqueueSnackbar } = useSnackbar();

  const snackbarState = useSelector((state) => state.snackBar);

  //   const handleClick = () => {
  //     enqueueSnackbar('I love snacks');
  //   };

  // the above useEffect function runs only when there is a change in the snackbarState.snackbar_toggler
  // value. (advantage of hooks)
  useEffect(() => {
    const message = snackbarState.message;
    const variant = snackbarState.variant;
    const action_function = snackbarState.action_function;
    const action_name = snackbarState.action_name;
    // the below is written to make sure the component is not rendered on the initial render where
    // it has the initial state message as "welcome" we want it to be re-rendered only when it changes
    // So we've kept a variable in state called 'snackbar_toggler' to observe for changes.
    if (message === 'welcome') {
      return;
    }
    enqueueSnackbar(message, {
      TransitionComponent: Zoom,
      style: { whiteSpace: 'pre-line' },

      variant: variant,
      autoHideDuration: 6000,
      action: action_function && (
        <Button onClick={() => action_function()}>{action_name}</Button>
      )
    });
  }, [snackbarState.snackbar_toggler]);

  return null;
}
const useStyles = makeStyles((theme) => ({
  error: {
    width: '100%'
  }
}));
export default function CustomSnackbar() {
  const classes = useStyles();
  return (
    <SnackbarProvider
      // action={<Button onClick={() => alert('message')}>'Alert'</Button>}
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      classes={{
        variantError: classes.error
      }}>
      <MyApp />
    </SnackbarProvider>
  );
}
