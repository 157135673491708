import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  }
}));

export default function ScrollingTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example">
          {props.list.map((element, index) => (
            <Tab
              label={element.label}
              icon={element.icon}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </AppBar>

      {props.list.map((element, index) => (
        <TabPanel value={value} index={index}>
          {element.value}
        </TabPanel>
      ))}
    </div>
  );
}

ScrollingTabs.propTypes = {
  list: PropTypes.any
};

// 'list' prop format:
/**

[
  {
    icon: Tab-1's MUI-Icon label
    label: 'item-1-label',
    value: 'Here goes the value(children) for the first tab'
  },
  {
    icon: Tab-1's MUI-Icon label
    label: 'item-2-label',
    value: 'Here goes the value(children) for the second tab'
  },
]

 */
