// React-Redux
import React from 'react';

// MUI
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '10px',
    width: '100%',
    height: '50px',
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    zIndex: 1000,
    position: 'absolute',
    left: '0px',
    top: '0px',
    textAlign: 'center'
  },
  errorText: {
    margin: '5px',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '1.2rem'
  }
}));

export default function ErrorBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.errorText}>This is an error message!</div>
    </div>
  );
}
