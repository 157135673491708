import { createReducer } from '@reduxjs/toolkit';

var initialObject = {
  loading: false,
  value: null,
  error: null,
  is_fetched: false
};

const initialState = {
  // my_pattern_subscriptions

  my_pattern_subscriptions: { ...initialObject },
  // create_pattern_subscriptions

  create_pattern_subscriptions: { ...initialObject },
  // update_pattern_subscriptions

  update_pattern_subscriptions: { ...initialObject },
  // delete_pattern_subscription

  delete_pattern_subscription: { ...initialObject }
};

export default createReducer(initialState, {
  // get -- my_pattern_subscriptions
  MY_PATTERN_SUBSCRIPTIONS_LOADING: (state, action) => {
    state.my_pattern_subscriptions['loading'] = action.payload;
  },
  MY_PATTERN_SUBSCRIPTIONS_SET: (state, action) => {
    state.my_pattern_subscriptions['value'] = action.payload;
    state.my_pattern_subscriptions['is_fetched'] = true;
  },
  MY_PATTERN_SUBSCRIPTIONS_ERROR: (state, action) => {
    state.my_pattern_subscriptions['error'] = action.payload;
  },

  // get -- create_pattern_subscriptions
  CREATE_PATTERN_SUBSCRIPTIONS_LOADING: (state, action) => {
    state.create_pattern_subscriptions['loading'] = action.payload;
  },
  CREATE_PATTERN_SUBSCRIPTIONS_SET: (state, action) => {
    state.create_pattern_subscriptions['value'] = action.payload;
    state.create_pattern_subscriptions['is_fetched'] = true;
    // To add the newly created subscription to the list -- to avoid making the API call again!
    state.my_pattern_subscriptions['value'].push(action.payload);
  },
  CREATE_PATTERN_SUBSCRIPTIONS_ERROR: (state, action) => {
    state.create_pattern_subscriptions['error'] = action.payload;
  },
  // patch -- update_pattern_subscriptions
  UPDATE_PATTERN_SUBSCRIPTIONS_LOADING: (state, action) => {
    state.update_pattern_subscriptions['loading'] = action.payload;
  },
  UPDATE_PATTERN_SUBSCRIPTIONS_SET: (state, action) => {
    state.update_pattern_subscriptions['value'] = action.payload;
    state.update_pattern_subscriptions['is_fetched'] = true;
  },
  UPDATE_PATTERN_SUBSCRIPTIONS_ERROR: (state, action) => {
    state.update_pattern_subscriptions['error'] = action.payload;
  },
  // patch -- delete_pattern_subscription
  DELETE_PATTERN_SUBSCRIPTION_LOADING: (state, action) => {
    state.delete_pattern_subscription['loading'] = action.payload;
  },
  DELETE_PATTERN_SUBSCRIPTION_SET: (state, action) => {
    state.delete_pattern_subscription['value'] = action.payload;
    state.delete_pattern_subscription['is_fetched'] = true;
  },
  DELETE_PATTERN_SUBSCRIPTION_ERROR: (state, action) => {
    state.delete_pattern_subscription['error'] = action.payload;
  }
});
