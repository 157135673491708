import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Divider,
  Grid,
  Link,
  Typography,
  makeStyles
} from '@material-ui/core';
import { ReadOnlyStar } from '../../components';

const useStyles = makeStyles((theme) => ({
  root: {},
  rating: {
    marginLeft: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold
  }
}));

function ReviewCard({ review, className, ...rest }) {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        avatar={
          <Avatar alt="Reviewer" className={classes.avatar} src={review.avatar}>
            {review.name[0]}
          </Avatar>
        }
        disableTypography
        subheader={
          <Box flexWrap="wrap" display="flex" alignItems="center">
            <Box display="flex" alignItems="center" mr={1}>
              {/* <Rating value={review.rating} /> */}
              <ReadOnlyStar count={review.rating} />
              <Typography className={classes.rating} variant="h6">
                {review.rating}
              </Typography>
            </Box>
            <Typography variant="body2" color="textSecondary">
              | Trained by{' '}
              <Link
                color="textPrimary"
                component={RouterLink}
                to="#"
                variant="h6">
                {review.trainedBy}
              </Link>{' '}
              {/* | {moment(review.createdAt).fromNow()} */}
            </Typography>
          </Box>
        }
        title={
          <Link color="textPrimary" component={RouterLink} to="#" variant="h5">
            {review.name}
          </Link>
        }
      />
      <Box pb={2} px={3}>
        <Typography variant="body2" color="textSecondary">
          {review.message}
        </Typography>
      </Box>
      <Divider />
      <Box py={2} px={3}>
        <Grid alignItems="center" container justify="space-between" spacing={3}>
          <Grid item>
            <Typography variant="h5" color="textPrimary">
              {review.footerText.leftValue}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {review.footerText.leftKey}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5" color="textPrimary">
              {review.footerText.middleValue}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {review.footerText.middleKey}
            </Typography>
          </Grid>
          {/* <Grid item>
            <Typography variant="h5" color="textPrimary">
              {review.footerText.rightValue}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {review.footerText.rightKey}
            </Typography>
          </Grid> */}
        </Grid>
      </Box>
    </Card>
  );
}

ReviewCard.propTypes = {
  className: PropTypes.string,
  review: PropTypes.object.isRequired
};

export default ReviewCard;
