import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  // Button,
  Box,
  Container,
  Grid,
  Typography,
  makeStyles,
  Card
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Flip from 'react-reveal/Flip';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 40,
    paddingBottom: 80,
    borderRadius: '5px;'
    // boxShadow: '0 5px 10px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23);'
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  }
}));

function FeaturesCard(props) {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root)} elevation={7}>
      <Container maxWidth="lg">
        <Typography
          component="p"
          variant="overline"
          color="secondary"
          align="center">
          {props.topLabel}
        </Typography>
        <Typography variant="h1" align="center" color="textPrimary">
          {props.heading}
        </Typography>
        <Box mt={2}>
          <Grid container spacing={3}>
            {props.featuresList &&
              props.featuresList.map((feature) => (
                <Grid item xs={12} md={4}>
                  <Flip top>
                    <Box display="flex">
                      <Avatar className={classes.avatar}>
                        <CheckCircleIcon />
                      </Avatar>
                      <Box ml={2}>
                        <Typography
                          variant="h4"
                          gutterBottom
                          color="textPrimary">
                          {feature.heading}
                        </Typography>
                        <Typography variant="body1" color="textPrimary">
                          {feature.text}
                        </Typography>
                      </Box>
                    </Box>
                  </Flip>
                </Grid>
              ))}
          </Grid>
        </Box>
      </Container>
    </Card>
  );
}

FeaturesCard.propTypes = {
  topLabel: PropTypes.string,
  heading: PropTypes.string,
  featuresList: PropTypes.string
};

export default FeaturesCard;
