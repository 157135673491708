import React from 'react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

import {
  getMyBankDetails as a_getMyBankDetails,
  updateMyBankDetails as a_updateMyBankDetails
} from '../../redux/actions/membershipActions';
import { setSnackbar as a_setSnackbar } from '../../redux/actions/snackbarActions';
import { Box, Typography, LinearProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  textField: {
    marginTop: theme.spacing(3)
  }
}));

export default function UpdateBankDetails(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [bank_account_number, setBankAccountNumber] = useState('');
  const [bank_ifsc_code, setBankIfscCode] = useState('');
  const [bank_account_name, setBankAccountName] = useState('');
  const [upi_id, setUpiId] = useState('');
  const [phone_number, setPhoneNumber] = useState('');

  useEffect(() => {
    dispatch(
      //   a_getMyBankDetails(function (responseData) {
      //     console.log(`SUCCESS`);
      //     console.log(responseData);
      //   })
      a_getMyBankDetails((responseData) => {
        console.log(responseData);
        setBankAccountNumber(responseData.bank_account_number);
        setBankIfscCode(responseData.bank_ifsc_code);
        setBankAccountName(responseData.bank_account_name);
        setUpiId(responseData.upi_id);
        setPhoneNumber(responseData.phone_number);
      })
    );
  }, []);

  let s_my_bank_details = useSelector(
    (state) => state.membership.my_bank_details
  );
  let s_update_my_bank_details = useSelector(
    (state) => state.membership.update_my_bank_details
  );
  let dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // // // Change handlers
  const handleBankAccountNumberChange = (event) => {
    event.persist();
    setBankAccountNumber(event.target.value);
  };
  const handleBankIfscCodeChange = (event) => {
    event.persist();
    setBankIfscCode(event.target.value);
  };
  const handleBankAccountNameChange = (event) => {
    event.persist();
    setBankAccountName(event.target.value);
  };
  const handleUpiIdChange = (event) => {
    event.persist();
    setUpiId(event.target.value);
  };
  const handlePhoneNumberChange = (event) => {
    event.persist();
    setPhoneNumber(event.target.value);
  };

  const handleUpdateBankDetails = async (event) => {
    event.preventDefault();

    dispatch(
      a_updateMyBankDetails(
        {
          bank_account_number,
          bank_ifsc_code,
          bank_account_name,
          upi_id,
          phone_number
        },
        function (response) {
          dispatch(a_setSnackbar('Updated Successfully', 'success'));
        }
      )
    );
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        size="small"
        variant="contained"
        color="primary">
        <AccountBalanceIcon className={classes.socialIcon} />
        Update bank details
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          Update your bank details
        </DialogTitle>
        <form className={classes.form} onSubmit={handleUpdateBankDetails}>
          <DialogContent>
            {s_my_bank_details.loading && <LinearProgress />}
            <DialogContentText>
              Please enter your right bank details so that you can start
              receiving the payments from what you've earned!
            </DialogContentText>

            <TextField
              className={classes.textField}
              error={
                s_update_my_bank_details.error &&
                s_update_my_bank_details.error.bank_account_number
              }
              fullWidth
              helperText={
                s_update_my_bank_details.error &&
                s_update_my_bank_details.error.bank_account_number
                  ? s_update_my_bank_details.error.bank_account_number
                  : null
              }
              label="Bank Account Number"
              name="bank_account_number"
              // required={true}
              onChange={handleBankAccountNumberChange}
              type="text"
              value={bank_account_number || ''}
              variant="outlined"
            />
            <TextField
              className={classes.textField}
              error={
                s_update_my_bank_details.error &&
                s_update_my_bank_details.error.bank_ifsc_code
              }
              fullWidth
              helperText={
                s_update_my_bank_details.error &&
                s_update_my_bank_details.error.bank_ifsc_code
                  ? s_update_my_bank_details.error.bank_ifsc_code
                  : null
              }
              label="Bank IFSC Code"
              name="bank_ifsc_code"
              // required={true}
              onChange={handleBankIfscCodeChange}
              type="text"
              value={bank_ifsc_code || ''}
              variant="outlined"
            />
            <TextField
              className={classes.textField}
              error={
                s_update_my_bank_details.error &&
                s_update_my_bank_details.error.bank_account_name
              }
              fullWidth
              helperText={
                s_update_my_bank_details.error &&
                s_update_my_bank_details.error.bank_account_name
                  ? s_update_my_bank_details.error.bank_account_name
                  : null
              }
              label="Bank Account Name"
              name="bank_account_name"
              // required={true}
              onChange={handleBankAccountNameChange}
              type="text"
              value={bank_account_name || ''}
              variant="outlined"
            />
            <TextField
              className={classes.textField}
              error={
                s_update_my_bank_details.error &&
                s_update_my_bank_details.error.upi_id
              }
              fullWidth
              helperText={
                s_update_my_bank_details.error &&
                s_update_my_bank_details.error.upi_id
                  ? s_update_my_bank_details.error.upi_id
                  : null
              }
              label="UPI ID"
              name="upi_id"
              // required={true}
              onChange={handleUpiIdChange}
              type="text"
              value={upi_id || ''}
              variant="outlined"
            />
            <TextField
              className={classes.textField}
              error={
                s_update_my_bank_details.error &&
                s_update_my_bank_details.error.phone_number
              }
              fullWidth
              helperText={
                s_update_my_bank_details.error &&
                s_update_my_bank_details.error.phone_number
                  ? s_update_my_bank_details.error.phone_number
                  : null
              }
              label="Phone Number (India)"
              name="phone_number"
              // required={true}
              onChange={handlePhoneNumberChange}
              type="text"
              value={phone_number || ''}
              variant="outlined"
            />
            <Box margin="10px 0">
              <Typography variant="subtitle2">
                {s_update_my_bank_details.value ? (
                  <>Successfully Updated your bank details </>
                ) : null}
              </Typography>
              {s_update_my_bank_details.loading && <LinearProgress />}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
            <Button color="primary" type="submit">
              {s_update_my_bank_details.loading ? (
                <>Saving details.....</>
              ) : (
                <>Update bank details</>
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
