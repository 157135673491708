import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { useTheme } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%'
    // overflowWrap: 'break-all'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.contrastText
  },
  main: {
    background: theme.palette.primary.main,
    borderRadius: '10px'
  }
}));

export default function TradingViewChartWidget(props) {
  let currentTheme = useSelector((state) => state.display.theme);
  const theme = useTheme(); // Problem is that, this doesn't change dynamically when the theme changes.
  const classes = useStyles();
  var chart;

  useEffect(() => {
    new window.TradingView.widget({
      width: '100%',
      height: 500,
      //   autosize: true,
      symbol: props.stockSymbol,
      interval: 'D',
      timezone: 'Asia/Kolkata',
      theme: currentTheme === 'lightTheme' ? 'light' : 'dark',
      style: '1',
      locale: 'in',
      toolbar_bg: '#f1f3f6',
      enable_publishing: false,
      allow_symbol_change: true,
      studies: ['MASimple@tv-basicstudies', 'RSI@tv-basicstudies'],
      container_id: 'tradingview_db165'
    });
  }, [currentTheme]);

  return (
    <div class="tradingview-widget-container">
      <div id="tradingview_db165"></div>
    </div>
  );
}

TradingViewChartWidget.propTypes = {
  stockSymbol: PropTypes.any
};
