let nifty50 = [
  'NSE:TATASTEEL',
  'NSE:BAJAJFINSV',
  'NSE:ADANIPORTS',
  'NSE:BHARTIARTL',
  'NSE:DIVISLAB',
  'NSE:HINDALCO',
  'NSE:JSWSTEEL',
  'NSE:GRASIM',
  'NSE:CIPLA',
  'NSE:ONGC',
  'NSE:NTPC',
  'NSE:TATACONSUM',
  'NSE:DRREDDY',
  'NSE:TATAMOTORS',
  'NSE:MARUTI',
  'NSE:INFY',
  'NSE:HDFCLIFE',
  'NSE:COALINDIA',
  'NSE:BAJFINANCE',
  'NSE:SUNPHARMA',
  'NSE:HCLTECH',
  'NSE:UPL',
  'NSE:HINDUNILVR',
  'NSE:NESTLEIND',
  'NSE:HEROMOTOCO',
  'NSE:LT',
  'NSE:SBIN',
  'NSE:SBILIFE',
  'NSE:IOC',
  'NSE:SHREECEM',
  'NSE:ULTRACEMCO',
  'NSE:WIPRO',
  'NSE:ITC',
  'NSE:BPCL',
  'NSE:BRITANNIA',
  'NSE:TITAN',
  'NSE:POWERGRID',
  'NSE:M&M',
  'NSE:ICICIBANK',
  'NSE:HDFC',
  'NSE:ASIANPAINT',
  'NSE:KOTAKBANK',
  'NSE:INDUSINDBK',
  'NSE:EICHERMOT',
  'NSE:TECHM',
  'NSE:AXISBANK',
  'NSE:RELIANCE',
  'NSE:HDFCBANK',
  'NSE:TCS',
  'NSE:BAJAJ-AUTO'
];

let next50 = [
  'NSE:DLF',
  'NSE:VEDL',
  'NSE:GLAND',
  'NSE:TORNTPHARM',
  'NSE:APOLLOHOSP',
  'NSE:ZYDUSLIFE',
  // 'NSE:CADILAHC',
  'NSE:MARICO',
  'NSE:ADANITRANS',
  // 'NSE:ADANITRANS-BE',
  'NSE:BANDHANBNK',
  'NSE:ALKEM',
  'NSE:BOSCHLTD',
  'NSE:LUPIN',
  'NSE:AMBUJACEM',
  'NSE:INDUSTOWER',
  'NSE:UBL',
  'NSE:PETRONET',
  'NSE:ICICIGI',
  'NSE:ACC',
  'NSE:ADANIENT',
  'NSE:LTI',
  'NSE:GODREJCP',
  'NSE:MUTHOOTFIN',
  'NSE:NMDC',
  'NSE:BERGEPAINT',
  'NSE:NAUKRI',
  'NSE:BIOCON',
  'NSE:PNB',
  'NSE:HDFCAMC',
  'NSE:DABUR',
  'NSE:ABBOTINDIA',
  'NSE:DMART',
  'NSE:MCDOWELL-N',
  'NSE:PGHH',
  'NSE:PIDILITIND',
  'NSE:AUROPHARMA',
  'NSE:HAVELLS',
  'NSE:BAJAJHLDNG',
  'NSE:PEL',
  'NSE:MRF',
  'NSE:SIEMENS',
  'NSE:INDIGO',
  'NSE:JUBLFOOD',
  'NSE:YESBANK',
  'NSE:GAIL',
  'NSE:SBICARD',
  'NSE:COLPAL',
  'NSE:ADANIGREEN',
  // 'NSE:ADANIGREEN-BE',
  'NSE:IGL',
  'NSE:ICICIPRULI',
  'NSE:HINDPETRO'
];

export default {
  maxSubscriptions: 5,

  stocks: {
    'NSE:TATASTEEL': {
      zerodhaKiteName: 'NSE:TATASTEEL',
      tradingViewName: 'BSE:TATASTEEL'
    },

    'NSE:BAJAJFINSV': {
      zerodhaKiteName: 'NSE:BAJAJFINSV',
      tradingViewName: 'BSE:BAJAJFINSV'
    },

    'NSE:ADANIPORTS': {
      zerodhaKiteName: 'NSE:ADANIPORTS',
      tradingViewName: 'BSE:ADANIPORTS'
    },

    'NSE:BHARTIARTL': {
      zerodhaKiteName: 'NSE:BHARTIARTL',
      tradingViewName: 'BSE:BHARTIARTL'
    },

    'NSE:DIVISLAB': {
      zerodhaKiteName: 'NSE:DIVISLAB',
      tradingViewName: 'BSE:DIVISLAB'
    },

    'NSE:HINDALCO': {
      zerodhaKiteName: 'NSE:HINDALCO',
      tradingViewName: 'BSE:HINDALCO'
    },

    'NSE:JSWSTEEL': {
      zerodhaKiteName: 'NSE:JSWSTEEL',
      tradingViewName: 'BSE:JSWSTEEL'
    },

    'NSE:GRASIM': {
      zerodhaKiteName: 'NSE:GRASIM',
      tradingViewName: 'BSE:GRASIM'
    },

    'NSE:CIPLA': {
      zerodhaKiteName: 'NSE:CIPLA',
      tradingViewName: 'BSE:CIPLA'
    },

    'NSE:ONGC': {
      zerodhaKiteName: 'NSE:ONGC',
      tradingViewName: 'BSE:ONGC'
    },

    'NSE:NTPC': {
      zerodhaKiteName: 'NSE:NTPC',
      tradingViewName: 'BSE:NTPC'
    },

    'NSE:TATACONSUM': {
      zerodhaKiteName: 'NSE:TATACONSUM',
      tradingViewName: 'BSE:TATACONSUM'
    },

    'NSE:DRREDDY': {
      zerodhaKiteName: 'NSE:DRREDDY',
      tradingViewName: 'BSE:DRREDDY'
    },

    'NSE:TATAMOTORS': {
      zerodhaKiteName: 'NSE:TATAMOTORS',
      tradingViewName: 'BSE:TATAMOTORS'
    },

    'NSE:MARUTI': {
      zerodhaKiteName: 'NSE:MARUTI',
      tradingViewName: 'BSE:MARUTI'
    },

    'NSE:INFY': {
      zerodhaKiteName: 'NSE:INFY',
      tradingViewName: 'BSE:INFY'
    },

    'NSE:HDFCLIFE': {
      zerodhaKiteName: 'NSE:HDFCLIFE',
      tradingViewName: 'BSE:HDFCLIFE'
    },

    'NSE:COALINDIA': {
      zerodhaKiteName: 'NSE:COALINDIA',
      tradingViewName: 'BSE:COALINDIA'
    },

    'NSE:BAJFINANCE': {
      zerodhaKiteName: 'NSE:BAJFINANCE',
      tradingViewName: 'BSE:BAJFINANCE'
    },

    'NSE:SUNPHARMA': {
      zerodhaKiteName: 'NSE:SUNPHARMA',
      tradingViewName: 'BSE:SUNPHARMA'
    },

    'NSE:HCLTECH': {
      zerodhaKiteName: 'NSE:HCLTECH',
      tradingViewName: 'BSE:HCLTECH'
    },

    'NSE:UPL': {
      zerodhaKiteName: 'NSE:UPL',
      tradingViewName: 'BSE:UPL'
    },

    'NSE:HINDUNILVR': {
      zerodhaKiteName: 'NSE:HINDUNILVR',
      tradingViewName: 'BSE:HINDUNILVR'
    },

    'NSE:NESTLEIND': {
      zerodhaKiteName: 'NSE:NESTLEIND',
      tradingViewName: 'BSE:NESTLEIND'
    },

    'NSE:HEROMOTOCO': {
      zerodhaKiteName: 'NSE:HEROMOTOCO',
      tradingViewName: 'BSE:HEROMOTOCO'
    },

    'NSE:LT': {
      zerodhaKiteName: 'NSE:LT',
      tradingViewName: 'BSE:LT'
    },

    'NSE:SBIN': {
      zerodhaKiteName: 'NSE:SBIN',
      tradingViewName: 'BSE:SBIN'
    },

    'NSE:SBILIFE': {
      zerodhaKiteName: 'NSE:SBILIFE',
      tradingViewName: 'BSE:SBILIFE'
    },

    'NSE:IOC': {
      zerodhaKiteName: 'NSE:IOC',
      tradingViewName: 'BSE:IOC'
    },

    'NSE:SHREECEM': {
      zerodhaKiteName: 'NSE:SHREECEM',
      tradingViewName: 'BSE:SHREECEM'
    },

    'NSE:ULTRACEMCO': {
      zerodhaKiteName: 'NSE:ULTRACEMCO',
      tradingViewName: 'BSE:ULTRACEMCO'
    },

    'NSE:WIPRO': {
      zerodhaKiteName: 'NSE:WIPRO',
      tradingViewName: 'BSE:WIPRO'
    },

    'NSE:ITC': {
      zerodhaKiteName: 'NSE:ITC',
      tradingViewName: 'BSE:ITC'
    },

    'NSE:BPCL': {
      zerodhaKiteName: 'NSE:BPCL',
      tradingViewName: 'BSE:BPCL'
    },

    'NSE:BRITANNIA': {
      zerodhaKiteName: 'NSE:BRITANNIA',
      tradingViewName: 'BSE:BRITANNIA'
    },

    'NSE:TITAN': {
      zerodhaKiteName: 'NSE:TITAN',
      tradingViewName: 'BSE:TITAN'
    },

    'NSE:POWERGRID': {
      zerodhaKiteName: 'NSE:POWERGRID',
      tradingViewName: 'BSE:POWERGRID'
    },
    // M&M is not yet supported yet as the nodejs server side accepts the stock names in query params and not
    // route params. So 'M&M' conflits with the params seperator symbol '&' sent in the URL.
    // Solution: Change the query params to route params in the NodeJs server.

    // 'NSE:M&M': {
    //   zerodhaKiteName: 'NSE:M&M',
    //   tradingViewName: 'BSE:M&M'
    // },

    'NSE:ICICIBANK': {
      zerodhaKiteName: 'NSE:ICICIBANK',
      tradingViewName: 'BSE:ICICIBANK'
    },

    'NSE:HDFC': {
      zerodhaKiteName: 'NSE:HDFC',
      tradingViewName: 'BSE:HDFC'
    },

    'NSE:ASIANPAINT': {
      zerodhaKiteName: 'NSE:ASIANPAINT',
      tradingViewName: 'BSE:ASIANPAINT'
    },

    'NSE:KOTAKBANK': {
      zerodhaKiteName: 'NSE:KOTAKBANK',
      tradingViewName: 'BSE:KOTAKBANK'
    },

    'NSE:INDUSINDBK': {
      zerodhaKiteName: 'NSE:INDUSINDBK',
      tradingViewName: 'BSE:INDUSINDBK'
    },

    'NSE:EICHERMOT': {
      zerodhaKiteName: 'NSE:EICHERMOT',
      tradingViewName: 'BSE:EICHERMOT'
    },

    'NSE:TECHM': {
      zerodhaKiteName: 'NSE:TECHM',
      tradingViewName: 'BSE:TECHM'
    },

    'NSE:AXISBANK': {
      zerodhaKiteName: 'NSE:AXISBANK',
      tradingViewName: 'BSE:AXISBANK'
    },

    'NSE:RELIANCE': {
      zerodhaKiteName: 'NSE:RELIANCE',
      tradingViewName: 'BSE:RELIANCE'
    },

    'NSE:HDFCBANK': {
      zerodhaKiteName: 'NSE:HDFCBANK',
      tradingViewName: 'BSE:HDFCBANK'
    },

    'NSE:TCS': {
      zerodhaKiteName: 'NSE:TCS',
      tradingViewName: 'BSE:TCS'
    },

    'NSE:BAJAJ-AUTO': {
      zerodhaKiteName: 'NSE:BAJAJ-AUTO',
      tradingViewName: 'BSE:BAJAJ-AUTO'
    },

    'NSE:DLF': {
      zerodhaKiteName: 'NSE:DLF',
      tradingViewName: 'BSE:DLF'
    },

    'NSE:VEDL': {
      zerodhaKiteName: 'NSE:VEDL',
      tradingViewName: 'BSE:VEDL'
    },

    'NSE:GLAND': {
      zerodhaKiteName: 'NSE:GLAND',
      tradingViewName: 'BSE:GLAND'
    },

    'NSE:TORNTPHARM': {
      zerodhaKiteName: 'NSE:TORNTPHARM',
      tradingViewName: 'BSE:TORNTPHARM'
    },

    'NSE:APOLLOHOSP': {
      zerodhaKiteName: 'NSE:APOLLOHOSP',
      tradingViewName: 'BSE:APOLLOHOSP'
    },

    'NSE:ZYDUSLIFE': {
      zerodhaKiteName: 'NSE:ZYDUSLIFE',
      tradingViewName: 'BSE:ZYDUSLIFE'
    },

    'NSE:MARICO': {
      zerodhaKiteName: 'NSE:MARICO',
      tradingViewName: 'BSE:MARICO'
    },

    'NSE:ADANITRANS': {
      zerodhaKiteName: 'NSE:ADANITRANS',
      tradingViewName: 'BSE:ADANITRANS'
    },

    'NSE:BANDHANBNK': {
      zerodhaKiteName: 'NSE:BANDHANBNK',
      tradingViewName: 'BSE:BANDHANBNK'
    },

    'NSE:ALKEM': {
      zerodhaKiteName: 'NSE:ALKEM',
      tradingViewName: 'BSE:ALKEM'
    },

    'NSE:BOSCHLTD': {
      zerodhaKiteName: 'NSE:BOSCHLTD',
      tradingViewName: 'BSE:BOSCHLTD'
    },

    'NSE:LUPIN': {
      zerodhaKiteName: 'NSE:LUPIN',
      tradingViewName: 'BSE:LUPIN'
    },

    'NSE:AMBUJACEM': {
      zerodhaKiteName: 'NSE:AMBUJACEM',
      tradingViewName: 'BSE:AMBUJACEM'
    },

    'NSE:INDUSTOWER': {
      zerodhaKiteName: 'NSE:INDUSTOWER',
      tradingViewName: 'BSE:INDUSTOWER'
    },

    'NSE:UBL': {
      zerodhaKiteName: 'NSE:UBL',
      tradingViewName: 'BSE:UBL'
    },

    'NSE:PETRONET': {
      zerodhaKiteName: 'NSE:PETRONET',
      tradingViewName: 'BSE:PETRONET'
    },

    'NSE:ICICIGI': {
      zerodhaKiteName: 'NSE:ICICIGI',
      tradingViewName: 'BSE:ICICIGI'
    },

    'NSE:ACC': {
      zerodhaKiteName: 'NSE:ACC',
      tradingViewName: 'BSE:ACC'
    },

    'NSE:ADANIENT': {
      zerodhaKiteName: 'NSE:ADANIENT',
      tradingViewName: 'BSE:ADANIENT'
    },

    'NSE:LTI': {
      zerodhaKiteName: 'NSE:LTI',
      tradingViewName: 'BSE:LTI'
    },

    'NSE:GODREJCP': {
      zerodhaKiteName: 'NSE:GODREJCP',
      tradingViewName: 'BSE:GODREJCP'
    },

    'NSE:MUTHOOTFIN': {
      zerodhaKiteName: 'NSE:MUTHOOTFIN',
      tradingViewName: 'BSE:MUTHOOTFIN'
    },

    'NSE:NMDC': {
      zerodhaKiteName: 'NSE:NMDC',
      tradingViewName: 'BSE:NMDC'
    },

    'NSE:BERGEPAINT': {
      zerodhaKiteName: 'NSE:BERGEPAINT',
      tradingViewName: 'BSE:BERGEPAINT'
    },

    'NSE:NAUKRI': {
      zerodhaKiteName: 'NSE:NAUKRI',
      tradingViewName: 'BSE:NAUKRI'
    },

    'NSE:BIOCON': {
      zerodhaKiteName: 'NSE:BIOCON',
      tradingViewName: 'BSE:BIOCON'
    },

    'NSE:PNB': {
      zerodhaKiteName: 'NSE:PNB',
      tradingViewName: 'BSE:PNB'
    },

    'NSE:HDFCAMC': {
      zerodhaKiteName: 'NSE:HDFCAMC',
      tradingViewName: 'BSE:HDFCAMC'
    },

    'NSE:DABUR': {
      zerodhaKiteName: 'NSE:DABUR',
      tradingViewName: 'BSE:DABUR'
    },

    'NSE:ABBOTINDIA': {
      zerodhaKiteName: 'NSE:ABBOTINDIA',
      tradingViewName: 'BSE:ABBOTINDIA'
    },

    'NSE:DMART': {
      zerodhaKiteName: 'NSE:DMART',
      tradingViewName: 'BSE:DMART'
    },

    'NSE:MCDOWELL-N': {
      zerodhaKiteName: 'NSE:MCDOWELL-N',
      tradingViewName: 'BSE:MCDOWELL-N'
    },

    'NSE:PGHH': {
      zerodhaKiteName: 'NSE:PGHH',
      tradingViewName: 'BSE:PGHH'
    },

    'NSE:PIDILITIND': {
      zerodhaKiteName: 'NSE:PIDILITIND',
      tradingViewName: 'BSE:PIDILITIND'
    },

    'NSE:AUROPHARMA': {
      zerodhaKiteName: 'NSE:AUROPHARMA',
      tradingViewName: 'BSE:AUROPHARMA'
    },

    'NSE:HAVELLS': {
      zerodhaKiteName: 'NSE:HAVELLS',
      tradingViewName: 'BSE:HAVELLS'
    },

    'NSE:BAJAJHLDNG': {
      zerodhaKiteName: 'NSE:BAJAJHLDNG',
      tradingViewName: 'BSE:BAJAJHLDNG'
    },

    'NSE:PEL': {
      zerodhaKiteName: 'NSE:PEL',
      tradingViewName: 'BSE:PEL'
    },

    'NSE:MRF': {
      zerodhaKiteName: 'NSE:MRF',
      tradingViewName: 'BSE:MRF'
    },

    'NSE:SIEMENS': {
      zerodhaKiteName: 'NSE:SIEMENS',
      tradingViewName: 'BSE:SIEMENS'
    },

    'NSE:INDIGO': {
      zerodhaKiteName: 'NSE:INDIGO',
      tradingViewName: 'BSE:INDIGO'
    },

    'NSE:JUBLFOOD': {
      zerodhaKiteName: 'NSE:JUBLFOOD',
      tradingViewName: 'BSE:JUBLFOOD'
    },

    'NSE:YESBANK': {
      zerodhaKiteName: 'NSE:YESBANK',
      tradingViewName: 'BSE:YESBANK'
    },

    'NSE:GAIL': {
      zerodhaKiteName: 'NSE:GAIL',
      tradingViewName: 'BSE:GAIL'
    },

    'NSE:SBICARD': {
      zerodhaKiteName: 'NSE:SBICARD',
      tradingViewName: 'BSE:SBICARD'
    },

    'NSE:COLPAL': {
      zerodhaKiteName: 'NSE:COLPAL',
      tradingViewName: 'BSE:COLPAL'
    },

    'NSE:ADANIGREEN': {
      zerodhaKiteName: 'NSE:ADANIGREEN',
      tradingViewName: 'BSE:ADANIGREEN'
    },

    'NSE:IGL': {
      zerodhaKiteName: 'NSE:IGL',
      tradingViewName: 'BSE:IGL'
    },

    'NSE:ICICIPRULI': {
      zerodhaKiteName: 'NSE:ICICIPRULI',
      tradingViewName: 'BSE:ICICIPRULI'
    },

    'NSE:HINDPETRO': {
      zerodhaKiteName: 'NSE:HINDPETRO',
      tradingViewName: 'BSE:HINDPETRO'
    }
  },

  tradingViewTickerTapeSymbols: [
    {
      proName: 'FOREXCOM:SPXUSD',
      title: 'S&P 500'
    },
    {
      proName: 'FOREXCOM:NSXUSD',
      title: 'Nasdaq 100'
    },
    {
      proName: 'FX_IDC:EURUSD',
      title: 'EUR/USD'
    },
    {
      proName: 'BITSTAMP:BTCUSD',
      title: 'BTC/USD'
    },
    {
      proName: 'BITSTAMP:ETHUSD',
      title: 'ETH/USD'
    },
    {
      description: 'BSE sensex',
      proName: 'BSE:SENSEX'
    }
  ],
  timeFrames: ['5min', '10min', '15min', '30min'],
  patternHistoryCandleCount: 100,
  patterns: [
    'RED',
    'GREEN',
    'RED_CLOSING_MARUBOZU',
    'RED_MARUBOZU',
    'RED_OPENING_MARUBOZU',
    'CLOSING_MARUBOZU',
    'DOJI',
    'LONG_CANDLE',
    'LONG_RED_CANDLE',
    'LONG_GREEN_CANDLE',
    'MARUBOZU',
    'OPENING_MARUBOZU',
    'SHORT_CANDLE',
    'SHORT_RED_CANDLE',
    'SHORT_GREEN_CANDLE',
    'TRUE_DOJI_CANDLE',
    'GREEN_CLOSING_MARUBOZU',
    'GREEN_MARUBOZU',
    'GREEN_OPENING_MARUBOZU',
    'ABANDONED_BABY_BULL',
    'BET_HOLD_BULL',
    'BREAKAWAY_BULL',
    'CONCEALING_BABY_SWALLOW_BULL',
    'DOJI_STAR_BULL',
    'ENGULFING_BULL',
    'DRAGONFLY_DOJI_BULL',
    'HARAMI_BULL',
    'HARAMI_CROSS_BULL',
    'HOMING_PIGEON_BULL',
    'KICKING_BULL',
    'LADDER_BOTTOM_BULL',
    'MAT_HOLD_BULL',
    'MATCHING_LOW_BULL',
    'MEETING_LINES_BULL',
    'MORNING_DOJI_STAR_BULL',
    'MORNING_STAR_BULL',
    'PIERCING_LINE_BULL',
    'RISING_THREE_METHOD_BULL',
    'SEPARATING_LINES_BULL',
    'SIDE_BY_SIDE_WHITE_LINES_BULL',
    'STICK_SANDWICH_BULL',
    'THREE_INSIDE_UP_BULL',
    'THREE_LINE_STRIKE_BULL',
    'THREE_OUTSIDE_UP_BULL',
    'THREE_STARS_IN_THE_SOUTH_BULL',
    'THREE_WHITE_SOLDIERS_BULL',
    'TRI_STAR_BULL',
    'TWEEZER_BOTTOM_BULL',
    'UNIQUE_THREE_RIVER_BOTTOM_BULL',
    'UPSIDE_GAP_THREE_METHODS_BULL',
    'UPSIDE_TASUKI_GAP_BULL',
    'ABANDONED_BABY_BEAR',
    'ADVANCE_BLOCKS_BEAR',
    'BELT_HOLD_BEAR',
    'BREAKAWAY_BEAR',
    'DARK_CLOUD_COVER_BEAR',
    'DELIBERATION_BEAR',
    'DOWNSIDE_GAP_THREE_METHODS_BEAR',
    'DOWNSIDE_TASUKI_GAP_BEAR',
    'DOJI_STAR_BEAR',
    'DOJI_GRAVESTONE_BEAR',
    'DOJI_HANGING_MAN_BEAR',
    'ENGULFING_BEAR',
    'EVENING_DOJI_STAR_BEAR',
    'EVENING_STAR_BEAR',
    'FALLING_THREE_METHODS_BEAR',
    'DOJI_SHOOTING_STAR_BEAR',
    'HANGING_MAN_BEAR',
    'HARAMI_BEAR',
    'HARAMI_CROSS_BEAR',
    'IDENTICAL_THREE_CROWS_BEAR',
    'IN_NECK_BEAR',
    'KICKING_BEAR',
    'MEETING_LINES_BEAR',
    'ON_NECK_BEAR',
    'SEPARATING_LINES_BEAR',
    'SIDE_BY_SIDE_WHITE_LINES_BEAR',
    'THREE_BLACK_CROWS_BEAR',
    'THREE_INSIDE_DOWN_BEAR',
    'THREE_LINE_STRIKE_BEAR',
    'THREE_OUTSIDE_DOWN_BEAR',
    'THRUSTING_BEAR',
    'TRI_STAR_BEAR',
    'TWEEZER_TOP_BEAR',
    'TWO_CROWS_BEAR',
    'UPSIDE_GAP_TWO_CROWS_BEAR'
  ]
};
