import general from '../general';
import membershipTypes from '../membershipTypes';
let company = general.company_name;
export default {
  features: {
    topLabel: `Explore Digital-Marketing-${company}`,
    heading: 'Our features',
    featuresList: [
      {
        heading: '150+ Videos',
        text: `150+ HD quality explanation streaming videos that covers most of the 
            latest digital-marketing concepts with practical approach.`
      },
      {
        heading: 'Audio Learning Section',
        text: `Your learning doesn't stop if you are busy to watch the videos.
            You can plug in your earphones and listen to digital-marketing concepts`
      },
      {
        heading: 'Digital Library',
        text: `You get access to almost all the digital-marketing books and a few 
            custom made PDF files which are accessible digitally with life-time validity`
      },
      {
        heading: 'Research Projects',
        text: `If you are into research, this section is gonna be a bomb of value!
            We've a decent collection of the open source digital-marketing projects that you can explore `
      },
      {
        heading: `${membershipTypes.MEMBERSHIP_2.name} Certification`,
        text: `On completion of all the courses (videos) and the live classes, you'll get an 
        ${membershipTypes.MEMBERSHIP_2.name} certificate from ${company} which is of a high value for your placements`
      },
      {
        heading: 'Individual Course Certifications',
        text: `For every course you complete, you'll automatically get a certificate that'll be 
            visible in the "certificates" section!`
      }
    ]
  },
  overview: [
    {
      heading: '150+ HD Videos',
      subtitle: `Access to all the digital-marketing videos that include practical examples 
          and hands-on training. It includes all the latest digital-marketing tools too. 
          The videos are self paced where you can watch them on your free time.`,
      imageUrl: '/images/undraw_youtube_tutorial_2gn3.svg',
      imageRight: false,
      isButton: true,
      actionButtonText: 'View Courses',
      actionButtonUrl: '/all-courses'
    },
    {
      heading: 'Audio Learning',
      subtitle: `Audio learning section is a newly introduced section in ${company}.
          You can plug in your earphones and listen to the digital-marketing concepts while 
          you're travelling or busy with some other work where you can't watch the videos`,
      imageUrl: '/images/undraw_walk_in_the_city_1ma6.svg',
      imageRight: true,
      isButton: true,
      actionButtonText: 'View Audio Learning',
      actionButtonUrl: '/audio-learning'
    },
    {
      heading: `${membershipTypes.MEMBERSHIP_2.name} Certification`,
      subtitle: `On completion of all the course videos and attending the weekend live 
          class online training, you'll get an ${membershipTypes.MEMBERSHIP_2.name} completion certificate from us which is of 
          a very high value when it comes to your placements/job interviews. This certification would 
          add a value to your profile`,
      imageUrl: '/images/undraw_certification.svg',
      imageRight: false,
      isButton: false,
      actionButtonText: '',
      actionButtonUrl: '/'
    },
    {
      heading: 'High Valued Course Certifications',
      subtitle: `On completion of every course (in the 'All Courses' section), you get a 
          certificate for it certifying that you've successfully completed the course in that 
          particular topic. You don't have to wait/ask for the certificate as it's automatically 
          available for generation in the course view and after generating, it'll be available in the
          'My Certificates' section`,
      imageUrl: '/images/undraw_certificate_343v.svg',
      imageRight: true,
      isButton: false,
      actionButtonText: '',
      actionButtonUrl: '/'
    },
    {
      heading: 'Digital Library',
      subtitle: `Get access to every damn book that's there on digital-marketing here
          in a digital PDF format which can be accessed indefinitely with life-time access!`,
      imageUrl: '/images/undraw_book_lover_mkck.svg',
      imageRight: false,
      isButton: true,
      actionButtonText: 'View Digital Library',
      actionButtonUrl: '/digital-library'
    },
    {
      heading: 'Dark Theme',
      subtitle: `Dark theme is one of the most requested feature which is good for 
          late night working as it strains your eye less with lesser light colours!
          Our dark theme follows all proper dark theme material design principals.
          You can switch to dark/light theme by clicking the theme button on the top navigation-bar`,
      imageUrl: '/images/undraw_dark_mode_2xam.svg',
      imageRight: true,
      isButton: false,
      actionButtonText: '',
      actionButtonUrl: '/'
    },
    {
      heading: 'Public Profile',
      subtitle: `Your public profile on ${company} gets automatically generated as you 
          complete courses. You'll need to fill up your basic profile details so that it's 
          reflected in your public profile. The main advantage of your public profile is that 
          you can share your public-profile URL with the recruiters or in the job application while 
          applying for a job and this would definitely add up a lot of value as the recruiter can 
          directly view all of your QR-code-signed certificates and your basic details from your profile like
          your resume. This is of a great value as all the details the recruiter is viewing is on 
          ${company} which is authentic!`,
      imageUrl: '/images/undraw_profile_6l1l.svg',
      imageRight: false,
      isButton: false,
      actionButtonText: '',
      actionButtonUrl: '/'
    },
    {
      heading: '4.7+ Star rating',
      subtitle: `We've no hesitation to say that we're ranked the best when it comes
          to technology, quality of training and industrial exposure. With having 4.7+ 
          star rating, we stand
          in the top in terms of digital education`,
      imageUrl: '/images/undraw_my_password_d6kg.svg',
      imageRight: true,
      isButton: true,
      actionButtonText: 'View All Reviews',
      actionButtonUrl: '/reviews'
    }
  ],
  callToAction: {
    heading1: 'Ready to start learning?',
    heading2: `Buy ${membershipTypes.MEMBERSHIP_2.name}-course membership now!`,
    buttonName: `Buy ${membershipTypes.MEMBERSHIP_2.name} Membership`,
    colorType: 'default'
  },
  largeTestimonial: {
    quote: `${company} has been the gateway to my success, that helped me start my own 
        advertising agency`,
    personName: 'Alex Fred',
    personDetails: 'Founder & CEO of Greenative Agency'
  },
  testimonial: {
    heading: 'Satisfied Digital Marketers',
    testimonial1: {
      text: `This is the best platform to get you the best ${membershipTypes.MEMBERSHIP_2.name} experience. 
          I learnt the most of digital-marketing from this platform which is technology oriented 
          that makes learning a lot easier with access to a lot of digital-marketing content!`,
      personName: 'Lawrence',
      personDetails: 'Google advertiser at InfoTech'
    },
    testimonial2: {
      text: `There's a lot to learn from this platform. They literally have everything 
          you need to become an expert in digital-marketing. The best thing is that 
          they have dedicated sections to prepare you for the interviews to bag you a job offer`,
      personName: 'Arjun Kumar',
      personDetails: 'Social media marketer '
    },
    testimonial3: {
      text: `Their MCQ, Interview, videos, and the creative Audio learning sections are 
          amazing! There's a life-time validity which makes this the best platform. The experience
          I gained from this programme trained me to bag an international job offer too`,
      personName: 'Sunitha',
      personDetails: '3m - UK, media marketer'
    }
  },
  faqs: {
    heading: 'Frequently asked questions',
    subtitle: `${membershipTypes.MEMBERSHIP_2.name} Membership`,
    faqs: [
      {
        question: `How long is the ${membershipTypes.MEMBERSHIP_2.name} programme?`,
        answer: `The ${membershipTypes.MEMBERSHIP_2.name} programme is for about 3 months long.`
      },
      {
        question: 'Are there live classes too?',
        answer: `We do have live classes on a few weekend days which will be informed in advance.
            The courses in the "All Courses" section which are the course videos can be 
            watched anytime on your own pace which is the most important part of your course 
            as it includes practical approaches`
      },
      {
        question: `How many certifications do we get in this ${membershipTypes.MEMBERSHIP_2.name} programme?`,
        answer: `You get one ${membershipTypes.MEMBERSHIP_2.name} certificate and then multiple other course completion 
            certificates after you complete a course from the "All courses section".
            You get one certificate for every course you complete which you can see in the 
            "My Certificates" section.`
      },
      {
        question: 'When is the audio-learning useful?',
        answer: `The audio-learning is a newly introduced feature in our application 
            where users can learn by just listening to audio. Yes it's more like a podcast which 
            is designed to help you keep learning even with your super busy lifestyle!`
      },
      {
        question: 'What is the public profile about?',
        answer: `The public profile is your profile that is public. It'll have a link/URL 
            which you can copy from either "My Certificates" or "Public Profile" section in the 
            application. Make sure you share this URL while applying for jobs or with the 
            recruiter as this will boost your profile by a lot. When this URL is opened by your 
            recruiter, they'll see all of your certificates and your other profile details 
            that include your profile image, email, phone, address, bio and resume. (These 
              details have to be entered by you. However the certificates are automatically 
              fetched from your certificates :))`
      },
      {
        question: 'What books are there in the Digital-Library?',
        answer: `It includes most of the digital-marketing books. You don't have to 
            go searching for books online or anywhere else. These books are for additional 
            information for those who'd wanna dig deeper into digital-marketing!`
      },
      {
        question: `How can I start earning with ${company}?`,
        answer: `Contact us on our email and we'll add you to our sales team if you qualify the 
            sales interview rounds`
      }
    ]
  },
  verticalStepper: [
    {
      stepIndex: 0,
      heading: `Create an Account/Signup with us`,
      text: `Create an account here on ${company} by signing up. (Enter your details to signup,
        verify your email address by clicking on the link emailed, and then login)
      This gives you the access to buy an appropriate membership plan`
    },
    {
      stepIndex: 1,
      heading: `Buy a Membership Plan`,
      text: `We currently support 3 membership plans. You can view them on the pricing page.
      Since you're on the ${membershipTypes.MEMBERSHIP_2.name} membership page, you can select ${membershipTypes.MEMBERSHIP_2.name} membership plan.
      You can either pay on the particular membership details page or on the pricing page.
      All payments are handled securely by RazorPay payment gateway.`
    },
    {
      stepIndex: 2,
      heading: `Automatic Access to All the Courses`,
      text: `The moment payment is made (successful) you'll be redirected to the "All Courses" 
      page where you can access all the courses. The one-time payment gives you life-time 
      access to all the courses on this platform. From here you can access all the courses 
      and watch their videos`
    },
    {
      stepIndex: 3,
      heading: `Start Watching Videos`,
      text: `With access to all the courses, you start watching all the courses of all the videos.
      A video will be marked 'complete' only after you watch it completely from the beginning.
      After you complete every video in the course, you'll see a green tick mark against it 
      indicating that you've completed the video. You can watch the video any number of times.
      To mark a course as 'completed', you'll need to watch all the videos.`
    },
    {
      stepIndex: 4,
      heading: `Get Course Certificates`,
      text: `While you're completing the videos in the course, you should be able to see your 
      course completion percentage increasing. Once you've watched all the videos for any course,
      you'll see that the course completion percentage is 100% and you should also be able to see 
      a new button enabled in the course video view page named "CHECK FOR COURSE CERTIFICATE".
      This will appear only after you've completed watching all the videos in a course.
      On clicking this, you'll see a popup to generate a certificate for this course and you'll even 
      be able to set the name on the certificate before generating here.
      If a certificate had already been generated previously, you'll see that certificate here when
      you click on this button.`
    },
    {
      stepIndex: 5,
      heading: `Certificates Automatically Added to 'My Certificates' Section`,
      text: `When you receive a certificate, you should also be able to see it in the 
      "My Certificates" section. This section in the app shows all the certificates you've received
      from us. You can click on each certificate and view them. You can share the certificate URL
      directly with others (recruiter). For this part, we have a whole new section!`
    },
    {
      stepIndex: 6,
      heading: `Something About the Certificates`,
      text: `Our certificates have a great value when it comes to placement/hiring.
      So we have made sure that our certificates cannot be duplicated as we sign every certificate 
      digitally with a QR-code that is uniquely generated. A certificate signed with QR-code 
      is generated only for the members who complete a course. On scanning the QR and visiting the 
      URL verifies that the certificate is valid. This is also used by the recruiters to check if the 
      certificate is valid and original. It verifies that the certificate is valid as it opens the 
      certificate in our web-app stating it's valid when scanned`
    },
    {
      stepIndex: 7,
      heading: `Something About the Certificates`,
      text: `Our certificates have a great value when it comes to placement/hiring.
      So we have made sure that our certificates cannot be duplicated as we sign every certificate 
      digitally with a QR-code that is uniquely generated. A certificate signed with QR-code 
      is generated only for the members who complete a course. On scanning the QR and visiting the 
      URL verifies that the certificate is valid. This is also used by the recruiters to check if the 
      certificate is valid and original. It verifies that the certificate is valid as it opens the 
      certificate in our web-app stating it's valid when scanned.
      You get one certificate for completing every course. So if there are 10 courses, you'll get 
      10 certificates. We constantly keep adding more courses`
    },
    {
      stepIndex: 8,
      heading: `Generation of Public Profile`,
      text: `Public profile is a newly introduced section in our web-app which comes in very 
      handy and useful during your placement/job time. The public profile is an automatically 
      generated profile that should shared with your recruiters/employers. You should share the URL
      of your public profile with them either on email or your job application or personally so 
      that they have a look at this. Sharing this is more valuable than just sharing your resume.
      The public profile will have all of your details that are entered by you in your profile page
      (Bio, Address, Country, Phone Number) plus your general details like your email address, 
      first name, last name - which were entered by you while creating the account.
      The most important part of your public profile is that it shows all of your certificates 
      in a list view where the recruiters can view them there. This is a much better way to 
      represent your certificates than uploading them anywhere else and showing them. They can be
      100% sure of the credibility of the certificate when they automatically see it in your
      profile (on our web-app) when opened through your public profile URL
      `
    },
    {
      stepIndex: 9,
      heading: `Audio Learning`,
      text: `Audio Learning is a creative introduction on our web-app. This is an 
      added feature which makes us stand out from the others. Audio learning focuses on teaching 
      the concepts with just audio and not videos. This can be very useful when the user is 
      either busy or not in a situation to watch videos (while travelling to work).
      You'd have to just put on earphone and list to the audio. 
      We've made sure that it is easy to understand all the lessons in the audio-learning
      section and also made sure that a lot of valuable content is given out here`
    },
    {
      stepIndex: 10,
      heading: `Projects`,
      text: `Projects section will be useful if you want to have a look at all the projects done.
      It'll also have a few open source projects uploaded here which when studied, can be 
      mentioned in your resume to add up more value to your profile. 
      This will also have all the project files uploaded for reference that were used in 
      the videos for teaching. These can be of great value as you'll have a quick access to these.`
    },
    {
      stepIndex: 11,
      heading: `Digital Library`,
      text: `We're sure you might have had tough days before searching for books online 
      related to the topic you're working on and found it difficult to get them.
      Also, it's no doubt that there are a lof of books lying around and you won't know 
      if it's worth reading or not. We've filtered the best books and placed it digitally here`
    },
    {
      stepIndex: 12,
      heading: `Attend Live Classes`,
      text: `As this is an ${membershipTypes.MEMBERSHIP_2.name} training, we've live weekend classes to train you on 
      various topics. The live weekend classes (mainly only on Sundays) would be informed
      a lot in advance.`
    },
    {
      stepIndex: 13,
      heading: `${membershipTypes.MEMBERSHIP_2.name} Certificate`,
      text: `When you've bought an ${membershipTypes.MEMBERSHIP_2.name} membership, you'll have an option to generate
      another certificate for the completion of the ${membershipTypes.MEMBERSHIP_2.name}. You'll have an option to 
      generate an ${membershipTypes.MEMBERSHIP_2.name} completion certificate in the "My Certificates" section.
      This feature is available only if you've bought the ${membershipTypes.MEMBERSHIP_2.name} membership.`
    },
    {
      stepIndex: 14,
      heading: `Training Complete`,
      text: `You've completed your training here and you're all set to start looking for jobs!`
    }
  ]
};
