import axios from 'axios';

import {
  // new_token
  NEW_TOKEN_LOADING,
  NEW_TOKEN_SET,
  NEW_TOKEN_ERROR,
  USER_SET_LOADING,
  USER_SET,
  USER_SET_ERROR,
  USER_SIGNUP_LOADING,
  USER_SIGNUP_SET,
  USER_SIGNUP_ERROR,
  RESEND_VERIFICATION_LOADING,
  RESEND_VERIFICATION_SET,
  RESEND_VERIFICATION_ERROR,
  VERIFY_USER_EMAIL_SET,
  VERIFY_USER_EMAIL_LOADING,
  VERIFY_USER_EMAIL_ERROR,
  FORGOT_PASSWORD_LOADING,
  FORGOT_PASSWORD_SET,
  FORGOT_PASSWORD_ERROR,
  VERIFY_FORGOT_PASSWORD_LOADING,
  VERIFY_FORGOT_PASSWORD_SET,
  VERIFY_FORGOT_PASSWORD_ERROR,
  CHANGE_PASSWORD_FP_LOADING,
  CHANGE_PASSWORD_FP_SET,
  CHANGE_PASSWORD_FP_ERROR,
  USER_UNSET,
  USER_DETAILS_LOADING,
  USER_DETAILS_SET,
  USER_PROFILE_DETAILS_LOADING,
  USER_PROFILE_DETAILS_SET,
  USER_PROFILE_IMAGE_LOADING,
  USER_PROFILE_IMAGE_SET,
  HANDLE_PROFILE_DETAILS_EDIT_CHANGE,
  USER_COMPLETIONS_SET,
  COMPLETE_COURSE_OR_LESSON,
  // get_user_public_profile
  GET_USER_PUBLIC_PROFILE_LOADING,
  GET_USER_PUBLIC_PROFILE_SET,
  GET_USER_PUBLIC_PROFILE_ERROR
} from '../types';
import { django } from '../URLS';

import store from '../store';
import { setSnackbar } from '../actions/snackbarActions';
import { requestHandler } from '../requestHandler';

export const login = (userObj, successCallback) => async (dispatch) => {
  requestHandler({
    dispatch: dispatch,
    requestType: 'POST',
    requestUrl: `/auth/token/`,
    postData: userObj,
    loadingReducer: USER_SET_LOADING,
    // successReducer is null as it is being set using the callback function with a separate dispatch
    successReducer: null,
    errorReducer: USER_SET_ERROR,
    successCallback: successCallback,
    errorCallback: null
  });
};

export const signUp = (userObj, successCallback) => async (dispatch) => {
  requestHandler({
    dispatch: dispatch,
    requestType: 'POST',
    requestUrl: `/auth/register/`,
    postData: userObj,
    loadingReducer: USER_SIGNUP_LOADING,
    successReducer: USER_SIGNUP_SET,
    errorReducer: USER_SIGNUP_ERROR,
    successCallback: successCallback,
    errorCallback: null
  });
};

export const resendVerification = (emailObj, successCallback) => async (
  dispatch
) => {
  requestHandler({
    dispatch: dispatch,
    requestType: 'POST',
    requestUrl: `/auth/resend_email_verification/`,
    postData: emailObj,
    loadingReducer: RESEND_VERIFICATION_LOADING,
    successReducer: RESEND_VERIFICATION_SET,
    errorReducer: RESEND_VERIFICATION_ERROR,
    successCallback: successCallback,
    errorCallback: null
  });
};

export const verifyUserEmail = (token, successCallback) => async (dispatch) => {
  requestHandler({
    dispatch: dispatch,
    requestType: 'GET',
    requestUrl: `/auth/verifyEmail/${token}/`,
    postData: null,
    loadingReducer: VERIFY_USER_EMAIL_LOADING,
    successReducer: VERIFY_USER_EMAIL_SET,
    errorReducer: VERIFY_USER_EMAIL_ERROR,
    successCallback: successCallback,
    errorCallback: null
  });
};

export const forgotPasswordRequest = (emailObj, successCallback) => async (
  dispatch
) => {
  requestHandler({
    dispatch: dispatch,
    requestType: 'POST',
    requestUrl: `/auth/send_forgot_password_email/`,
    postData: emailObj,
    loadingReducer: FORGOT_PASSWORD_LOADING,
    successReducer: FORGOT_PASSWORD_SET,
    errorReducer: FORGOT_PASSWORD_ERROR,
    successCallback: successCallback,
    errorCallback: null
  });
};

export const verifyForgotPassword = (token, successCallback) => async (
  dispatch
) => {
  requestHandler({
    dispatch: dispatch,
    requestType: 'POST',
    requestUrl: `/auth/verifyForgotPasswordToken/${token}/`,
    postData: null,
    loadingReducer: VERIFY_FORGOT_PASSWORD_LOADING,
    successReducer: VERIFY_FORGOT_PASSWORD_SET,
    errorReducer: VERIFY_FORGOT_PASSWORD_ERROR,
    successCallback: successCallback,
    errorCallback: null
  });
};

export const changePasswordForgotPasswordFlow = (
  passwordObj,
  token,
  successCallback
) => async (dispatch) => {
  requestHandler({
    dispatch: dispatch,
    requestType: 'POST',
    requestUrl: `/auth/changePassword/${token}/`,
    postData: passwordObj,
    loadingReducer: CHANGE_PASSWORD_FP_LOADING,
    successReducer: CHANGE_PASSWORD_FP_SET,
    errorReducer: CHANGE_PASSWORD_FP_ERROR,
    successCallback: successCallback,
    errorCallback: null
  });
};

export const getUserDetails = () => async (dispatch) => {
  try {
    dispatch({ type: USER_DETAILS_LOADING });
    let res = await axios.get(`${django}/auth/myDetails/`);

    dispatch({
      type: USER_DETAILS_SET,
      payload: res.data
    });
  } catch (err) {
    console.log(`ERROR_HAPPENED in getUserProfileDetails ACTION: ${err}`);
  }
};

export const updateProfileImage = (data) => async (dispatch) => {
  try {
    dispatch({ type: USER_PROFILE_IMAGE_LOADING });
    let res = await axios.patch(`${django}/auth/updateProfile/`, data);
    dispatch({
      type: USER_PROFILE_IMAGE_SET,
      payload: res.data
    });
    store.dispatch(setSnackbar('Updated profile picture', 'success'));
  } catch (err) {
    store.dispatch(
      setSnackbar(`Error while updating profile picture ${err}`, 'error')
    );
    console.log(`ERROR_HAPPENED in updateProfileImage ACTION: ${err}`);
  }
};

export const updateProfileDetails = (data) => async (dispatch) => {
  try {
    dispatch({ type: USER_PROFILE_DETAILS_LOADING });
    let res = await axios.patch(`${django}/auth/updateProfile/`, data);
    dispatch({
      type: USER_PROFILE_DETAILS_SET,
      payload: res.data
    });
    store.dispatch(setSnackbar('Updated profile details', 'success'));
  } catch (err) {
    store.dispatch(
      setSnackbar(`Error while updating profile details ${err}`, 'error')
    );
    console.log(`ERROR_HAPPENED in updateProfileDetails ACTION: ${err}`);
  }
};

export const getUserCompletions = () => async (dispatch) => {
  try {
    let res = await axios.get(`${django}/auth/user_completions/`);

    dispatch({
      type: USER_COMPLETIONS_SET,
      payload: res.data
    });
  } catch (err) {
    console.error(`ERROR_HAPPENED in getUserCompletions ACTION: ${err}`);
  }
};

export const completeCourseOrLesson = (courseLessonObject) => async (
  dispatch
) => {
  try {
    console.log('course action called');
    let res = await axios.post(
      `${django}/auth/user_completions/`,
      courseLessonObject
    );

    // throw new Error('testing error snackbar');
    dispatch({
      type: COMPLETE_COURSE_OR_LESSON,
      payload: courseLessonObject
    });
  } catch (err) {
    // store.dispatch(
    //   setSnackbar(`Error while fetching the course modules ${err}`, 'error')
    // );
    console.log(`ERROR_HAPPENED in CompleteCourseOrLesson ACTION: ${err}`);
  }
};

export const handleProfileDetailsEditChange = (obj) => async (dispatch) => {
  dispatch({
    type: HANDLE_PROFILE_DETAILS_EDIT_CHANGE,
    payload: { ...obj }
  });
};

export const userSet = (user) => async (dispatch) => {
  dispatch({
    type: USER_SET,
    payload: { user }
  });
};

export const userUnset = () => async (dispatch) => {
  dispatch({
    type: USER_UNSET
  });
};

// setNewToken
export const setNewToken = () => async (dispatch) => {
  requestHandler({
    dispatch: dispatch,
    requestType: 'GET',
    requestUrl: `/auth/get_new_token/`,
    postData: null,
    loadingReducer: NEW_TOKEN_LOADING,
    successReducer: NEW_TOKEN_SET,
    errorReducer: NEW_TOKEN_ERROR,
    successCallback: null,
    errorCallback: null
  });
};

// GetUserPublicProfile
export const getUserPublicProfile = (username, successCallback) => async (
  dispatch
) => {
  requestHandler({
    dispatch: dispatch,
    requestType: 'GET',
    requestUrl: `/auth/get_user_public_profile/${username}/`,
    postData: null,
    loadingReducer: GET_USER_PUBLIC_PROFILE_LOADING,
    successReducer: GET_USER_PUBLIC_PROFILE_SET,
    errorReducer: GET_USER_PUBLIC_PROFILE_ERROR,
    successCallback: successCallback,
    errorCallback: null
  });
};
