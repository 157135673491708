import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
const icon = <CheckCircleIcon />;
export default {
  heading: 'Buy Membership',
  subHeading: 'Get Certifications & Tools',
  doubleSideList: [
    {
      left: 'Video Courses',
      right: icon
    },
    {
      left: 'Certifications',
      right: icon
    },
    {
      left: 'Pattern Alerts',
      right: icon
    },
    {
      left: 'Scalp Platform',
      right: icon
    },
    {
      left: 'Virtual Trading',
      right: icon
    },
    {
      left: 'Pattern History',
      right: icon
    },
    {
      left: 'Audio Learning',
      right: icon
    },
    {
      left: 'Digital Library',
      right: icon
    },
    {
      left: 'Stock News',
      right: icon
    },
    {
      left: 'MCQs',
      right: icon
    },
    {
      left: 'Interview Prep',
      right: icon
    },
    {
      left: 'Job Portal',
      right: icon
    },
    {
      left: 'Public Profile',
      right: icon
    }
  ],
  bottomRightButtonLink: '/buy-membership',
  bottomRightButtonLinkName: 'Buy',
  // introductory video of the whole application
  bottomLeftVideoSrc:
    'https://all-resources-dj.s3.ap-south-1.amazonaws.com/webinar-videos/digital-marketing/Graduator-Digital-Marketing-Webinar.mp4',
  bottomLeftVideoHeading: 'Introduction'
};
