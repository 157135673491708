import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Link } from 'react-router-dom';
import { CourseCard } from '../../components';

import { getUserCompletions } from 'redux/actions/userActions';

const useStyles = makeStyles((theme) => ({
  root: {}
}));

const MyCourses = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserCompletions());
  }, []);

  const user = useSelector((state) => state.user);
  const userCompletions = useSelector((state) => state.user.user_completions);

  const getMyCourses = (courseId) => {
    const isCourseCompleted = (courseId) =>
      userCompletions.courses.length
        ? userCompletions.courses.includes(courseId)
        : false;

    const f_getCourseActionButton = (courseId) => (
      <>
        <Button
          size="small"
          color="primary"
          variant="contained"
          component={Link}
          to={`/course-view/${courseId}`}>
          Open and watch
        </Button>
        {isCourseCompleted(courseId) ? (
          <>
            <CheckCircleIcon />
            <p>complete</p>{' '}
          </>
        ) : (
          <p>(you did not complete this)</p>
        )}
      </>
    );

    return (
      <>
        {user.user_details_loading ? (
          <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
            <LinearProgress />
          </Grid>
        ) : (
          user.courses.map((course) => (
            <Grid item lg={4} md={6} sm={6} xl={4} xs={12}>
              <CourseCard
                name={course.name}
                description={course.description}
                thumbnailImage={course.thumbnail_image}
                courseActionButton={f_getCourseActionButton(course.id)}
              />
            </Grid>
          ))
        )}
      </>
    );
  };

  let myCourses = getMyCourses();

  return (
    <div className={classes.root}>
      <Grid container spacing={4} justify="space-between" alignItems="stretch">
        {myCourses}
      </Grid>
    </div>
  );
};

export default MyCourses;
