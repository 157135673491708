import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

// Reducer imports
// import dataReducer from './reducers/dataReducer';
import userReducer from './reducers/userReducer';
import snackbarReducer from './reducers/snackbarReducer';
import courseReducer from './reducers/courseReducer';
import certificateReducer from './reducers/certificateReducer';
import displayReducer from './reducers/displayReducer';
import membershipReducer from './reducers/membershipReducer';
import resourceReducer from './reducers/resourceReducer';
import questionBankReducer from './reducers/questionBankReducer';
import jobReducer from './reducers/jobReducer';
import dataReducer from './reducers/dataReducer';
import stockReducer from './reducers/stockReducer';
import stockAlertReducer from './reducers/stockAlertReducer';
import scalperReducer from './reducers/scalperReducer';

import config from 'config/main';
const useDevTools = config.general.enableReduxDevTools;

const initialState = {};

const middleware = [thunk];

const reducers = combineReducers({
  // data: dataReducer,
  user: userReducer,
  snackBar: snackbarReducer,
  course: courseReducer,
  certificate: certificateReducer,
  display: displayReducer,
  membership: membershipReducer,
  resource: resourceReducer,
  questionBank: questionBankReducer,
  job: jobReducer,
  data: dataReducer,
  stock: stockReducer,
  stockAlert: stockAlertReducer,
  scalper: scalperReducer
});

let composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

if (!useDevTools) {
  composeEnhancers = compose;
}

const enhancer = composeEnhancers(applyMiddleware(...middleware));
const store = createStore(reducers, initialState, enhancer);

export default store;
