import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Typography from '@material-ui/core/Typography';

// audio player
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    borderRadius: theme.spacing(1)
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto'
  },
  cover: {
    width: 151
  },
  controls: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    // paddingLeft: theme.spacing(1),
    // paddingBottom: theme.spacing(1),
    padding: theme.spacing(1)
  },
  playIcon: {
    height: 28,
    width: 28
  },
  player: {
    background: theme.palette.background.dark,
    borderRadius: theme.spacing(1)
  }
}));

function AudioCard(props) {
  const classes = useStyles();

  const getAudioName = (key_name) => {
    let file = key_name.split('.');
    let file_name = file[0];
    return file_name;
  };

  return (
    <Card className={classes.root}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="h5" variant="h5">
            {getAudioName(props.key_name)}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {props.subtitle}
          </Typography>
        </CardContent>
        <div className={classes.controls}>
          <AudioPlayer className={classes.player} src={props.url} />
        </div>
      </div>
    </Card>
  );
}

AudioCard.propTypes = {
  // className: PropTypes.string,
  key_name: PropTypes.any,
  url: PropTypes.any,
  subtitle: PropTypes.any
};

export default AudioCard;
