import React from 'react';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import DialogTitle from '@material-ui/core/DialogTitle';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import PropTypes from 'prop-types';
import { MiniVideoPlayer } from '../../components';

const useStyles = makeStyles((theme) => ({
  socialIcon: {
    marginRight: theme.spacing(1)
  }
}));

export default function VideoPlayerPopup(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        size="small"
        variant="contained"
        color="secondary">
        <PlayCircleFilledIcon /> &nbsp;
        {props.buttonName}
      </Button>

      <Dialog
        maxWidth="md"
        fullWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{props.heading}</DialogTitle>
        <MiniVideoPlayer src={props.src} />
      </Dialog>
    </div>
  );
}

VideoPlayerPopup.defaultProps = {
  buttonName: 'Watch Video'
};

VideoPlayerPopup.propTypes = {
  src: PropTypes.any,
  heading: PropTypes.any,
  buttonName: PropTypes.any
};
