import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import clsx from 'clsx';

// Django URL
// import theme from 'theme';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 350,
    transition: 'transform 0.2s;',
    '&:hover': {
      '-ms-transform': 'scale(1.08);' /* IE 9 */,
      '-webkit-transform': 'scale(1.08);' /* Safari 3-8 */,
      '  transform': 'scale(1.08);'
      // background: theme.palette.primary.light
    }
  },
  media: {
    minHeight: 160
    // minWidth: 300
  }
}));

export default function CourseCard(props) {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, ' ')}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={`${props.thumbnailImage}`}
          title="course card "
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {props.name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {props.description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        {props.courseActionButton}
        {props.courseActionButton2}
      </CardActions>
    </Card>
  );
}
