import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Zoom from 'react-reveal/Zoom';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { useSelector } from 'react-redux';
import Can from 'rbac/Can';
import config from 'config/main';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1)
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  buttonPosition: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
    zIndex: 5
  }
}));

export default function FloatingBuyButton() {
  const [showButton, setShowButton] = React.useState(true);
  const location = useLocation();
  let USER = useSelector((state) => state.user.user);
  const classes = useStyles();

  useEffect(() => {
    if (location.pathname === config.pageData.BuyMembership.route.path) {
      setShowButton(false);
    } else {
      setShowButton(true);
    }
  }, [location.pathname]);

  return (
    <Can
      role={USER && USER.user_type}
      perform="paid_content:view"
      no={() => (
        <>
          {showButton && (
            <Zoom top>
              <div className={classes.buttonPosition}>
                <Fab
                  href={config.pageData.BuyMembership.route.path}
                  variant="extended"
                  size="medium"
                  color="primary"
                  aria-label="add"
                  className={classes.margin}>
                  <ShoppingCartIcon className={classes.extendedIcon} />
                  {config.general.buyFloatingButtonName}
                </Fab>
              </div>
            </Zoom>
          )}
        </>
      )}
    />
  );
}
