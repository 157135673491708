// React-Redux
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
// MUI
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import IconButton from '@material-ui/core/IconButton';

import Brightness4Icon from '@material-ui/icons/Brightness4';

// actions
import { setTheme } from '../../../redux/actions/displayActions';

import { setSnackbar } from '../../../redux/actions/snackbarActions';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '20px',
    background: theme.palette.error.light,
    color: theme.palette.error.contrastText
  },
  icon: {
    color: theme.palette.primary.contrastText
  }
}));

export default function ThemeChanger() {
  //   const [name, setName] = React.useState(null);
  //   useEffect(() => {}, []);
  let currentTheme = useSelector((state) => state.display.theme);
  let dispatch = useDispatch();

  const classes = useStyles();

  const handleThemeChange = () => {
    if (currentTheme === 'lightTheme') {
      console.log(`changing to dark theme`);
      dispatch(setTheme('darkTheme'));
      localStorage.setItem('theme', 'darkTheme');
    } else {
      console.log(`changing to light theme`);
      dispatch(setTheme('lightTheme'));
      localStorage.setItem('theme', 'lightTheme');
    }
  };

  return (
    <Tooltip
      title={currentTheme === 'lightTheme' ? 'Dark Theme' : 'Light Theme'}>
      <IconButton
        aria-label="Theme change"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleThemeChange}
        color="inherit">
        <Brightness4Icon className={classes.icon} />
      </IconButton>
    </Tooltip>
  );
}
