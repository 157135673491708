const memberships = {
  MEMBERSHIP_1: {
    static: ['membership_1_resources:view']
  },
  MEMBERSHIP_2: {
    static: ['membership_1_resources:view', 'membership_2_resources:view']
  },
  MEMBERSHIP_3: {
    static: [
      'membership_1_resources:view',
      'membership_2_resources:view',
      'membership_3_resources:view'
    ]
  }
};

export default memberships;

/*
Using can component template:


<Can
  role={USER.user_type}
  perform="RESOURCE:ACTION"
  yes={() => <>YES_COMPONENT</>}
  no={() => <>NO_COMPONENT</>}
/>

*/
