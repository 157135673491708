import { createReducer } from '@reduxjs/toolkit';

var initialObject = {
  loading: false,
  value: null,
  error: null,
  is_fetched: false
};

const initialState = {
  // scalp_patterns

  scalp_patterns: { ...initialObject },
  // scalp_data

  scalp_data: { ...initialObject },
  // add_scalp_trade_stat

  add_scalp_trade_stat: { ...initialObject }
};

export default createReducer(initialState, {
  // get -- scalp_patterns
  SCALP_PATTERNS_LOADING: (state, action) => {
    state.scalp_patterns['loading'] = action.payload;
  },
  SCALP_PATTERNS_SET: (state, action) => {
    state.scalp_patterns['value'] = action.payload;
    state.scalp_patterns['is_fetched'] = true;
  },
  SCALP_PATTERNS_ERROR: (state, action) => {
    state.scalp_patterns['error'] = action.payload;
  },
  // get -- scalp_data
  SCALP_DATA_LOADING: (state, action) => {
    state.scalp_data['loading'] = action.payload;
  },
  SCALP_DATA_SET: (state, action) => {
    state.scalp_data['value'] = action.payload;
    state.scalp_data['is_fetched'] = true;
  },
  SCALP_DATA_ERROR: (state, action) => {
    state.scalp_data['error'] = action.payload;
  },
  // get -- add_scalp_trade_stat
  ADD_SCALP_TRADE_STAT_LOADING: (state, action) => {
    state.add_scalp_trade_stat['loading'] = action.payload;
  },
  ADD_SCALP_TRADE_STAT_SET: (state, action) => {
    state.add_scalp_trade_stat['value'] = action.payload;
    state.add_scalp_trade_stat['is_fetched'] = true;
  },
  ADD_SCALP_TRADE_STAT_ERROR: (state, action) => {
    state.add_scalp_trade_stat['error'] = action.payload;
  }
});
