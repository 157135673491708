// React-Redux
import React from 'react';

import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
// MUI
import { makeStyles } from '@material-ui/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.background.dark
    },
    borderRadius: '10px'
  },
  listItem: {
    marginTop: theme.spacing(2),
    color: theme.palette.text.primary
  },
  listText: {
    overflowWrap: 'break-word'
  }
}));

function ListElement(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ListItem
        component="a"
        href={props.link}
        target="_blank"
        // download
        className={classes.listItem}>
        <ListItemAvatar>
          <Avatar>{props.listAvatar}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={props.mainText}
          secondary={props.secondaryText}
          className={classes.listText}
        />
      </ListItem>
    </div>
  );
}

ListElement.propTypes = {
  mainText: PropTypes.any,
  secondaryText: PropTypes.any,
  link: PropTypes.any,
  listAvatar: PropTypes.any
};

export default ListElement;
