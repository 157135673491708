// React-Redux
import React from 'react';

import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

// import clsx from 'clsx';
import {
  Typography,
  Paper,
  Divider,
  Chip,
  Grow,
  Button
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',

    borderRadius: '0.65rem',

    background: theme.palette.customColors.dark,
    // background: theme.palette.customColors.blueGradient,

    color: theme.palette.primary.contrastText
  },
  top: {
    padding: '3rem',
    textAlign: 'center'
  },
  header: {
    fontWeight: '700',
    fontSize: '2.1rem',
    lineHeight: '1.2'
  },
  subHeader: {
    color: theme.palette.primary.contrastDimText,
    paddingLeft: '1rem',
    fontSize: '1.265rem'
  },
  divider: {
    background: theme.palette.text.secondary
  },
  mid: {
    // paddingTop: '3rem',
    padding: '0 3rem 3rem 3rem'
  },
  midListElement: {
    display: 'flex',
    paddingBottom: '1rem',
    paddingTop: '1rem',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  midLeftEle: {
    fontWeight: '700'
  },
  bottom: {
    padding: '1.5rem',
    background: '#ffffff1a',
    // background: theme.palette.success.main,
    display: 'flex',
    // paddingBottom: '1rem',
    // paddingTop: '1rem',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomLeftRadius: '0.65rem',
    borderBottomRightRadius: '0.65rem'
  },
  leftBtm: {
    fontWeight: '700',
    lineHeight: '1.2',
    fontSize: '2.5rem'
  },
  chip: {
    color: theme.palette.primary.contrastText,
    background: theme.palette.success.main,
    fontWeight: '700',
    textTransform: 'uppercase',
    fontSize: '0.875rem'
  }
}));

export default function BlueGradientCard(props) {
  //   const [name, setName] = React.useState(null);
  //   useEffect(() => {}, []);
  //   let certName = useSelector((state) => state.user.profile.name_on_certificate);
  //   let dispatch = useDispatch();

  const classes = useStyles();

  const getBottomActionButton = (link, linkName, component) => {
    if (component) {
      return component;
    } else if (link) {
      return (
        <Button variant="contained" className={classes.button} href={link}>
          {linkName}
        </Button>
      );
    } else {
      return null;
    }
  };

  return (
    <Grow in={true} timeout={1000}>
      <Paper className={classes.root} elevation={3}>
        <div className={classes.top}>
          <Typography className={classes.header}>{props.heading}</Typography>

          <Typography className={classes.subHeader}>
            {props.subHeading}
          </Typography>
        </div>
        {props.doubleSideList && (
          <div className={classes.mid}>
            {props.doubleSideList.map((list) => (
              <>
                <div className={classes.midListElement}>
                  <Typography className={classes.midLeftEle}>
                    {list.left}
                  </Typography>

                  <Chip
                    size="medium"
                    label={list.right}
                    className={classes.chip}
                  />
                </div>
                <Divider className={classes.divider} />
              </>
            ))}
          </div>
        )}

        <div className={classes.bottom}>
          <Typography className={classes.leftBtm}>
            {props.bottomLeft}
          </Typography>
          {getBottomActionButton(
            props.bottomRightButtonLink,
            props.bottomRightButtonLinkName,
            props.bottomRightCustomComponent
          )}
        </div>
      </Paper>
    </Grow>
  );
}
BlueGradientCard.propTypes = {
  heading: PropTypes.any,
  subHeading: PropTypes.any,
  doubleSideList: PropTypes.any,
  bottomLeft: PropTypes.any,
  // it should either have bottomRightButtonLink & bottomRightButtonLinkName or bottomRightCustomComponent
  bottomRightButtonLink: PropTypes.any,
  bottomRightButtonLinkName: PropTypes.any,
  bottomRightCustomComponent: PropTypes.any
};
