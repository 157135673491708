export default {
  coreInterview: {
    notesBlock: {
      heading: `Why do you believe that online marketing will be more 
        beneficial to our company compared to offline marketing?`,
      textData: `This is a common question that is asked to beginners who are keen to enter the world of Internet Marketing. Since the most common statement they make in every interview is that “I am interested in Marketing”, the interview definitely wants to understand then why online marketing. The answer to this one can be subjective and you can have your own reasons and preferences, here is our advice.`,
      listDataTitle: 'Points:',
      listData: [
        'Talk about the constant technological changes & developments happening.',
        'How online marketing platforms have eased the path of reaching out to a larger audience.',
        'There is a higher capacity to reach out to a larger audience, the relevant audience at a reasonable cost. ',
        'A better scope to analyze the performance; online marketing gives a quick analysis of the tasks done, amount spent and the ROI. This helps you grow and understand business and customers faster and better.',
        'Digital Marketing is now used by a small and medium-sized business as well, so there are ample job opportunities.'
      ],
      codeTitle: null,
      codeLanguage: null,
      code: null
    }
  },
  notesAndDefinitions: {
    notesBlock: {
      heading: 'What Is Digital Marketing?',
      textData: `Digital marketing is an all-encompassing term that consists of digital channels, 
      such as content marketing, SEO, email marketing, social media marketing, mobile marketing 
      and so on, to create elaborate strategies to reach and connect with prospects and customers.`,
      listDataTitle: 'Types of Digital Marketing Channels:',
      listData: [
        'Website',
        'Content Marketing',
        'Search Engine Optimization (SEO)',
        'Digital Advertising',
        'Email Marketing',
        'Social Media Marketing',
        'Affiliate Marketing',
        'Mobile Marketing',
        'Online PR',
        'Conversational AI',
        'Web Analytics'
      ],
      codeTitle: null,
      codeLanguage: null,
      code: null
    }
  },
  generalInterview: {
    notesBlock: {
      heading: 'Tell me about yourself',
      textData: `Even though this looks like a very simple question, it isn't. Generally this is the 
      first question that'll be asked to you during the interview. The interviewer's impression 
      would largely depend on how you answer this question. If you don't use catchy terms while you 
      answer this question, it's going to become difficult for the interviewer to remember you while 
      screening candidates to the next round. The main goal of this question is to check if the 
      candidate will be outstanding with the job that'll be given. You need to talk about why 
      you took your previous jobs..be sure in everything that you answer. Make sure you're 
      confident while answering this..It's okay even if you're not that confident in the other 
      questions asked, but for this one, be confident.. + more`,
      listDataTitle: null,
      listData: null,
      codeTitle: null,
      codeLanguage: null,
      code: null
    }
  },
  interactiveMcqs: {
    mcqElement: {
      question: `Which of the following is not a type of digital marketing activity?`,
      option1: ` e-marketing`,
      option2: `Social marketing`,
      option3: ` Print advert`,
      option4: `Internet marketing`,
      rightOption: `option3`,
      explanation: `Types of digital marketing activities include: Digital marketing, 
      Direct marketing, Interactive marketing, e-marketing, Internet marketing, Mobile marketing,
       Social Marketing, Social Media Marketing.`
    }
  }
};
