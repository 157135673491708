import {
  // book_call
  BOOK_CALL_LOADING,
  BOOK_CALL_SET,
  BOOK_CALL_ERROR
} from '../types';

import { createReducer } from '@reduxjs/toolkit';

var initialObject = {
  loading: false,
  value: null,
  error: null,
  is_fetched: false
};

const initialState = {
  // book_call

  book_call: { ...initialObject }
};

export default createReducer(initialState, {
  //  -- book_call
  BOOK_CALL_LOADING: (state, action) => {
    state.book_call['loading'] = action.payload;
  },
  BOOK_CALL_SET: (state, action) => {
    state.book_call['value'] = action.payload;
    state.book_call['is_fetched'] = true;
  },
  BOOK_CALL_ERROR: (state, action) => {
    state.book_call['error'] = action.payload;
  }
});
