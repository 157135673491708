import razorPayConfig from './razorPay';
import userTypesConfig from './userTypes';
import popupVideos from './popupVideos';
import pageData from './pageData';
import buyMembershipCard from './buyMembershipCard';
import samples from './samples';
import general from './general';
import membershipTypes from './membershipTypes';
import reviewsData from './reviewsData';
import pricing from './pricing';
import stock from './stock';

export default {
  razorPay_config: razorPayConfig,
  userTypes: userTypesConfig,
  popupVideos,
  pageData,
  buyMembershipCard,
  samples,
  general,
  membershipTypes,
  reviewsData,
  pricing,
  stock
};
