export default {
  audioLearning: {
    heading: 'Using Audio Learning',
    src: 'https://media.w3.org/2010/05/sintel/trailer_hd.mp4'
  },
  projects: {
    heading: 'Using Projects',
    src: 'https://media.w3.org/2010/05/sintel/trailer_hd.mp4'
  },
  digitalLibrary: {
    heading: 'Using Digital Library',
    src: 'https://media.w3.org/2010/05/sintel/trailer_hd.mp4'
  }
};
