import React from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import VpnKeyIcon from '@material-ui/icons/VpnKey';

import { forgotPasswordRequest as a_forgotPasswordRequest } from '../../redux/actions/userActions';
import { Box, Typography, LinearProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  socialIcon: {
    marginRight: theme.spacing(1)
  }
}));

export default function ForgotPasswordEmailPopup(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = useState('');

  //   useEffect(() => {}, []);
  let s_forgotPasswordLoading = useSelector(
    (state) => state.user.user_auth.forgot_password_loading
  );
  let s_forgotPassword = useSelector(
    (state) => state.user.user_auth.forgot_password
  );
  let s_forgotPasswordError = useSelector(
    (state) => state.user.user_auth.forgot_password_error
  );
  let dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEmailChange = (event) => {
    event.persist();
    setEmail(event.target.value);
  };
  const getNextUrl = () => {
    let nextUrl = '/all-courses';
    // const parsed = queryString.parse(props.location.search);
    // if (parsed && parsed.next) {
    //   nextUrl = parsed.next;
    // }

    return nextUrl;
  };

  const handleForgotPasswordRequestEmail = async (event) => {
    event.preventDefault();

    dispatch(
      a_forgotPasswordRequest({
        email: email,
        next_url: getNextUrl()
      })
    );
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        size="small"
        variant="contained"
        color="primary">
        <VpnKeyIcon className={classes.socialIcon} />
        Forgot Password?
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          Request for a password reset link
        </DialogTitle>
        <form
          className={classes.form}
          onSubmit={handleForgotPasswordRequestEmail}>
          <DialogContent>
            <DialogContentText>
              Please enter a valid email that is registered with your account so
              that we send you a link to reset your password
            </DialogContentText>

            <TextField
              className={classes.textField}
              error={s_forgotPasswordError && s_forgotPasswordError.email}
              fullWidth
              helperText={
                s_forgotPasswordError && s_forgotPasswordError.email
                  ? s_forgotPasswordError.email
                  : null
              }
              label="Email ID"
              name="email"
              // required={true}
              onChange={handleEmailChange}
              type="text"
              value={email || ''}
              variant="standard"
            />
            <Box margin="10px 0">
              <Typography variant="subtitle2">
                {s_forgotPassword ? (
                  <>Successfully sent the password reset email </>
                ) : null}
              </Typography>
              {s_forgotPasswordLoading && <LinearProgress />}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
            <Button color="primary" type="submit">
              {s_forgotPasswordLoading ? (
                <>Sending email..........</>
              ) : (
                <>Send Verification Email</>
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
