import { createReducer } from '@reduxjs/toolkit';

import { THEME_SET } from '../types';

const getThemeFromLocalStorage = () => {
  let theme = localStorage.getItem('theme');
  if (!theme) {
    return 'lightTheme';
  } else {
    return theme;
  }
};

const initialState = {
  theme: getThemeFromLocalStorage()
  // theme: 'darkTheme'
};

export default createReducer(initialState, {
  THEME_SET: (state, action) => {
    state['theme'] = action.payload;
  }
});
