import React from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReplayIcon from '@material-ui/icons/Replay';

import { resendVerification as a_resendVerification } from '../../redux/actions/userActions';
import { Box, Typography, LinearProgress, Slide } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  socialIcon: {
    marginRight: theme.spacing(1)
  }
}));

export default function ResendVerificationEmailPopup() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = useState('');

  //   useEffect(() => {}, []);
  let s_resendVerificationLoading = useSelector(
    (state) => state.user.user_auth.resend_verification_loading
  );
  let s_resendVerification = useSelector(
    (state) => state.user.user_auth.resend_verification
  );
  let s_resendVerificationError = useSelector(
    (state) => state.user.user_auth.resend_verification_error
  );
  let dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEmailChange = (event) => {
    event.persist();
    setEmail(event.target.value);
  };

  const handleResendVerificationEmail = async (event) => {
    event.preventDefault();
    dispatch(
      a_resendVerification({
        email: email
      })
    );
  };

  return (
    <Slide in={true} direction="top" timeout={1000}>
      <div>
        <Button
          onClick={handleClickOpen}
          size="large"
          variant="contained"
          color="primary">
          <ReplayIcon className={classes.socialIcon} />
          Resend verification email
        </Button>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            Resend Email Verification
          </DialogTitle>
          <form
            className={classes.form}
            onSubmit={handleResendVerificationEmail}>
            <DialogContent>
              <DialogContentText>
                Please enter a valid email that is registered with your account
                here so that we send you a verification email
              </DialogContentText>

              <TextField
                className={classes.textField}
                error={
                  s_resendVerificationError && s_resendVerificationError.email
                }
                fullWidth
                helperText={
                  s_resendVerificationError && s_resendVerificationError.email
                    ? s_resendVerificationError.email
                    : null
                }
                label="Email ID"
                name="email"
                // required={true}
                onChange={handleEmailChange}
                type="text"
                value={email || ''}
                variant="standard"
              />
              <Box margin="10px 0">
                <Typography variant="subtitle2">
                  {s_resendVerification ? (
                    <>Successfully sent the email </>
                  ) : null}
                </Typography>
                {s_resendVerificationLoading && <LinearProgress />}
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
              <Button color="primary" type="submit">
                {s_resendVerificationLoading ? (
                  <>Sending email...</>
                ) : (
                  <>Send Verification Email</>
                )}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    </Slide>
  );
}
