import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  socialIcon: {
    marginRight: theme.spacing(1)
  }
}));

export default function PopupContainer(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        size="small"
        variant={props.buttonVariant}
        color={props.buttonColor}
        fullWidth={props.buttonFullWidth}>
        {props.buttonIcon}
        &nbsp;
        {props.openerButtonName}
      </Button>

      <Dialog
        maxWidth={props.dialogMaxWidth}
        fullWidth={props.isDialogFullWidth}
        // fullScreen
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{props.contentText}</DialogContentText>

          {/* content from parent element -- main content */}
          {props.children}
        </DialogContent>
        <DialogActions>
          {props.isCloseButtonRequired && (
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          )}
          {props.actionButton}
        </DialogActions>
      </Dialog>
    </div>
  );
}

PopupContainer.defaultProps = {
  isCloseButtonRequired: true,
  isDialogFullWidth: true,
  dialogMaxWidth: 'sm',
  buttonVariant: 'contained',
  buttonColor: 'primary',
  buttonFullWidth: false,
  buttonIcon: null
};

PopupContainer.propTypes = {
  openerButtonName: PropTypes.any,
  title: PropTypes.any,
  contentText: PropTypes.any,
  actionButton: PropTypes.any,
  // default valued props
  isCloseButtonRequired: PropTypes.any,
  isDialogFullWidth: PropTypes.any,
  dialogMaxWidth: PropTypes.any,
  buttonVariant: PropTypes.any, // button
  buttonColor: PropTypes.any,
  buttonFullWidth: PropTypes.any,
  buttonIcon: PropTypes.any
};
