import {
  // jobs
  JOBS_LOADING,
  JOBS_SET,
  JOBS_ERROR
} from '../types';

import { createReducer } from '@reduxjs/toolkit';

var initialObject = {
  loading: false,
  value: null,
  error: null,
  is_fetched: false
};

const initialState = {
  // jobs

  jobs: { ...initialObject }
};

export default createReducer(initialState, {
  // get -- jobs
  JOBS_LOADING: (state, action) => {
    state.jobs['loading'] = action.payload;
  },
  JOBS_SET: (state, action) => {
    state.jobs['value'] = action.payload;
    state.jobs['is_fetched'] = true;
  },
  JOBS_ERROR: (state, action) => {
    state.jobs['error'] = action.payload;
  }
});
