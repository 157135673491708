// React-Redux
import React from 'react';

import PropTypes from 'prop-types';
import '../../assets/scss/testimonial.css';
// MUI
import { makeStyles } from '@material-ui/styles';
import { Typography, Avatar, Card } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  testimonial: {
    backgroundColor: theme.palette.background.default,
    borderRadius: '20px;',
    boxShadow:
      '0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);',
    overflow: 'hidden'
    // maxWidth: '350px;'
    // width: '400px;'
  },
  testimonialBody: {
    padding: '40px 40px 80px;'
  },
  testimonialText: {
    fontSize: '20px;',
    lineHeight: '36px;',
    margin: '0'
  },
  testimonialFooter: {
    backgroundColor: theme.palette.secondary.main,
    padding: '40px;',
    textAlign: 'center;'
  },
  footerImg: {
    background: theme.palette.primary.main,
    borderRadius: '50%;',
    border: '5px solid #fff;',
    height: '100px;',
    width: '100px;',
    marginTop: '-100px;'
  },
  personName: {
    color: theme.palette.secondary.contrastText,
    margin: '10px 0;',
    letterSpacing: '2px;',
    textTransform: 'uppercase;'
  },
  personDetails: {
    color: theme.palette.secondary.contrastText,
    margin: '0;',
    letterSpacing: '1px;'
  }
}));

export default function TestimonialCard(props) {
  const classes = useStyles();

  return (
    <Card class={classes.testimonial}>
      <div class={classes.testimonialBody}>
        <Typography className={classes.testimonialText}>
          {props.text}
        </Typography>
      </div>
      <div class={classes.testimonialFooter}>
        <Avatar className={classes.footerImg} />
        {/* <img
            className={classes.footerImg}
            // src="https://randomuser.me/api/portraits/men/32.jpg"
            src={props.personImage}
            alt="user image"
          /> */}
        <Typography className={classes.personName}>
          {props.personName}
        </Typography>
        <Typography className={classes.personDetails}>
          {props.personDetails}
        </Typography>
      </div>
    </Card>
  );
}

TestimonialCard.propTypes = {
  text: PropTypes.any,
  personName: PropTypes.any,
  personDetails: PropTypes.any
};
