import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link, Box } from '@material-ui/core';
import config from 'config/main';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Footer = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Box display="flex" mb={2} flexWrap="wrap" justifyContent="space-around">
        <Link
          component="a"
          href={config.pageData.AboutUs.route.path}
          target="_blank">
          {config.pageData.AboutUs.pageTitle}
        </Link>
        <Link
          component="a"
          href={config.pageData.ContactUs.route.path}
          target="_blank">
          {config.pageData.ContactUs.pageTitle}
        </Link>
        <Link
          component="a"
          href={config.pageData.PrivacyPolicy.route.path}
          target="_blank">
          {config.pageData.PrivacyPolicy.pageTitle}
        </Link>
        <Link
          component="a"
          href={config.pageData.TermsAndConditions.route.path}
          target="_blank">
          {config.pageData.TermsAndConditions.pageTitle}
        </Link>
        <Link
          component="a"
          href={config.pageData.CancellationAndRefundPolicies.route.path}
          target="_blank">
          {config.pageData.CancellationAndRefundPolicies.pageTitle}
        </Link>
      </Box>

      <Typography variant="body1" align="center" gutterBottom>
        &copy;{' '}
        <Link component="a" href="#">
          {config.general.company_name}
        </Link>
      </Typography>
      <Typography variant="subtitle2" align="center">
        {config.general.company_slogan}
      </Typography>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
