// React-Redux
import React from 'react';

// MUI
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {}
}));

export default function BuyMembershipPopup() {
  const classes = useStyles();

  return <div className={classes.root}>This is Error message</div>;
}

BuyMembershipPopup.propTypes = {};
