import {
  // book_call
  BOOK_CALL_LOADING,
  BOOK_CALL_SET,
  BOOK_CALL_ERROR
} from '../types';

import { requestHandler } from '../requestHandler';

// BookCall
export const BookCall = (data, successCallback) => async (dispatch) => {
  requestHandler({
    dispatch: dispatch,
    requestType: 'POST',
    requestUrl: `/auth/book_call/`,
    postData: data,
    loadingReducer: BOOK_CALL_LOADING,
    successReducer: BOOK_CALL_SET,
    errorReducer: BOOK_CALL_ERROR,
    successCallback: successCallback,
    errorCallback: null
  });
};
