import axios from 'axios';

import {
  CERTIFICATES_LOADING,
  CERTIFICATES_SET,
  CERTIFICATES_VERIFY_LOADING,
  CERTIFICATE_VERIFY_SET,
  CERTIFICATES_VERIFY_ERROR,
  CERTIFICATE_GENERATE_LOADING,
  CERTIFICATE_GENERATE_SET,
  CERTIFICATE_GENERATE_ERROR,
  // generate_specialization_certificate
  GENERATE_SPECIALIZATION_CERTIFICATE_LOADING,
  GENERATE_SPECIALIZATION_CERTIFICATE_SET,
  GENERATE_SPECIALIZATION_CERTIFICATE_ERROR,
  // generate_sample_certificate
  GENERATE_SAMPLE_CERTIFICATE_LOADING,
  GENERATE_SAMPLE_CERTIFICATE_SET,
  GENERATE_SAMPLE_CERTIFICATE_ERROR
} from '../types';
import { django } from '../URLS';

import store from '../store';
import { setSnackbar } from '../actions/snackbarActions';

import { requestHandler } from '../requestHandler';

export const getCertificates = () => async (dispatch) => {
  try {
    dispatch({
      type: CERTIFICATES_LOADING,
      payload: true
    });
    let res = await axios.get(`${django}/auth/myCertificatesList/`);

    dispatch({
      type: CERTIFICATES_SET,
      payload: res.data
    });
    dispatch({
      type: CERTIFICATES_LOADING,
      payload: false
    });
  } catch (err) {
    store.dispatch(
      setSnackbar(`Error while fetching getCertificates- ${err}`, 'error')
    );
    console.log(`ERROR_HAPPENED in getCertificates ACTION: ${err}`);
  }
};

export const getCertificateVerify = (certificateId) => async (dispatch) => {
  requestHandler({
    dispatch: dispatch,
    requestType: 'GET',
    requestUrl: `/auth/certificateDetail/${certificateId}/`,
    postData: null,
    loadingReducer: CERTIFICATES_VERIFY_LOADING,
    successReducer: CERTIFICATE_VERIFY_SET,
    errorReducer: CERTIFICATES_VERIFY_ERROR,
    successCallback: null,
    errorCallback: null
  });
};

export const generateCertificate = (courseId) => async (dispatch) => {
  requestHandler({
    dispatch: dispatch,
    requestType: 'GET',
    requestUrl: `/auth/certificateGenerate/${courseId}/`,
    postData: null,
    loadingReducer: CERTIFICATE_GENERATE_LOADING,
    successReducer: CERTIFICATE_GENERATE_SET,
    errorReducer: CERTIFICATE_GENERATE_ERROR,
    successCallback: null,
    errorCallback: null
  });
};

// GenerateSpecializationCertificate
export const generateSpecializationCertificate = (
  data,
  successCallback
) => async (dispatch) => {
  requestHandler({
    dispatch: dispatch,
    requestType: 'POST',
    requestUrl: `/auth/generate_specialization_certificate/`,
    postData: data,
    loadingReducer: GENERATE_SPECIALIZATION_CERTIFICATE_LOADING,
    successReducer: GENERATE_SPECIALIZATION_CERTIFICATE_SET,
    errorReducer: GENERATE_SPECIALIZATION_CERTIFICATE_ERROR,
    successCallback: successCallback,
    errorCallback: null
  });
};

// GenerateSampleCertificate
export const generateSampleCertificate = (data, successCallback) => async (
  dispatch
) => {
  requestHandler({
    dispatch: dispatch,
    requestType: 'POST',
    requestUrl: `/auth/generate_sample_certificate/`,
    postData: data,
    loadingReducer: GENERATE_SAMPLE_CERTIFICATE_LOADING,
    successReducer: GENERATE_SAMPLE_CERTIFICATE_SET,
    errorReducer: GENERATE_SAMPLE_CERTIFICATE_ERROR,
    successCallback: successCallback,
    errorCallback: null
  });
};
