import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import generalConfig from 'config/general';

import auth from '../../authentication';
import Alert from '@material-ui/lab/Alert';
import { Box } from '@material-ui/core';

const RouteWithLayout = (props) => {
  const {
    layout: Layout,
    component: Component,
    onlyAuthUsers,
    ...rest
  } = props;

  let show_layout = false;

  let is_auth = auth.isAuthenticated();
  console.log(`IS_AUTH: ${is_auth}`);
  console.log(`onlyAuthUsers: ${onlyAuthUsers}`);
  if (onlyAuthUsers) {
    if (is_auth) {
      show_layout = true;
    }
  } else {
    show_layout = true;
  }

  return (
    <Route
      {...rest}
      render={(matchProps) => {
        if (show_layout) {
          return (
            <Layout>
              <>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Alert severity="info" icon={false}>
                    {generalConfig.workspace_name}
                  </Alert>
                </Box>
                <Component {...matchProps} />
              </>
            </Layout>
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: '/sign-in2',
                state: {
                  from: matchProps.location
                }
              }}
            />
          );
        }
      }}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

export default RouteWithLayout;
