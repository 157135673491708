import get from './envConfig';

export default {
  MEMBERSHIP_3: {
    // the below 2 are used to fetch the price (new implementation)
    brochureUrl:
      'https://all-resources-dj.s3.ap-south-1.amazonaws.com/brochures/antshade/AntShade+Brochure.pdf',
    offerId: 'antshade-complete-membership',
    type: 'MEMBERSHIP_3',
    display_name: 'AntShade Membership',
    description: "This membership gives you '1 year Access'",
    price: {
      // Price is not taken from here. It's take from the DB (from the offers -- new implementation)
      INR: {
        value: 2800000,
        name: 'Rs. 28,999'
      },
      USD: {
        value: 79900,
        name: '$799'
      }
    },
    access_validity: '1 year membership',
    detail_points: [
      '200 + HD quick streaming videos',
      'Real-Time SMS pattern alerts',
      'Scalping Trading Platform',
      'Virtual Trading - Trading/Investing',
      'Patterns History',
      'Course Certifications',
      'Audio-learning, Digital Library',
      'Stock News Feed',
      'Live Classes',
      'Interactive-MCQ',
      'Stock Market Interview Prep',
      'Jobs listings Portal',
      'Resume, Cover-letter templates',
      'Public Profile'
    ]
  }
};
