import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { Avatar, Typography, Box } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 500,
    borderRadius: theme.spacing(1),
    width: '100%'
  },
  media: {
    // height: 250
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  avatar: {
    width: 180,
    height: 180,
    background: theme.palette.primary.main
  },
  name: {
    marginTop: '10px'
  },
  cardContent: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

export default function ProfileCard(props) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <Typography variant="h3" align="center" className={classes.name}>
          {props.name}
        </Typography>
        <CardMedia className={classes.media}>
          <Avatar
            alt="Person"
            className={classes.avatar}
            src={props.profile_pic}
          />
        </CardMedia>

        <CardContent className={classes.cardContent}>
          {/* first name */}
          <Box>
            {props.first_name && (
              <>
                <Typography gutterBottom variant="h5" component="h2">
                  First Name
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {props.first_name}
                </Typography>
              </>
            )}
          </Box>
          {/* last name */}
          <Box>
            {props.last_name && (
              <>
                <Typography gutterBottom variant="h5" component="h2">
                  Last Name
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {props.last_name}
                </Typography>
              </>
            )}
          </Box>
          {/* Number of certificates */}
          <Box>
            {props.last_name && (
              <>
                <Typography gutterBottom variant="h5" component="h2">
                  Number of certifications
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {props.number_of_certifications}
                </Typography>
              </>
            )}
          </Box>
          {/* email */}
          <Box>
            {props.email && (
              <>
                <Typography gutterBottom variant="h5" component="h2">
                  Email ID
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {props.email}
                </Typography>
              </>
            )}
          </Box>
          {/* Bio */}
          <Box>
            {props.bio && (
              <>
                <Typography gutterBottom variant="h5" component="h2">
                  Bio
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {props.bio}
                </Typography>
              </>
            )}
          </Box>
          {/* address */}
          <Box>
            {props.address && (
              <>
                <Typography gutterBottom variant="h5" component="h2">
                  Address
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {props.address}
                </Typography>
              </>
            )}
          </Box>
          {/* country */}
          <Box>
            {props.country && (
              <>
                <Typography gutterBottom variant="h5" component="h2">
                  Country
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {props.country}
                </Typography>
              </>
            )}
          </Box>
          {/* phone_number */}
          <Box>
            {props.phone_number && (
              <>
                <Typography gutterBottom variant="h5" component="h2">
                  Phone Number
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {props.phone_number}
                </Typography>
              </>
            )}
          </Box>
          {/* resume */}
          <Box>
            {props.resume && (
              <>
                <Typography gutterBottom variant="h5" component="h2">
                  Resume
                </Typography>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  component="a"
                  download="resume.pdf"
                  target="_blank"
                  href={props.resume}>
                  View Resume
                </Button>
              </>
            )}
          </Box>
        </CardContent>
      </CardActionArea>
      {/* <CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions> */}
    </Card>
  );
}

ProfileCard.propTypes = {
  name: PropTypes.any,
  profile_pic: PropTypes.any,
  first_name: PropTypes.any,
  last_name: PropTypes.any,
  number_of_certifications: PropTypes.any,
  email: PropTypes.any,
  bio: PropTypes.any,
  address: PropTypes.any,
  country: PropTypes.any,
  phone_number: PropTypes.any,
  resume: PropTypes.any
};
