import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
  makeStyles
} from '@material-ui/core';

import { getGetOffer as a_getGetOffer } from 'redux/actions/membershipActions';

import { BuyMembershipPaymentLink } from 'components';
import config from 'config/main';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    height: '100%',
    paddingTop: 120,
    paddingBottom: 120
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  product: {
    position: 'relative',
    padding: theme.spacing(5, 3),
    cursor: 'pointer',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    '&:hover': {
      transform: 'scale(1.1)'
    }
  },
  productImage: {
    borderRadius: theme.shape.borderRadius,
    position: 'absolute',
    top: -24,
    left: theme.spacing(3),
    height: 48,
    width: 48,
    fontSize: 24
  },
  recommendedProduct: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white
  },
  chooseButton: {
    backgroundColor: theme.palette.common.white
  }
}));

const countryMapper = {
  US: {
    offerPriceStrField: 'price_usd_str',
    offerPriceField: 'price_usd',
    currency: 'USD'
  },
  IN: {
    offerPriceStrField: 'price_inr_str',
    offerPriceField: 'price_inr',
    currency: 'INR'
  }
};

function PricingView(props) {
  let pricingConfig = config.pricing;
  const queryCountry = localStorage.getItem('country');
  const countryConfig = countryMapper[queryCountry];
  let s_user = useSelector((state) => state.user.user);
  let s_get_offer = useSelector((state) => state.membership.get_offer);
  let dispatch = useDispatch();
  const classes = useStyles();

  const getPrice = (offerId) => {
    dispatch(a_getGetOffer(offerId));
  };

  return (
    <>
      <Typography align="center" variant="h1" color="textPrimary">
        Pricing
      </Typography>
      <Box mt={3}></Box>

      <Box mt={3} textAlign="center">
        <Grid container spacing={4}>
          <Grid item md={3} xs={12}></Grid>
          <Grid item md={6} xs={12}>
            <Paper className={classes.product} elevation={1}>
              <img
                alt="Product"
                className={classes.productImage}
                src="/images/pricing_icons/product_enterprise.svg"
              />
              <Typography
                component="h3"
                gutterBottom
                variant="overline"
                color="textSecondary">
                {pricingConfig['MEMBERSHIP_3'].display_name}
              </Typography>
              <BuyMembershipPaymentLink
                buttonName={`Buy now @ ${config.general.price}`}
                offerId={config.pricing.MEMBERSHIP_3.offerId}
                colorType="secondary"
                buttonFullWidth={true}
              />

              {/* THE BELOW IS USED WHILE USING THE "Get Price" TO GET THE OFFER. ELSE NOT NEEDED IF THE PRICE IS STATIC FROM CLIENT-SIDE */}
              {/* <div>
                {s_get_offer.is_fetched ? (
                  <>
                    <Typography
                      component="span"
                      display="inline"
                      variant="h3"
                      color="textPrimary">
                      {s_get_offer.value[countryConfig.offerPriceStrField]} (
                      {countryConfig.currency})
                    </Typography>
                    <Box mt={1}>
                      <BuyMembershipPaymentLink
                        buttonName="Buy now"
                        offerId={config.pricing.MEMBERSHIP_3.offerId}
                        colorType="secondary"
                        buttonFullWidth={true}
                      />
                    </Box>
                  </>
                ) : (
                  <Button
                    variant="contained"
                    fullWidth
                    color="secondary"
                    onClick={() => {
                      getPrice(config.pricing.MEMBERSHIP_3.offerId);
                    }}>
                    {s_get_offer.loading ? 'Loading Price' : 'Get Price'}
                  </Button>
                )}
              </div> */}
              <Typography variant="overline" color="textSecondary">
                {pricingConfig['MEMBERSHIP_3'].access_validity}
              </Typography>
              <Box my={2}>
                <Divider />
              </Box>
              <Typography variant="body2" color="textPrimary">
                {pricingConfig['MEMBERSHIP_3'].detail_points.map((element) => (
                  <>
                    {element}
                    <br />
                  </>
                ))}
              </Typography>
              <Box my={2}>
                <Divider />
              </Box>
              <Button
                target="_blank"
                variant="contained"
                fullWidth
                color="default"
                href={config.pricing.MEMBERSHIP_3.brochureUrl}>
                Download Brochure
              </Button>
              <Box mt={1}>
                <BuyMembershipPaymentLink
                  buttonName="Buy now"
                  offerId={config.pricing.MEMBERSHIP_3.offerId}
                  colorType="secondary"
                  buttonFullWidth={true}
                />
              </Box>
            </Paper>
          </Grid>
          <Grid item md={3} xs={12}></Grid>
        </Grid>
      </Box>
    </>
  );
}

export default PricingView;
