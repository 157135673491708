import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/styles';
import Chart from 'react-apexcharts';

const useStyles = makeStyles((theme) => ({
  root: {},
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.contrastText
  },
  main: {
    background: theme.palette.primary.main,
    borderRadius: '10px'
  },
  chart: {
    color: 'purple'
    // textColor: 'red'
  }
}));

// Data format below:
let options = {
  chart: {
    type: 'candlestick',
    height: 350
  },
  title: {
    text: 'Chart',
    align: 'left'
  },
  xaxis: {
    // type: 'datetime'
    // labels: {
    //   format: 'dd MMM HH:mm'
    // }
    // datetimeFormatter: {
    //   year: 'yyyy',
    //   month: "MMM 'yy",
    //   day: 'dd MMM',
    //   hour: 'HH:mm'
    // }
  },
  yaxis: {
    tooltip: {
      enabled: true
    }
  }
};

// Series chart data format below:
// let series = [
//   {
//     data: [
//       {
//         x: 1538778600000,
//         y: [6629.81, 6650.5, 6623.04, 6633.33]
//       },
//       {
//         x: new Date(1538780400000),
//         y: [6632.01, 6643.59, 6620, 6630.11]
//       },
//       {
//         x: new Date(1538782200000),
//         y: [6630.71, 6648.95, 6623.34, 6635.65]
//       }
//     ]
//   }
// ];

export default function ApexChartCandle(props) {
  //   let widgetRef = useRef(null);
  let currentTheme = useSelector((state) => state.display.theme);
  const theme = useTheme(); // Problem is that, this doesn't change dynamically when the theme changes.
  const classes = useStyles();
  var chart;

  return (
    <div>
      <Chart
        className={classes.chart}
        options={options}
        series={props.series}
        type="candlestick"
        width="100%"
        height={320}
      />
    </div>
  );
}

ApexChartCandle.defaultProps = {
  //   showSymbolLogo: true
};

ApexChartCandle.propTypes = {
  series: PropTypes.any
};
