export default [
  {
    id: '1',
    rating: 5,
    message:
      'Just like how the platform looks authentic, the course is too. This is probably the best platform to learn about digital marketing. This is the only place that gives you practical experience too. They have some amazing projects too and their source files too. I am just too super impressed with this. You can blindly join this. And it is too cheap for the amount of stuff you can get out of this.',
    name: 'Arun Kumar',
    avatar: '',
    trainedBy: 'Clinton',

    footerText: {
      // leftKey: 'Salary Backed',
      // leftValue: 'Rs.12 L',
      leftKey: 'Rating',
      leftValue: '5/5',
      middleKey: 'Certifications Completed',
      middleValue: '5',
      rightKey: 'Projects',
      rightValue: '4'
    }

    // createdAt: moment().subtract(4, 'hours').toDate().getTime()
  },
  {
    id: '2',
    rating: 4,
    message:
      "The course is very helpful. I'm learning new things  at every step. This is really helping me progress, I never expected to move towards.",
    name: 'Ramesh Bhat',
    avatar: '',
    trainedBy: 'Clinton',

    footerText: {
      // leftKey: 'Salary Backed',
      // leftValue: 'Rs.15 L',
      leftKey: 'Rating',
      leftValue: '4/5',
      middleKey: 'Certifications Completed',
      middleValue: '5',
      rightKey: 'Projects',
      rightValue: '4'
    }

    // createdAt: moment().subtract(4, 'hours').toDate().getTime()
  },
  {
    id: '3',
    rating: 5,
    message:
      'A real world practical approach, things which are not there in most of the courses. I would also like to add that the course puts light on various aspects that can be used to promote the products or services.',
    name: 'Aaradhya',
    avatar: '',
    trainedBy: 'Anthony, Susan B.',

    footerText: {
      // leftKey: 'Salary Backed',
      // leftValue: 'Rs.18 L',
      leftKey: 'Rating',
      leftValue: '5/5',
      middleKey: 'Certifications Completed',
      middleValue: '5',
      rightKey: 'Projects',
      rightValue: '2'
    }

    // createdAt: moment().subtract(4, 'hours').toDate().getTime()
  },
  {
    id: '4',
    rating: 5,
    message:
      'Best course which cover all the areas. SEO part should be more explored but still this course is better for  marketing foundation and strong knowledge.',
    name: 'Saisha',
    avatar: '',
    trainedBy: 'Ayushman',

    footerText: {
      // leftKey: 'Salary Backed',
      // leftValue: 'Rs.11 L',
      leftKey: 'Rating',
      leftValue: '5/5',
      middleKey: 'Certifications Completed',
      middleValue: '5',
      rightKey: 'Projects',
      rightValue: '5'
    }

    // createdAt: moment().subtract(4, 'hours').toDate().getTime()
  },
  {
    id: '5',
    rating: 4,
    message:
      'The best course including most part of digital marketing and the audio is also amazing !!!!!',
    name: 'Viti',
    avatar: '',
    trainedBy: 'Ayushman',

    footerText: {
      // leftKey: 'Salary Backed',
      // leftValue: 'Rs.13 L',
      leftKey: 'Rating',
      leftValue: '5/5',
      middleKey: 'Certifications Completed',
      middleValue: '2',
      rightKey: 'Projects',
      rightValue: '5'
    }

    // createdAt: moment().subtract(4, 'hours').toDate().getTime()
  },
  {
    id: '6',
    rating: 5,
    message: 'Excellent guide for beginners !',
    name: 'Jash',
    avatar: '',
    trainedBy: 'Anthony, Susan B.',

    footerText: {
      // leftKey: 'Salary Backed',
      // leftValue: 'Rs.12 L',
      leftKey: 'Rating',
      leftValue: '5/5',
      middleKey: 'Certifications Completed',
      middleValue: '4',
      rightKey: 'Projects',
      rightValue: '2'
    }

    // createdAt: moment().subtract(4, 'hours').toDate().getTime()
  },
  {
    id: '7',
    rating: 4,
    message:
      'The course had a very practical way towards learning and the Illustrations were very simple and can be understood by all',
    name: 'Naksh',
    avatar: '',
    trainedBy: 'Ayushman',

    footerText: {
      // leftKey: 'Salary Backed',
      // leftValue: 'Rs.6.5 L',
      leftKey: 'Rating',
      leftValue: '4/5',
      middleKey: 'Certifications Completed',
      middleValue: '6',
      rightKey: 'Projects',
      rightValue: '3'
    }

    // createdAt: moment().subtract(4, 'hours').toDate().getTime()
  },
  {
    id: '8',
    rating: 5,
    message: 'The course is interesting and structured like it very much',
    name: 'Reyansh',
    avatar: '',
    trainedBy: 'Anthony, Susan B.',

    footerText: {
      // leftKey: 'Salary Backed',
      // leftValue: 'Rs.7.5 L',
      leftKey: 'Rating',
      leftValue: '5/5',
      middleKey: 'Certifications Completed',
      middleValue: '5',
      rightKey: 'Projects',
      rightValue: '2'
    }

    // createdAt: moment().subtract(4, 'hours').toDate().getTime()
  },
  {
    id: '9',
    rating: 5,
    message:
      'The course is for beginners and experts and takes you from A-Z covering all the concepts. It is practical, which I value the most compared to the other training centres out there.',
    name: 'Vivaan',
    avatar: '',
    trainedBy: 'Ayushman',

    footerText: {
      // leftKey: 'Salary Backed',
      // leftValue: 'Rs.8 L',
      leftKey: 'Rating',
      leftValue: '5/5',
      middleKey: 'Certifications Completed',
      middleValue: '4',
      rightKey: 'Projects',
      rightValue: '1'
    }

    // createdAt: moment().subtract(4, 'hours').toDate().getTime()
  },
  {
    id: '10',
    rating: 5,
    message:
      'I highly recommend this course to the all of them. No doubt, this course is a value for money. Need patience to extract the most out of it. Best part of the course is that the content is going to be accessible for life time. So no anxiety ....',
    name: 'Hills',
    avatar: '',
    trainedBy: 'Anthony, Susan B.',

    footerText: {
      // leftKey: 'Salary Backed',
      // leftValue: 'Rs.12 L',
      leftKey: 'Rating',
      leftValue: '5/5',
      middleKey: 'Certifications Completed',
      middleValue: '5',
      rightKey: 'Projects',
      rightValue: '5'
    }

    // createdAt: moment().subtract(4, 'hours').toDate().getTime()
  },
  {
    id: '11',
    rating: 5,
    message:
      'Previously I had no clue about digital marketing. Thanks to Adithya & team and all the instructors for helping me acquire this new skills which I can use it for getting this new job. This course is such a blessing , helped me get a new skill before my college .',
    name: 'Adams',
    avatar: '',
    trainedBy: 'Ayushman',

    footerText: {
      // leftKey: 'Salary Backed',
      // leftValue: 'Rs.24 L',
      leftKey: 'Rating',
      leftValue: '5/5',
      middleKey: 'Certifications Completed',
      middleValue: '6',
      rightKey: 'Projects',
      rightValue: '2'
    }

    // createdAt: moment().subtract(4, 'hours').toDate().getTime()
  },
  {
    id: '12',
    rating: 5,
    message:
      'It was an extremely useful and pleasurable course, I was a complete naive, but by the end, I was already confident in getting my hands of all the digital marketing concepts. It gave me much more than I could ever expect.',
    name: 'Baker',
    avatar: '',
    trainedBy: 'Ayushman',

    footerText: {
      // leftKey: 'Salary Backed',
      // leftValue: 'Rs.13 L',
      leftKey: 'Rating',
      leftValue: '5/5',
      middleKey: 'Certifications Completed',
      middleValue: '4',
      rightKey: 'Projects',
      rightValue: '2'
    }

    // createdAt: moment().subtract(4, 'hours').toDate().getTime()
  },
  {
    id: '13',
    rating: 5,
    message:
      'I was very interested to learn about digital marketing completely , as I was exposed to digital marketing initiatives at workplace. This course offers valuable concepts especially on creating up a web apps and understanding SEO. Thank you for the amazing teaching Adithya. Will for sure utilize the video and blog content in the future.',
    name: 'Clark',
    avatar: '',
    trainedBy: 'Anthony, Susan B.',

    footerText: {
      // leftKey: 'Salary Backed',
      // leftValue: 'Rs.12 L',
      leftKey: 'Rating',
      leftValue: '5/5',
      middleKey: 'Certifications Completed',
      middleValue: '7',
      rightKey: 'Projects',
      rightValue: '7'
    }

    // createdAt: moment().subtract(4, 'hours').toDate().getTime()
  },
  {
    id: '14',
    rating: 4,
    message:
      'Just AWESOME, an unbelievable experience to learn Digital Marketing in this course. I recommend everyone to learn because the way the course is designed explained is just amazing.',
    name: 'Davis',
    avatar: '',
    trainedBy: 'Ayushman',

    footerText: {
      // leftKey: 'Salary Backed',
      // leftValue: 'Rs.14 L',
      leftKey: 'Rating',
      leftValue: '4/5',
      middleKey: 'Certifications Completed',
      middleValue: '5',
      rightKey: 'Projects',
      rightValue: '2'
    }

    // createdAt: moment().subtract(4, 'hours').toDate().getTime()
  },
  {
    id: '15',
    rating: 5,
    message:
      'Assolutamente fantastico! Completo e pratico. Adithya non solo è molto preparato ma è capace di ispirarti mentre spiega. Consigliato 100%',
    name: 'Evans',
    avatar: '',
    trainedBy: 'Ayushman',

    footerText: {
      // leftKey: 'Salary Backed',
      // leftValue: 'Rs.10 L',
      leftKey: 'Rating',
      leftValue: '5/5',
      middleKey: 'Certifications Completed',
      middleValue: '5',
      rightKey: 'Projects',
      rightValue: '2'
    }

    // createdAt: moment().subtract(4, 'hours').toDate().getTime()
  },
  {
    id: '16',
    rating: 4,
    message:
      'excellent course. Worth every second. Such clear explanations, I really did not expect this course to be this useful. I have even recommended this platform to my other friends who are interested in digital marketing as well.',
    name: 'Frank',
    avatar: '',
    trainedBy: 'Anthony, Susan B.',

    footerText: {
      // leftKey: 'Salary Backed',
      // leftValue: 'Rs.20 L',
      leftKey: 'Rating',
      leftValue: '4/5',
      middleKey: 'Certifications Completed',
      middleValue: '6',
      rightKey: 'Projects',
      rightValue: '3'
    }

    // createdAt: moment().subtract(4, 'hours').toDate().getTime()
  },
  {
    id: '17',
    rating: 4,
    message:
      'I am extremely excited with this course and I am going to start freelancing. I feel this is the best course that one can get to learn digital marketing from scratch. Thank you so much for taking time in teaching this information.',
    name: 'Ghosh',
    avatar: '',
    trainedBy: 'Rajesh Kumar',

    footerText: {
      // leftKey: 'Salary Backed',
      // leftValue: 'Rs.33 L',
      leftKey: 'Rating',
      leftValue: '4/5',
      middleKey: 'Certifications Completed',
      middleValue: '5',
      rightKey: 'Projects',
      rightValue: '2'
    }

    // createdAt: moment().subtract(4, 'hours').toDate().getTime()
  },
  {
    id: '18',
    rating: 5,
    message:
      'I had a great learning experience taking this course. Learned a lot of amazing things that to get ahead in my career',
    name: 'Hills',
    avatar: '',
    trainedBy: 'Anthony, Susan B.',

    footerText: {
      // leftKey: 'Salary Backed',
      // leftValue: 'Rs.14 L',
      leftKey: 'Rating',
      leftValue: '5/5',
      middleKey: 'Certifications Completed',
      middleValue: '2',
      rightKey: 'Projects',
      rightValue: '5'
    }

    // createdAt: moment().subtract(4, 'hours').toDate().getTime()
  },
  {
    id: '19',
    rating: 4,
    message:
      'I really enjoyed this - It covers the everything of Digital Marketing with a practical approach which makes the content easy to understand. I highly recommend this to everyone interested in digital marketing!',
    name: 'Irwin',
    avatar: '',
    trainedBy: 'Rajesh Kumar',

    footerText: {
      // leftKey: 'Salary Backed',
      // leftValue: 'Rs.12 L',
      leftKey: 'Rating',
      leftValue: '4/5',
      middleKey: 'Certifications Completed',
      middleValue: '6',
      rightKey: 'Projects',
      rightValue: '2'
    }

    // createdAt: moment().subtract(4, 'hours').toDate().getTime()
  },
  {
    id: '20',
    rating: 5,
    message:
      'probably the best course! I have learnt so much from the lectures and even found the best tools that I never knew existed. Thank you so much for creating this course',
    name: 'Jones',
    avatar: '',
    trainedBy: 'Anthony, Susan B.',

    footerText: {
      // leftKey: 'Salary Backed',
      // leftValue: 'Rs.8 L',
      leftKey: 'Rating',
      leftValue: '5/5',
      middleKey: 'Certifications Completed',
      middleValue: '4',
      rightKey: 'Projects',
      rightValue: '2'
    }

    // createdAt: moment().subtract(4, 'hours').toDate().getTime()
  },
  {
    id: '21',
    rating: 5,
    message:
      'First of all the course is really cheap for what its offering, Im very happy that I found this course it was 2 weeks well spent, i got to learn so much that I had never learnt before',
    name: 'Klein',
    avatar: '',
    trainedBy: 'Dinesh Shetty',

    footerText: {
      // leftKey: 'Salary Backed',
      // leftValue: 'Rs.8 L',
      leftKey: 'Rating',
      leftValue: '5/5',
      middleKey: 'Certifications Completed',
      middleValue: '7',
      rightKey: 'Projects',
      rightValue: '3'
    }

    // createdAt: moment().subtract(4, 'hours').toDate().getTime()
  },
  {
    id: '22',
    rating: 5,
    message: 'Very Informative and to the point',
    name: 'Lopez',
    avatar: '',
    trainedBy: 'Rajesh Kumar',

    footerText: {
      // leftKey: 'Salary Backed',
      // leftValue: 'Rs.5 L',
      leftKey: 'Rating',
      leftValue: '5/5',
      middleKey: 'Certifications Completed',
      middleValue: '7',
      rightKey: 'Projects',
      rightValue: '3'
    }

    // createdAt: moment().subtract(4, 'hours').toDate().getTime()
  },
  {
    id: '23',
    rating: 5,
    message:
      'This course is very useful to get an entire crowd of all the marketing agencies. It can implemented into your own business expansion.',
    name: 'Mason',
    avatar: '',
    trainedBy: 'Rajesh Kumar',

    footerText: {
      // leftKey: 'Salary Backed',
      // leftValue: 'Rs.14 L',
      leftKey: 'Rating',
      leftValue: '5/5',
      middleKey: 'Certifications Completed',
      middleValue: '5',
      rightKey: 'Projects',
      rightValue: '5'
    }

    // createdAt: moment().subtract(4, 'hours').toDate().getTime()
  },
  {
    id: '24',
    rating: 4,
    message: 'Adithya was really great during the all time session.',
    name: 'Ramesh Bhat',
    avatar: '',
    trainedBy: 'Clinton',

    footerText: {
      // leftKey: 'Salary Backed',
      // leftValue: 'Rs.12 L',
      leftKey: 'Rating',
      leftValue: '4/5',
      middleKey: 'Certifications Completed',
      middleValue: '5',
      rightKey: 'Projects',
      rightValue: '4'
    }

    // createdAt: moment().subtract(4, 'hours').toDate().getTime()
  },
  {
    id: '25',
    rating: 5,
    message: 'There is literally nothing better than this course',
    name: 'Sumukh',
    avatar: '',
    trainedBy: 'Adithya',

    footerText: {
      // leftKey: 'Salary Backed',
      // leftValue: 'Rs.12 L',
      leftKey: 'Rating',
      leftValue: '5/5',
      middleKey: 'Certifications Completed',
      middleValue: '4',
      rightKey: 'Projects',
      rightValue: '2'
    }

    // createdAt: moment().subtract(4, 'hours').toDate().getTime()
  },
  {
    id: '26',
    rating: 4,
    message:
      'Why did I not find this course before? I would have learnt so much before only. I am at least happy that I found it now',
    name: 'Sudheesh',
    avatar: '',
    trainedBy: 'Berkeley',

    footerText: {
      // leftKey: 'Salary Backed',
      // leftValue: 'Rs.6 L',
      leftKey: 'Rating',
      leftValue: '4/5',
      middleKey: 'Certifications Completed',
      middleValue: '5',
      rightKey: 'Projects',
      rightValue: '4'
    }

    // createdAt: moment().subtract(4, 'hours').toDate().getTime()
  },
  {
    id: '27',
    rating: 5,
    message: 'Just go for this. It is the best',
    name: 'Ramesh',
    avatar: '',
    trainedBy: 'Ravish',

    footerText: {
      // leftKey: 'Salary Backed',
      // leftValue: 'Rs.11 L',
      leftKey: 'Rating',
      leftValue: '5/5',
      middleKey: 'Certifications Completed',
      middleValue: '4',
      rightKey: 'Projects',
      rightValue: '2'
    }

    // createdAt: moment().subtract(4, 'hours').toDate().getTime()
  },
  {
    id: '28',
    rating: 4,
    message: 'Blindly buy this course',
    name: 'James Martin',
    avatar: '',
    trainedBy: 'Sara',

    footerText: {
      // leftKey: 'Salary Backed',
      // leftValue: 'Rs.12 L',
      leftKey: 'Rating',
      leftValue: '4/5',
      middleKey: 'Certifications Completed',
      middleValue: '6',
      rightKey: 'Projects',
      rightValue: '12'
    }

    // createdAt: moment().subtract(4, 'hours').toDate().getTime()
  },
  {
    id: '29',
    rating: 5,
    message: 'This teaches you digital marketing from A-Z',
    name: 'Sukesh Kumar',
    avatar: '',
    trainedBy: 'Rajesh',

    footerText: {
      // leftKey: 'Salary Backed',
      // leftValue: 'Rs.10 L',
      leftKey: 'Rating',
      leftValue: '5/5',
      middleKey: 'Certifications Completed',
      middleValue: '5',
      rightKey: 'Projects',
      rightValue: '2'
    }

    // createdAt: moment().subtract(4, 'hours').toDate().getTime()
  },
  {
    id: '30',
    rating: 5,
    message:
      'If you really wanna learn digital marketing from scratch, then you are in the right place',
    name: 'Anand',
    avatar: '',
    trainedBy: 'Ajay',

    footerText: {
      // leftKey: 'Salary Backed',
      // leftValue: 'Rs.11.5 L',
      leftKey: 'Rating',
      leftValue: '5/5',
      middleKey: 'Certifications Completed',
      middleValue: '8',
      rightKey: 'Projects',
      rightValue: '2'
    }

    // createdAt: moment().subtract(4, 'hours').toDate().getTime()
  },
  {
    id: '31',
    rating: 5,
    message:
      'THE BEST -- this is the only 2 words that can describe the course',
    name: 'Rony',
    avatar: '',
    trainedBy: 'Suresh',

    footerText: {
      // leftKey: 'Salary Backed',
      // leftValue: 'Rs.14 L',
      leftKey: 'Rating',
      leftValue: '5/5',
      middleKey: 'Certifications Completed',
      middleValue: '2',
      rightKey: 'Projects',
      rightValue: '6'
    }

    // createdAt: moment().subtract(4, 'hours').toDate().getTime()
  }
];
