import {
  MEMBERSHIPS_LOADING,
  MEMBERSHIPS_SET,
  MEMBERSHIPS_ERROR,
  // order
  CREATE_ORDER_LOADING,
  CREATE_ORDER_SET,
  CREATE_ORDER_ERROR,
  // verify signature
  VERIFY_PAYMENT_SIGNATURE_LOADING,
  VERIFY_PAYMENT_SIGNATURE_SET,
  VERIFY_PAYMENT_SIGNATURE_ERROR,
  /////////////////////////////////////// NEW ///////////////////////////////////////
  // referred memberships
  REFERRED_MEMBERSHIPS_LOADING,
  REFERRED_MEMBERSHIPS_SET,
  REFERRED_MEMBERSHIPS_ERROR,
  // 7 day referred membership counts
  REFERRED_MEMBERSHIPS_COUNTS_LOADING,
  REFERRED_MEMBERSHIPS_COUNTS_SET,
  REFERRED_MEMBERSHIPS_COUNTS_ERROR,
  // referred memberships
  ALL_MEMBERSHIPS_LOADING,
  ALL_MEMBERSHIPS_SET,
  ALL_MEMBERSHIPS_ERROR,
  // all membership counts
  ALL_MEMBERSHIPS_COUNTS_LOADING,
  ALL_MEMBERSHIPS_COUNTS_SET,
  ALL_MEMBERSHIPS_COUNTS_ERROR,
  // My earning sales
  MY_EARNING_SALES_LOADING,
  MY_EARNING_SALES_SET,
  MY_EARNING_SALES_ERROR,
  // All earning sales
  All_EARNING_SALES_LOADING,
  All_EARNING_SALES_SET,
  All_EARNING_SALES_ERROR,
  // My bank details
  MY_BANK_DETAILS_LOADING,
  MY_BANK_DETAILS_SET,
  MY_BANK_DETAILS_ERROR,
  // Update My bank details
  UPDATE_MY_BANK_DETAILS_LOADING,
  UPDATE_MY_BANK_DETAILS_SET,
  UPDATE_MY_BANK_DETAILS_ERROR,
  // pay the earned money to the user (for referrer)
  PAY_EARNED_MONEY_LOADING,
  PAY_EARNED_MONEY_SET,
  PAY_EARNED_MONEY_ERROR,
  // money_transactions
  MONEY_TRANSACTIONS_LOADING,
  MONEY_TRANSACTIONS_SET,
  MONEY_TRANSACTIONS_ERROR,
  // add_membership
  ADD_MEMBERSHIP_LOADING,
  ADD_MEMBERSHIP_SET,
  ADD_MEMBERSHIP_ERROR,
  // all_stats
  ALL_STATS_LOADING,
  ALL_STATS_SET,
  ALL_STATS_ERROR,
  // get_offer
  GET_OFFER_LOADING,
  GET_OFFER_SET,
  GET_OFFER_ERROR
} from '../types';

import { createReducer } from '@reduxjs/toolkit';

var initialObject = {
  loading: false,
  value: null,
  error: null,
  is_fetched: false
};

const initialState = {
  // all memberships
  memberships: { ...initialObject },
  // create order
  create_order: { ...initialObject },
  // verify signature
  verify_payment_signature: { ...initialObject },
  /////////////////////////////////////// NEW ///////////////////////////////////////
  referred_memberships: { ...initialObject },
  referred_memberships_counts: { ...initialObject },
  all_memberships: { ...initialObject },
  all_memberships_counts: { ...initialObject },
  my_earning_sales: { ...initialObject },
  all_earning_sales: { ...initialObject },
  my_bank_details: { ...initialObject },
  update_my_bank_details: { ...initialObject },
  pay_earned_money: { ...initialObject },
  // money_transactions

  money_transactions: { ...initialObject },
  // add_membership

  add_membership: { ...initialObject },
  // all_stats

  all_stats: { ...initialObject },
  // get_offer

  get_offer: { ...initialObject }
};

export default createReducer(initialState, {
  MEMBERSHIPS_LOADING: (state, action) => {
    state.memberships['loading'] = action.payload;
  },
  MEMBERSHIPS_SET: (state, action) => {
    state.memberships['value'] = action.payload;
    state.memberships['is_fetched'] = true;
  },
  MEMBERSHIPS_ERROR: (state, action) => {
    state.memberships['error'] = action.payload;
  },
  CREATE_ORDER_LOADING: (state, action) => {
    state.create_order['loading'] = action.payload;
  },
  CREATE_ORDER_SET: (state, action) => {
    state.create_order['value'] = action.payload;
    state.create_order['is_fetched'] = true;
  },
  CREATE_ORDER_ERROR: (state, action) => {
    state.create_order['error'] = action.payload;
  },
  VERIFY_PAYMENT_SIGNATURE_LOADING: (state, action) => {
    state.verify_payment_signature['loading'] = action.payload;
  },
  VERIFY_PAYMENT_SIGNATURE_SET: (state, action) => {
    state.verify_payment_signature['value'] = action.payload;
    state.verify_payment_signature['is_fetched'] = true;
  },
  VERIFY_PAYMENT_SIGNATURE_ERROR: (state, action) => {
    state.verify_payment_signature['error'] = action.payload;
  },
  /////////////////////////////////////// NEW ///////////////////////////////////////

  // referred memberships
  REFERRED_MEMBERSHIPS_LOADING: (state, action) => {
    state.referred_memberships['loading'] = action.payload;
  },
  REFERRED_MEMBERSHIPS_SET: (state, action) => {
    state.referred_memberships['value'] = action.payload;
    state.referred_memberships['is_fetched'] = true;
  },
  REFERRED_MEMBERSHIPS_ERROR: (state, action) => {
    state.referred_memberships['error'] = action.payload;
  },
  // 7 day referred membership counts
  REFERRED_MEMBERSHIPS_COUNTS_LOADING: (state, action) => {
    state.referred_memberships_counts['loading'] = action.payload;
  },
  REFERRED_MEMBERSHIPS_COUNTS_SET: (state, action) => {
    state.referred_memberships_counts['value'] = action.payload;
    state.referred_memberships_counts['is_fetched'] = true;
  },
  REFERRED_MEMBERSHIPS_COUNTS_ERROR: (state, action) => {
    state.referred_memberships_counts['error'] = action.payload;
  },
  // referred memberships
  ALL_MEMBERSHIPS_LOADING: (state, action) => {
    state.all_memberships['loading'] = action.payload;
  },
  ALL_MEMBERSHIPS_SET: (state, action) => {
    state.all_memberships['value'] = action.payload;
    state.all_memberships['is_fetched'] = true;
  },
  ALL_MEMBERSHIPS_ERROR: (state, action) => {
    state.all_memberships['error'] = action.payload;
  },
  // all membership counts
  ALL_MEMBERSHIPS_COUNTS_LOADING: (state, action) => {
    state.all_memberships_counts['loading'] = action.payload;
  },
  ALL_MEMBERSHIPS_COUNTS_SET: (state, action) => {
    state.all_memberships_counts['value'] = action.payload;
    state.all_memberships_counts['is_fetched'] = true;
  },
  ALL_MEMBERSHIPS_COUNTS_ERROR: (state, action) => {
    state.all_memberships_counts['error'] = action.payload;
  },
  // My earning sales
  MY_EARNING_SALES_LOADING: (state, action) => {
    state.my_earning_sales['loading'] = action.payload;
  },
  MY_EARNING_SALES_SET: (state, action) => {
    state.my_earning_sales['value'] = action.payload;
    state.my_earning_sales['is_fetched'] = true;
  },
  MY_EARNING_SALES_ERROR: (state, action) => {
    state.my_earning_sales['error'] = action.payload;
  },
  // All earning sales
  All_EARNING_SALES_LOADING: (state, action) => {
    state.all_earning_sales['loading'] = action.payload;
  },
  All_EARNING_SALES_SET: (state, action) => {
    state.all_earning_sales['value'] = action.payload;
    state.all_earning_sales['is_fetched'] = true;
  },
  All_EARNING_SALES_ERROR: (state, action) => {
    state.all_earning_sales['error'] = action.payload;
  },
  // My bank details
  MY_BANK_DETAILS_LOADING: (state, action) => {
    state.my_bank_details['loading'] = action.payload;
  },
  MY_BANK_DETAILS_SET: (state, action) => {
    state.my_bank_details['value'] = action.payload;
    state.my_bank_details['is_fetched'] = true;
  },
  MY_BANK_DETAILS_ERROR: (state, action) => {
    state.my_bank_details['error'] = action.payload;
  },
  // Update My bank details
  UPDATE_MY_BANK_DETAILS_LOADING: (state, action) => {
    state.update_my_bank_details['loading'] = action.payload;
  },
  UPDATE_MY_BANK_DETAILS_SET: (state, action) => {
    state.update_my_bank_details['value'] = action.payload;
    state.update_my_bank_details['is_fetched'] = true;
  },
  UPDATE_MY_BANK_DETAILS_ERROR: (state, action) => {
    state.update_my_bank_details['error'] = action.payload;
  },
  // pay the earned money to the user (for referrer)
  PAY_EARNED_MONEY_LOADING: (state, action) => {
    state.pay_earned_money['loading'] = action.payload;
  },
  PAY_EARNED_MONEY_SET: (state, action) => {
    state.pay_earned_money['value'] = action.payload;
    state.pay_earned_money['is_fetched'] = true;
  },
  PAY_EARNED_MONEY_ERROR: (state, action) => {
    state.pay_earned_money['error'] = action.payload;
  },
  // get -- money_transactions
  MONEY_TRANSACTIONS_LOADING: (state, action) => {
    state.money_transactions['loading'] = action.payload;
  },
  MONEY_TRANSACTIONS_SET: (state, action) => {
    state.money_transactions['value'] = action.payload;
    state.money_transactions['is_fetched'] = true;
  },
  MONEY_TRANSACTIONS_ERROR: (state, action) => {
    state.money_transactions['error'] = action.payload;
  },
  //  -- add_membership
  ADD_MEMBERSHIP_LOADING: (state, action) => {
    state.add_membership['loading'] = action.payload;
  },
  ADD_MEMBERSHIP_SET: (state, action) => {
    state.add_membership['value'] = action.payload;
    state.add_membership['is_fetched'] = true;
  },
  ADD_MEMBERSHIP_ERROR: (state, action) => {
    state.add_membership['error'] = action.payload;
  },
  // get -- all_stats
  ALL_STATS_LOADING: (state, action) => {
    state.all_stats['loading'] = action.payload;
  },
  ALL_STATS_SET: (state, action) => {
    state.all_stats['value'] = action.payload;
    state.all_stats['is_fetched'] = true;
  },
  ALL_STATS_ERROR: (state, action) => {
    state.all_stats['error'] = action.payload;
  },
  // get -- get_offer
  GET_OFFER_LOADING: (state, action) => {
    state.get_offer['loading'] = action.payload;
  },
  GET_OFFER_SET: (state, action) => {
    state.get_offer['value'] = action.payload;
    state.get_offer['is_fetched'] = true;
  },
  GET_OFFER_ERROR: (state, action) => {
    state.get_offer['error'] = action.payload;
  }
});
