export default {
  MEMBERSHIP_1: {
    type: 'MEMBERSHIP_1',
    name: 'Course',
    membership_name: 'Course Membership',
    certificateName: 'Course Mastery Certificate'
  },
  MEMBERSHIP_2: {
    type: 'MEMBERSHIP_2',
    name: 'Internship',
    membership_name: 'Internship membership',
    certificateName: 'Internship Certificate'
  },
  MEMBERSHIP_3: {
    type: 'MEMBERSHIP_3',
    name: 'Post Graduation',
    membership_name: 'AntShade Stock Trading Program',
    certificateName: 'Industry Recognized Certificate'
  }
};
