import React from 'react';

import PropTypes from 'prop-types';
// MUI
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import StarIcon from '@material-ui/icons/Star';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row'
  },
  starYellow: {
    color: '#ffb400'
  },
  starBlack: {
    color: '#5f6268'
  }
}));
function ReadOnlyStar(props) {
  const classes = useStyles();

  const getStars = (count) => {
    let stars = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= count) {
        stars.push(<StarIcon className={classes.starYellow} />);
      } else {
        stars.push(<StarIcon className={classes.starBlack} />);
      }
    }
    return stars;
  };

  return <div className={classes.root}>{getStars(props.count)}</div>;
}

ReadOnlyStar.propTypes = {
  count: PropTypes.string
};

export default ReadOnlyStar;
